import React from 'react';
import { Row, Column } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { ImageInformation } from './ImageInformation';
import { FileModel } from '@nimles/models';

const Images = styled.div`
  margin-bottom: 10px;
`;

const ImageButton = styled.button<{ isSelected: boolean }>`
  position: relative;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ isSelected }) =>
    isSelected ? '2px solid green' : '1px solid lightgrey'};
  background-color: ${({ isSelected }) =>
    isSelected ? 'lightgreen' : '#efefef'};

  &::hover > div {
    display: block;
  }
`;

const Image = styled.img`
  width: 100%;
`;

interface ImageGridProps {
  selected?: string;
  onSelect: (image: FileModel) => void;
  images: FileModel[];
}

export const ImageGrid = ({ selected, onSelect, images }: ImageGridProps) => {
  return (
    <Images>
      <Row wrap>
        {images &&
          images.map((image) => {
            return (
              <Column padding xs={100} sm={50} md={33} lg={25}>
                <ImageButton
                  isSelected={selected === image.id}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelect(image);
                  }}
                >
                  <ImageInformation image={image} />
                  <Image
                    src={
                      image.thumbnailUri ??
                      `https://media.nimles.com/file/${image.tenantId}/${image.id}?width=480`
                    }
                  />
                </ImageButton>
              </Column>
            );
          })}
      </Row>
    </Images>
  );
};
