import { FC, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Formik, FormikValues, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import {
  Column,
  Row,
  Modal,
  ModalBody,
  IconButton,
} from '@nimles/react-web-components';
import {
  InputField,
  ReactSelectField,
  TextAreaField,
} from '@nimles/react-web-forms';
import { QuillField } from '../../../components/fields/QuillField';
import { LocalizedField } from '../../../components/LocalizedField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { EditorActions } from '../../../components/Editor';
import {
  ProductModel,
  OrganizationModel,
  ProductGroupModel,
  ProductPropertyModel,
  ProductPropertyValueModel,
} from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { ProductPropertyField } from '../../../components/fields/ProductPropertyField';
import { ImageIdsField } from '../../../components/fields/ImageIdsField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  product: ProductModel;
  organizations: OrganizationModel[];
  productGroups: ProductGroupModel[];
  productProperties: ProductPropertyModel[];
  onSave: (product: any, publish: boolean) => Promise<void>;
}

interface PropertyInputProps {
  name: string;
  label?: string;
  required?: boolean;
}

const LocalizedFields = {
  en: '',
  sv: '',
};

const properties = [
  'name',
  'sku',
  'gtin',
  'mpn',
  'condition',
  'color',
  'size',
  'multiPack',
  'minEnergyEfficiencyClass',
  'maxEnergyEfficiencyClass',
  'listPrice',
  'netPrice',
  'grossPrice',
] as const;

export const ProductEditor: FC<Props> = ({
  product,
  organizations,
  productGroups,
  productProperties,
  onSave,
}) => {
  const variants = product.variants;
  const [publish, setPublish] = useState(false);
  const [variantProperties, setVariantProperties] = useState(
    properties.reduce(
      (result, property: typeof properties[number]) => ({
        ...result,
        [property]: variants?.some((variant) => variant[property]),
      }),
      {}
    )
  );

  const [variantToRemove, setVariantToRemove] = useState<number>(-1);

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const product = {
        ...values,
      };
      await onSave(product, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error: any) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: ProductModel = product && {
    id: product.id,
    imageIds: product.imageIds?.map((x) => x) || [],
    name: product.name || '',
    nameLocalized: { ...LocalizedFields, ...product.nameLocalized },
    longName: product.longName || '',
    longNameLocalized: { ...LocalizedFields, ...product.longNameLocalized },
    additionalName: product.additionalName || '',
    additionalNameLocalized: {
      ...LocalizedFields,
      ...product.additionalNameLocalized,
    },
    summary: product.summary || '',
    summaryLocalized: { ...LocalizedFields, ...product.summaryLocalized },
    description: product.description || '',
    descriptionLocalized: {
      ...LocalizedFields,
      ...product.descriptionLocalized,
    },
    uniqueName: product.uniqueName || '',
    properties: product.properties?.map((x) => x) || [],
    productGroupIds: product.productGroupIds?.map((x) => x) || [],
    organizationId: product.organizationId,
    sku: product.sku || '',
    mpn: product.mpn || '',
    gtin: product.gtin || '',
    condition: product.condition || '',
    color: product.color || '',
    size: product.size || '',
    sizeType: product.sizeType || '',
    sizeSystem: product.sizeSystem || '',
    minEnergyEfficiencyClass: product.minEnergyEfficiencyClass || '',
    maxEnergyEfficiencyClass: product.maxEnergyEfficiencyClass || '',
    variants:
      product.variants?.map((x) => ({
        ...x,
        imageIds: x.imageIds || [],
      })) || [],
  };

  const PropertyInputField = ({
    name,
    label,
    required,
  }: PropertyInputProps) => {
    const propertyName = name
      .split('.')[0]
      .toLowerCase()
      .replace('localized', '');
    return (
      <Row alignItems="flex-end">
        <Column flex>
          <InputField name={name} label={label} required={required} />
        </Column>
        <Column>
          <IconButton
            type="button"
            primary={!!variantProperties[propertyName]}
            onClick={(e) => {
              e.preventDefault();
              setVariantProperties((prev) => ({
                ...prev,
                [propertyName]: !prev[propertyName],
              }));
            }}
          >
            <FontAwesomeIcon icon={faLayerGroup} />
          </IconButton>
        </Column>
      </Row>
    );
  };

  const VariantPropertyInputField = ({
    index,
    name,
    label,
  }: {
    index: number;
    name: string;
    label: string;
  }) =>
    variantProperties[name] ? (
      <InputField name={`variants.${index}.${name}`} label={label} />
    ) : null;

  const LocalizedVariantPropertyInputField = ({
    index,
    name,
    label,
  }: {
    index: number;
    name: string;
    label: string;
  }) =>
    variantProperties[name] ? (
      <LocalizedField label={label} name={`variants.${index}.${name}`}>
        {({ localizedName }) => <InputField name={localizedName} />}
      </LocalizedField>
    ) : null;

  return product ? (
    <Formik<ProductModel>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => {
        // useEffect(() => {
        //   const unsetProperties = Object.entries(variantProperties)
        //     .filter(([key, value]) => !value)
        //     .map(([key]) => key);

        //   values.variants.forEach((variant) =>
        //     unsetProperties.forEach((property) => {
        //       if (variant[property]) {
        //         variant[property] = undefined;
        //       }
        //     })
        //   );
        // }, [variantProperties]);

        return (
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onPublish={() => {
                setPublish(true);
                submitForm();
              }}
              onSave={() => {
                setPublish(false);
                submitForm();
              }}
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Information</legend>
                  {values.id && (
                    <InputField name="id" label="Id" disabled required />
                  )}
                  <OrganizationSelectField
                    organizations={organizations}
                    defaultOrganizationId={product.organizationId}
                  />
                  <LocalizedField label="Name" name="name">
                    {({ localizedName }) => (
                      <PropertyInputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <LocalizedField label="Long name" name="longName">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <InputField name="uniqueName" label="Unique name" />
                  <LocalizedField label="Additional name" name="additionalName">
                    {({ localizedName }) => <InputField name={localizedName} />}
                  </LocalizedField>
                  <PropertyInputField name="sku" label="SKU" />
                  <PropertyInputField name="gtin" label="GTIN" />
                  <PropertyInputField name="mpn" label="MPN" />
                </fieldset>
              </Column>
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Images</legend>
                  <ImageIdsField name="imageIds" />
                </fieldset>
              </Column>
            </Row>
            <ReactSelectField
              name="productGroupIds"
              label="Product Groups"
              placeholder="Select product groups"
              options={productGroups}
              optionLabel={(o) => o.name}
              optionValue={(o) => o.id}
              isMulti
            />
            <LocalizedField label="Summary" name="summary">
              {({ localizedName }) => (
                <TextAreaField name={localizedName} wordCount />
              )}
            </LocalizedField>
            <LocalizedField label="Description" name="description">
              {({ localizedName }) => <QuillField name={localizedName} />}
            </LocalizedField>
            <Row>
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Properties</legend>
                  <PropertyInputField name="condition" label="Condition" />
                  <PropertyInputField name="color" label="Color" />
                  <FieldArray
                    name="properties"
                    render={(arrayHelpers) => (
                      <div>
                        {values.properties?.map(
                          (property: ProductPropertyValueModel, index: any) => (
                            <ProductPropertyField
                              productProperties={productProperties}
                              name={`properties.${index}`}
                              productPropertyValue={property}
                            />
                          )
                        )}
                        <Row wrap="wrap" margin="5px -5px">
                          {productProperties
                            .filter(
                              ({ id }) =>
                                !values.properties?.some(
                                  ({ productPropertyId }) =>
                                    id === productPropertyId
                                )
                            )
                            .map((property) => (
                              <Column padding={5}>
                                <Button
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      productPropertyId: property.id,
                                    })
                                  }
                                >
                                  {property.name}
                                </Button>
                              </Column>
                            ))}
                        </Row>
                      </div>
                    )}
                  />
                </fieldset>
              </Column>
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Size</legend>
                  <PropertyInputField name="size" label="Size" />
                  <Row>
                    <Column xs={50}>
                      <InputField name="sizeType" label="Size type" />
                    </Column>
                    <Column xs={50}>
                      <InputField name="sizeSystem" label="Size system" />
                    </Column>
                  </Row>
                  <PropertyInputField name="multiPack" label="MultiPack" />
                </fieldset>
                <fieldset>
                  <legend>Energy</legend>
                  <PropertyInputField
                    name="minEnergyEfficiencyClass"
                    label="Min energy efficiency class"
                  />
                  <PropertyInputField
                    name="maxEnergyEfficiencyClass"
                    label="Max energy efficiency class"
                  />
                </fieldset>
                <fieldset>
                  <legend>Prices</legend>
                  <PropertyInputField name="listPrice" label="List price" />
                  <PropertyInputField name="netPrice" label="Net price" />
                  <PropertyInputField name="grossPrice" label="Gross price" />
                </fieldset>
              </Column>
            </Row>

            <h2>Variants</h2>
            <FieldArray
              name="variants"
              render={(arrayHelpers) => (
                <Row
                  wrap="wrap"
                  style={{
                    background: 'rgba(127,127,127, 0.2)',
                    padding: '15px 0',
                    borderRadius: '0.25rem',
                  }}
                >
                  {values.variants?.map(
                    (
                      variant: { properties: { productPropertyId: any }[] },
                      index: number
                    ) => (
                      <Column
                        xs={100}
                        lg={variantProperties['images'] ? 100 : 50}
                      >
                        <Card>
                          <CardHeader>
                            <CardTitle>Variant {index + 1}</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Column
                                xs={100}
                                lg={variantProperties['images'] ? 50 : 100}
                              >
                                <LocalizedVariantPropertyInputField
                                  index={index}
                                  name="name"
                                  label="Name"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="sku"
                                  label="SKU"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="gtin"
                                  label="GTIN"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="mpn"
                                  label="MPN"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="condition"
                                  label="Condition"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="color"
                                  label="Color"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="size"
                                  label="Size"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="multiPack"
                                  label="MultiPack"
                                />
                                {variant.properties?.map(
                                  ({ productPropertyId }: any, pIndex: any) => (
                                    <InputField
                                      name={`variants.${index}.properties.${pIndex}.numberValue`}
                                      label={
                                        productProperties?.find(
                                          (pp) => pp.id === productPropertyId
                                        )?.name ?? undefined
                                      }
                                    />
                                  )
                                ) ?? null}
                                <VariantPropertyInputField
                                  index={index}
                                  name="listPrice"
                                  label="List price"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="netPrice"
                                  label="Net price"
                                />
                                <VariantPropertyInputField
                                  index={index}
                                  name="grossPrice"
                                  label="Gross price"
                                />
                              </Column>
                              {variantProperties['images'] ? (
                                <Column xs={100} lg={50}>
                                  <fieldset>
                                    <legend>Images</legend>
                                    <ImageIdsField
                                      name={`variants.${index}.imageIds`}
                                    />
                                  </fieldset>
                                </Column>
                              ) : null}
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <Button
                              type="button"
                              color="danger"
                              size="sm"
                              onClick={() => setVariantToRemove(index)}
                            >
                              Remove variant
                            </Button>
                          </CardFooter>
                        </Card>
                      </Column>
                    )
                  )}
                  <Column xs={100}>
                    <Row>
                      <Column>
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => arrayHelpers.push({})}
                        >
                          Add variant
                        </Button>
                      </Column>
                    </Row>
                  </Column>

                  <Modal
                    open={variantToRemove !== -1}
                    onClose={() => setVariantToRemove(-1)}
                  >
                    <ModalHeader>Remove variant</ModalHeader>
                    <ModalBody>
                      Do you want to remove variant{' '}
                      {values.variants?.[variantToRemove]?.name ??
                        variantToRemove + 1}
                      ?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="danger"
                        onClick={() => {
                          arrayHelpers.remove(variantToRemove);
                          setVariantToRemove(-1);
                        }}
                      >
                        Remove
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Row>
              )}
            />
          </Form>
        );
      }}
    />
  ) : null;
};
