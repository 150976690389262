import {
  setDeliveryOptionAccessibility,
  updateDeliveryOption,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { DeliveryOptionEditor } from './DeliveryOptionEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { useDeliveryOptions } from '../../../hooks/deliveryOptions';

interface Params {
  deliveryOptionId: string;
}

export const DeliveryOption: FC = () => {
  const dispatch = useDispatch();
  const { deliveryOptionId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { deliveryOptions } = useDeliveryOptions();
  const deliveryOption = deliveryOptions.find((o) => o.id === deliveryOptionId);

  const onSave = async (values: any) => {
    await dispatch(
      updateDeliveryOption({
        ...values,
      })
    );
  };

  const setAccessibility = async (accessibility: any) => {
    await dispatch(
      deliveryOption?.id &&
        setDeliveryOptionAccessibility(deliveryOption.id, accessibility)
    );
  };

  return deliveryOption && organizations.length ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Delivery Option</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={deliveryOption.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <DeliveryOptionEditor
            deliveryOption={deliveryOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
