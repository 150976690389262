import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { usePages } from '../../../hooks/pages';

const pageTypes = [
  'Undefined',
  'FirstPage',
  'About',
  'Contact',
  'Locations',
  'TermsOfSales',
  'Information',
  'Catalog',
  'Map',
  'NotFound',
  'SignUp',
  'SignIn',
  'VerifyEmail',
  'ForgotPassword',
  'ResetPassword',
  'RegisterOrganization',
  'Blog',
];

export const Pages: FC = () => {
  const history = useHistory();

  const { pages } = usePages();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Pages
              <div className="card-header-actions">
                <UncontrolledDropdown>
                  <DropdownToggle>New Page</DropdownToggle>
                  <DropdownMenu>
                    {pageTypes.map((type) => (
                      <DropdownItem
                        key={type}
                        tag={Link}
                        to={`/cms/pages/new?type=${type}`}
                      >
                        {type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {pages &&
                  pages.map((page) => {
                    const pageLink = `/cms/pages/${page.id}`;
                    return (
                      <tr key={page.id?.toString()}>
                        <td>
                          <Link to={pageLink}>
                            <div>
                              <strong>{page.title}</strong>
                            </div>
                            <div>
                              <small>{page.id}</small>
                            </div>
                            <div>{page.uniqueName}</div>
                          </Link>
                        </td>
                        <td>{getOrganizationName(page.organizationId)}</td>
                        <td>{page.pageType}</td>
                        <td>{page.accessibility}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle nav>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/cms/pages/new?pageId=${page.id}`
                                  )
                                }
                              >
                                Clone
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
