import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useRelationships } from '../../../hooks/relationships';

export const Relationships: FC = () => {
  const { relationships } = useRelationships();

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Relationships
              <div className="card-header-actions">
                <Link to="/crm/contacts/new" className="btn btn-sm btn-primary">
                  New Relationship
                </Link>
              </div>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Belongs To</th>
                  </tr>
                </thead>
                <tbody>
                  {relationships &&
                    relationships.map((relationship) => {
                      const relationshipLink = `/crm/contacts/${relationship.id}`;
                      return (
                        <tr key={relationship.id?.toString()}>
                          <th scope="row">
                            <Link to={relationshipLink}>
                              <div>
                                {relationship.contact?.name}
                                {relationship.contact?.firstName}{' '}
                                {relationship.contact?.lastName}
                              </div>
                              <div>
                                <small>{relationship.id}</small>
                              </div>
                            </Link>
                          </th>
                          <td>{relationship.contact?.email}</td>
                          <td>
                            {relationship.contact?.phoneNumbers?.map(
                              (phoneNumber) => (
                                <div>{phoneNumber}</div>
                              )
                            )}
                          </td>
                          <td>
                            {relationship.parentId
                              ? relationships.find(
                                  (r) => r.id === relationship.parentId
                                )?.contact?.name
                              : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
