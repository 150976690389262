import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Table,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap';
import { publishProduct } from '@nimles/react-redux';
import { useDispatch } from 'react-redux';
import { Row, Column, Input } from '@nimles/react-web-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import {
  OrganizationModel,
  ProductGroupModel,
  ProductModel,
} from '@nimles/models';
import {
  useOrganizations,
  useProductGroups,
  useProducts,
} from '../../../hooks';

interface Option<TValue> {
  label?: string | null;
  value?: TValue | null;
}

export const Products: FC = () => {
  const dispatch = useDispatch();
  const { organizations } = useOrganizations();
  const { products } = useProducts();
  const { productGroups } = useProductGroups();
  const [query, setQuery] = useState<string>();
  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [tags, setTags] = useState<Option<string>[]>([]);
  const [selectedTags, selectTags] = useState<Option<string>[]>([]);
  const [selectedOrganizations, selectOrganizations] = useState<
    Option<OrganizationModel>[]
  >([]);
  const [selectedProductGroups, selectProductGroups] = useState<
    Option<ProductGroupModel>[]
  >([]);

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  const getProductGroupName = (productGroupId?: string | null) => {
    const productGroup = productGroups.find((o) => o.id === productGroupId);

    return productGroup ? productGroup.name : productGroupId;
  };

  useEffect(() => {
    setFilteredProducts(
      products.filter(
        (prod) =>
          (query
            ? prod.uniqueName?.includes(query.toLowerCase()) ||
              prod.name?.toLowerCase().includes(query.toLowerCase())
            : true) &&
          (selectedTags?.length
            ? selectedTags.some(
                (tag) => tag.value && prod.tags?.includes(tag.value)
              )
            : true) &&
          (selectedOrganizations?.length
            ? selectedOrganizations.some(
                (org) => org.value === prod.organizationId
              )
            : true) &&
          (selectedProductGroups?.length
            ? selectedProductGroups.some(
                (pg) =>
                  pg.value?.id && prod.productGroupIds?.includes(pg.value.id)
              )
            : true)
      )
    );
  }, [
    products,
    query,
    selectedTags,
    selectedOrganizations,
    selectedProductGroups,
  ]);

  useEffect(() => {
    setTags(
      products
        .reduce(
          (list, prod) => (prod.tags ? [...list, ...prod.tags] : list),
          []
        )
        .sort()
        .reduce<{ name: string; count: number }[]>((items, name) => {
          let item = items.find((i) => i.name === name);
          if (item) {
            item.count++;
          } else {
            items.push({ name, count: 1 });
          }
          return items;
        }, [])
        .map(({ name, count }) => ({
          label: `${name} (${count})`,
          value: name,
        }))
    );
  }, [products, query]);

  const actions = [
    {
      label: 'Publish',
      onClick: (product: ProductModel) =>
        product.id && dispatch(publishProduct(product.id)),
    },
    {
      label: 'Delete',
      onClick: (product: ProductModel) =>
        product.id && dispatch(publishProduct(product.id)),
    },
  ];

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Products
          <div className="card-header-actions">
            <Link to="/pim/products/new" className="btn btn-sm btn-primary">
              New Product
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <Row wrap="wrap">
            <Column xs={100} md={50}>
              <FormGroup>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search..."
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={tags}
                  value={selectedTags}
                  onChange={(value: Option<string>[]) => selectTags(value)}
                  placeholder="Select tags"
                  isMulti
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={organizations
                    .filter(({ id, name }) => id && name)
                    .map((org) => ({
                      label: org.name,
                      value: org,
                    }))}
                  value={selectedOrganizations}
                  onChange={(value: Option<OrganizationModel>[]) =>
                    selectOrganizations(value)
                  }
                  placeholder="Select organizations"
                  isMulti
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={productGroups.map((pg) => ({
                    label: pg.name,
                    value: pg,
                  }))}
                  value={selectedProductGroups}
                  onChange={(value: Option<ProductGroupModel>[]) =>
                    selectProductGroups(value)
                  }
                  placeholder="Select product groups"
                  isMulti
                />
              </FormGroup>
            </Column>
          </Row>
        </CardBody>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" style={{ textAlign: 'right' }}>
                Variants
              </th>
              <th scope="col">Organization</th>
              <th scope="col">Product Groups</th>
              <th scope="col">Accessibility</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts &&
              filteredProducts.map((product) => {
                const productLink = `/pim/products/${product.id}`;
                return (
                  <tr key={product.id?.toString()}>
                    <td>
                      <Link to={productLink}>
                        <div>
                          <strong>{product.name}</strong>
                        </div>
                        <div>
                          <small>{product.id}</small>
                        </div>
                        <div>{product.uniqueName}</div>
                      </Link>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {product.variants?.length}
                    </td>
                    <td>{getOrganizationName(product.organizationId)}</td>
                    <td>
                      {product.productGroupIds?.map((productGroupId) => (
                        <div>{getProductGroupName(productGroupId)}</div>
                      ))}
                    </td>
                    <td>{product.accessibility}</td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle nav>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </DropdownToggle>
                        <DropdownMenu>
                          {actions.map(({ label, onClick }) => (
                            <DropdownItem
                              key={label}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick(product);
                              }}
                            >
                              {label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
