import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilAddressBook,
  cilAddressCard,
  cilLeaf,
  cilTags,
  cilApps,
  cilBookmarks,
  cilGroup,
  cilBadge,
  cilScrubber,
  cilSitemap,
  cilLocationPin,
  cilBox,
  cilCreditCard,
  cilFilterSquare,
  cilFileImage,
  cilLineStyle,
  cilColorPalette,
  cilDocument,
  cilNewspaper,
  cilPaperclip,
  cilBell,
  cilSettingsAlt,
  cilText,
  cilChevronRightAlt,
  cilCart,
  cilSchool,
  cilTranslate,
} from '@coreui/icons-pro/js/linear';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilAddressBook,
    cilAddressCard,
    cilLeaf,
    cilTags,
    cilApps,
    cilBookmarks,
    cilGroup,
    cilBadge,
    cilScrubber,
    cilSitemap,
    cilLocationPin,
    cilBox,
    cilCreditCard,
    cilFilterSquare,
    cilFileImage,
    cilLineStyle,
    cilColorPalette,
    cilDocument,
    cilNewspaper,
    cilPaperclip,
    cilBell,
    cilSettingsAlt,
    cilText,
    cilChevronRightAlt,
    cilCart,
    cilSchool,
    cilTranslate,
  }
);
