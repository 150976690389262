import React, { useEffect } from 'react';
import { userManager } from '../App';

export const Login = () => {
  useEffect(() => {
    userManager.signinRedirect();
  }, []);

  return <div></div>;
};
