import React, { FC, useState } from 'react';
import { CustomInputProps, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field, FieldProps } from 'formik';
import { SelectImageModal } from '../SelectImageModal';
import { ImageButton, ImagePlaceholder } from '../ImageButton';
import { ImagePreview } from '../images';

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div key={name}>
      {props.label ? <Label for={name}>{props.label}</Label> : null}
      <FormGroup>
        <ImageButton
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          {value ? (
            <ImagePreview value={value} />
          ) : (
            <ImagePlaceholder>Select Image</ImagePlaceholder>
          )}
        </ImageButton>
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
      <SelectImageModal
        title="Select Logo"
        isOpen={isModalOpen}
        onToggle={() => setModalOpen(!isModalOpen)}
        onSelect={(image) => {
          if (image.id) {
            setFieldValue(name, image.id);
          }
          setModalOpen(false);
        }}
      />
    </div>
  );
};

export const ImageIdField = (props: any) => (
  <Field {...props} component={StandardInput} />
);
