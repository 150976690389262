import { Column, Row } from '@nimles/react-web-components';
import { InputField, TextAreaField } from '@nimles/react-web-forms';
import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { EditorActions } from '../../../components/Editor';
import { OrganizationMemberModel, OrganizationModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  organizationMember: OrganizationMemberModel;
  organizations: OrganizationModel[];
  onSave: (member: any) => Promise<void>;
}

export const MemberEditor: FC<Props> = ({
  organizationMember,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = organizationMember && {
    id: organizationMember.id,
    organizationId: organizationMember.organizationId,
    firstName: organizationMember.firstName || '',
    lastName: organizationMember.lastName || '',
    description: organizationMember.description || '',
    email: organizationMember.email || '',
    phoneNumber: organizationMember.phoneNumber || '',
  };

  return organizationMember ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <>
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            {values.id && <InputField name="id" label="Id" disabled={true} />}
            <OrganizationSelectField
              organizations={organizations}
              defaultOrganizationId={organizationMember.organizationId}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <InputField
                  label="First name"
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  required
                />
              </Column>
              <Column xs={100} lg={50}>
                <InputField
                  label="Last name"
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  required
                />
              </Column>
            </Row>
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <InputField name="title" label="Title" placeholder="Title" />
                <InputField name="email" label="Email" placeholder="Email" />
                <InputField
                  name="phoneNumber"
                  label="Phone number"
                  placeholder="Phone number"
                />
              </Column>
              <Column xs={100} lg={50} justify="center"></Column>
            </Row>
            <TextAreaField
              name="description"
              label="Description"
              placeholder="Description"
            />
          </Form>
        </>
      )}
    />
  ) : null;
};
