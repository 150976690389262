import React, { FC } from 'react';
import { ImageSelector } from './ImageSelector';
import { ImageUpload } from './ImageUpload';
import { useDispatch } from 'react-redux';
import { createFile } from '@nimles/react-redux';
import { ImagePreview } from './ImagePreview';
import { FileModel } from '@nimles/models';

interface Props {
  value?: string | null;
  onChange: (image: FileModel) => void;
  organizationId?: string;
  mimeTypes?: string[];
}

export const ImageEditor: FC<Props> = ({
  value,
  onChange,
  organizationId,
  mimeTypes,
}) => {
  const dispatch = useDispatch();

  const imageId = value
    ?.split('/')
    .reverse()
    .find((x) => x);

  const handleUpload = async (data: any, file: File) => {
    await dispatch(
      createFile({
        uri: data,
        name: file.name,
        mimeType: file.type,
        size: file.size,
        fileName: file.name,
        organizationId,
        accessibility: 'Public',
      })
    );
  };

  return (
    <div>
      {value && <ImagePreview value={value} />}

      <ImageSelector
        selected={imageId}
        onSelect={onChange}
        mimeTypes={mimeTypes}
      />
      <ImageUpload onUpload={handleUpload} />
    </div>
  );
};
