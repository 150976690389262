import { Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import React, { FC, ReactNode } from 'react';
import { CultureSelector } from './CultureSelector';
import { Row, Column } from '@nimles/react-web-components';
import { CultureState, RootState } from '../redux/types';
import { TranslatorField } from './TranslatorField';

interface Props {
  name: string;
  label?: string;
  selector?: boolean;
  translator?: boolean;
  children: ({
    name,
    localizedName,
    culture,
  }: {
    name: string;
    localizedName: string;
    culture?: string;
  }) => ReactNode;
}

export const LocalizedField: FC<Props> = ({
  label,
  children,
  selector = true,
  translator = true,
  ...props
}) => {
  const { values: cultures, selected: selectedCulture } = useSelector<
    RootState,
    CultureState
  >(({ culture }) => culture);

  const name = props.name;
  const parts = name.split('.');

  parts[parts.length - 1] = parts[parts.length - 1] + 'Localized';

  const nameLocalized = parts.join('.');

  return (
    <>
      {selector || translator || label ? (
        <Row justify="flex-end" align="flex-end">
          {label ? (
            <Column flex>
              <Label for={name}>{label}</Label>
            </Column>
          ) : null}
          {selector ? (
            <Column>
              <CultureSelector size="xs" />
            </Column>
          ) : null}
          {translator ? (
            <Column>
              <TranslatorField {...props} />
            </Column>
          ) : null}
        </Row>
      ) : null}
      {!selectedCulture
        ? children({
            name: name,
            localizedName: name,
          })
        : null}
      {cultures.map((culture) =>
        selectedCulture === culture
          ? children({
              name: nameLocalized,
              localizedName: nameLocalized + '.' + culture,
              culture,
            })
          : null
      )}
    </>
  );
};
