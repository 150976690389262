import {
  setProductStockAccessibility,
  updateProductStock,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { ProductStockEditor } from './ProductStockEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { useProductStocks } from '../../../hooks/productStocks';

interface Params {
  productStockId: string;
}

export const ProductStock: FC = () => {
  const dispatch = useDispatch();
  const { productStockId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { productStocks } = useProductStocks();
  const productStock = productStocks.find((o) => o.id === productStockId);

  const onSave = async (values: any) => {
    await dispatch(
      updateProductStock({
        ...values,
      })
    );
  };

  const setAccessibility = async (accessibility: any) => {
    await dispatch(
      productStock?.id &&
        setProductStockAccessibility(productStock.id, accessibility)
    );
  };

  return productStock && organizations.length ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <strong>Product stock</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={productStock.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ProductStockEditor
            productStock={productStock}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
