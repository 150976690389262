import { VehicleModel } from '@nimles/models';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { DisplayFields } from '../../../components/entities/DisplayFields';
import { Price } from '../../../components/Price';
import { useOrders } from '../../../hooks/orders';
import { useOrganizations } from '../../../hooks/organizations';
import { useUsers } from '../../../hooks/users';
import { RootState } from '../../../redux/types';

interface Params {
  orderId: string;
}

export const Order: FC = () => {
  const { orderId } = useParams<Params>();
  const { orders } = useOrders();
  const { users } = useUsers();
  const vehicles = useSelector<RootState, VehicleModel[]>(
    ({ vehicles }) => vehicles.values
  );
  const order = orders.find((o) => o.id === orderId);
  const { organizations } = useOrganizations();
  console.log('ORDER ', order);

  if (!order) {
    return null;
  }
  const orderHead = order.head;

  let organization;
  if (orderHead?.seller?.organizationId) {
    organization = organizations.find(
      (org) => org.id === orderHead.seller?.organizationId
    );
  }

  let user;
  if (orderHead?.buyer?.userId) {
    user = users && users.find((user) => user.id === orderHead.buyer?.userId);
  }

  console.log(vehicles.values);

  const orderButton = '';

  const { buyer, seller } = order?.head || {};

  console.log(order);
  return order && orderHead ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <h4>{orderHead.orderNumber}</h4>
          <p>Order Id: {order.id}</p>
          <Row>
            <Col sm={6}>
              <fieldset>
                <legend>Buyer</legend>
                {buyer && (
                  <div>
                    <p>
                      Name: {buyer.firstName} {buyer.lastName}
                    </p>
                    <p>Email: {buyer.email}</p>
                    {buyer.deliveryAddress && (
                      <p>
                        Adress: {buyer.deliveryAddress.street},{' '}
                        {buyer.deliveryAddress.postalCode}{' '}
                        {buyer.deliveryAddress.city}
                      </p>
                    )}
                  </div>
                )}
                {user && (
                  <div>
                    <h5>User</h5>
                    <p>User Id:{user.id}</p>
                    <p>
                      Name: {user.firstName} {user.lastName}
                    </p>
                    <p>Email: {user.email}</p>
                    {user.address && (
                      <p>
                        Adress: {user.address.street}, {user.address.postalCode}{' '}
                        {user.address.city}
                      </p>
                    )}
                  </div>
                )}
              </fieldset>
            </Col>
            <Col sm={6}>
              <fieldset>
                <legend>Seller</legend>
                {seller && (
                  <div>
                    <p>Company name: {organization?.name}</p>
                  </div>
                )}
              </fieldset>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
        </CardHeader>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            {order.lines &&
              order.lines.map((line) => {
                return (
                  <tr key={line.id?.toString()}>
                    <th scope="row">{line.name}</th>
                    <td>{line.quantity}</td>
                    <td>
                      <Price
                        currency={orderHead.currency}
                        value={line.totalNetPrice}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <td></td>
              <th>
                <Price
                  currency={orderHead.currency}
                  value={orderHead.totalNetPrice}
                />
              </th>
            </tr>
          </tfoot>
        </Table>
        <CardBody>
          <fieldset>
            <legend>Message</legend>
            {orderHead.message && (
              <div
                dangerouslySetInnerHTML={{ __html: orderHead?.message }}
              ></div>
            )}
          </fieldset>
          <DisplayFields fields={order.fields} />
          {orderButton}
        </CardBody>
      </Card>
    </div>
  ) : null;
};
