import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { useOrganizations } from '../../../hooks/organizations';
import { useLogisticsOptions } from '../../../hooks/logisticsOptions';

export const LogisticsOptions: FC = () => {
  const { logisticsOptions } = useLogisticsOptions();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              Logistics Options
              <div className="card-header-actions">
                <Link
                  to="/ecommerce/logisticsOptions/new"
                  className="card-header-action"
                >
                  Add Logistics Option
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {logisticsOptions &&
                  logisticsOptions.map((logisticsOption: any) => {
                    const logisticsOptionLink = `/ecommerce/logisticsOptions/${logisticsOption.id}`;

                    return (
                      <tr
                        key={
                          logisticsOption.id && logisticsOption.id.toString()
                        }
                      >
                        <th scope="row">
                          <Link to={logisticsOptionLink}>
                            {logisticsOption.id}
                          </Link>
                        </th>
                        <td>
                          <Link to={logisticsOptionLink}>
                            {logisticsOption.name}
                          </Link>
                        </td>
                        <td>
                          {getOrganizationName(logisticsOption.organizationId)}
                        </td>
                        <td>{logisticsOption.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
