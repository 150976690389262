import React, { FC, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectOrganization, State } from '@nimles/react-redux';
import { RootState } from '../../redux/types';
import CIcon from '@coreui/icons-react';
import { css } from '@emotion/react';
import { OrganizationModel } from '@nimles/models';

export const OrganizationHeaderDropdown: FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { values: organizations, selected } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);

  const handleSelectOrganization = async (
    organization?: OrganizationModel | any
  ) => {
    await dispatch(selectOrganization(organization));
  };

  return (
    <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle nav>
        <CIcon name="cil-sitemap" />
      </DropdownToggle>
      <DropdownMenu>
        {selected ? (
          <DropdownItem
            css={css`
              flex-direction: column;
            `}
            onClick={(e) => {
              e.preventDefault();
              handleSelectOrganization(undefined);
            }}
            active
          >
            <div>
              <div>{selected.name}</div>
              <div>
                <small>{selected.id}</small>
              </div>
            </div>
          </DropdownItem>
        ) : null}
        {organizations
          .filter((organization) => organization.id !== selected?.id)
          .slice(0, 10)
          .map((organization) => {
            return (
              <DropdownItem
                key={'dropdown-organizations-' + organization.id}
                css={css`
                  flex-direction: column;
                `}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectOrganization(organization);
                }}
              >
                <div>
                  <div>{organization.name}</div>
                  <div>
                    <small>{organization.id}</small>
                  </div>
                </div>
              </DropdownItem>
            );
          })}
        <DropdownItem>
          <Link
            to="/organization-management/organizations"
            className="btn btn-sm btn-primary"
          >
            See all
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
