import React, { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useCourses } from '../../../hooks/courses';

const courseTypes = ['Web', 'App', 'Header', 'Footer', 'Undefined'];

export const Courses: FC = () => {
  const history = useHistory();

  const { courses } = useCourses();
  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const courseType = query?.get('courseType?');

  const selectedCourses = courseType
    ? courses.filter((course: any) => course.courseType === courseType)
    : courses;

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Courses
              <div className="card-header-actions">
                <UncontrolledDropdown>
                  <DropdownToggle size="sm" primary>
                    New Course
                  </DropdownToggle>
                  <DropdownMenu>
                    {courseTypes.map((type) => (
                      <DropdownItem
                        key={type}
                        tag={Link}
                        to={`/lms/courses/new?type=${type}`}
                      >
                        {type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <Nav tabs>
              <NavItem>
                <NavLink tag={Link} to="/lms/courses" active={!courseType}>
                  All
                </NavLink>
              </NavItem>
              {courseTypes.map((type) => (
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={`/lms/courses?courseType=${type}`}
                    active={courseType === type}
                  >
                    {type}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {selectedCourses &&
                  selectedCourses.map((course) => {
                    const courseLink = `/lms/courses/${course.id}`;
                    return (
                      <tr key={course.id?.toString()}>
                        <td>
                          <Link to={courseLink}>
                            <div>{course.name}</div>
                            {/* <small>{course.id}</small> */}
                          </Link>
                        </td>
                        <td>{getOrganizationName(course.organizationId)}</td>
                        <td>{course.courseType}</td>
                        <td>{course.accessibility}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle nav>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/lms/courses/new?courseId=${course.id}`
                                  )
                                }
                              >
                                Clone
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
