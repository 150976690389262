import styled from '@emotion/styled';

export const ImageButton = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 15px 0;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  min-height: 100px;
  flex-grow: 1;
  background: #ededed;
  border: 2px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
`;
