import {
  setPaymentOptionAccessibility,
  updatePaymentOption,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { PaymentOptionEditor } from './PaymentOptionEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { usePaymentOptions } from '../../../hooks/paymentOptions';

interface Params {
  paymentOptionId: string;
}

export const PaymentOption: FC = () => {
  const dispatch = useDispatch();
  const { paymentOptionId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { paymentOptions } = usePaymentOptions();
  const paymentOption = paymentOptions.find((o) => o.id === paymentOptionId);

  const setAccessibility = async (accessibility: any) => {
    await dispatch(
      paymentOption?.id &&
        setPaymentOptionAccessibility(paymentOption.id, accessibility)
    );
  };

  const onSave = async (values: any) => {
    await dispatch(
      updatePaymentOption({
        ...values,
      })
    );
    if (!paymentOption) {
      return;
    }

    const { fields, connected } = paymentOption;

    const connectUrl = fields?.find(
      ({ name }) => name === 'connect:url'
    )?.value;

    if (connectUrl && !connected) {
      window.location.href = connectUrl;
    }
  };

  return paymentOption && organizations.length ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Payment Option</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={paymentOption.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <PaymentOptionEditor
            paymentOption={paymentOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
