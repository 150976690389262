import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Badge, Button } from 'reactstrap';

import { validateCertificateVersion } from '@nimles/react-redux';
import { useParams } from 'react-router';
import { CertificateEditor } from './CertificateEditor';
import { RootState } from '../../../redux/types';
import { TenantModel } from '@nimles/models';
import { useCertificates } from '../../../hooks/certificates';

interface Params {
  certificateId: string;
  versionId: string;
}

export const CertificateVersion: FC = () => {
  const dispatch = useDispatch();
  const { certificateId, versionId } = useParams<Params>();
  const { certificates } = useCertificates();
  const certificate = certificates.find((c) => c.id === certificateId);
  const versions = certificate && certificate.versions;
  const version = versions && versions.find((c) => c.id === versionId);
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );

  const validate = async () => {
    await dispatch(
      certificate?.id &&
        version?.id &&
        validateCertificateVersion(certificate.id, version.id, true)
    );
  };

  return version && tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>
            Certificate <Badge>{version.status}</Badge>
          </strong>
          <div className="card-header-actions">
            <Button onClick={validate} size="sm">
              Validate
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <CertificateEditor
            certificate={certificate}
            version={version}
            tenant={tenant}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
