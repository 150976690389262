import { ClassNames } from '@emotion/react';
import React, { PropsWithChildren, Ref } from 'react';
import { BaseProps } from './Base';
import { Menu } from './Menu';

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement>
  ) => (
    <ClassNames>
      {({ css, cx }) => (
        <Menu
          {...props}
          ref={ref}
          className={cx(
            className,
            css`
              position: relative;
              padding: 1px 18px 17px;
              margin: 0;
              border-bottom: 2px solid #eee;
              margin-bottom: 20px;
            `
          )}
        />
      )}
    </ClassNames>
  )
);
