import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { useOrganizations } from '../../../hooks/organizations';
import { usePricelists } from '../../../hooks/pricelists';

export const Pricelists: FC = () => {
  const { pricelists } = usePricelists();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Pricelists
          <div className="card-header-actions">
            <Link
              to="/ecommerce/pricelists/new"
              className="btn btn-sm btn-primary"
            >
              New Pricelist
            </Link>
          </div>
        </CardHeader>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Organization</th>
              <th scope="col">Currency</th>
              <th scope="col">Prices</th>
              <th scope="col">Accessibility</th>
            </tr>
          </thead>
          <tbody>
            {pricelists.map((pricelist) => {
              const pricelistLink = `/ecommerce/pricelists/${pricelist.id}`;
              return (
                <tr key={pricelist.id?.toString()}>
                  <td>
                    <Link to={pricelistLink}>
                      <div>
                        <strong>{pricelist.name}</strong>
                      </div>
                      <div>
                        <small>{pricelist.id}</small>
                      </div>
                    </Link>
                  </td>
                  <td>{getOrganizationName(pricelist.organizationId)}</td>
                  <td>{pricelist.currency}</td>
                  <td>{pricelist.prices?.length}</td>
                  <td>{pricelist.accessibility}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
