import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createPricelist } from '@nimles/react-redux';
import { PricelistEditor } from './PricelistEditor';
import { PricelistModel } from '@nimles/models';
import { useOrganizations } from '../../../hooks/organizations';
import { useProducts } from '../../../hooks';

const pricelist: PricelistModel = {
  name: '',
  prices: [],
};

export const NewPricelist: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { products } = useProducts();

  const handleSave = async (values: any) => {
    const pricelist: any = await dispatch(createPricelist({ ...values }));

    history.push('/ecommerce/pricelists/' + pricelist.id);
  };

  console.log(pricelist);
  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Pricelist</strong>
        </CardHeader>
        <CardBody>
          <PricelistEditor
            pricelist={pricelist}
            products={products}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
