import { createPaymentOption, State } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { PaymentOptionEditor } from './PaymentOptionEditor';
import { RootState } from '../../../redux/types';
import { OrganizationModel, PaymentOptionModel } from '@nimles/models';

export const NewPaymentOption: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const processor = query?.get('processor?');

  const { values: organizations, selected } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);
  const organization = selected || organizations.find((o) => o);

  const onSave = async (values: any) => {
    const paymentOption: any = await dispatch(
      createPaymentOption({
        ...values,
      })
    );

    const { fields, connected }: PaymentOptionModel = paymentOption;

    const connectUrl = fields?.find(
      ({ name }) => (name = 'connect:url')
    )?.value;
    if (connectUrl && !connected) {
      window.location.href = connectUrl;
    } else {
      history.push(`/ecommerce/paymentOptions/${paymentOption.id}`);
    }
  };

  const paymentOption: PaymentOptionModel = {
    paymentProcessor: processor?.toString(),
    paymentType: processor && 'PaymentProcessor',
    name: processor?.toString(),
    organizationId: organization?.id,
  };

  return organization ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>
            {processor ? 'Add ' + processor : 'New paymentOption'}
          </strong>
          <p>
            {processor ? 'Setup payment processor' : 'Create new paymentOption'}
          </p>
        </CardHeader>
        <CardBody>
          <PaymentOptionEditor
            paymentOption={paymentOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
