const nav = [
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Identity',
    icon: 'cil-address-card',
    route: '/identity',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Tenants',
        to: '/identity/tenants',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/identity/users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Certificates',
        to: '/identity/certificates',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Clients',
        to: '/identity/clients',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Organization Management',
    icon: 'cil-sitemap',
    route: '/organization',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Categories',
        to: '/organization-management/categories',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Organizations',
        to: '/organization-management/organizations',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Members',
        to: '/organization-management/members',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Sites',
        to: '/organization-management/sites',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'E-Commerce',
    icon: 'cil-cart',
    route: '/ecommerce',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Customers',
        to: '/ecommerce/customers',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Quote Requests',
        to: '/ecommerce/quoteRequests',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Quotes',
        to: '/ecommerce/quotes',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Orders',
        to: '/ecommerce/orders',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Logistics Options',
        to: '/ecommerce/logisticsOptions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Payment Options',
        to: '/ecommerce/paymentOptions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Delivery Options',
        to: '/ecommerce/deliveryOptions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pricelists',
        to: '/ecommerce/pricelists',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Discounts',
        to: '/ecommerce/discounts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Product Stocks',
        to: '/ecommerce/productStocks',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Product Management',
    icon: 'cil-bookmarks',
    route: '/pim',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Product Groups',
        to: '/pim/productGroups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Product Properties',
        to: '/pim/productProperties',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Products',
        to: '/pim/products',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Review Groups',
        to: '/pim/reviewGroups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Reviews',
        to: '/pim/reviews',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Customer Relationship',
    icon: 'cil-address-book',
    route: '/crm',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Contacts',
        to: '/crm/contacts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pipelines',
        to: '/crm/pipelines',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Media',
    icon: 'cil-file-image',
    route: '/media',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Files',
        to: '/media/files',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Content Management',
    icon: 'cil-document',
    route: '/cms',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Layouts',
        to: '/cms/layouts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Themes',
        to: '/cms/themes',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pages',
        to: '/cms/pages',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Posts',
        to: '/cms/posts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Texts',
        to: '/cms/texts',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Learning Management',
    icon: 'cil-school',
    route: '/lms',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Subjects',
        to: '/lms/subjects',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Courses',
        to: '/lms/courses',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Courseworkss',
        to: '/lms/courseworkss',
      },
    ],
  },
];

export default nav;
