import { Identity } from './pages/identity/Identity';
import { Tenants } from './pages/identity/tenants/Tenants';
import { Tenant } from './pages/identity/tenants/Tenant';
import { NewTenant } from './pages/identity/tenants/NewTenant';
import { Users } from './pages/identity/users/Users';
import { User } from './pages/identity/users/User';
import { NewUser } from './pages/identity/users/NewUser';
import { Certificates } from './pages/identity/certificates/Certificates';
import { Certificate } from './pages/identity/certificates/Certificate';
import { CertificateVersion } from './pages/identity/certificates/CertificateVersion';
import { NewCertificate } from './pages/identity/certificates/NewCertificate';
import { Clients } from './pages/identity/clients/Clients';
import { Client } from './pages/identity/clients/Client';
import { NewClient } from './pages/identity/clients/NewClient';

import { OrganizationManagement } from './pages/organizationManagement/OrganizationManagement';
import { Organizations } from './pages/organizationManagement/organizations/Organizations';
import { Organization } from './pages/organizationManagement/organizations/Organization';
import { NewOrganization } from './pages/organizationManagement/organizations/NewOrganization';

import { Categories } from './pages/organizationManagement/categories/Categories';
import { Category } from './pages/organizationManagement/categories/Category';
import { NewCategory } from './pages/organizationManagement/categories/NewCategory';

import { Sites } from './pages/organizationManagement/sites/Sites';
import { Site } from './pages/organizationManagement/sites/Site';
import { NewSite } from './pages/organizationManagement/sites/NewSite';

import { Members } from './pages/organizationManagement/members/Members';
import { Member } from './pages/organizationManagement/members/Member';
import { NewMember } from './pages/organizationManagement/members/NewMember';

import { ECommerce } from './pages/ecommerce/Ecommerce';

import { Quotes } from './pages/ecommerce/quotes/Quotes';
import { Quote } from './pages/ecommerce/quotes/Quote';
import { NewQuote } from './pages/ecommerce/quotes/NewQuote';

import { QuoteRequests } from './pages/ecommerce/quoteRequests/QuoteRequests';
import { QuoteRequest } from './pages/ecommerce/quoteRequests/QuoteRequest';
import { NewQuoteRequest } from './pages/ecommerce/quoteRequests/NewQuoteRequest';

import { Orders } from './pages/ecommerce/orders/Orders';
import { Order } from './pages/ecommerce/orders/Order';
import { NewOrder } from './pages/ecommerce/orders/NewOrder';

import { Customers } from './pages/ecommerce/customers/Customers';
import { Customer } from './pages/ecommerce/customers/Customer';
import { NewCustomer } from './pages/ecommerce/customers/NewCustomer';

import { PaymentOptions } from './pages/ecommerce/paymentOptions/PaymentOptions';
import { PaymentOption } from './pages/ecommerce/paymentOptions/PaymentOption';
import { NewPaymentOption } from './pages/ecommerce/paymentOptions/NewPaymentOption';
import { ConnectPaymentOption } from './pages/ecommerce/paymentOptions/ConnectPaymentOption';

import { DeliveryOptions } from './pages/ecommerce/deliveryOptions/DeliveryOptions';
import { DeliveryOption } from './pages/ecommerce/deliveryOptions/DeliveryOption';
import { NewDeliveryOption } from './pages/ecommerce/deliveryOptions/NewDeliveryOption';

import { ProductStocks } from './pages/ecommerce/productStocks/ProductStocks';
import { ProductStock } from './pages/ecommerce/productStocks/ProductStock';
import { NewProductStock } from './pages/ecommerce/productStocks/NewProductStock';

import { LogisticsOptions } from './pages/ecommerce/logisticsOptions/LogisticsOptions';
import { LogisticsOption } from './pages/ecommerce/logisticsOptions/LogisticsOption';
import { NewLogisticsOption } from './pages/ecommerce/logisticsOptions/NewLogisticsOption';

import { Pim } from './pages/pim/Pim';
import { ProductGroups } from './pages/pim/productGroups/ProductGroups';
import { ProductGroup } from './pages/pim/productGroups/ProductGroup';
import { NewProductGroup } from './pages/pim/productGroups/NewProductGroup';

import { ProductProperties } from './pages/pim/productProperties/ProductProperties';
import { ProductProperty } from './pages/pim/productProperties/ProductProperty';
import { NewProductProperty } from './pages/pim/productProperties/NewProductProperty';

import { Products } from './pages/pim/products/Products';
import { Product } from './pages/pim/products/Product';
import { NewProduct } from './pages/pim/products/NewProduct';

import { ReviewGroups } from './pages/pim/reviewGroups/ReviewGroups';
import { ReviewGroup } from './pages/pim/reviewGroups/ReviewGroup';
import { NewReviewGroup } from './pages/pim/reviewGroups/NewReviewGroup';

import { Reviews } from './pages/pim/reviews/Reviews';
import { Review } from './pages/pim/reviews/Review';
import { NewReview } from './pages/pim/reviews/NewReview';

import { Pricelists } from './pages/ecommerce/pricelists/Pricelists';
import { Pricelist } from './pages/ecommerce/pricelists/Pricelist';
import { NewPricelist } from './pages/ecommerce/pricelists/NewPricelist';

import { Discounts } from './pages/ecommerce/discounts/Discounts';
import { Discount } from './pages/ecommerce/discounts/Discount';
import { NewDiscount } from './pages/ecommerce/discounts/NewDiscount';

import { Cms } from './pages/cms/Cms';
import { Layouts } from './pages/cms/layouts/Layouts';
import { Layout } from './pages/cms/layouts/Layout';
import { NewLayout } from './pages/cms/layouts/NewLayout';

import { Posts } from './pages/cms/posts/Posts';
import { Post } from './pages/cms/posts/Post';
import { NewPost } from './pages/cms/posts/NewPost';

import { Pages } from './pages/cms/pages/Pages';
import { Page } from './pages/cms/pages/Page';
import { NewPage } from './pages/cms/pages/NewPage';

import { Themes } from './pages/cms/themes/Themes';
import { Theme } from './pages/cms/themes/Theme';
import { NewTheme } from './pages/cms/themes/NewTheme';

import { Texts } from './pages/cms/texts/Texts';
import { Text } from './pages/cms/texts/Text';
import { NewText } from './pages/cms/texts/NewText';

import { Crm } from './pages/crm/Crm';
import { Relationships } from './pages/crm/relationships/Relationships';
import { Relationship } from './pages/crm/relationships/Relationship';
import { NewRelationship } from './pages/crm/relationships/NewRelationship';
import { Pipelines } from './pages/crm/pipelines/Pipelines';
import { Pipeline } from './pages/crm/pipelines/Pipeline';
import { NewPipeline } from './pages/crm/pipelines/NewPipeline';

import { Media } from './pages/media/Media';
import { Files } from './pages/media/files/Files';
import { File } from './pages/media/files/File';
import { NewFile } from './pages/media/files/NewFile';
import { Home } from './pages/home/Home';

import { Lms } from './pages/lms/Lms';
import { Courses } from './pages/lms/courses/Courses';
import { Course } from './pages/lms/courses/Course';
import { NewCourse } from './pages/lms/courses/NewCourse';

import { Courseworks } from './pages/lms/courseworks/Courseworks';
import { Coursework } from './pages/lms/courseworks/Coursework';
import { NewCoursework } from './pages/lms/courseworks/NewCoursework';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/identity', exact: true, name: 'Identity', component: Identity },
  {
    path: '/identity/tenants',
    exact: true,
    name: 'Tenants',
    component: Tenants,
  },
  {
    path: '/identity/tenants/new',
    exact: true,
    name: 'New Tenant',
    component: NewTenant,
  },
  {
    path: '/identity/tenants/:tenantId',
    exact: true,
    name: 'Tenant',
    component: Tenant,
  },
  { path: '/identity/users', exact: true, name: 'Users', component: Users },
  { path: '/identity/users/new', exact: true, name: 'New', component: NewUser },
  {
    path: '/identity/users/:userId',
    exact: true,
    name: 'User',
    component: User,
  },
  {
    path: '/identity/certificates',
    exact: true,
    name: 'Certificates',
    component: Certificates,
  },
  {
    path: '/identity/certificates/new',
    exact: true,
    name: 'New',
    component: NewCertificate,
  },
  {
    path: '/identity/certificates/:certificateId',
    exact: true,
    name: 'Certificate',
    component: Certificate,
  },
  {
    path: '/identity/certificates/:certificateId/:versionId',
    exact: true,
    name: 'CertificateVersion',
    component: CertificateVersion,
  },
  {
    path: '/identity/clients',
    exact: true,
    name: 'Clients',
    component: Clients,
  },
  {
    path: '/identity/clients/new',
    exact: true,
    name: 'New',
    component: NewClient,
  },
  {
    path: '/identity/clients/:clientId',
    exact: true,
    name: 'Client',
    component: Client,
  },

  {
    path: '/organization-management',
    exact: true,
    name: 'Organization Management',
    component: OrganizationManagement,
  },
  {
    path: '/organization-management/organizations',
    exact: true,
    name: 'Organizations',
    component: Organizations,
  },
  {
    path: '/organization-management/organizations/new',
    exact: true,
    name: 'New',
    component: NewOrganization,
  },
  {
    path: '/organization-management/organizations/:organizationId',
    exact: true,
    name: 'Organization',
    component: Organization,
  },
  {
    path: '/organization-management/sites',
    exact: true,
    name: 'Sites',
    component: Sites,
  },
  {
    path: '/organization-management/sites/new',
    exact: true,
    name: 'New',
    component: NewSite,
  },
  {
    path: '/organization-management/sites/:siteId',
    exact: true,
    name: 'Site',
    component: Site,
  },
  {
    path: '/organization-management/members',
    exact: true,
    name: 'Members',
    component: Members,
  },
  {
    path: '/organization-management/members/new',
    exact: true,
    name: 'New',
    component: NewMember,
  },
  {
    path: '/organization-management/members/:organizationMemberId',
    exact: true,
    name: 'Member',
    component: Member,
  },
  {
    path: '/organization-management/categories',
    exact: true,
    name: 'Categories',
    component: Categories,
  },
  {
    path: '/organization-management/categories/new',
    exact: true,
    name: 'New',
    component: NewCategory,
  },
  {
    path: '/organization-management/categories/:categoryId',
    exact: true,
    name: 'Category',
    component: Category,
  },

  { path: '/ecommerce', exact: true, name: 'Ecommerce', component: ECommerce },
  {
    path: '/ecommerce/quotes',
    exact: true,
    name: 'Quotes',
    component: Quotes,
  },
  {
    path: '/ecommerce/quotes/new',
    exact: true,
    name: 'New',
    component: NewQuote,
  },
  {
    path: '/ecommerce/quotes/:quoteId',
    exact: true,
    name: 'Quote',
    component: Quote,
  },

  {
    path: '/ecommerce/quoteRequests',
    exact: true,
    name: 'QuoteRequests',
    component: QuoteRequests,
  },
  {
    path: '/ecommerce/quoteRequests/new',
    exact: true,
    name: 'New',
    component: NewQuoteRequest,
  },
  {
    path: '/ecommerce/quoteRequests/:quoteRequestId',
    exact: true,
    name: 'QuoteRequest',
    component: QuoteRequest,
  },
  { path: '/ecommerce/orders', exact: true, name: 'Orders', component: Orders },
  {
    path: '/ecommerce/orders/new',
    exact: true,
    name: 'New',
    component: NewOrder,
  },
  {
    path: '/ecommerce/orders/:orderId',
    exact: true,
    name: 'Order',
    component: Order,
  },
  {
    path: '/ecommerce/customers',
    exact: true,
    name: 'Customers',
    component: Customers,
  },
  {
    path: '/ecommerce/customers/new',
    exact: true,
    name: 'New',
    component: NewCustomer,
  },
  {
    path: '/ecommerce/customers/:customerId',
    exact: true,
    name: 'Customer',
    component: Customer,
  },
  {
    path: '/ecommerce/paymentOptions',
    exact: true,
    name: 'Payment Options',
    component: PaymentOptions,
  },
  {
    path: '/ecommerce/paymentOptions/new',
    exact: true,
    name: 'New',
    component: NewPaymentOption,
  },
  {
    path: '/ecommerce/paymentOptions/:paymentOptionId',
    exact: true,
    name: 'Payment Option',
    component: PaymentOption,
  },
  {
    path: '/ecommerce/paymentOptions/connect/:paymentProcessor',
    exact: true,
    name: 'Connect Payment Option',
    component: ConnectPaymentOption,
  },
  {
    path: '/ecommerce/deliveryOptions',
    exact: true,
    name: 'Delivery Options',
    component: DeliveryOptions,
  },
  {
    path: '/ecommerce/deliveryOptions/new',
    exact: true,
    name: 'New',
    component: NewDeliveryOption,
  },
  {
    path: '/ecommerce/deliveryOptions/:deliveryOptionId',
    exact: true,
    name: 'Delivery Option',
    component: DeliveryOption,
  },
  {
    path: '/ecommerce/productStocks',
    exact: true,
    name: 'Delivery Options',
    component: ProductStocks,
  },
  {
    path: '/ecommerce/productStocks/new',
    exact: true,
    name: 'New',
    component: NewProductStock,
  },
  {
    path: '/ecommerce/productStocks/:productStockId',
    exact: true,
    name: 'Delivery Option',
    component: ProductStock,
  },
  {
    path: '/ecommerce/logisticsOptions',
    exact: true,
    name: 'Delivery Options',
    component: LogisticsOptions,
  },
  {
    path: '/ecommerce/logisticsOptions/new',
    exact: true,
    name: 'New',
    component: NewLogisticsOption,
  },
  {
    path: '/ecommerce/logisticsOptions/:logisticsOptionId',
    exact: true,
    name: 'Delivery Option',
    component: LogisticsOption,
  },

  {
    path: '/ecommerce/pricelists',
    exact: true,
    name: 'Pricelists',
    component: Pricelists,
  },
  {
    path: '/ecommerce/pricelists/new',
    exact: true,
    name: 'New',
    component: NewPricelist,
  },
  {
    path: '/ecommerce/pricelists/:pricelistId',
    exact: true,
    name: 'Pricelist',
    component: Pricelist,
  },

  {
    path: '/ecommerce/discounts',
    exact: true,
    name: 'Discounts',
    component: Discounts,
  },
  {
    path: '/ecommerce/discounts/new',
    exact: true,
    name: 'New',
    component: NewDiscount,
  },
  {
    path: '/ecommerce/discounts/:discountId',
    exact: true,
    name: 'Discount',
    component: Discount,
  },

  { path: '/pim', exact: true, name: 'Pim', component: Pim },
  {
    path: '/pim/productgroups',
    exact: true,
    name: 'Product Groups',
    component: ProductGroups,
  },
  {
    path: '/pim/productgroups/new',
    exact: true,
    name: 'New',
    component: NewProductGroup,
  },
  {
    path: '/pim/productgroups/:productGroupId',
    exact: true,
    name: 'Product Group',
    component: ProductGroup,
  },

  {
    path: '/pim/productproperties',
    exact: true,
    name: 'Product Properties',
    component: ProductProperties,
  },
  {
    path: '/pim/productproperties/new',
    exact: true,
    name: 'New',
    component: NewProductProperty,
  },
  {
    path: '/pim/productproperties/:productPropertyId',
    exact: true,
    name: 'Product Property',
    component: ProductProperty,
  },

  { path: '/pim/products', exact: true, name: 'Products', component: Products },
  {
    path: '/pim/products/new',
    exact: true,
    name: 'New',
    component: NewProduct,
  },
  {
    path: '/pim/products/:productId',
    exact: true,
    name: 'Product',
    component: Product,
  },
  {
    path: '/pim/reviewgroups',
    exact: true,
    name: 'Review Groups',
    component: ReviewGroups,
  },
  {
    path: '/pim/reviewgroups/new',
    exact: true,
    name: 'New',
    component: NewReviewGroup,
  },
  {
    path: '/pim/reviewgroups/:reviewGroupId',
    exact: true,
    name: 'Review Group',
    component: ReviewGroup,
  },

  { path: '/pim/reviews', exact: true, name: 'Reviews', component: Reviews },
  {
    path: '/pim/reviews/new',
    exact: true,
    name: 'New',
    component: NewReview,
  },
  {
    path: '/pim/reviews/:reviewId',
    exact: true,
    name: 'Review',
    component: Review,
  },

  { path: '/cms', exact: true, name: 'Cms', component: Cms },
  { path: '/cms/layouts', exact: true, name: 'Layouts', component: Layouts },
  { path: '/cms/layouts/new', exact: true, name: 'New', component: NewLayout },
  {
    path: '/cms/layouts/:layoutId',
    exact: true,
    name: 'Layout',
    component: Layout,
  },
  { path: '/cms/posts', exact: true, name: 'Posts', component: Posts },
  { path: '/cms/posts/new', exact: true, name: 'New', component: NewPost },
  { path: '/cms/posts/:postId', exact: true, name: 'Post', component: Post },
  { path: '/cms/pages', exact: true, name: 'Pages', component: Pages },
  { path: '/cms/pages/new', exact: true, name: 'New', component: NewPage },
  { path: '/cms/pages/:pageId', exact: true, name: 'Page', component: Page },
  { path: '/cms/themes', exact: true, name: 'Themes', component: Themes },
  { path: '/cms/themes/new', exact: true, name: 'New', component: NewTheme },
  {
    path: '/cms/themes/:themeId',
    exact: true,
    name: 'Theme',
    component: Theme,
  },
  { path: '/cms/texts', exact: true, name: 'Texts', component: Texts },
  { path: '/cms/texts/new', exact: true, name: 'New', component: NewText },
  {
    path: '/cms/texts/:textId',
    exact: true,
    name: 'Text',
    component: Text,
  },

  { path: '/crm', exact: true, name: 'Crm', component: Crm },
  {
    path: '/crm/contacts',
    exact: true,
    name: 'Contacts',
    component: Relationships,
  },
  {
    path: '/crm/contacts/new',
    exact: true,
    name: 'New',
    component: NewRelationship,
  },
  {
    path: '/crm/contacts/:relationshipId',
    exact: true,
    name: 'Contact',
    component: Relationship,
  },

  {
    path: '/crm/pipelines',
    exact: true,
    name: 'Pipelines',
    component: Pipelines,
  },
  {
    path: '/crm/pipelines/new',
    exact: true,
    name: 'New',
    component: NewPipeline,
  },
  {
    path: '/crm/pipelines/:pipelineId',
    exact: true,
    name: 'Pipeline',
    component: Pipeline,
  },

  { path: '/media', exact: true, name: 'Media', component: Media },
  { path: '/media/files', exact: true, name: 'Files', component: Files },
  { path: '/media/files/new', exact: true, name: 'New', component: NewFile },
  { path: '/media/files/:fileId', exact: true, name: 'File', component: File },

  { path: '/', name: 'Home', component: Home, exact: true },

  { path: '/lms', exact: true, name: 'Lms', component: Lms },
  { path: '/lms/courses', exact: true, name: 'Courses', component: Courses },
  { path: '/lms/courses/new', exact: true, name: 'New', component: NewCourse },
  {
    path: '/lms/courses/:courseId',
    exact: true,
    name: 'Course',
    component: Course,
  },
  {
    path: '/lms/courseworks',
    exact: true,
    name: 'Courseworks',
    component: Courseworks,
  },
  {
    path: '/lms/courseworks/new',
    exact: true,
    name: 'New',
    component: NewCoursework,
  },
  {
    path: '/lms/courseworks/:courseworkId',
    exact: true,
    name: 'Course',
    component: Coursework,
  },
];

export default routes;
