import { Formik, FormikHelpers, FormikValues } from 'formik';
import React, { FC } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import {
  InputField,
  TextAreaField,
  CheckboxField,
} from '@nimles/react-web-forms';
import {
  CertificateModel,
  CertificateVersionModel,
  TenantModel,
} from '@nimles/models';

const validationSchema = function () {
  return Yup.object().shape({
    domain: Yup.string().required('Domain is required'),
  });
};

interface Props {
  certificate: CertificateModel;
  version?: CertificateVersionModel;
  tenant: TenantModel;
  onSave?: (relationship: any) => Promise<void>;
}

export const CertificateEditor: FC<Props> = ({
  certificate,
  version,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      onSave && (await onSave(values));
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = certificate && {
    id: certificate.id,
    domain: certificate.domain || '',
    uniqueName: certificate.uniqueName || '',
    pemKey: certificate.pemKey || '',
    pem: version?.pem || '',
    pfx: version?.pfx || '',
    password: certificate.password || '',
    certificateType: certificate.certificateType || '',
    csr: {
      countryName: certificate?.csr?.countryName || '',
      state: certificate?.csr?.state || '',
      locality: certificate?.csr?.locality || '',
      organization: certificate?.csr?.organization || '',
      organizationUnit: certificate?.csr?.organizationUnit || '',
      commonName: certificate?.csr?.commonName || '',
    },
    useDnsChallenge: version?.useDnsChallenge || false,
    challenge: {
      dnsText: version?.challenge?.dnsText || '',
      httpToken: version?.challenge?.httpToken || '',
      keyAuthorization: version?.challenge?.keyAuthorization || '',
    },
  };

  const handlePfxDownload = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    window.location.href = 'data:application/x-pkcs12;base64,' + values.pfx;
  };

  return certificate ? (
    <Formik<typeof values>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              {values.id && (
                <InputField
                  label="Id"
                  type="text"
                  name="id"
                  required
                  disabled={true}
                />
              )}
              <InputField
                label="Domain"
                type="text"
                name="domain"
                required
                disabled={!!values.id}
              />
              <InputField
                label="Unique name"
                type="text"
                name="uniqueName"
                required
                disabled={!!values.id}
              />
              {(values.certificateType === 'LetsEncrypt' ||
                values.certificateType === 'ImportPfx') && (
                <InputField
                  label="Password"
                  type="password"
                  name="password"
                  required
                  disabled={!!values.id}
                />
              )}
              {values.certificateType === 'LetsEncrypt' && (
                <InputField
                  label="Email"
                  type="email"
                  name="email"
                  disabled={!!values.id}
                />
              )}
              {values.certificateType === 'LetsEncrypt' && (
                <TextAreaField
                  label="Pem key"
                  name="pemKey"
                  disabled={!!values.id}
                />
              )}
              {values.certificateType === 'LetsEncrypt' && (
                <fieldset>
                  <legend>CSR</legend>
                  <InputField
                    label="Country name"
                    name="csr.countryName"
                    required
                    disabled={!!values.id}
                  />
                  <InputField
                    label="State"
                    name="csr.state"
                    required
                    disabled={!!values.id}
                  />
                  <InputField
                    label="Localilty"
                    name="csr.locality"
                    required
                    disabled={!!values.id}
                  />
                  <InputField
                    label="Organization"
                    name="csr.organization"
                    required
                    disabled={!!values.id}
                  />
                  <InputField
                    label="Organization unit"
                    name="csr.organizationUnit"
                    required
                    disabled={!!values.id}
                  />
                  <InputField
                    label="Common name"
                    name="csr.commonName"
                    required
                    disabled={!!values.id}
                  />
                </fieldset>
              )}
              {values.certificateType === 'LetsEncrypt' && (
                <fieldset>
                  <legend>Challenge</legend>
                  {!values.id ? (
                    <CheckboxField
                      label="Use DNS challenge"
                      name="useDnsChallenge"
                    />
                  ) : null}
                  {values.challenge.dnsText ? (
                    <div>
                      <InputField
                        label="DNS text"
                        name="challenge.dnsText"
                        required
                        disabled={true}
                      />
                      <div>
                        Add the DNS text as a <strong>TXT</strong> record to{' '}
                        <strong>_acme-challenge.{certificate.domain}</strong>
                      </div>
                    </div>
                  ) : null}
                  {values.challenge.httpToken && (
                    <InputField
                      label="Http token"
                      name="challenge.httpToken"
                      required
                      disabled
                    />
                  )}
                  {values.challenge.keyAuthorization && (
                    <InputField
                      label="Key authorization"
                      name="challenge.keyAuthorization"
                      required
                      disabled
                    />
                  )}
                </fieldset>
              )}
              {(values.certificateType === 'ImportPem' ||
                values.certificateType === 'ImportPfx' ||
                values.pem ||
                values.pfx) && (
                <fieldset>
                  <legend>Certificates</legend>
                  {(values.certificateType === 'ImportPem' || values.pem) && (
                    <TextAreaField
                      label="Pem"
                      name="pem"
                      disabled={!!values.id}
                    />
                  )}
                  {(values.certificateType === 'ImportPfx' || values.pfx) &&
                    (!values.id ? (
                      <TextAreaField
                        label="Pfx"
                        name="pfx"
                        disabled={!!values.id}
                      />
                    ) : (
                      <Button onClick={handlePfxDownload}>Download pfx</Button>
                    ))}
                </fieldset>
              )}
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  className="mr-1"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
