import { FC } from 'react';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { validate } from '../../_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, TextModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  text: TextModel;
  organizations?: OrganizationModel[];
  onSave: (text: any) => void;
}

export const TextEditor: FC<Props> = ({ text, organizations, onSave }) => {
  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const text = {
        ...values,
      };
      onSave(text);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: text.id,
    name: text.name || '',
    uniqueName: text.uniqueName || '',
    translations: {
      en: '',
      sv: '',
    },
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField label="Id" name="id" disabled required />}
          <InputField label="Name" type="text" name="name" id="name" required />
          <InputField
            label="Unique name"
            type="text"
            name="uniqueName"
            id="uniqueName"
          />
          {organizations && organizations.length ? (
            <OrganizationSelectField
              organizations={organizations}
              defaultOrganizationId={text.organizationId}
            />
          ) : null}
        </Form>
      )}
    />
  );
};
