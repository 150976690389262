import { CourseModel } from '@nimles/models';
import { loadCourses, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useCourses = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<CourseModel>>(
    ({ courses }) => courses
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadCourses());
    }
  }, [tenantId, dispatch]);

  return {
    courses: values,
  };
};
