import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ProductPropertyEditor } from './ProductPropertyEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  updateProductProperty,
  setProductPropertyAccessibility,
} from '@nimles/react-redux';
import { useProductProperties } from '../../../hooks';

interface Params {
  productPropertyId: string;
}

export const ProductProperty: FC = () => {
  const dispatch = useDispatch();
  const { productPropertyId } = useParams<Params>();
  const { productProperties } = useProductProperties();
  const productProperty = productProperties.find(
    (pp) => pp.id === productPropertyId
  );

  const onSave = async (values: any) => {
    await dispatch(updateProductProperty({ ...productProperty, ...values }));
  };

  const setAccessibility = async (accessibility: string) => {
    await dispatch(
      productProperty?.id &&
        setProductPropertyAccessibility(productProperty.id, accessibility)
    );
  };
  return productProperty ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>ProductProperty</strong>
          <div className="card-header-actions">
            <Link
              to={`/pim/productProperties/${productProperty.id}/permissions`}
              className="btn btn-sm btn-primary"
            >
              Permissions
            </Link>

            <AccessibilityButton
              accessibility={productProperty.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ProductPropertyEditor
            productProperty={productProperty}
            productProperties={productProperties}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
