import { Column, Row } from '@nimles/react-web-components';
import React, { useState, ChangeEvent, FormEvent, FC } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { searchFiles } from '@nimles/rest-api';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/types';
import { createFile, AuthenticationState } from '@nimles/react-redux';
import styled from '@emotion/styled';
import { ImageGrid } from './images';
import { FileModel } from '@nimles/models';

const Saving = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
`;

interface Props {
  onSelect: (image: FileModel) => void;
  provider: string;
  perPage?: number;
}

export const ImageProviderSearch: FC<Props> = ({
  onSelect,
  provider,
  perPage = 30,
}) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>();
  const auth = useSelector<RootState, AuthenticationState>(({ auth }) => auth);
  const [files, setFiles] = useState<FileModel[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(() => 1);
    setFiles([]);
    if (query) {
      const response = await searchFiles(auth, {
        query,
        provider,
        perPage,
        page,
      });
      setFiles(response.data ?? []);
    }
  };

  const handleShowMore = async (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    if (!isLoading) {
      setLoading(true);
      const nextPage = page + 1;
      setPage((prev) => prev + 1);
      if (query) {
        const response = await searchFiles(auth, {
          query,
          provider,
          perPage,
          page: nextPage,
        });
        setFiles((prev) => [...prev, ...(response.data ?? [])]);
        setLoading(false);
      }
    }
  };

  const handleSelect = async ({ id, ...image }: FileModel) => {
    setSaving(true);
    const file: any = await dispatch(createFile(image));
    onSelect(file);
    setSaving(false);
  };

  return (
    <Row>
      {isSaving ? <Saving>Saving...</Saving> : null}
      <Column xs={100}>
        <form onSubmit={handleSearch}>
          <InputGroup>
            <Input value={query} onChange={handleQuery} />
            <InputGroupAddon addonType="append">
              <Button>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
        <ImageGrid
          images={files}
          onSelect={handleSelect}
          selected={undefined}
        />
        {files.length ? (
          <Button onClick={(e) => handleShowMore(e)} disabled={isLoading}>
            Show more
          </Button>
        ) : null}
      </Column>
    </Row>
  );
};
