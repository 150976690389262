import {
  setProductGroupAccessibility,
  updateProductGroup,
  publishProductGroup,
} from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { ProductGroupEditor } from './ProductGroupEditor';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import {
  useCategories,
  useProductGroups,
  useProductProperties,
} from '../../../hooks';

interface Params {
  productGroupId: string;
}

export const ProductGroup: FC = () => {
  const dispatch = useDispatch();
  const { productGroupId } = useParams<Params>();
  const { categories } = useCategories();
  const { productGroups } = useProductGroups();
  const { productProperties } = useProductProperties();
  const productGroup = productGroups.find((o: any) => o.id === productGroupId);

  const onSave = async (values: any, publish: boolean) => {
    await dispatch(updateProductGroup({ ...productGroup, ...values }));
    if (publish) {
      productGroup?.id &&
        (await dispatch(publishProductGroup(productGroup.id)));
    }
  };

  const setAccessibility = async (accessibility: string) => {
    await dispatch(
      productGroup?.id &&
        setProductGroupAccessibility(productGroup.id, accessibility)
    );
  };

  return productGroup ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {productGroup.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(productGroup.createdDate).format('LLL')}
            </div>
          ) : null}
          {productGroup.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(productGroup.lastModified).format('LLL')}
            </div>
          ) : null}
          {productGroup.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(productGroup.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>ProductGroup</strong>
          <div className="card-header-actions">
            <Link
              to={`/pim/productGroups/${productGroup.id}/permissions`}
              className="btn btn-sm btn-primary"
            >
              Permissions
            </Link>
            <AccessibilityButton
              accessibility={productGroup.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ProductGroupEditor
            productGroup={productGroup}
            productGroups={productGroups}
            productProperties={productProperties}
            categories={categories}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
