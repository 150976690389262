import { BaseEditor, Editor, Element } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

export interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export interface FullEditor extends BaseEditor, ReactEditor, HistoryEditor {}

export const isBlockActive = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  format: string | null | undefined
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  });

  return !!match;
};

export const isMarkActive = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  format: string | number
) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
