import styled from '@emotion/styled';
import { useFiles } from '../../hooks/files';

const imageBaseUrl = 'https://media.nimles.com/file';

const StyledImage = styled.img<{ background?: string }>`
  max-width: 100%;
  background: ${({ background }) => background};
`;

export const Image = ({
  value,
  uri,
  image,
  background,
  width,
}: {
  value?: string;
  uri?: string;
  image?: any;
  background?: string;
  width?: number;
}) => {
  const { files } = useFiles();
  let source;
  let imageId: string;
  if (image) {
    if (image.uri?.indexOf('data') === 0) {
      source = image.uri;
    } else {
      source = `${imageBaseUrl}/${image.tenantId}/${image.id}?width=${
        width ?? '860'
      }`;
    }
  } else if (value) {
    if (value.indexOf('data') === 0) {
      source = value;
    } else if (value.indexOf('http') === 0) {
      source = `${value}?width=${width ?? '860'}`;
    } else if (value.indexOf('/') === 0) {
      source = `${imageBaseUrl}${value}?width=${width ?? '860'}`;
    } else {
      imageId = value;
      image = files && imageId && files.find((i) => i.id === imageId);
      if (image) {
        source = `${image.uri}?width=${width ?? '860'}`;
      }
    }
  } else if (uri) {
    source = `${uri}?width=${width ?? '860'}`;
  }

  return source ? <StyledImage src={source} background={background} /> : null;
};
