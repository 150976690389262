export interface ElementTemplate {
  title: string;
  type: string;
  icon: string;
  parents?: string[];
  children?: string[];
  roots?: string[];
  properties?: PropertyTemplate[];
  hasChildren?: boolean;
  closeOnAdd?: boolean;
  closeOnChange?: boolean;
}

export interface PropertyTemplate {
  name: string;
  displayName: string;
  type: string;
  options?: string[];
  isAdvanced?: boolean;
  icon?: string;
}

const fixedProperty = {
  name: 'fixed',
  displayName: 'Fixed',
  type: 'checkbox',
};

const positionProperty = {
  name: 'position',
  displayName: 'Position',
  type: 'select',
  options: ['static', 'fixed'],
  isAdvanced: true,
};

const overflowHorizontalProperty = {
  name: 'overflowX',
  displayName: 'Overflow horizontal',
  type: 'select',
  options: ['visible', 'hidden', 'scroll', 'auto', 'initial', 'inherit'],
  isAdvanced: true,
};

const overflowVerticalProperty = {
  name: 'overflowY',
  displayName: 'Overflow vertical',
  type: 'select',
  options: ['visible', 'hidden', 'scroll', 'auto', 'initial', 'inherit'],
  isAdvanced: true,
};

const colorProperty = {
  name: 'color',
  displayName: 'Color',
  type: 'color',
  icon: 'fa fa-palette',
};

const backgroundColorProperty = {
  name: 'backgroundColor',
  displayName: 'Background color',
  type: 'color',
  icon: 'fa fa-fill',
};

const backgroundImageProperty = {
  name: 'backgroundImage',
  displayName: 'Background image',
  type: 'image',
  icon: 'fa fa-image',
};

const backgroundPositionProperty = {
  name: 'backgroundPosition',
  displayName: 'Background position',
  type: 'text',
  icon: 'fa fa-image',
};

const backgroundRepeatProperty = {
  name: 'backgroundRepeat',
  displayName: 'Background repeat',
  type: 'text',
  icon: 'fa fa-image',
};

const backgroundSizeProperty = {
  name: 'backgroundSize',
  displayName: 'Background size',
  type: 'text',
  icon: 'fa fa-image',
};

const wrapProperty = {
  name: 'wrap',
  displayName: 'Wrap',
  type: 'select',
  options: ['nowrap', 'wrap', 'wrap-reverse'],
  isAdvanced: true,
  icon: 'fa fa-paragraph',
};

const alignItemsProperty = {
  name: 'alignItems',
  displayName: 'Align items',
  type: 'select',
  options: ['normal', 'flex-start', 'flex-end', 'center', 'stretch'],
  isAdvanced: true,
  icon: 'fa fa-align-center',
};

const justifyProperty = {
  name: 'justify',
  displayName: 'Justify',
  type: 'select',
  options: [
    'normal',
    'flex-start',
    'flex-end',
    'center',
    'stretch',
    'space-between',
    'space-around',
    'space-evenly',
  ],
  isAdvanced: true,
  icon: 'fa fa-align-justify',
};

const displayProperty = {
  name: 'display',
  displayName: 'Display',
  type: 'select',
  options: ['block', 'flex'],
  isAdvanced: true,
};
const directionProperty = {
  name: 'direction',
  displayName: 'Direction',
  type: 'select',
  options: ['row', 'column'],
  isAdvanced: true,
};

const flexProperty = {
  name: 'flex',
  displayName: 'Flex',
  type: 'text',
};

const paddingProperty = {
  name: 'padding',
  displayName: 'Padding',
  type: 'text',
  isAdvanced: true,
};

const fluidProperty = {
  name: 'fluid',
  displayName: 'Fluid',
  type: 'checkbox',
};

const primaryProperty = {
  name: 'primary',
  displayName: 'Primary',
  type: 'checkbox',
};

const secondaryProperty = {
  name: 'secondary',
  displayName: 'Secondary',
  type: 'checkbox',
};

const raisedProperty = {
  name: 'raised',
  displayName: 'Raised',
  type: 'checkbox',
};

const borderProperty = {
  name: 'border',
  displayName: 'Border',
  type: 'checkbox',
};

const inverseProperty = {
  name: 'inverse',
  displayName: 'Inverse',
  type: 'checkbox',
};

const darkenProperty = {
  name: 'darken',
  displayName: 'Darken',
  type: 'checkbox',
};

const lightenProperty = {
  name: 'lighten',
  displayName: 'Lighten',
  type: 'checkbox',
};

const toProperty = {
  name: 'to',
  displayName: 'To',
  type: 'text',
};

const srcProperty = {
  name: 'src',
  displayName: 'Src',
  type: 'text',
};

const hrefProperty = {
  name: 'href',
  displayName: 'Url',
  type: 'text',
};

const titleProperty = {
  name: 'title',
  displayName: 'Title',
  type: 'text',
};

const alignTextProperty = {
  name: 'alignText',
  displayName: 'Align text',
  type: 'text',
};

const minHeightProperty = {
  name: 'minHeight',
  displayName: 'Min height',
  type: 'text',
  isAdvanced: true,
};

const verticalProperty = {
  name: 'vertical',
  displayName: 'Vertical',
  type: 'checkbox',
};

export const propertyTemplates: PropertyTemplate[] = [
  fixedProperty,
  positionProperty,
  overflowHorizontalProperty,
  overflowVerticalProperty,
  colorProperty,
  backgroundColorProperty,
  backgroundImageProperty,
  wrapProperty,
  alignItemsProperty,
  justifyProperty,
  displayProperty,
  directionProperty,
  flexProperty,
  paddingProperty,
  fluidProperty,
  primaryProperty,
  primaryProperty,
  secondaryProperty,
  raisedProperty,
  borderProperty,
  inverseProperty,
  darkenProperty,
  lightenProperty,
  toProperty,
  srcProperty,
  hrefProperty,
  titleProperty,
  minHeightProperty,
  backgroundPositionProperty,
  verticalProperty,
];

const elementTemplates: ElementTemplate[] = [
  {
    title: 'Header',
    type: 'Header',
    icon: 'fa fa-window-maximize',
    parents: ['Web', 'App', 'Container', 'Section'],
    roots: ['Web', 'App'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      fixedProperty,
      flexProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Header menu',
    type: 'HeaderMenu',
    icon: 'fa fa-bars',
    children: ['HeaderItem'],
    roots: ['Header'],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Header item',
    type: 'HeaderItem',
    icon: 'fa fa-minus',
    parents: ['HeaderMenu'],
    roots: ['Header'],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Language selector',
    type: 'LanguageSelector',
    icon: 'fa fa-language',
    roots: ['Header', 'Footer'],
    closeOnAdd: true,
  },
  {
    title: 'Footer',
    type: 'Footer',
    icon: 'fa fa-window-maximize fa-flip-vertical',
    parents: ['Web', 'App', 'Container', 'Section'],
    roots: ['Web', 'App'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      fixedProperty,
      flexProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Main',
    type: 'Main',
    icon: 'far fa-window-maximize',
    roots: ['Web', 'App'],
    properties: [flexProperty, paddingProperty],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Routes',
    type: 'Routes',
    icon: 'far fa-route',
    parents: ['Web', 'App', 'Container', 'Section', 'Main'],
    roots: ['Web', 'App'],
    properties: [flexProperty],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Container',
    type: 'Container',
    icon: 'fa fa-expand',
    parents: ['Web', 'App', 'Page', 'Header', 'Footer', 'Main', 'Section'],
    properties: [
      flexProperty,
      fluidProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
      paddingProperty,
      minHeightProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Section',
    type: 'Section',
    icon: 'fa fa-stream',
    parents: [
      'Web',
      'App',
      'Page',
      'Post',
      'Container',
      'Header',
      'Footer',
      'Section',
    ],
    roots: ['Web', 'App', 'Page', 'Post', 'Header', 'Footer'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      backgroundImageProperty,
      backgroundPositionProperty,
      backgroundRepeatProperty,
      backgroundSizeProperty,
      flexProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
      paddingProperty,
      minHeightProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Row',
    type: 'Row',
    icon: 'fa fa-grip-lines',
    parents: [
      'Web',
      'App',
      'Page',
      'Post',
      'Header',
      'Footer',
      'Main',
      'Page',
      'Container',
      'Section',
      'Column',
      'Card',
      'CardBody',
    ],
    children: ['Column'],
    properties: [
      flexProperty,
      wrapProperty,
      alignItemsProperty,
      justifyProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Column',
    type: 'Column',
    icon: 'fa fa-grip-lines-vertical',
    parents: ['Row'],
    properties: [
      paddingProperty,
      flexProperty,
      alignItemsProperty,
      justifyProperty,
      { name: 'xs', displayName: 'Width X-Small (%)', type: 'number' },
      { name: 'sm', displayName: 'Width Small (%)', type: 'number' },
      { name: 'md', displayName: 'Width Medium (%)', type: 'number' },
      { name: 'lg', displayName: 'Width Large (%)', type: 'number' },
      { name: 'xl', displayName: 'Width X-Large (%)', type: 'number' },
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Card',
    type: 'Card',
    icon: 'fa fa-square',
    parents: ['Web', 'App', 'Page', 'Post', 'Section', 'Container', 'Column'],
    roots: ['Web', 'App', 'Page', 'Post'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      titleProperty,
      flexProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Card header',
    type: 'CardHeader',
    icon: 'fa fa-square',
    parents: ['Card'],
    roots: ['Web', 'App', 'Page', 'Post'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      flexProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Card body',
    type: 'CardBody',
    icon: 'fa fa-square',
    parents: ['Card'],
    roots: ['Web', 'App', 'Page', 'Post'],
    properties: [
      colorProperty,
      backgroundColorProperty,
      flexProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
    ],
    hasChildren: true,
    closeOnAdd: true,
  },
  {
    title: 'Text',
    type: 'Text',
    icon: 'fa fa-font',
  },
  {
    title: 'Rich text',
    type: 'Html',
    icon: 'fa fa-align-left',
    properties: [
      alignTextProperty,
      displayProperty,
      directionProperty,
      alignItemsProperty,
      justifyProperty,
    ],
  },
  // {
  //   title: 'Rich text (Slate)',
  //   type: 'Slate',
  //   icon: 'fa fa-align-left',
  //   properties: [
  //     displayProperty,
  //     directionProperty,
  //     alignProperty,
  //     justifyProperty,
  //   ],
  // },
  {
    title: 'Image',
    type: 'Image',
    icon: 'fa fa-image',
    properties: [
      { displayName: 'Alternative text', name: 'alt', type: 'text' },
      { displayName: 'Width', name: 'width', type: 'number' },
    ],
    closeOnChange: true,
  },
  {
    title: 'SVG',
    type: 'Svg',
    icon: 'fa fa-image',
    properties: [
      colorProperty,
      { displayName: 'Alternative text', name: 'alt', type: 'text' },
      { displayName: 'Width', name: 'width', type: 'text' },
    ],
    closeOnChange: true,
  },
  {
    title: 'Icon',
    type: 'Icon',
    icon: 'fa fa-icons',
    closeOnChange: true,
  },
  {
    title: 'Nav button',
    type: 'NavButton',
    icon: 'fa fa-link',
    properties: [
      toProperty,
      primaryProperty,
      secondaryProperty,
      raisedProperty,
      borderProperty,
      inverseProperty,
      darkenProperty,
      lightenProperty,
    ],
  },
  {
    title: 'External Link',
    type: 'ExternalLink',
    icon: 'fa fa-link',
    properties: [hrefProperty],
  },
  {
    title: 'Products',
    type: 'Products',
    icon: 'fa fa-list',
    properties: [
      {
        name: 'organizationId',
        displayName: 'Organization',
        type: 'organization',
      },
      {
        name: 'productGroupId',
        displayName: 'Product group',
        type: 'productGroup',
      },
      { name: 'start', displayName: 'Start', type: 'number' },
      { name: 'limit', displayName: 'Limit', type: 'number' },
      {
        name: 'orderBy',
        displayName: 'Order By',
        type: 'select',
        options: ['name', 'popularity', 'distance', 'publishedDate'],
      },
    ],
  },
  {
    title: 'Map',
    type: 'Map',
    icon: 'fa fa-map',
    roots: ['Web', 'App', 'Page', 'Post'],
  },
  {
    title: 'Contact form',
    type: 'ContactForm',
    icon: 'fa fa-envelope',
    roots: ['Web', 'App', 'Page', 'Post'],
  },
  {
    title: 'Brand',
    type: 'Brand',
    icon: 'fa fa-bold',
    roots: ['Header', 'Footer'],
    properties: [
      {
        name: 'showName',
        displayName: 'Show name',
        type: 'checkbox',
      },
      {
        name: 'showLogo',
        displayName: 'Show logo',
        type: 'checkbox',
      },
      verticalProperty,
    ],
  },
  {
    title: 'User menu',
    type: 'UserMenu',
    icon: 'fa fa-user',
    roots: ['Header'],
  },
  {
    title: 'Organization menu',
    type: 'OrganizationMenu',
    icon: 'fa fa-building',
    roots: ['Header'],
  },
  {
    title: 'Shopping cart',
    type: 'ShoppingCart',
    icon: 'fa fa-shopping-basket',
  },
  {
    title: 'Product groups',
    type: 'ProductGroups',
    icon: 'fa fa-th',
  },
  {
    title: 'Page link',
    type: 'PageLink',
    icon: 'fa fa-file-alt',
    properties: [
      {
        name: 'page',
        displayName: 'Page',
        type: 'page',
      },
      {
        name: 'authRestriction',
        displayName: 'Authentication Restriction',
        type: 'select',
        options: ['authenticated', 'notAuthenticated'],
      },
    ],
  },
  {
    title: 'HubSpot Form',
    type: 'HubSpotForm',
    icon: 'fab fa-hubspot',
    roots: ['Page', 'Post'],
    properties: [
      {
        name: 'portalId',
        displayName: 'Portal Id',
        type: 'text',
      },
      {
        name: 'formId',
        displayName: 'Form Id',
        type: 'text',
      },
    ],
  },
  {
    title: 'Share',
    type: 'Share',
    icon: 'fa fa-share-alt',
    properties: [
      {
        name: 'facebook',
        displayName: 'Facebook',
        type: 'checkbox',
      },
      {
        name: 'twitter',
        displayName: 'Twitter',
        type: 'checkbox',
      },
      {
        name: 'pinterest',
        displayName: 'Pinterest',
        type: 'checkbox',
      },
      {
        name: 'linkedin',
        displayName: 'LinkedIn',
        type: 'checkbox',
      },
      {
        name: 'mail',
        displayName: 'Mail',
        type: 'checkbox',
      },
    ],
  },
  {
    title: 'Categories',
    type: 'Categories',
    icon: 'fa fa-th',
  },
  {
    title: 'Search',
    type: 'Search',
    icon: 'fa fa-search',
  },
  {
    title: 'License plate',
    type: 'LicensePlate',
    icon: 'fa fa-search',
  },
  {
    title: 'Organizations',
    type: 'Organizations',
    icon: 'fa fa-list',
    properties: [
      {
        name: 'orderBy',
        displayName: 'Order By',
        type: 'select',
        options: ['name', 'popularity', 'distance', 'publishedDate'],
      },
      { name: 'numberOfItems', displayName: 'Number of items', type: 'number' },
      {
        name: 'singleRow',
        displayName: 'Single row',
        type: 'checkbox',
      },
    ],
  },
  {
    title: 'BlogPosts',
    type: 'BlogPosts',
    icon: 'fa fa-list',
    properties: [
      {
        name: 'orderBy',
        displayName: 'Order By',
        type: 'select',
        options: ['name', 'popularity', 'distance', 'publishedDate'],
      },
      { name: 'numberOfPosts', displayName: 'Number of posts', type: 'number' },
      {
        name: 'singleRow',
        displayName: 'Single row',
        type: 'checkbox',
      },
    ],
  },
];

export default elementTemplates;
