import React from 'react';
import {
  TheContent,
  TheSidebar,
  TheAside,
  TheFooter,
  TheHeader,
} from './index';
import { useUiControls } from '../hooks/uiControls';

const TheLayout = () => {
  const { darkMode } = useUiControls();

  const classes = `c-app c-default-layout ${darkMode ? 'c-dark-theme' : ''}`;

  return (
    <div className={classes}>
      <TheSidebar />
      <TheAside />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayout;
