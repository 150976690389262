import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  CustomInputProps,
  FormFeedback,
  FormGroup,
  Label,
} from 'reactstrap';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field: { name, value },
  form: { errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  ...props
}) => {
  const handleChange = (value: any) => {
    setFieldValue(name, value);
  };

  return (
    <div key={name}>
      {props.label ? <Label for={name}>{props.label}</Label> : null}
      <FormGroup>
        <ButtonToolbar>
          <ButtonGroup>
            {options &&
              options.map((o: any) => (
                <Button
                  color={
                    (o.id ?? o.key ?? o) === value ? 'primary' : 'secondary'
                  }
                  key={o.id ?? o.key ?? o}
                  onClick={(e) => {
                    e.preventDefault();
                    handleChange(o.id ?? o.key ?? o);
                  }}
                >
                  {o.name ?? o.label ?? o}
                </Button>
              ))}
          </ButtonGroup>
        </ButtonToolbar>
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    </div>
  );
};

const ButtonGroupField = (props: any) => (
  <Field {...props} component={StandardInput} />
);

export default ButtonGroupField;
