import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { Column, Row } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { useOrganizations } from '../../../hooks/organizations';
import { useFiles } from '../../../hooks/files';
import { FileModel } from '@nimles/models';

const getImageSrc = ({ tenantId, id, mimeType }: FileModel) => {
  if (!mimeType || mimeType.indexOf('image') < 0) {
    return null;
  }

  return `https://media.nimles.com/file/${tenantId}/${id}?width=200`;
};

export const Files: FC = () => {
  const { files } = useFiles();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  const Thumbnail = styled.img<{ src: string }>`
    height: 48px;
    width: 48px;
    background-image: url('${({ src }) => src}');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  `;

  const ThumbnailBackground = styled.div`
    background-color: #eee;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid #ddd;
  `;

  return (
    <div className="animated fadeIn">
      <Row>
        <Column xl={100}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Files
              <div className="card-header-actions">
                <Link to="/media/files/new" className="btn btn-sm btn-primary">
                  New File
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">Name</th>
                  <th scope="col">Mime Type</th>
                  <th scope="col">Size</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {files &&
                  files.map((file: any, index: number) => {
                    const fileLink = `/media/files/${file.id}`;

                    const src = getImageSrc(file);

                    return (
                      <tr key={file.id.toString()}>
                        <td>
                          <ThumbnailBackground>
                            {src ? <Thumbnail src={src} /> : null}
                          </ThumbnailBackground>
                        </td>
                        <th scope="row">
                          <Link to={fileLink}>
                            <div>{file.name}</div>
                            <div>
                              <small>{file.id}</small>
                            </div>
                          </Link>
                        </th>
                        <td>
                          <Link to={fileLink}>{file.mimeType}</Link>
                        </td>
                        <td>
                          {file.size > 1048576
                            ? Math.round(file.size / 1048576) + ' MB'
                            : file.size > 1024
                            ? Math.round(file.size / 1024) + ' kB'
                            : file.size + ' B'}
                        </td>
                        <td>{getOrganizationName(file.organizationId)}</td>
                        <td>{file.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Column>
      </Row>
    </div>
  );
};
