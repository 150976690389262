import styled from '@emotion/styled';
import {
  faCheck,
  faCheckCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TenantModel } from '@nimles/models';
import {
  setLayoutAccessibility,
  setPageAccessibility,
} from '@nimles/react-redux';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, ListGroup, ListGroupItem } from 'reactstrap';
import { AccessibilityButton } from '../../components/AccessibilityButton';
import { useLayouts } from '../../hooks/layouts';
import { usePages } from '../../hooks/pages';
import { useThemes } from '../../hooks/themes';
import { RootState } from '../../redux/types';

const Step = styled(ListGroupItem)``;

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StepName = styled.div`
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  flex: 1;
`;

const StepAction = styled.div``;

const StepSummary = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  padding: 10px 20px;
`;

export const SetupProgress = () => {
  const dispatch = useDispatch();
  const [selectedIndex, selectIndex] = useState<number>();
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );
  const { layouts } = useLayouts();
  const { themes } = useThemes();
  const { pages } = usePages();

  const layout = layouts.find((l) => l.layoutType === 'WebAndApp');
  const theme =
    layout && themes.length
      ? // ? themes.find((t) => t.id === layout.themeId) || themes[0]
        themes[0]
      : undefined;

  const publicPages = pages.filter((p) => p.accessibility === 'Public');

  const changeLayoutAccessibility = async (layout: any, accessibility: any) => {
    await dispatch(setLayoutAccessibility(layout.id, accessibility));
  };

  const changePageAccessibility = async (page: any, accessibility: any) => {
    await dispatch(setPageAccessibility(page.id, accessibility));
  };

  const steps = [
    {
      name: () => (
        <div>
          Tenant: <small>{tenant?.name}</small>
        </div>
      ),
      summary: () => (
        <Table size="sm">
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>{tenant?.name}</td>
            </tr>
          </tbody>
        </Table>
      ),
      validate: () => {
        return !!tenant?.name;
      },
      editLink: () => `/identity/tenants/${tenant?.id}`,
    },
    {
      name: () => (
        <div>
          Layout: <small>{layout ? layout.name : ''}</small>
        </div>
      ),
      summary: () => (
        <Table size="sm">
          <tbody>
            {layouts.map((layout) => (
              <tr key={layout.id}>
                <td>
                  <strong>{layout.layoutType}</strong>
                </td>
                <td>{layout.name}</td>
                <td>
                  {layout.accessibility === 'Public' ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <AccessibilityButton
                      accessibility={layout.accessibility}
                      onChange={(a) => changeLayoutAccessibility(layout, a)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ),
      validate: () => {
        return !!layout;
      },
      editLink: () => `/cms/layouts`,
    },
    {
      name: () => (
        <div>
          Theme: <small>{theme ? theme.name : ''}</small>
        </div>
      ),
      summary: () => (
        <Table size="sm">
          <tbody>
            <tr>
              <td>
                <strong>{theme && theme.name}</strong>
              </td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      ),
      validate: () => {
        return !!theme;
      },
      editLink: () => `/cms/themes`,
    },
    {
      name: () => (
        <div>
          Pages: <small>{pages.map((p) => p.pageType).join(', ')}</small>
        </div>
      ),
      summary: () => (
        <Table size="sm">
          <tbody>
            {pages.map((page) => (
              <tr key={page.id}>
                <td>
                  <strong>{page.pageType}</strong>
                </td>
                <td>{page.title}</td>
                <td>
                  {page.accessibility === 'Public' ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <AccessibilityButton
                      accessibility={page.accessibility}
                      onChange={(a) => changePageAccessibility(page, a)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ),
      validate: () => {
        return !!publicPages.some((p) => p.pageType === 'FirstPage');
      },
      editLink: () => `/cms/pages`,
    },
  ];

  return (
    <ListGroup>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepHeader
            onClick={() =>
              selectIndex(selectedIndex === index ? undefined : index)
            }
          >
            {step.validate() ? (
              <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
            ) : (
              <FontAwesomeIcon icon={faCircle} size="lg" color="lightgrey" />
            )}
            <StepName>{step.name()}</StepName>
            <StepAction>
              <Link to={step.editLink()}>Modify</Link>
            </StepAction>
          </StepHeader>
          <StepSummary open={selectedIndex === index}>
            {step.summary()}
          </StepSummary>
        </Step>
      ))}
    </ListGroup>
  );
};
