import { FC, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ElementEditor } from './ElementEditor';
import availableElements, { ElementTemplate } from './elementTemplates';
import { Column, Row } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { ElementModel } from '@nimles/models';

const ElementButton = styled.button`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  background: transparent;
  outline: none;
  padding: 20px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export interface AddElemenModalProps {
  parent: ElementModel;
  root?: string | null;
  onClose: () => void;
  onAdd: (element: ElementModel, parent: ElementModel) => void;
}

export const AddElementModal: FC<AddElemenModalProps> = ({
  parent,
  root,
  onClose,
  onAdd,
}) => {
  const [elementTemplate, setElementTemplate] = useState<ElementTemplate>();
  const [element, setElement] = useState<any>();

  const handleToggle = () => {
    if (element && parent) {
      onAdd(element, parent);
    } else {
      onClose();
    }
    setElementTemplate(undefined);
    setElement(undefined);
  };

  const handleInsertElement = (element: ElementTemplate) => {
    const { type } = element;
    if (element.closeOnAdd) {
      onAdd({ type }, parent);
    } else {
      setElementTemplate(element);
      setElement({ type });
    }
  };

  const handleChange = (element: ElementModel) => {
    if (elementTemplate?.closeOnChange) {
      onAdd(element, parent);
      setElement(undefined);
      setElementTemplate(undefined);
    } else {
      setElement(element);
    }
  };

  const parentTemplate =
    parent && availableElements.find(({ type }) => parent.type === type);

  return (
    <Modal isOpen={!!parent} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>Add element</ModalHeader>
      <ModalBody>
        {elementTemplate ? (
          <ElementEditor element={element} onChange={(e) => handleChange(e)} />
        ) : (
          <Row wrap="wrap">
            {parent &&
              availableElements
                .filter(
                  (e) =>
                    (!parentTemplate?.children ||
                      parentTemplate.children.indexOf(e.type) !== -1) &&
                    (!e.parents ||
                      (parent.type && e.parents.indexOf(parent.type) !== -1)) &&
                    (!e.roots || (root && e.roots.indexOf(root) !== -1))
                )
                .map((element) => (
                  <Column
                    key={element.type}
                    padding
                    xs={100}
                    sm={50}
                    md={33}
                    align="center"
                  >
                    <ElementButton
                      onClick={(e) => {
                        e.preventDefault();
                        handleInsertElement(element);
                      }}
                    >
                      <h4>{element.title}</h4>
                      <i className={element.icon} />
                    </ElementButton>
                  </Column>
                ))}
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export interface UpdateElemenModalProps {
  element?: ElementModel;
  onClose: () => void;
  onChange: (element: ElementModel) => void;
}

export const UpdateElementModal: FC<UpdateElemenModalProps> = ({
  element,
  onClose,
  onChange,
}) => {
  const handleToggle = () => {
    onClose();
  };

  const handleUpdate = (element: ElementModel) => {
    onChange(element);
  };

  return (
    <Modal isOpen={!!element} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>Edit element</ModalHeader>
      <ModalBody>
        {element && <ElementEditor element={element} onChange={handleUpdate} />}
      </ModalBody>
    </Modal>
  );
};
