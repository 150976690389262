import { FC } from 'react';
import { Form } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { InputField, SelectField, RadioField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, CustomerModel } from '@nimles/models';

const validationSchema = function () {
  return Yup.object().shape({});
};

interface Props {
  customer: CustomerModel;
  customers: CustomerModel[];
  organizations: OrganizationModel[];
  onSave: (customer: any) => Promise<void>;
}

const customerTypes = [
  { name: 'Private person', value: 'Private' },
  { name: 'Organization', value: 'Organization' },
];
export const CustomerEditor: FC<Props> = ({
  customer,
  customers,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const user = {
        ...values,
      };
      await onSave(user);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values = customer && {
    id: customer?.id,
    buyer: {
      customerType: customer.buyer?.customerType || '',
      firstName: customer.buyer?.firstName || '',
      lastName: customer.buyer?.lastName || '',
      organizationName: customer.buyer?.organizationName || '',
      email: customer?.buyer?.email || '',
      phoneNumber: customer?.buyer?.phoneNumber || '',
      billingAddress: {
        id: customer?.buyer?.billingAddress?.id,
        street: customer?.buyer?.billingAddress?.street || '',
        coAddress: customer?.buyer?.billingAddress?.coAddress || '',
        city: customer?.buyer?.billingAddress?.city || '',
        postalCode: customer?.buyer?.billingAddress?.postalCode || '',
        state: customer?.buyer?.billingAddress?.state || '',
        country: customer?.buyer?.billingAddress?.country || '',
      },
      deliveryAddress: {
        id: customer?.buyer?.deliveryAddress?.id,
        street: customer?.buyer?.deliveryAddress?.street || '',
        coAddress: customer?.buyer?.deliveryAddress?.coAddress || '',
        city: customer?.buyer?.deliveryAddress?.city || '',
        postalCode: customer?.buyer?.deliveryAddress?.postalCode || '',
        state: customer?.buyer?.deliveryAddress?.state || '',
        country: customer?.buyer?.deliveryAddress?.country || '',
      },
      userId: customer?.buyer?.userId,
      organizationId: customer?.buyer?.organizationId,
    },
    seller: {
      userId: customer?.seller?.userId,
      organizationId: customer?.seller?.organizationId,
    },
  };

  return customer ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}

          {!values.id ? (
            <RadioField
              name="buyer.customerType"
              label="Contact Type"
              options={customerTypes}
            />
          ) : null}
          {values.buyer.customerType === 'Organization' ? (
            <>
              {organizations && (
                <SelectField
                  name="buyer.organizationId"
                  label="Organization"
                  placeholder="Select organization"
                  options={organizations}
                  optionName={(o) => o.name}
                  optionValue={(o) => o.id}
                />
              )}
              <InputField
                name="buyer.organizationName"
                label="Organization Name"
                required
              />
            </>
          ) : (
            <>
              <InputField
                name="buyer.firstName"
                label="First name"
                placeholder="First name"
                required
              />
              <InputField
                name="buyer.lastName"
                label="Last name"
                placeholder="Last name"
                required
              />
            </>
          )}
          <InputField
            name="buyer.identityNumber"
            label="Identity Number"
            placeholder="Identity Number"
          />
          <InputField name="buyer.email" label="Email" placeholder="Email" />
          <InputField
            name="buyer.phoneNumber"
            label="Phone Number"
            placeholder="Phone number"
          />
        </Form>
      )}
    />
  ) : null;
};
