import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useParams } from 'react-router';
import {
  updateCoursework,
  publishCoursework,
  setCourseworkAccessibility,
} from '@nimles/react-redux';
import { CourseworkEditor } from './CourseworkEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useOrganizations } from '../../../hooks/organizations';
import { useCourseworks } from '../../../hooks/courseworks';

interface Params {
  courseworkId: string;
}

export const Coursework = () => {
  const dispatch = useDispatch();
  const { courseworkId } = useParams<Params>();

  const { organizations } = useOrganizations();
  const { courseworks } = useCourseworks();
  const coursework = courseworks.find((l: any) => l.id === courseworkId);
  const organizationId = coursework && coursework.organizationId;

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, organizationId);
    await dispatch(
      updateCoursework({ ...values, elements: removeTempIds(values.elements) })
    );
    if (publish) {
      coursework?.id && (await dispatch(publishCoursework(coursework.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    coursework?.id &&
      (await dispatch(
        setCourseworkAccessibility(coursework.id, accessibility)
      ));
  };

  return coursework ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit {coursework.courseworkType} Coursework</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={coursework.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <CourseworkEditor
            coursework={coursework}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
