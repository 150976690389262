import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { createPost, publishPost } from '@nimles/react-redux';
import { useHistory } from 'react-router';
import { PostEditor } from './PostEditor';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useOrganizations } from '../../../hooks/organizations';

const post = {
  elements: [
    {
      type: 'Section',
      id: 'temp-1',
      uniqueName: 'section-1',
      properties: { backgroundImage: '#999999' },
      children: [
        {
          type: 'Html',
          content: '<h1>My Post</h1><p>Here is my post</p>',
          id: 'temp-111',
          uniqueName: 'html-1',
        },
      ],
    },
  ],
};

export const NewPost: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    const post: any = await dispatch(
      createPost({
        ...values,
        elements: removeTempIds(values.elements),
        accessibility: 'Public',
      })
    );
    if (publish) {
      await dispatch(publishPost(post.id));
    }
    history.push('/cms/posts/' + post.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Post</strong>
        </CardHeader>
        <CardBody>
          <PostEditor
            post={post}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
