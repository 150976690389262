import { createLogisticsOption, State } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { LogisticsOptionEditor } from './LogisticsOptionEditor';
import { RootState } from '../../../redux/types';
import { OrganizationModel, LogisticsOptionModel } from '@nimles/models';

export const NewLogisticsOption: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const processor = query?.get('processor?');

  const { values: organizations, selected: organization } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);

  const onSave = async (values: any) => {
    const logisticsOption: any = await dispatch(
      createLogisticsOption({
        ...values,
      })
    );

    history.push(`/ecommerce/logisticsOptions/${logisticsOption.id}`);
  };

  const logisticsOption: LogisticsOptionModel = {
    organizationId: organization?.id,
  };

  return logisticsOption ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>
            {processor ? 'Add ' + processor : 'New logisticsOption'}
          </strong>
          <p>
            {processor
              ? 'Setup delivery processor'
              : 'Create new logisticsOption'}
          </p>
        </CardHeader>
        <CardBody>
          <LogisticsOptionEditor
            logisticsOption={logisticsOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
