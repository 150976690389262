import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { selectTenant, State } from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../redux/types';
import { TenantModel } from '@nimles/models';

export const Tenants: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { values: tenants, selected } = useSelector<
    RootState,
    State<TenantModel>
  >(({ tenants }) => tenants);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Tenants
              <div className="card-header-actions">
                <Link
                  to="/identity/tenants/new"
                  className="btn btn-sm btn-primary"
                >
                  New Tenant
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Hostname</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {tenants.map((tenant) => {
                  const tenantLink = `/identity/tenants/${tenant.id}`;
                  return (
                    <tr
                      key={tenant.id?.toString()}
                      className={tenant.id === selected?.id ? 'bg-info' : ''}
                    >
                      <td>
                        <Link to={tenantLink}>
                          <div>
                            <strong>{tenant.name}</strong>
                          </div>
                          <div>
                            <small>{tenant.id}</small>
                          </div>
                          <div>{tenant.uniqueName}</div>
                        </Link>
                      </td>
                      <td>{tenant.hostName}</td>
                      <td>{tenant.accessibility}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle nav>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                history.push(
                                  `/identity/tenants/new?tenantId=${tenant.id}`
                                )
                              }
                            >
                              Clone
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => dispatch(selectTenant(tenant))}
                            >
                              Select
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
