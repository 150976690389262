import {
  publishOrganization,
  selectOrganization,
  State,
} from '@nimles/react-redux';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Table,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import { RootState } from '../../../redux/types';
import { Row, Column, Input } from '@nimles/react-web-components';
import Select from 'react-select';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { OrganizationModel } from '@nimles/models';
import { Image } from '../../../components/images';
import { useTenants } from '../../../hooks';

interface MenuItem {
  label: string;
  onClick: (organization: OrganizationModel) => void;
}

const ImageCol = styled.col`
  width: 180px;
`;

const NameCol = styled.col`
  min-width: 240px;
`;

const Col = styled.col``;

const NameLink = styled(Link)`
  white-space: nowrap;
  text-decoration: none;
`;
export const Organizations: FC = () => {
  const dispatch = useDispatch();

  const { tenants } = useTenants();
  const { values: organizations, selected } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);
  const [query, setQuery] = useState<string>();
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    OrganizationModel[]
  >([]);
  interface TagOption {
    label: string;
    value: string;
  }

  const [tags, setTags] = useState<TagOption[]>([]);
  const [selectedTags, selectTags] = useState<
    { label: string; value: string }[]
  >([]);

  const getTenantName = (tenantId: string) => {
    const tenant = tenants.find((o) => o.id === tenantId);

    return tenant ? tenant.name : tenantId;
  };

  useEffect(() => {
    setFilteredOrganizations(
      organizations?.filter(
        (org) =>
          (query
            ? org.uniqueName?.includes(query.toLowerCase()) ||
              org.name?.toLowerCase().includes(query.toLowerCase())
            : true) &&
          (selectedTags?.length
            ? selectedTags.some((tag) => org.tags?.includes(tag.value))
            : true)
      )
    );
  }, [organizations, query, selectedTags]);

  useEffect(() => {
    setTags(
      organizations
        .reduce((list, org) => (org.tags ? [...list, ...org.tags] : list), [])
        .sort()
        .reduce<{ name: string; count: number }[]>((items, name) => {
          let item = items.find((i) => i.name === name);
          if (item) {
            item.count++;
          } else {
            items.push({ name, count: 1 });
          }
          return items;
        }, [])
        .map(({ name, count }) => ({
          label: `${name} (${count})`,
          value: name,
        }))
    );
  }, [organizations, query]);

  const menuItems: MenuItem[] = [
    {
      label: 'Publish',
      onClick: (organization) =>
        organization.id && dispatch(publishOrganization(organization.id)),
    },
    {
      label: 'Select',
      onClick: (organization) =>
        dispatch(
          selectOrganization(
            organization.id === selected?.id ? {} : organization
          )
        ),
    },
  ];

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Organizations
          <div className="card-header-actions">
            <Link
              to="/organization-management/organizations/new"
              className="btn btn-sm btn-primary"
            >
              New Organization
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <Row wrap="wrap">
            <Column xs={100} md={50}>
              <Input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search..."
              />
            </Column>
            <Column xs={100} md={50}>
              <Select
                options={tags}
                value={selectedTags}
                onChange={(tags: TagOption[]) => selectTags(tags)}
                isMulti
              />
            </Column>
          </Row>
        </CardBody>
        <Table responsive hover>
          <colgroup>
            <ImageCol />
            <NameCol />
            <Col />
            <Col />
            <Col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Tags</th>
              <th scope="col">Tenant</th>
              <th scope="col">Accessibility</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrganizations.map((organization: any, index: any) => {
              const organizationLink = `/organization-management/organizations/${organization.id}`;

              return (
                <tr
                  key={organization.id.toString()}
                  className={organization.id === selected?.id ? 'bg-info' : ''}
                >
                  <td>
                    {organization.logoId || organization.bannerId ? (
                      <Image
                        width={180}
                        image={{
                          id: organization.logoId ?? organization.bannerId,
                          tenantId: organization.tenantId,
                        }}
                      />
                    ) : null}
                  </td>
                  <td>
                    <NameLink to={organizationLink}>
                      <div>
                        <strong>{organization.name}</strong>
                      </div>
                      <div>
                        <small>{organization.id}</small>
                      </div>
                      <div>{organization.uniqueName}</div>
                    </NameLink>
                  </td>
                  <td>{organization.tags?.join(', ')}</td>
                  <td>{getTenantName(organization.tenantId)}</td>
                  <td>
                    <Badge
                      color={organization.published ? 'success' : 'secondary'}
                    >
                      <Badge
                        color={
                          organization.status === 'Published'
                            ? 'success'
                            : 'secondary'
                        }
                      >
                        {organization.accessibility}
                      </Badge>
                    </Badge>
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle nav>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </DropdownToggle>
                      <DropdownMenu>
                        {menuItems.map(({ label, onClick }) => (
                          <DropdownItem
                            key={label}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClick(organization);
                            }}
                          >
                            {label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
