import { FC } from 'react';
import { updateUser } from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { UserEditor } from './UserEditor';
import { RootState } from '../../../redux/types';
import { TenantModel } from '@nimles/models';
import { useUsers } from '../../../hooks/users';

interface Params {
  userId: string;
}

export const User: FC = () => {
  const dispatch = useDispatch();
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );
  const { userId } = useParams<Params>();
  const { users } = useUsers();
  const user = users.find((c: any) => c.id === userId);

  const handleSave = async (values: any) => {
    await dispatch(updateUser({ ...values }));
  };

  return user && tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>User</strong>
        </CardHeader>
        <CardBody>
          <UserEditor user={user} tenant={tenant} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
