import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createDiscount, State } from '@nimles/react-redux';
import { DiscountEditor } from './DiscountEditor';
import { RootState } from '../../../redux/types';
import { OrganizationModel, DiscountModel } from '@nimles/models';
import { useProducts } from '../../../hooks/products';

export const NewDiscount: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { values: organizations, selected: organization } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);
  const { products } = useProducts();

  const handleSave = async (values: any) => {
    const discount: any = await dispatch(createDiscount({ ...values }));

    history.push('/ecommerce/discounts/' + discount.id);
  };

  const discount: DiscountModel = {
    name: '',
    organizationId: organization?.id,
  };

  console.log(discount);
  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Discount</strong>
        </CardHeader>
        <CardBody>
          <DiscountEditor
            discount={discount}
            products={products}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
