import { FC } from 'react';
import { Form, Input } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import DragAndDropField from '../../../components/fields/DragAndDropField';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, PipelineModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({});
};

interface Props {
  pipeline: PipelineModel;
  organizations: OrganizationModel[];
  onSave: (pipeline: any) => Promise<void>;
}

export const PipelineEditor: FC<Props> = ({
  pipeline,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: PipelineModel,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<PipelineModel>
  ) => {
    try {
      console.info(values);
      const user = {
        ...values,
      };
      await onSave(user);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };
  const values = pipeline && {
    id: pipeline.id,
    organizationId: pipeline.organizationId,
    name: pipeline.name || '',
    stages: pipeline.stages || [],
  };

  const organization = organizations.find(
    (o) => o.id === values.organizationId
  );
  const organizationName = organization?.name ?? '';

  return pipeline ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}
          {organizations &&
            (values.id ? (
              <>
                <label>Organization</label>
                <Input disabled value={organizationName} />
              </>
            ) : (
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={pipeline.organizationId}
              />
            ))}
          <InputField name="name" label="Name" required />
          <DragAndDropField
            name="stages"
            label="Stages"
            required
            onAdd={(v: any) =>
              setFieldValue('stages', [
                ...values.stages,
                { ...v, name: 'New Stage' },
              ])
            }
          />
        </Form>
      )}
    />
  ) : null;
};
