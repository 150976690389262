import styled from '@emotion/styled';
import {
  ProductGroupModel,
  ProductPropertyModel,
  FileModel,
  QuoteLineModel,
} from '@nimles/models';
import { ListItem, Row, Column, Image } from '@nimles/react-web-components';
import { useSelector } from 'react-redux';
import {
  Property,
  PropertyTitle,
  PropertyValue,
} from '../../../components/Property';
import { useFiles } from '../../../hooks/files';
import { RootState } from '../../../redux/types';

const StyledLine = styled.div`
  border-bottom: 1px solid #ecdede;
`;

interface Props {
  line: QuoteLineModel;
}

export const QuoteLine = ({ line }: Props) => {
  const productGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ productGroups }) => productGroups.values
  );
  const productProperties = useSelector<RootState, ProductPropertyModel[]>(
    ({ productProperties }) => productProperties.values
  );
  const { files } = useFiles();

  const productGroup = productGroups.find(
    ({ id }) => id === line.productGroupId
  );

  return (
    <StyledLine key={line.id}>
      <h4>
        <Row>
          <Column flex>
            {productGroup?.name} {line.name}
          </Column>
          <Column>
            {line.totalNetPrice &&
              new Intl.NumberFormat('sv', {
                style: 'currency',
                currency: 'EUR',
              }).format(line.totalNetPrice)}
          </Column>
        </Row>
      </h4>
      {line.description && (
        <div dangerouslySetInnerHTML={{ __html: line.description }} />
      )}
      {line.productProperties?.map(({ productPropertyId, values }) => {
        const productProperty = productProperties.find(
          ({ id }) => id === productPropertyId
        );

        if (!productProperty) {
          return <div key={productPropertyId}>{productPropertyId}</div>;
        }

        return (
          <Property key={productProperty.id}>
            <PropertyTitle>{productProperty.name}</PropertyTitle>
            {line.description ? (
              <PropertyValue
                dangerouslySetInnerHTML={{
                  __html: line.description,
                }}
              />
            ) : null}

            {productProperty.propertyType?.indexOf('File') === 0
              ? values
                  ?.map<FileModel>(
                    (fileId) =>
                      files.find(({ id }) => id === fileId) ?? {
                        id: fileId,
                      }
                  )
                  .map(({ id, name, mimeType, uri }) => (
                    <ListItem key={id}>
                      <Row wrap="wrap" align="center">
                        <Column xs={100} sm={20} align="center">
                          {!mimeType ? (
                            id
                          ) : uri &&
                            mimeType.toLowerCase()?.indexOf('image') === 0 ? (
                            <Image src={uri} alt="" />
                          ) : (
                            mimeType
                              ?.split('/')
                              .map((s, index) => <div key={index}>{s}</div>)
                          )}
                        </Column>
                        <Column flex>
                          <div>{name}</div>
                        </Column>
                      </Row>
                    </ListItem>
                  ))
              : values?.map((value, index) => {
                  value =
                    productProperty.propertyType?.indexOf('Option') !== -1
                      ? productProperty.options?.find((o) => value === o.value)
                          ?.name ?? value
                      : value;
                  return (
                    <PropertyValue
                      key={index}
                      dangerouslySetInnerHTML={{ __html: value }}
                    />
                  );
                })}
          </Property>
        );
      })}
    </StyledLine>
  );
};
