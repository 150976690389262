import { FC } from 'react';
import { Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import { validate } from '../../_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import { Fieldset } from '../../../components/Fieldset';
import { emptyTheme, defaultTheme, Column } from '@nimles/react-web-components';
import ColorField from '../../../components/fields/ColorField';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, ThemeModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { merge } from 'merge-anything';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  theme: ThemeModel;
  organizations?: OrganizationModel[];
  onSave: (theme: any) => void;
}

const colors = [
  'background',
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'surface',
  'highlight',
];

const texts = [
  { name: 'Normal', value: 'normal' },
  { name: 'Heading 1', value: 'heading1' },
  { name: 'Heading 2', value: 'heading2' },
  { name: 'Heading 3', value: 'heading3' },
  { name: 'Heading 4', value: 'heading4' },
  { name: 'Heading 5', value: 'heading5' },
  { name: 'Heading 6', value: 'heading6' },
];

export const ThemeEditor: FC<Props> = ({ theme, organizations, onSave }) => {
  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const theme = {
        ...values,
      };
      onSave(theme);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: ThemeModel = {
    id: theme.id,
    name: theme.name || '',
    uniqueName: theme.uniqueName || '',
    styles: theme.styles ? merge(emptyTheme, theme.styles) : defaultTheme,
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField label="Id" name="id" disabled required />}
          <OrganizationSelectField
            organizations={organizations}
            defaultOrganizationId={theme.organizationId}
          />
          <InputField label="Name" type="text" name="name" id="name" required />
          <InputField
            label="Unique name"
            type="text"
            name="uniqueName"
            id="uniqueName"
          />

          <Fieldset legend="Colors">
            {colors.map((color) => {
              return (
                <Row>
                  <Column xs={40}>
                    <ColorField
                      name={`styles.colors.${color}.color`}
                      textColor={values['styles']['colors'][color]['onColor']}
                      placeholder={color}
                      required
                    />
                  </Column>
                  <Column xs={30}>
                    <ColorField
                      name={`styles.colors.${color}.onColor`}
                      textColor={values['styles']['colors'][color]['color']}
                      placeholder={'on'}
                      required
                    />
                  </Column>
                </Row>
              );
            })}
          </Fieldset>
          <Fieldset legend="Texts">
            {texts.map((text) => (
              <Row>
                <Column xs={50}>
                  <InputField
                    label={text.name}
                    name={`styles.texts.${text.value}.font`}
                    placeholder="Font"
                    required
                  />
                </Column>
                <Column xs={30}>
                  <InputField
                    label="Size"
                    name={`styles.texts.${text.value}.fontSize`}
                    placeholder="Size"
                    required
                  />
                </Column>
                <Column xs={20}>
                  <ColorField
                    label="Color"
                    name={`styles.texts.${text.value}.color`}
                    required
                  />
                </Column>
              </Row>
            ))}
          </Fieldset>
          <Fieldset legend="Header">
            <Row>
              <Column xs={50}>
                <ColorField label="Color" name="styles.header.color" required />
              </Column>
              <Column xs={50}>
                <ColorField
                  label="Background"
                  name="styles.header.background"
                  required
                />
              </Column>
            </Row>
            <Row>
              <Column xs={100}>
                <InputField
                  label="Box shadow"
                  name="styles.header.boxShadow"
                  placeholder="shadow"
                  required
                />
              </Column>
              <Column xs={100}>
                <InputField
                  label="Position"
                  name="styles.header.position"
                  placeholder="static|fixed"
                  required
                />
              </Column>
            </Row>
          </Fieldset>
          <Fieldset legend="Footer">
            <Row>
              <Column xs={50}>
                <ColorField label="Color" name="styles.footer.color" required />
              </Column>
              <Column xs={50}>
                <ColorField
                  label="Background"
                  name="styles.footer.background"
                  required
                />
              </Column>
            </Row>
            <Row>
              <Column xs={100}>
                <InputField
                  label="Box shadow"
                  name="styles.footer.boxShadow"
                  placeholder="shadow"
                  required
                />
              </Column>
              <Column xs={100}>
                <InputField
                  label="Position"
                  name="styles.footer.position"
                  placeholder="static|fixed"
                  required
                />
              </Column>
            </Row>
          </Fieldset>
          <Fieldset legend="Global">
            <InputField
              label="Border radius"
              name="styles.borderRadius"
              placeholder="px"
              required
            />
          </Fieldset>
        </Form>
      )}
    />
  );
};
