import React, { FC, InputHTMLAttributes, useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';

const getBase64 = (file: File) => {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString());
    reader.onerror = (error) => reject(error);
  });
};

const Image = styled.img`
  width: 100%;
`;

interface Props extends FieldProps {
  label: string;
  onFileChange: (data: string, file: File) => void;
  disabled?: boolean;
}

const FileInput: FC<Props> = ({ field, label, onFileChange, disabled }) => {
  const onDrop = useCallback(
    async (files) => {
      const data = files.length && (await getBase64(files[0]));
      if (data) {
        onFileChange(data, files[0]);
      }
    },
    [onFileChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return disabled ? (
    <Image src={field.value} alt="" />
  ) : (
    <div key={field.name} {...getRootProps()}>
      <input {...getInputProps()} />
      <label>{label}</label>
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
      <Image src={field.value} alt="" />
    </div>
  );
};

interface FileProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onFileChange: (data: string, file: File) => void;
}

export const FileField: FC<FileProps> = (props) => (
  <Field {...props} component={FileInput} />
);

export default FileField;
