import moment from 'moment';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { OrderModel } from '@nimles/models';
import { Price } from '../../../components/Price';
import { useOrders } from '../../../hooks/orders';

function OrderRow({ order }: { order: OrderModel }) {
  const orderLink = `/ecommerce/orders/${order.id}`;

  const getBadge = (status?: string | null) => {
    return status === 'Active'
      ? 'success'
      : status === 'Inactive'
      ? 'secondary'
      : status === 'Pending'
      ? 'warning'
      : status === 'Banned'
      ? 'danger'
      : 'primary';
  };

  const orderHead = order.head;
  return (
    <tr key={order.id?.toString()}>
      <th scope="row">
        <Link to={orderLink}>{orderHead?.orderNumber}</Link>
      </th>
      <td>
        {orderHead?.orderDate && moment(orderHead?.orderDate).format('lll')}
      </td>
      <td>
        <div>
          {`${orderHead?.buyer?.firstName ?? '-'} ${
            orderHead?.buyer?.lastName ?? '-'
          }`}
        </div>
        <div>
          <strong>{orderHead?.buyer?.organizationName}</strong>
        </div>
        <div>{orderHead?.buyer?.email}</div>
        <div>{orderHead?.buyer?.phoneNumber}</div>
        <div>
          <small>{orderHead?.id}</small>
        </div>
      </td>
      <td>{orderHead?.seller?.organizationId}</td>
      <td style={{ textAlign: 'right' }}>{order.lines?.length}</td>
      <td style={{ textAlign: 'right' }}>
        <Price
          currency={orderHead?.currency}
          value={orderHead?.totalNetPrice}
        />
      </td>
      <td>
        <Link to={orderLink}>
          <Badge color={getBadge(orderHead?.orderStatus)}>
            {orderHead?.orderStatus}
          </Badge>
        </Link>
      </td>
    </tr>
  );
}

export const Orders: FC = () => {
  const { orders } = useOrders();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orderType = query?.get('orderType?');

  const selectedOrders = orderType
    ? orders.filter((order: OrderModel) => order)
    : orders;

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Orders
              <div className="card-header-actions">
                <Link
                  to="/ecommerce/orders/new"
                  className="btn btn-sm btn-primary"
                >
                  New Order
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover striped>
              <thead>
                <tr>
                  <th scope="col">Order number</th>
                  <th scope="col">Order date</th>
                  <th scope="col">Buyer</th>
                  <th scope="col">Seller</th>
                  <th scope="col" style={{ textAlign: 'right' }}>
                    Nbr of lines
                  </th>
                  <th scope="col" style={{ textAlign: 'right' }}>
                    Price
                  </th>
                  <th scope="col">status</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrders &&
                  selectedOrders.map((order: any, index: number) => (
                    <OrderRow key={index} order={order} />
                  ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
