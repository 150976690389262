import { FC } from 'react';
import { Form, Input } from 'reactstrap';
import { Formik, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { InputField, SelectField, RadioField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, RelationshipModel } from '@nimles/models';

const validationSchema = function () {
  return Yup.object().shape({});
};

interface Props {
  relationship: RelationshipModel;
  relationships: RelationshipModel[];
  organizations: OrganizationModel[];
  onSave: (relationship: any) => Promise<void>;
}

const contactTypes = [
  { name: 'Person', value: 'Person' },
  { name: 'Organization', value: 'Organization' },
];
export const RelationshipEditor: FC<Props> = ({
  relationship,
  relationships,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const user = {
        ...values,
      };
      await onSave(user);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values = relationship && {
    id: relationship.id,
    organizationId: relationship.organizationId,
    contactType: relationship.contactType || '',
    parentId: relationship.parentId,
    contact: {
      id: relationship.contact?.id,
      name: relationship.contact?.name || '',
      firstName: relationship.contact?.firstName || '',
      lastName: relationship.contact?.lastName || '',
      email: relationship.contact?.email || '',
      identityNumber: relationship.contact?.identityNumber || '',
    },
  };

  const organization = organizations.find(
    (o) => o.id === values.organizationId
  );
  const organizationName = organization ? organization.name : '';

  return relationship ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}
          {organizations &&
            (values.id ? (
              <>
                <label>Organization</label>
                <Input disabled value={organizationName ?? ''} />
              </>
            ) : (
              <SelectField
                name="organizationId"
                label="Organization"
                placeholder="Select organization"
                options={organizations}
                optionName={(o) => o.name}
                optionValue={(o) => o.id}
                value={values.organizationId}
              />
            ))}
          {!values.id ? (
            <RadioField
              name="contactType"
              label="Contact Type"
              options={contactTypes}
            />
          ) : null}
          {values.contactType === 'Organization' ? (
            <>
              <InputField
                name="contact.name"
                label="Organization Name"
                required
              />
              <InputField name="contact.domain" label="Domain" />
              <InputField name="contact.email" label="Email" />
              <InputField
                name="contact.identityNumber"
                label="Identity Number"
              />
            </>
          ) : values.contactType === 'Person' ? (
            <>
              <InputField
                name="contact.firstName"
                label="First name"
                placeholder="First name"
                required
              />
              <InputField
                name="contact.lastName"
                label="Last name"
                placeholder="Last name"
                required
              />
              <InputField
                name="contact.email"
                label="Email"
                placeholder="Email"
              />
              <InputField
                name="contact.phoneNumber"
                label="Phone Number"
                placeholder="phoneNumber"
              />
              <InputField
                name="contact.identityNumber"
                label="Identity Number"
                placeholder="Identity Number"
              />
              <SelectField
                name="parentId"
                label="Associated Organization"
                placeholder="Select organization"
                options={relationships}
                optionName={(o) => o.contact.name}
                optionValue={(o) => o.id}
                value={values.parentId}
              />
            </>
          ) : null}
        </Form>
      )}
    />
  ) : null;
};
