import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { useParams } from 'react-router';
import {
  updatePost,
  setPostAccessibility,
  publishPost,
} from '@nimles/react-redux';
import { PostEditor } from './PostEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { usePosts } from '../../../hooks/posts';

interface Params {
  postId: string;
}

export const Post = () => {
  const dispatch = useDispatch();
  const { postId } = useParams<Params>();
  const { posts } = usePosts();
  const post = posts.find((l) => l.id === postId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    await dispatch(
      updatePost({ ...values, elements: removeTempIds(values.elements) })
    );
    if (publish) {
      post?.id && (await dispatch(publishPost(post.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    post?.id && (await dispatch(setPostAccessibility(post.id, accessibility)));
  };

  return post ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {post.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(post.createdDate).format('LLL')}
            </div>
          ) : null}
          {post.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(post.lastModified).format('LLL')}
            </div>
          ) : null}
          {post.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(post.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Post</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={post.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <PostEditor post={post} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
