import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { paymentOptionTemplates } from './paymentOptionsTemplates';
import { useOrganizations } from '../../../hooks/organizations';
import { usePaymentOptions } from '../../../hooks/paymentOptions';

export const PaymentOptions: FC = () => {
  const { paymentOptions } = usePaymentOptions();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> paymentOptions
              <div className="card-header-actions">
                {paymentOptionTemplates.map(({ name }) => (
                  <Link
                    key={name}
                    to={`/ecommerce/paymentOptions/new?processor=${name}`}
                    className="card-header-action"
                  >
                    Add {name}
                  </Link>
                ))}
                <Link
                  to="/ecommerce/paymentOptions/new"
                  className="card-header-action"
                >
                  Add Payment Option
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {paymentOptions &&
                  paymentOptions.map((paymentOption: any) => {
                    const paymentOptionLink = `/ecommerce/paymentOptions/${paymentOption.id}`;

                    return (
                      <tr key={paymentOption.id && paymentOption.id.toString()}>
                        <th scope="row">
                          <Link to={paymentOptionLink}>
                            <div>
                              <strong>{paymentOption.name}</strong>
                            </div>
                            <div>
                              <small>{paymentOption.id}</small>
                            </div>
                            <div>{paymentOption.uniqueName}</div>
                          </Link>
                        </th>
                        <td>
                          {getOrganizationName(paymentOption.organizationId)}
                        </td>
                        <td>{paymentOption.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
