import {
  setLogisticsOptionAccessibility,
  updateLogisticsOption,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { LogisticsOptionEditor } from './LogisticsOptionEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { useLogisticsOptions } from '../../../hooks/logisticsOptions';

interface Params {
  logisticsOptionId: string;
}

export const LogisticsOption: FC = () => {
  const dispatch = useDispatch();
  const { logisticsOptionId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { logisticsOptions } = useLogisticsOptions();
  const logisticsOption = logisticsOptions.find(
    (o) => o.id === logisticsOptionId
  );

  const onSave = async (values: any) => {
    await dispatch(
      updateLogisticsOption({
        ...values,
      })
    );
  };

  const setAccessibility = async (accessibility: any) => {
    await dispatch(
      logisticsOption?.id &&
        setLogisticsOptionAccessibility(logisticsOption.id, accessibility)
    );
  };

  return logisticsOption && organizations.length ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <strong>Product stock</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={logisticsOption.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <LogisticsOptionEditor
            logisticsOption={logisticsOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
