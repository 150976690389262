import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { useParams } from 'react-router';
import {
  updatePage,
  publishPage,
  setPageAccessibility,
} from '@nimles/react-redux';
import { PageEditor } from './PageEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { usePages } from '../../../hooks/pages';

interface Params {
  pageId: string;
}

export const Page = () => {
  const dispatch = useDispatch();
  const { pageId } = useParams<Params>();

  const { pages } = usePages();
  const page = pages.find((l: any) => l.id === pageId);
  const { organizations } = useOrganizations();
  const organizationId = page && page.organizationId;

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, organizationId);
    await dispatch(
      updatePage({ ...values, elements: removeTempIds(values.elements) })
    );
    if (publish) {
      page?.id && (await dispatch(publishPage(page.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    page?.id && (await dispatch(setPageAccessibility(page.id, accessibility)));
  };

  return page ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {page.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(page.createdDate).format('LLL')}
            </div>
          ) : null}
          {page.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(page.lastModified).format('LLL')}
            </div>
          ) : null}
          {page.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(page.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit {page.pageType} Page</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={page.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <PageEditor
            page={page}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
