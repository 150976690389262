import styled from '@emotion/styled';
import { Column, Row, Textarea } from '@nimles/react-web-components';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

const DesignLabel = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const DesignInput = styled.input`
  width: 30px;
  height: 20px;
  position: absolute;
  text-align: center;
  font-size: 12px;
`;

const DesignTopInput = styled(DesignInput)`
  top: 10px;
  left: 50%;
  margin-left: -15px;
`;

const DesignRightInput = styled(DesignInput)`
  top: 50%;
  right: 5px;
  margin-top: -10px;
`;

const DesignBottomInput = styled(DesignInput)`
  bottom: 10px;
  left: 50%;
  margin-left: -15px;
`;

const DesignLeftInput = styled(DesignInput)`
  top: 50%;
  left: 5px;
  margin-top: -10px;
`;

const DesignContainer = styled.div`
  position: relative;
  padding: 40px;
`;

const DesignMargin = styled(DesignContainer)`
  border: 1px dashed grey;
`;

const DesignBorder = styled(DesignContainer)`
  border: 1px solid grey;
`;

const DesignPadding = styled(DesignContainer)`
  border: 1px dashed grey;
`;

const DesignContent = styled.div`
  position: relative;
  width: 80px;
  height: 30px;
  border: 1px solid grey;
  margin: auto;
`;

interface DesignEditorProps {
  styles?: {
    [key: string]: any;
  } | null;
  onStyleChange: (name: string, value: string) => void;
  css?: string | null;
  onCssChange: (css: string) => void;
}

export const DesignEditor = ({
  styles,
  onStyleChange,
  css,
  onCssChange,
}: DesignEditorProps) => {
  const handleInputChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    onStyleChange(name, e.target.value);
  };
  const handleCssChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    onCssChange(e.target.value);
  };

  if (!styles) {
    styles = {};
  }

  return (
    <Row wrap="wrap">
      <Column xs={100} lg={50}>
        <DesignMargin>
          <DesignLabel>Margin</DesignLabel>
          <DesignTopInput
            value={styles['margin-top'] || ''}
            onChange={(e) => handleInputChange('margin-top', e)}
          />
          <DesignRightInput
            value={styles['margin-right'] || ''}
            onChange={(e) => handleInputChange('margin-right', e)}
          />
          <DesignBottomInput
            value={styles['margin-bottom'] || ''}
            onChange={(e) => handleInputChange('margin-bottom', e)}
          />
          <DesignLeftInput
            value={styles['margin-left'] || ''}
            onChange={(e) => handleInputChange('margin-left', e)}
          />
          <DesignBorder>
            <DesignLabel>Border</DesignLabel>
            <DesignTopInput
              value={styles['border-top-width'] || ''}
              onChange={(e) => handleInputChange('border-top-width', e)}
            />
            <DesignRightInput
              value={styles['border-right-width'] || ''}
              onChange={(e) => handleInputChange('border-right-width', e)}
            />
            <DesignBottomInput
              value={styles['border-bottom-width'] || ''}
              onChange={(e) => handleInputChange('border-bottom-width', e)}
            />
            <DesignLeftInput
              value={styles['border-left-width'] || ''}
              onChange={(e) => handleInputChange('border-left-width', e)}
            />
            <DesignPadding>
              <DesignLabel>Padding</DesignLabel>
              <DesignTopInput
                value={styles['padding-top'] || ''}
                onChange={(e) => handleInputChange('padding-top', e)}
              />
              <DesignRightInput
                value={styles['padding-right'] || ''}
                onChange={(e) => handleInputChange('padding-right', e)}
              />
              <DesignBottomInput
                value={styles['padding-bottom'] || ''}
                onChange={(e) => handleInputChange('padding-bottom', e)}
              />
              <DesignLeftInput
                value={styles['padding-left'] || ''}
                onChange={(e) => handleInputChange('padding-left', e)}
              />
              <DesignContent></DesignContent>
            </DesignPadding>
          </DesignBorder>
        </DesignMargin>
      </Column>
      <Column xs={100} lg={50}>
        <FormGroup>
          <Label>CSS</Label>
          <Textarea value={css ?? ''} onChange={handleCssChange} rows={10} />
        </FormGroup>
      </Column>
    </Row>
  );
};
