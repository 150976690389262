import { Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FC, ReactNode } from 'react';
import { CultureSelector } from './CultureSelector';
import { Row, Column } from '@nimles/react-web-components';
import { CultureState, RootState } from '../redux/types';
import { Translator } from './Translator';
import { Translation } from '../services/translator';

interface Props {
  label?: string;
  model?: any;
  name?: string;
  selector?: boolean;
  translator?: string;
  onChange: (value: string, culture?: string) => void;
  children: ({
    value,
    name,
    culture,
    onChange,
  }: {
    value: string;
    name: string;
    localizedName: string;
    culture?: string;
    onChange: (value: string, culture?: string) => void;
  }) => ReactNode;
}

export const LocalizedValue: FC<Props> = ({
  label,
  children,
  model,
  name = 'content',
  selector = true,
  translator = true,
  onChange,
}) => {
  const { values: cultures, selected: selectedCulture } = useSelector<
    RootState,
    CultureState
  >(({ culture }) => culture);

  const parts = name.split('.');

  parts[parts.length - 1] = parts[parts.length - 1] + 'Localized';

  const nameLocalized = parts.join('.');

  const contentLocalized = model && model[nameLocalized];

  const defaultValue = (model && model[name]) || '';

  const handleTranslate = (translations: Translation[]) => {
    translations?.forEach((translation) => {
      if (translation?.text) {
        onChange(translation.text, translation.to);
      }
    });
  };

  return (
    <>
      {selector || translator || label ? (
        <Row justify="flex-end" align="flex-end">
          {label ? (
            <Column flex>
              <Label for={name}>{label}</Label>
            </Column>
          ) : null}
          {selector ? (
            <Column>
              <CultureSelector size="xs" />
            </Column>
          ) : null}
          {translator && selectedCulture ? (
            <Column>
              <Translator
                size="xs"
                text={defaultValue}
                from="en"
                to={[selectedCulture]}
                onTranslated={handleTranslate}
              />
            </Column>
          ) : null}
        </Row>
      ) : null}
      {!selectedCulture
        ? children({
            value: defaultValue,
            name: name,
            localizedName: name,
            onChange: (value) => onChange(value),
          })
        : null}
      {cultures.map((culture) =>
        selectedCulture === culture
          ? children({
              value: (contentLocalized && contentLocalized[culture]) || '',
              name: nameLocalized,
              localizedName: nameLocalized + '.' + culture,
              culture,
              onChange: (value) => onChange(value, culture),
            })
          : null
      )}
    </>
  );
};
