import { Column, Input, Row } from '@nimles/react-web-components';
import {
  InputField,
  TextAreaField,
  ReactSelectField,
} from '@nimles/react-web-forms';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { FC, useState } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { QuillField } from '../../../components/fields/QuillField';
import { ImageButton, ImagePlaceholder } from '../../../components/ImageButton';
import { SelectImageModal } from '../../../components/SelectImageModal';
import { EditorActions } from '../../../components/Editor';
import {
  AddressModel,
  CategoryModel,
  LocationModel,
  OrganizationModel,
} from '@nimles/models';
import { Map, toLatLng, toLocation } from '../../../components/Map';
import { Marker } from '@react-google-maps/api';
import { ImagePreview } from '../../../components/images';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  organization: OrganizationModel;
  categories: CategoryModel[];
  tags: { name: string; count: number }[];
  onSave: (values: any, publish: boolean) => Promise<void>;
}

function codeAddress({
  street,
  postalCode,
  city,
}: AddressModel): Promise<LocationModel | undefined> {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      { address: `${street}, ${postalCode} ${city}` },
      function (results, status) {
        if (status === 'OK') {
          resolve(
            toLocation(results?.find((r) => r)?.geometry?.location?.toJSON())
          );
        } else {
          reject(status);
        }
      }
    );
  });
}

interface AddressFieldSetProps {
  label: string;
  name: string;
  address?: AddressModel;
  onGeocoded: (location: LocationModel) => void;
}

const AddressFieldSet = ({
  label,
  name,
  address,
  onGeocoded,
}: AddressFieldSetProps) => {
  const handleGeocode = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const location = address && (await codeAddress(address));
    location && onGeocoded(location);
  };

  return (
    <fieldset>
      <legend>{label}</legend>
      <FormGroup>
        <InputField
          name={name + '.street'}
          label="Street"
          placeholder="Street"
        />
        <InputField
          name={name + '.postalCode'}
          label="Postal code"
          placeholder="Postal code"
        />
        <InputField name={name + '.city'} label="City" placeholder="City" />
        <InputField name={name + '.state'} label="State" placeholder="State" />
        <InputField
          name={name + '.country'}
          label="Country"
          placeholder="Country"
        />
      </FormGroup>

      <Row>
        <Column>
          <Button type="button" color="primary" onClick={handleGeocode}>
            Geocode
          </Button>
        </Column>
      </Row>
    </fieldset>
  );
};

const AddSocialLink = ({ onAdd }: { onAdd: (name: string) => void }) => {
  const [name, setName] = useState('');

  return (
    <Row align="center">
      <Column flex>
        <Input
          placeholder="Socal link name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Column>
      <Column>
        <Button
          type="button"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onAdd(name);
            setName('');
          }}
        >
          Add Social Link
        </Button>
      </Column>
    </Row>
  );
};

export const OrganizationEditor: FC<Props> = ({
  organization,
  categories,
  onSave,
  tags,
}) => {
  const [isLogoOpen, setLogoOpen] = useState(false);
  const [isBannerOpen, setBannerOpen] = useState(false);
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  interface ExtendedOrganizationModel extends OrganizationModel {
    logoData?: string;
    bannerData?: string;
  }

  const values: ExtendedOrganizationModel = organization && {
    ...organization,
    id: organization.id,
    name: organization.name || '',
    description: organization.description || '',
    corporateIdentity: organization.corporateIdentity || '',
    organizationType: organization.organizationType || 'organization',
    uniqueName: organization.uniqueName || '',
    hostName: organization.hostName || '',
    categoryIds: organization.categoryIds || [],
    organizationShortId: organization.organizationShortId || null,
    organizationNumber: organization.organizationNumber || null,
    email: organization.email || '',
    emails: organization.emails || [],
    phoneNumber: organization.phoneNumber || '',
    phoneNumbers: organization.phoneNumbers || [],
    address: {
      street: (organization.address && organization.address.street) || '',
      postalCode:
        (organization.address && organization.address.postalCode) || '',
      city: (organization.address && organization.address.city) || '',
      country: (organization.address && organization.address.country) || '',
      state: (organization.address && organization.address.state) || '',
    },
    billingAddress: {
      street:
        (organization.billingAddress && organization.billingAddress.street) ||
        '',
      postalCode:
        (organization.billingAddress &&
          organization.billingAddress.postalCode) ||
        '',
      city:
        (organization.billingAddress && organization.billingAddress.city) || '',
      country:
        (organization.billingAddress && organization.billingAddress.country) ||
        '',
    },
    deliveryAddress: {
      street:
        (organization.deliveryAddress && organization.deliveryAddress.street) ||
        '',
      postalCode:
        (organization.deliveryAddress &&
          organization.deliveryAddress.postalCode) ||
        '',
      city:
        (organization.deliveryAddress && organization.deliveryAddress.city) ||
        '',
      country:
        (organization.deliveryAddress &&
          organization.deliveryAddress.country) ||
        '',
    },
    slogan: organization.slogan || '',
    summary: organization.summary || '',
    imageIds: organization.imageIds || [],
    website: organization.website || '',
    tags: organization.tags || [],
    useDefaultAddressForDelivery:
      organization.useDefaultAddressForDelivery || true,
    useDefaultAddressForBilling:
      organization.useDefaultAddressForBilling || true,
    hasSales: organization.hasSales || false,
    hasPurchase: organization.hasPurchase || false,
    logoId: organization.logoId,
    logoData: '',
    bannerId: organization.bannerId,
    bannerData: '',
    location: {
      latitude: null,
      longitude: null,
      ...(organization.location || {}),
    },
    socialLinks: organization.socialLinks || {},
  };
  return organization ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }: FormikProps<ExtendedOrganizationModel>) => {
        const logoValue = values.logoId || values.logoData;
        const bannerValue = values.bannerId || values.bannerData;

        const mapLocation =
          (values.location?.latitude &&
            values.location?.longitude &&
            values.location) ||
          undefined;
        const mapLatLng = toLatLng(mapLocation);
        return (
          <>
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onPublish={() => {
                  setPublish(true);
                  submitForm();
                }}
                onSave={() => {
                  setPublish(false);
                  submitForm();
                }}
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />

              <Row wrap="wrap">
                <Column xs={100} lg={50}>
                  <fieldset>
                    <legend>Information</legend>
                    {values.id && (
                      <InputField name="id" label="Id" disabled={true} />
                    )}
                    <InputField
                      label="Name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                    <InputField
                      label="Unique name"
                      type="text"
                      name="uniqueName"
                      required
                    />
                    <InputField label="Host name" type="text" name="hostName" />
                    <InputField
                      name="corporateIdentity"
                      label="Corporate Identity"
                      placeholder="Corporate Identity"
                    />
                    <InputField
                      name="website"
                      label="Website"
                      placeholder="Website"
                    />
                  </fieldset>
                </Column>
                <Column xs={100} lg={50}>
                  <fieldset>
                    <legend>Images</legend>
                    <Label>Logo</Label>
                    <ImageButton
                      onClick={(e) => {
                        e.preventDefault();
                        setLogoOpen(true);
                      }}
                    >
                      {logoValue ? (
                        <ImagePreview value={logoValue} />
                      ) : (
                        <ImagePlaceholder>Select Logo</ImagePlaceholder>
                      )}
                    </ImageButton>
                    <Label>Banner</Label>
                    <ImageButton
                      onClick={(e) => {
                        e.preventDefault();
                        setBannerOpen(true);
                      }}
                    >
                      {bannerValue ? (
                        <ImagePreview value={bannerValue} />
                      ) : (
                        <ImagePlaceholder>Select Banner</ImagePlaceholder>
                      )}
                    </ImageButton>
                  </fieldset>
                </Column>
              </Row>
              <ReactSelectField
                name="categoryIds"
                label="Categories"
                placeholder="Select categories"
                options={categories}
                optionLabel={(o) => `${o.name}`}
                optionValue={(o) => o.id}
                isMulti
              />
              <TextAreaField
                name="slogan"
                label="Slogan"
                placeholder="Slogan"
                wordCount
              />
              <TextAreaField
                name="summary"
                label="Summary"
                placeholder="Summary"
                wordCount
              />
              <QuillField
                name="description"
                label="Description"
                placeholder="Description"
              />
              <ReactSelectField
                name="tags"
                label="Tags"
                placeholder="Select tags"
                options={tags}
                optionLabel={(o) => `${o.name} (${o.count})`}
                optionValue={(o) => o.name}
                onCreateOption={(label) =>
                  setFieldValue('tags', [...(values.tags || []), label])
                }
                isMulti
              />
              <fieldset>
                <legend>Emails</legend>
                {values.emails?.map((email, index) => (
                  <FormGroup key={index}>
                    <Row align="center">
                      <Column flex>
                        <InputField
                          name={`emails.${index}`}
                          placeholder="Email"
                          required
                        />
                      </Column>
                      <Column>
                        <Button
                          color="danger"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              'emails',
                              values.emails?.filter((p, i) => index !== i)
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </Column>
                    </Row>
                  </FormGroup>
                ))}
                <Row>
                  <Column>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue('emails', [...(values.emails || []), '']);
                      }}
                    >
                      Add Email
                    </Button>
                  </Column>
                </Row>
              </fieldset>
              <fieldset>
                <legend>Phone Numbers</legend>
                {values.phoneNumbers?.map((phoneNumber, index) => (
                  <FormGroup key={index}>
                    <Row align="center">
                      <Column flex>
                        <InputField
                          name={`phoneNumbers.${index}`}
                          placeholder="PhoneNumber"
                          required
                        />
                      </Column>
                      <Column>
                        <Button
                          color="danger"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              'phoneNumbers',
                              values.phoneNumbers?.filter((p, i) => index !== i)
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </Column>
                    </Row>
                  </FormGroup>
                ))}
                <Row>
                  <Column>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue('phoneNumbers', [
                          ...(values.phoneNumbers || []),
                          '',
                        ]);
                      }}
                    >
                      Add PhoneNumber
                    </Button>
                  </Column>
                </Row>
              </fieldset>
              <fieldset>
                <legend>Social Links</legend>
                {values.socialLinks
                  ? Object.entries(values.socialLinks).map(
                      ([name, url], index) => (
                        <FormGroup key={index}>
                          <Row align="center">
                            <Column>
                              <Label>{name}</Label>
                            </Column>
                            <Column flex>
                              <InputField
                                name={`socialLinks.${name}`}
                                placeholder=""
                                required
                              />
                            </Column>
                            <Column>
                              <Button
                                color="danger"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue('socialLinks', {
                                    ...values.socialLinks,
                                    [name]: undefined,
                                  });
                                }}
                              >
                                Remove
                              </Button>
                            </Column>
                          </Row>
                        </FormGroup>
                      )
                    )
                  : null}
                <AddSocialLink
                  onAdd={(name) =>
                    setFieldValue('socialLinks', {
                      ...values.socialLinks,
                      [name]: '',
                    })
                  }
                />
              </fieldset>
              <Row wrap="wrap">
                <Column xs={100} md={50}>
                  <AddressFieldSet
                    name="address"
                    label="Address"
                    address={values.address}
                    onGeocoded={(location) =>
                      setFieldValue('location', location)
                    }
                  />
                </Column>
                <Column xs={100} md={50}>
                  <fieldset>
                    <legend>Location</legend>
                    <FormGroup>
                      <InputField
                        label="Latitude"
                        type="number"
                        name="location.latitude"
                      />
                      <InputField
                        label="Longitude"
                        type="number"
                        name="location.longitude"
                      />
                    </FormGroup>
                    <Map
                      center={
                        mapLocation || { latitude: 62.3858, longitude: 16.322 }
                      }
                      locations={mapLocation ? [mapLocation] : []}
                    >
                      {mapLatLng ? <Marker position={mapLatLng} /> : null}
                    </Map>
                  </fieldset>
                </Column>
              </Row>
            </Form>
            <SelectImageModal
              title="Select Logo"
              isOpen={isLogoOpen}
              onToggle={() => setLogoOpen(!isLogoOpen)}
              onSelect={(image) => {
                if (image.id) {
                  setFieldValue('logoId', image.id);
                  setFieldValue('logoData', undefined);
                } else {
                  setFieldValue('logoId', undefined);
                  setFieldValue('logoData', image.uri);
                }
                setLogoOpen(false);
              }}
            />
            <SelectImageModal
              title="Select Banner"
              isOpen={isBannerOpen}
              onToggle={() => setBannerOpen(!isBannerOpen)}
              onSelect={(image) => {
                if (image.id) {
                  setFieldValue('bannerId', image.id);
                  setFieldValue('bannerData', undefined);
                } else {
                  setFieldValue('bannerId', undefined);
                  setFieldValue('bannerData', image.uri);
                }
                setBannerOpen(false);
              }}
            />
          </>
        );
      }}
    />
  ) : null;
};
