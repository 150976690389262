import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Table,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap';
import { publishReview } from '@nimles/react-redux';
import { useDispatch } from 'react-redux';
import { Row, Column, Input } from '@nimles/react-web-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import {
  OrganizationModel,
  ReviewGroupModel,
  ReviewModel,
} from '@nimles/models';
import { useOrganizations, useReviewGroups, useReviews } from '../../../hooks';

interface Option<TValue> {
  label?: string | null;
  value?: TValue | null;
}

export const Reviews: FC = () => {
  const dispatch = useDispatch();
  const { organizations } = useOrganizations();
  const { reviewGroups } = useReviewGroups();
  const { reviews } = useReviews();
  const [query, setQuery] = useState<string>();
  const [filteredReviews, setFilteredReviews] = useState<ReviewModel[]>([]);
  const [tags, setTags] = useState<Option<string>[]>([]);
  const [selectedTags, selectTags] = useState<Option<string>[]>([]);
  const [selectedOrganizations, selectOrganizations] = useState<
    Option<OrganizationModel>[]
  >([]);
  const [selectedReviewGroups, selectReviewGroups] = useState<
    Option<ReviewGroupModel>[]
  >([]);

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  const getReviewGroupName = (reviewGroupId?: string | null) => {
    const reviewGroup = reviewGroups.find((o) => o.id === reviewGroupId);

    return reviewGroup ? reviewGroup.title : reviewGroupId;
  };

  useEffect(() => {
    setFilteredReviews(
      reviews.filter(
        (prod) =>
          (query
            ? prod.uniqueName?.includes(query.toLowerCase()) ||
              prod.title?.toLowerCase().includes(query.toLowerCase())
            : true) &&
          (selectedTags?.length
            ? selectedTags.some(
                (tag) => tag.value && prod.tags?.includes(tag.value)
              )
            : true) &&
          (selectedOrganizations?.length
            ? selectedOrganizations.some(
                (org) => org.value === prod.organizationId
              )
            : true) &&
          (selectedReviewGroups?.length
            ? selectedReviewGroups.some(
                (pg) =>
                  pg.value?.id && prod.reviewGroupIds?.includes(pg.value.id)
              )
            : true)
      )
    );
  }, [
    reviews,
    query,
    selectedTags,
    selectedOrganizations,
    selectedReviewGroups,
  ]);

  useEffect(() => {
    setTags(
      reviews
        .reduce(
          (list, prod) => (prod.tags ? [...list, ...prod.tags] : list),
          []
        )
        .sort()
        .reduce<{ name: string; count: number }[]>((items, name) => {
          let item = items.find((i) => i.name === name);
          if (item) {
            item.count++;
          } else {
            items.push({ name, count: 1 });
          }
          return items;
        }, [])
        .map(({ name, count }) => ({
          label: `${name} (${count})`,
          value: name,
        }))
    );
  }, [reviews, query]);

  const actions = [
    {
      label: 'Publish',
      onClick: (review: ReviewModel) =>
        review.id && dispatch(publishReview(review.id)),
    },
    {
      label: 'Delete',
      onClick: (review: ReviewModel) =>
        review.id && dispatch(publishReview(review.id)),
    },
  ];

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Reviews
          <div className="card-header-actions">
            <Link to="/pim/reviews/new" className="btn btn-sm btn-primary">
              New Review
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <Row wrap="wrap">
            <Column xs={100} md={50}>
              <FormGroup>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search..."
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={tags}
                  value={selectedTags}
                  onChange={(value: Option<string>[]) => selectTags(value)}
                  placeholder="Select tags"
                  isMulti
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={organizations
                    .filter(({ id, name }) => id && name)
                    .map((org) => ({
                      label: org.name,
                      value: org,
                    }))}
                  value={selectedOrganizations}
                  onChange={(value: Option<OrganizationModel>[]) =>
                    selectOrganizations(value)
                  }
                  placeholder="Select organizations"
                  isMulti
                />
              </FormGroup>
            </Column>
            <Column xs={100} md={50}>
              <FormGroup>
                <Select
                  options={reviewGroups.map((rg) => ({
                    label: rg.title,
                    value: rg,
                  }))}
                  value={selectedReviewGroups}
                  onChange={(value: Option<ReviewGroupModel>[]) =>
                    selectReviewGroups(value)
                  }
                  placeholder="Select review groups"
                  isMulti
                />
              </FormGroup>
            </Column>
          </Row>
        </CardBody>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Organization</th>
              <th scope="col">Review Groups</th>
              <th scope="col">Accessibility</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredReviews &&
              filteredReviews.map((review) => {
                const reviewLink = `/pim/reviews/${review.id}`;
                return (
                  <tr key={review.id?.toString()}>
                    <td>
                      <Link to={reviewLink}>
                        <div>
                          <strong>{review.title}</strong>
                        </div>
                        <div>
                          <small>{review.id}</small>
                        </div>
                        <div>{review.uniqueName}</div>
                      </Link>
                    </td>
                    <td>{getOrganizationName(review.organizationId)}</td>
                    <td>
                      {review.reviewGroupIds?.map((reviewGroupId) => (
                        <div>{getReviewGroupName(reviewGroupId)}</div>
                      ))}
                    </td>
                    <td>{review.accessibility}</td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle nav>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </DropdownToggle>
                        <DropdownMenu>
                          {actions.map(({ label, onClick }) => (
                            <DropdownItem
                              key={label}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick(review);
                              }}
                            >
                              {label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
