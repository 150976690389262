import { createQuoteRequest } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { QuoteRequestEditor } from './QuoteRequestEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { useQuoteRequests } from '../../../hooks/quoteRequests';

interface Params {
  parentId: string;
}

export const NewQuoteRequest: FC = () => {
  const dispatch = useDispatch();
  const { parentId } = useParams<Params>();
  const { quoteRequests } = useQuoteRequests();
  const parentQuoteRequest = quoteRequests.find((o) => o.id === parentId);
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    await dispatch(
      createQuoteRequest({
        ...values,
        bySeller: true,
        seller: {
          organizationId: values.organizationId,
        },
        parentQuoteRequestId: parentQuoteRequest?.id,
        buyer: {
          userId: parentQuoteRequest?.head?.buyer?.userId,
        },
        message: values.message,
      })
    );
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New QuoteRequest</strong>
          <p>Create new quoteRequest:</p>
          <QuoteRequestEditor
            quoteRequest={{}}
            onSave={onSave}
            newQuoteRequestType="Quote"
            organizations={organizations}
          />
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </div>
  );
};
