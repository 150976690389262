import {
  validateCertificateVersion,
  addCertificateVersion,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useCertificates } from '../../../hooks/certificates';

interface Params {
  certificateId: string;
}

export const Certificate: FC = () => {
  const dispatch = useDispatch();
  const { certificateId } = useParams<Params>();
  const { certificates } = useCertificates();
  const certificate = certificates.find((c) => c.id === certificateId);
  const versions = certificate && certificate.versions;

  const handleNewCertificate = async () => {
    certificate?.id &&
      (await dispatch(addCertificateVersion(certificate.id, {})));
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Certificates
              <div className="card-header-actions">
                <Button size="sm" primary onClick={handleNewCertificate}>
                  New Certificate Version
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <fieldset>
                <legend>Versions</legend>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Domain</th>
                      <th scope="col">Organization</th>
                      <th scope="col">Type</th>
                      <th scope="col">Accessibility</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versions &&
                      versions.map((version: any) => {
                        const versionLink = `/identity/certificates/${certificate.id}/${version.id}`;
                        return (
                          <tr key={version.id}>
                            <th scope="row">
                              <Link to={versionLink}>{version.id}</Link>
                            </th>
                            <td>
                              <Link to={versionLink}>{certificate.domain}</Link>
                            </td>
                            <td></td>
                            <td></td>
                            <td>{version.status}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle nav>
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      certificate.id &&
                                      validateCertificateVersion(
                                        certificate.id,
                                        version.id,
                                        true
                                      )
                                    }
                                  >
                                    Clone
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </fieldset>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
