import { FC, useState, useRef, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Column } from '@nimles/react-web-components';
import { CultureSelector } from './CultureSelector';
import { Button } from 'reactstrap';
import { useUiControls } from '../hooks/uiControls';

interface StickyProps {
  isSticky: boolean;
  sidebarShow: boolean;
  sidebarMinimize: boolean;
  darkMode: boolean;
}

const StickyRow = styled.div<StickyProps>`
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  top: ${({ isSticky }) => (isSticky ? '104px' : 0)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background-color: ${({ darkMode }) => (darkMode ? '#24252f' : '#fff')};
  padding: ${({ isSticky, sidebarShow, sidebarMinimize }) =>
    !isSticky
      ? 0
      : sidebarShow
      ? sidebarMinimize
        ? '10px 30px 10px 86px'
        : '10px 30px 10px 286px'
      : '10px 30px 10px 30px'};
  box-shadow: ${({ isSticky }) =>
    isSticky ? '0 0 1px 1px rgba(0, 0, 0, 0.2)' : 0};
  z-index: 2;
`;

const StyledRow = styled.div<{ minHeight: number }>`
  min-height: ${({ minHeight }) => (minHeight ? minHeight + 'px' : 'auto')};
  margin-bottom: 15px;
`;

const ActionColumn = styled.div`
  display: flex;
`;

export const EditorContainer = styled.div`
  padding: 0 15px;
`;

interface ActionsProps {
  onReset?: () => void;
  onSave?: () => void;
  onPublish?: () => void;
  isSubmitting: boolean;
  isValid: boolean;
}

export const EditorActions: FC<ActionsProps> = ({
  onReset,
  onSave,
  onPublish,
  isSubmitting,
  isValid,
  children,
}) => {
  const { sidebarShow, sidebarMinimize, darkMode } = useUiControls();

  const [isSticky, setSticky] = useState(false);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (ref.current) {
      if (!height) {
        setHeight(ref.current.getBoundingClientRect().height);
      }
      setSticky(ref.current.getBoundingClientRect().top <= 104);
    }
  }, [height]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return (
    <StyledRow ref={ref} minHeight={height}>
      <StickyRow
        isSticky={isSticky}
        sidebarShow={sidebarShow}
        sidebarMinimize={sidebarMinimize}
        darkMode={darkMode}
      >
        <Column padding="0">
          <CultureSelector />
        </Column>
        <Column flex></Column>
        <ActionColumn>
          {children ?? null}
          {onReset ? (
            <Button
              type="reset"
              color="danger"
              className="mr-1"
              disabled={isSubmitting || !isValid}
              onClick={onReset}
            >
              Reset
            </Button>
          ) : null}
          {onSave ? (
            <Button
              type="button"
              color="primary"
              className="mr-1"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          )}
          {onPublish ? (
            <Button
              type="button"
              color="success"
              className="mr-1"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                onPublish();
              }}
            >
              Save and Publish
            </Button>
          ) : null}
        </ActionColumn>
      </StickyRow>
    </StyledRow>
  );
};
