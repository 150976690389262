import { createOrganizationMember } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { MemberEditor } from './MemberEditor';
import { useHistory } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';

export const NewMember: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();

  const handleSave = async (values: any) => {
    const organizationMember: any = await dispatch(
      createOrganizationMember({ ...values })
    );

    history.push('/organization-management/members/' + organizationMember.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Member</strong>
        </CardHeader>
        <CardBody>
          <MemberEditor
            organizationMember={{}}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
