import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useOrganizations } from '../../../hooks';
import { useCustomers } from '../../../hooks/customers';

export const Customers: FC = () => {
  const { customers } = useCustomers();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Customers
              <div className="card-header-actions">
                <Link
                  to="/ecommerce/customers/new"
                  className="btn btn-sm btn-primary"
                >
                  New Customer
                </Link>
              </div>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Customer</th>
                    <th scope="col">Seller</th>
                  </tr>
                </thead>
                <tbody>
                  {customers &&
                    customers.map((customer) => {
                      const customerLink = `/ecommerce/customers/${customer.id}`;
                      return (
                        <tr key={customer.id?.toString()}>
                          <th scope="row">
                            <Link to={customerLink}>
                              <div>
                                {`${customer.buyer?.firstName ?? '-'} ${
                                  customer.buyer?.lastName ?? '-'
                                }`}
                              </div>
                              <div>
                                <strong>
                                  {customer.buyer?.organizationName}
                                </strong>
                              </div>
                              <div>{customer.buyer?.email}</div>
                              <div>{customer.buyer?.phoneNumber}</div>
                              <div>
                                <small>{customer.id}</small>
                              </div>
                            </Link>
                          </th>
                          <td>
                            {getOrganizationName(
                              customer.seller?.organizationId
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
