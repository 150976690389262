import { publishCategory } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Image } from '../../../components/images';
import { useCategories, useTenants } from '../../../hooks';
import { CategoryModel } from '@nimles/models';

const ImageCol = styled.col`
  width: 180px;
`;

const NameCol = styled.col`
  min-width: 240px;
`;

const Col = styled.col``;

export const Categories: FC = () => {
  const dispatch = useDispatch();

  const { tenants } = useTenants();
  const { categories } = useCategories();

  const getTenantName = (tenantId?: string | null) => {
    const tenant = tenants.find((o) => o.id === tenantId);

    return tenant ? tenant.name : tenantId;
  };

  const actions = [
    {
      label: 'Publish',
      onClick: (category: CategoryModel) =>
        category.id && dispatch(publishCategory(category.id)),
    },
  ];

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Categories
          <div className="card-header-actions">
            <Link
              to="/organization-management/categories/new"
              className="btn btn-sm btn-primary"
            >
              New Category
            </Link>
          </div>
        </CardHeader>
        <Table responsive hover>
          <colgroup>
            <ImageCol />
            <NameCol />
            <Col />
            <Col />
            <Col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Tags</th>
              <th scope="col">Tenant</th>
              <th scope="col">Accessibility</th>
            </tr>
          </thead>
          <tbody>
            {categories &&
              categories.map((category: any, index: any) => {
                const categoryLink = `/organization-management/categories/${category.id}`;

                return (
                  <tr key={category.id.toString()}>
                    <td>
                      {category.logoId || category.bannerId ? (
                        <Image
                          width={180}
                          image={{
                            id: category.logoId ?? category.bannerId,
                            tenantId: category.tenantId,
                          }}
                        />
                      ) : null}
                    </td>
                    <td>
                      <Link to={categoryLink}>
                        <div>
                          <strong>{category.name}</strong>
                        </div>
                        <div>
                          <small>{category.id}</small>
                        </div>
                        <div>{category.uniqueName}</div>
                      </Link>
                    </td>
                    <td>{category.tags?.join(', ')}</td>
                    <td>{getTenantName(category.tenantId)}</td>
                    <td>
                      <Badge
                        color={category.published ? 'success' : 'secondary'}
                      >
                        <Badge
                          color={
                            category.status === 'Published'
                              ? 'success'
                              : 'secondary'
                          }
                        >
                          {category.accessibility}
                        </Badge>
                      </Badge>
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle nav>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </DropdownToggle>
                        <DropdownMenu>
                          {actions.map(({ label, onClick }) => (
                            <DropdownItem
                              key={label}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick(category);
                              }}
                            >
                              {label}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
