import { ImageGrid } from './ImageGrid';
import { FileModel } from '@nimles/models';
import { useFiles } from '../../hooks/files';

interface Props {
  selected?: string;
  onSelect: (image: FileModel) => void;
  mimeTypes?: string[];
}

export const ImageSelector = ({ selected, onSelect, mimeTypes }: Props) => {
  const { files } = useFiles();

  return (
    <ImageGrid
      images={
        mimeTypes?.length
          ? files.filter(
              ({ mimeType }) => mimeType && mimeTypes.includes(mimeType)
            )
          : files
      }
      onSelect={onSelect}
      selected={selected}
    />
  );
};
