import React, { useMemo } from 'react';

interface Props {
  value?: number | null;
  currency?: string | null;
  language?: string | null;
}

export const Price = ({ value, currency, language = 'en' }: Props) => {
  const numberFormatter = useMemo(
    () =>
      language && currency
        ? Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
          })
        : undefined,
    [language, currency]
  );

  return <>{(value && numberFormatter?.format(value)) || value}</>;
};
