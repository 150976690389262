import {
  addOrganizationPermission,
  setOrganizationAccessibility,
  updateOrganization,
  removeOrganizationPermission,
  publishOrganization,
} from '@nimles/react-redux';
import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Permissions } from '../../../components/Permissions';
import { OrganizationEditor } from './OrganizationEditor';
import { Row, Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { useCategories } from '../../../hooks';

interface Params {
  organizationId: string;
}

export const Organization: FC = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams<Params>();

  const { organizations } = useOrganizations();
  const { categories } = useCategories();
  const organization = organizations.find((o: any) => o.id === organizationId);

  const handleSave = async (values: any, publish: boolean) => {
    await dispatch(updateOrganization({ ...organization, ...values }));
    if (publish) {
      organization?.id &&
        (await dispatch(publishOrganization(organization.id)));
    }
  };

  const onAddPermission = async (values: any) => {
    organization?.id &&
      (await dispatch(addOrganizationPermission(organization.id, values)));
  };

  const onRemovePermission = async (permissionId: string) => {
    organization?.id &&
      (await dispatch(
        removeOrganizationPermission(organization.id, permissionId)
      ));
  };

  const setAccessibility = async (accessibility: any) => {
    organization?.id &&
      (await dispatch(
        setOrganizationAccessibility(organization.id, accessibility)
      ));
  };

  const [activeTab, setActiveTab] = useState('editor');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const tags = organizations
    .reduce<string[]>(
      (list, org) => (org.tags ? [...list, ...org.tags] : list),
      []
    )
    .reduce<{ name: string; count: number }[]>((items, name) => {
      let item = items.find((i) => i.name === name);
      if (item) {
        item.count++;
      } else {
        items.push({ name, count: 1 });
      }
      return items;
    }, [])
    .sort();

  return organization ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {organization.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(organization.createdDate).format('LLL')}
            </div>
          ) : null}
          {organization.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(organization.lastModified).format('LLL')}
            </div>
          ) : null}
          {organization.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(organization.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Organization</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={organization.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'editor' })}
              onClick={() => {
                toggle('editor');
              }}
            >
              Editor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'permissions' })}
              onClick={() => {
                toggle('permissions');
              }}
            >
              Permissions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="editor">
            <OrganizationEditor
              organization={organization}
              categories={categories}
              tags={tags}
              onSave={handleSave}
            />
          </TabPane>
          <TabPane tabId="permissions">
            <Permissions
              permissions={organization.permissions ?? []}
              onAdd={onAddPermission}
              onRemove={onRemovePermission}
            />
          </TabPane>
        </TabContent>
      </Card>
    </div>
  ) : null;
};
