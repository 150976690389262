import { Input } from 'reactstrap';
import { SelectField } from '@nimles/react-web-forms';
import { OrganizationModel } from '@nimles/models';

interface Props {
  name?: string;
  organizations?: OrganizationModel[];
  defaultOrganizationId?: string | null;
}

export const OrganizationSelectField = ({
  name,
  organizations,
  defaultOrganizationId,
}: Props) => {
  if (!organizations) {
    return null;
  }

  const organization = defaultOrganizationId
    ? organizations.find((o) => o.id === defaultOrganizationId)
    : null;
  const organizationName = organization?.name ?? '';

  return organizationName ? (
    <>
      <label>Organization</label>
      <Input disabled value={organizationName} />
    </>
  ) : (
    <SelectField
      name={name ?? 'organizationId'}
      label="Organization"
      placeholder="Select organization"
      options={organizations}
      optionName={(o) => o.name}
      optionValue={(o) => o.id}
    />
  );
};
