import React from 'react';
import { ResponsiveLine } from '@nivo/line';

export const TimeChart = () => {
  return (
    <div style={{ height: 350 }} className="App">
      <ResponsiveLine
        data={[
          {
            id: 'LineOne',
            data: [
              { x: '2019-05-01', y: 2 },
              { x: '2019-06-01', y: 7 },
              { x: '2020-03-01', y: 1 },
            ],
          },
          {
            id: 'LineTwo',
            data: [
              { x: '2019-05-01', y: 1 },
              { x: '2019-06-01', y: 5 },
              { x: '2020-05-01', y: 5 },
            ],
          },
          {
            id: 'LineThree',
            data: [
              { x: '2020-02-01', y: 4 },
              { x: '2020-03-01', y: 6 },
              { x: '2020-04-01', y: 1 },
            ],
          },
        ]}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        axisBottom={{
          format: '%b %d',
          //tickValues: "every 2 days",
          // tickRotation: -90,
          legend: 'time scale',
          legendOffset: -12,
        }}
        colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
