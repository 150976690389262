import { Field, FieldProps } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { Translation } from '../services/translator';
import { RootState } from '../redux/types';
import { Translator } from './Translator';

const TranslatorComponent = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: { setFieldValue },
}: FieldProps) => {
  const culture = useSelector<RootState, any>(
    ({ culture }) => culture.selected
  );

  const parts = name.split('.');

  parts[parts.length - 1] = parts[parts.length - 1] + 'Localized';

  const nameLocalized = parts.join('.');

  const handleTranslate = (translations: Translation[]) => {
    translations?.forEach((translation) => {
      if (translation?.text) {
        setFieldValue(nameLocalized + '.' + translation.to, translation.text);
      }
    });
  };

  return (
    <Translator
      size="xs"
      text={value}
      from="en"
      to={[culture]}
      onTranslated={handleTranslate}
    />
  );
};

export const TranslatorField = (props: any) => (
  <Field {...props} component={TranslatorComponent} />
);
