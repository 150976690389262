import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { FullEditor, isMarkActive } from './Base';
import { Icon } from './Icon';
import { ToolbarButton } from './ToolbarButton';

export const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </ToolbarButton>
  );
};

export const toggleMark = (editor: FullEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
