import styled from '@emotion/styled';
import { FileModel } from '@nimles/models';
import React from 'react';

const StyledImageInformation = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
`;

export const ImageInformation = ({ image }: { image?: FileModel }) =>
  image ? (
    <StyledImageInformation>
      {image.mimeType ? <div>{image.mimeType}</div> : null}
      {image.name ? <div>{image.name}</div> : null}
      {image.id ? <div>{image.id}</div> : null}
      {image.size ? (
        <div>
          {image.size > 1048576
            ? Math.round(image.size / 1048576) + ' MB'
            : image.size > 1024
            ? Math.round(image.size / 1024) + ' kB'
            : image.size + ' B'}
        </div>
      ) : null}
      {image.provider ? <div>{image.provider}</div> : null}
      {image.author ? <div>{image.author}</div> : null}
    </StyledImageInformation>
  ) : null;
