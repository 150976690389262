import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FileEditor } from './FileEditor';
import { updateFile, setFileAccessibility } from '@nimles/react-redux';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { useOrganizations } from '../../../hooks/organizations';
import { useFiles } from '../../../hooks/files';

interface Params {
  fileId: string;
}

export const File: FC = () => {
  const dispatch = useDispatch();
  const { fileId } = useParams<Params>();

  const { files } = useFiles();
  const file = files.find((c: any) => c.id === fileId);
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    await dispatch(updateFile({ ...file, ...values }));
  };

  const setAccessibility = async (accessibility: string) => {
    file?.id && (await dispatch(setFileAccessibility(file.id, accessibility)));
  };

  return file ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>File</strong>
          <div className="card-header-actions">
            <Link
              to={`/media/files/${file.id}/permissions`}
              className="btn btn-sm btn-primary"
            >
              Permissions
            </Link>
            <AccessibilityButton
              accessibility={file.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <FileEditor
            file={{
              ...file,
              uri: `https://media.nimles.com/file/${file.tenantId}/${file.id}`,
            }}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
