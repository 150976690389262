import { FC, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { validate } from '../../_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { CourseModel, OrganizationModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  course: CourseModel;
  organizations?: OrganizationModel[];
  onSave: (course: any, publish: boolean) => Promise<void>;
}

export const CourseEditor: FC<Props> = ({ course, organizations, onSave }) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const course = {
        ...values,
      };
      await onSave(course, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: course.id,
    name: course.name || '',
    courseType: course.courseType,
    organizationId: course.organizationId,
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onPublish={() => {
                  setPublish(true);
                  submitForm();
                }}
                onSave={() => {
                  setPublish(false);
                  submitForm();
                }}
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
              {values.id && (
                <InputField label="Id" name="id" disabled required />
              )}
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={course.organizationId}
              />
              <InputField
                label="Name"
                type="text"
                name="name"
                id="name"
                required
              />
              <InputField
                label="Unique name"
                type="text"
                name="uniqueName"
                id="uniqueName"
                required
              />
            </Form>
          </Col>
        </Row>
      )}
    />
  );
};
