import { ClassNames } from '@emotion/react';
import React, { PropsWithChildren, Ref } from 'react';
import { BaseProps } from './Base';

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement>
  ) => (
    <ClassNames>
      {({ css, cx }) => (
        <div
          {...props}
          ref={ref}
          className={cx(
            className,
            css`
              & > * {
                display: inline-block;
              }

              & > * + * {
                margin-left: 15px;
              }
            `
          )}
        />
      )}
    </ClassNames>
  )
);
