import { createUser } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { UserEditor } from './UserEditor';
import { RootState } from '../../../redux/types';
import { TenantModel } from '@nimles/models';

const user = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
};

export const NewUser: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );

  const handleSave = async (values: any) => {
    const user: any = await dispatch(createUser({ ...values }));
    history.push('/identity/users/' + user.id);
  };

  return tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New User</strong>
        </CardHeader>
        <CardBody>
          <UserEditor user={user} tenant={tenant} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
