import { createTenant } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { TenantEditor } from './TenantEditor';
import { useClients } from '../../../hooks/clients';

export const NewTenant: FC = () => {
  const dispatch = useDispatch();
  const { clients } = useClients();

  const onSave = async (values: any) => {
    await dispatch(createTenant({ ...values }));
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Client</strong>
        </CardHeader>
        <CardBody>
          <TenantEditor tenant={{}} clients={clients} onSave={onSave} />
        </CardBody>
      </Card>
    </div>
  );
};
