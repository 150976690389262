import { FC, useState } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import {
  InputField,
  TextAreaField,
  // TextAreaField
} from '@nimles/react-web-forms';
import { ElementsEditor } from '../../../components/elements/ElementsEditor';
import TagsField from '../../../components/fields/TagsField';
import { EditorActions } from '../../../components/Editor';
import { QuillField } from '../../../components/fields/QuillField';
import { OrganizationModel, PostModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { ImageButton, ImagePlaceholder } from '../../../components/ImageButton';
import { ImagePreview } from '../../../components/images';
import { SelectImageModal } from '../../../components/SelectImageModal';

const validationSchema = function () {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, `Title has to be at least 2 characters`)
      .required('Title is required'),
  });
};

interface Props {
  post: PostModel;
  organizations?: OrganizationModel[];
  onSave: (post: any, publish: boolean) => Promise<void>;
}

export const PostEditor: FC<Props> = ({ post, organizations, onSave }) => {
  const [publish, setPublish] = useState(false);
  const [isImageOpen, setImageOpen] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const post = {
        ...values,
      };
      await onSave(post, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: post.id,
    title: post.title || '',
    longTitle: post.longTitle || '',
    uniqueName: post.uniqueName || '',
    summary: post.summary || '',
    content: post.content,
    organizationId: post.organizationId,
    elements: post.elements || [],
    tags: post.tags || [],
    imageId: post.imageId,
    imageData: '',
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => {
        const imageValue = values.imageId || values.imageData;
        return (
          <Row>
            <Col lg="12">
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <EditorActions
                  onPublish={() => {
                    setPublish(true);
                    submitForm();
                  }}
                  onSave={() => {
                    setPublish(false);
                    submitForm();
                  }}
                  onReset={handleReset}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                />
                {values.id && (
                  <InputField name="id" label="Id" disabled required />
                )}
                <OrganizationSelectField
                  organizations={organizations}
                  defaultOrganizationId={post.organizationId}
                />
                <InputField name="title" label="Title" required />
                <InputField name="longTitle" label="Longitle" required />
                <InputField name="uniqueName" label="Unique name" />

                <TextAreaField name="summary" label="Summary" />
                <QuillField name="description" label="Description" />
                <Label>Image</Label>
                <ImageButton
                  onClick={(e) => {
                    e.preventDefault();
                    setImageOpen(true);
                  }}
                >
                  {imageValue ? (
                    <ImagePreview value={imageValue} />
                  ) : (
                    <ImagePlaceholder>Select Image</ImagePlaceholder>
                  )}
                </ImageButton>
                <TagsField name="tags" label="Tags" required />
                <ElementsEditor
                  type="Post"
                  elements={values.elements}
                  onChange={(elements) => setFieldValue('elements', elements)}
                />
              </Form>
              <SelectImageModal
                title="Select Image"
                isOpen={isImageOpen}
                onToggle={() => setImageOpen(!isImageOpen)}
                onSelect={(image) => {
                  if (image.id) {
                    setFieldValue('imageId', image.id);
                    setFieldValue('imageData', undefined);
                  } else {
                    setFieldValue('imageId', undefined);
                    setFieldValue('imageData', image.uri);
                  }
                  setImageOpen(false);
                }}
              />
            </Col>
          </Row>
        );
      }}
    />
  );
};
