import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { useOrganizations, useProductProperties } from '../../../hooks';

export const ProductProperties: FC = () => {
  const { organizations } = useOrganizations();
  const { productProperties } = useProductProperties();
  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> ProductProperties
              <div className="card-header-actions">
                <Link
                  to="/pim/productProperties/new"
                  className="btn btn-sm btn-primary"
                >
                  New ProductProperty
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {productProperties &&
                  productProperties.map((productProperty: any) => {
                    const productPropertyLink = `/pim/productProperties/${productProperty.id}`;

                    return (
                      <tr key={productProperty.id.toString()}>
                        <th scope="row">
                          <Link to={productPropertyLink}>
                            {productProperty.id}
                          </Link>
                        </th>
                        <td>
                          <Link to={productPropertyLink}>
                            {productProperty.name}
                          </Link>
                        </td>
                        <td>
                          {getOrganizationName(productProperty.organizationId)}
                        </td>
                        <td>{productProperty.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
