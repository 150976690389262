import { ElementModel } from '@nimles/models';
import { createFile } from '@nimles/react-redux';
import { Dispatch } from 'redux';

export function removeTempIds(elements: ElementModel[]): ElementModel[] {
  return elements.map((element) => {
    return {
      ...element,
      id: element.id?.indexOf('temp') === 0 ? undefined : element.id,
      children: element.children && removeTempIds(element.children),
    };
  });
}

export function copyElements(elements: ElementModel[]): ElementModel[] {
  return elements.map((element) => {
    return {
      type: element.type,
      content: element.content,
      css: element.css,
      styles: element.styles && { ...element.styles },
      properties: element.properties && { ...element.properties },
      children: element.children && copyElements(element.children),
    };
  });
}

export const extractImageElements = (elements: ElementModel[]) => {
  const imageElements: ElementModel[] = [];
  if (elements) {
    elements.forEach((element) => {
      if (
        element.type === 'Image' &&
        element.content &&
        element.content.indexOf('data') === 0
      ) {
        imageElements.push(element);
      }

      const imageChildren =
        element.children && extractImageElements(element.children);

      if (imageChildren && imageChildren.length) {
        imageElements.push(...imageChildren);
      }
    });
  }

  return imageElements;
};

export const saveImages = async (
  elements: ElementModel[],
  dispatch: Dispatch<any>,
  organizationId?: string | null
) => {
  const imageElements = extractImageElements(elements);
  await Promise.all(
    imageElements.map(async (element) => {
      const image = organizationId
        ? { uri: element.content, organizationId }
        : { uri: element.content };

      const file: any = await dispatch(createFile(image));

      element.content = file.id;
    })
  );
};
