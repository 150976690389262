import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { updateClient } from '@nimles/react-redux';
import { useParams } from 'react-router';
import { ClientEditor } from './ClientEditor';
import { useTenants } from '../../../hooks/tenants';
import { useClients } from '../../../hooks/clients';

interface Params {
  clientId: string;
}

export const Client: FC = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams<Params>();

  const { clients } = useClients();
  const client = clients.find((c: any) => c.id === clientId);
  const { selectedTenant } = useTenants();

  const onSave = async (values: any) => {
    await dispatch(updateClient({ ...client, ...values }));
  };

  return client && selectedTenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Client</strong>
        </CardHeader>
        <CardBody>
          <ClientEditor
            client={client}
            tenant={selectedTenant}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
