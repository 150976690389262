import { InputField } from '@nimles/react-web-forms';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { FC } from 'react';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, UserModel } from '@nimles/models';

const validationSchema = function () {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  user: UserModel;
  tenant: OrganizationModel;
  onSave: (relationship: any) => Promise<void>;
}

export const UserEditor: FC<Props> = ({ user, tenant, onSave }) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const user = {
        ...values,
      };
      await onSave(user);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: user.id,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    username: user.username || '',
    password: user.password || '',
  };

  return user ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
          name="simpleForm"
          autocomplete="off"
        >
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && (
            <FormGroup>
              <Label for="id">Id</Label>
              <Input
                type="text"
                name="id"
                id="id"
                required
                disabled={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.id}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
          )}
          <InputField
            name="firstName"
            label="First name"
            placeholder="First name"
            value={values.firstName}
          />
          <InputField
            name="lastName"
            label="Last name"
            placeholder="Last name"
            value={values.lastName}
          />
          <InputField
            name="username"
            label="Username / Email"
            required
            placeholder="Username"
            value={values.username}
          />
          <InputField
            name="password"
            label="Password"
            type="password"
            required
            placeholder="Password"
            value={values.password}
          />
        </Form>
      )}
    />
  ) : null;
};
