import { createOrganization, publishOrganization } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { OrganizationEditor } from './OrganizationEditor';
import { useHistory } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';
import { useCategories } from '../../../hooks';

export const NewOrganization: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { categories } = useCategories();

  const handleSave = async (values: any, publish: boolean) => {
    const organization: any = await dispatch(createOrganization({ ...values }));
    if (publish) {
      await dispatch(publishOrganization(organization.id));
    }
    history.push('/organization-management/organization/' + organization.id);
  };

  const tags = organizations
    .reduce<string[]>(
      (list, org) => (org.tags ? [...list, ...org.tags] : list),
      []
    )
    .reduce<{ name: string; count: number }[]>((items, name) => {
      let item = items.find((i) => i.name === name);
      if (item) {
        item.count++;
      } else {
        items.push({ name, count: 1 });
      }
      return items;
    }, [])
    .sort();

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Organization</strong>
        </CardHeader>
        <CardBody>
          <OrganizationEditor
            organization={{}}
            categories={categories}
            tags={tags}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
