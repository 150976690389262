import { createDeliveryOption, State } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { DeliveryOptionEditor } from './DeliveryOptionEditor';
import { RootState } from '../../../redux/types';
import { OrganizationModel, DeliveryOptionModel } from '@nimles/models';
import { deliveryOptionTemplates } from './deliveryOptionsTemplates';

export const NewDeliveryOption: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const processor = query?.get('processor?');

  const { values: organizations, selected } = useSelector<
    RootState,
    State<OrganizationModel>
  >(({ organizations }) => organizations);
  const organization = selected || organizations?.find((o) => o);

  const onSave = async (values: any) => {
    const deliveryOption: any = await dispatch(
      createDeliveryOption({
        ...values,
      })
    );

    history.push(`/ecommerce/deliveryOptions/${deliveryOption.id}`);
  };

  const deliveryOption: DeliveryOptionModel = {
    deliveryType: processor && 'DeliveryIntegration',
    name: processor?.toString(),
    organizationId: organization && organization.id,
    fields:
      (processor &&
        deliveryOptionTemplates.find(
          ({ name }) =>
            name.toLowerCase() === processor.toString().toLowerCase()
        )?.fields) ||
      [],
  };

  return organization ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>
            {processor ? 'Add ' + processor : 'New deliveryOption'}
          </strong>
          <p>
            {processor
              ? 'Setup delivery processor'
              : 'Create new deliveryOption'}
          </p>
        </CardHeader>
        <CardBody>
          <DeliveryOptionEditor
            deliveryOption={deliveryOption}
            onSave={onSave}
            organizations={organizations}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
