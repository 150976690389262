import { Reducer } from 'react';
import { Action } from '@nimles/react-redux';
import { CultureState } from './types';

const availableCultures = ['en', 'sv'];

const CHANGE_CULTURE = 'CHANGE_CULTURE';
const LOAD_CULTURES = 'LOAD_CULTURES';

export const changeCulture = (culture?: string) => {
  return {
    type: CHANGE_CULTURE,
    data: culture,
  };
};

export const loadCultures = (cultures: string[]) => {
  return {
    type: LOAD_CULTURES,
    data: cultures,
  };
};

export const cultureReducer: Reducer<CultureState, Action> = (
  state = { loaded: false, values: [...availableCultures] },
  action
) => {
  switch (action.type) {
    case CHANGE_CULTURE:
      return {
        ...state,
        selected: !action.data
          ? undefined
          : state.values.find((v) => v === action.data) || state.selected,
      };
    case LOAD_CULTURES:
      const values: string[] = action.data?.length ? action.data : state.values;
      return {
        ...state,
        values,
        selected:
          state.selected && values.includes(state.selected)
            ? state.selected
            : values.find((x) => x),
      };
    default:
      return { ...state };
  }
};
