import React from 'react';
import { useDispatch } from 'react-redux';
import { CSidebar, CSidebarClose } from '@coreui/react';
import { SHOW_ASIDE } from '../redux/actionTypes';
import { useUiControls } from '../hooks/uiControls';

const TheAside = () => {
  const { asideShow } = useUiControls();
  const dispatch = useDispatch();
  const setState = (state: boolean) =>
    dispatch({ type: SHOW_ASIDE, data: state });

  return (
    <CSidebar
      aside
      colorScheme="light"
      size="lg"
      overlaid
      show={asideShow}
      onShowChange={(state: boolean) => setState(state)}
    >
      <CSidebarClose onClick={() => setState(false)} />
      {/*aside content*/}
      <div className="nav-underline">
        <div className="nav nav-tabs">
          <div className="nav-item">
            <div className="nav-link">Aside</div>
          </div>
        </div>
      </div>
    </CSidebar>
  );
};

export default React.memo(TheAside);
