import { Column, Row } from '@nimles/react-web-components';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { useOrganizations, useReviewGroups } from '../../../hooks';

export const ReviewGroups: FC = () => {
  const { organizations } = useOrganizations();
  const { reviewGroups } = useReviewGroups();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Column width={100}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> ReviewGroups
              <div className="card-header-actions">
                <Link
                  to="/pim/reviewGroups/new"
                  className="btn btn-sm btn-primary"
                >
                  New ReviewGroup
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {reviewGroups &&
                  reviewGroups.map((reviewGroup) => {
                    const reviewGroupLink = `/pim/reviewGroups/${reviewGroup.id}`;

                    return (
                      <tr key={reviewGroup.id?.toString()}>
                        <th scope="row">
                          <Link to={reviewGroupLink}>
                            <div>
                              <strong>{reviewGroup.title}</strong>
                            </div>
                            <div>
                              <small>{reviewGroup.id}</small>
                            </div>
                            <div>{reviewGroup.uniqueName}</div>
                          </Link>
                        </th>
                        <td>
                          {getOrganizationName(reviewGroup.organizationId)}
                        </td>
                        <td>{reviewGroup.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Column>
      </Row>
    </div>
  );
};
