import React from 'react';
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CToggler,
  CBreadcrumbRouter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

// routes config
import routes from '../routes';
import { TenantHeaderDropdown } from './DefaultLayout/TenantHeaderDropdown';
import { AccountHeaderDropdown } from './DefaultLayout/AccountHeaderDropdown';
import TheHeaderDropdownNotifications from './TheHeaderDropdownNotifications';
import { OrganizationHeaderDropdown } from './DefaultLayout/OrganizationHeaderDropdown';
import { useUiControls } from '../hooks/uiControls';

const TheHeader = () => {
  const { toggleSidebarMobile, toggleSidebar, toggleDarkMode } =
    useUiControls();

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleDarkMode}
          title="Toggle Light/Dark Mode"
        >
          <CIcon name="cil-moon" className="c-d-dark-none" alt="Dark mode" />
          <CIcon name="cil-sun" className="c-d-default-none" alt="Light mode" />
        </CToggler>
        <TheHeaderDropdownNotifications />
        <TenantHeaderDropdown />
        <OrganizationHeaderDropdown />
        <AccountHeaderDropdown />
        {/* <CToggler
          inHeader
          className="d-md-down-none"
          onClick={() => dispatch({ type: SHOW_ASIDE, data: !asideShow })}
        >
          <CIcon className="mr-2" size="lg" name="cil-applications-settings" />
        </CToggler> */}
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  );
};

export default TheHeader;
