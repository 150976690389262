import { FC } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import { Row, Column } from '@nimles/react-web-components';
import { InputField, SelectField } from '@nimles/react-web-forms';
import { LocalizedField } from '../../../components/LocalizedField';
import { EditorActions } from '../../../components/Editor';
import { DiscountModel, OrganizationModel, ProductModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const discountTypes = [
  { name: 'Net Amount', value: 'NetAmount' },
  { name: 'Gross Amount', value: 'GrossAmount' },
  { name: 'Percentage', value: 'Percentage' },
];

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });
};

interface Props {
  discount: DiscountModel;
  organizations: OrganizationModel[];
  products: ProductModel[];
  onSave: (discount: DiscountModel) => Promise<void>;
}

export const DiscountEditor: FC<Props> = ({
  discount,
  organizations,
  products,
  onSave,
}) => {
  const handleSubmit = async (
    values: DiscountModel,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<DiscountModel>
  ) => {
    try {
      console.info(values);
      const discount: DiscountModel = {
        ...values,
      };
      await onSave(discount);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };

  const values: DiscountModel = discount && {
    id: discount.id,
    name: discount.name || '',
    code: discount.code || '',
    organizationId: discount.organizationId,
    value: discount.value || 0,
    startDate: discount.startDate || '',
    endDate: discount.endDate || '',
    discountType: discount.discountType,
  };

  return values ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onSave={() => {
              submitForm();
            }}
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}
          <OrganizationSelectField
            organizations={organizations}
            defaultOrganizationId={discount.organizationId}
          />
          <FormGroup>
            <LocalizedField label="Name" name="name">
              {({ localizedName }) => (
                <InputField name={localizedName} required />
              )}
            </LocalizedField>
          </FormGroup>
          <FormGroup>
            <LocalizedField label="Code" name="code">
              {({ localizedName }) => (
                <InputField name={localizedName} required />
              )}
            </LocalizedField>
          </FormGroup>
          <Row>
            <Column xs={50}>
              <FormGroup>
                <InputField type="date" name="startDate" label="Start" />
              </FormGroup>
            </Column>
            <Column xs={50}>
              <FormGroup>
                <InputField type="date" name="endDate" label="End" />
              </FormGroup>
            </Column>
          </Row>
          <FormGroup>
            <SelectField
              name="discountType"
              label="Discount type"
              placeholder="Select type"
              options={discountTypes}
              optionName={(o) => o.name}
              optionValue={(o) => o.value}
              value={values.discountType}
              disabled={!!discount.discountType}
            />
          </FormGroup>
          {values.discountType === 'NetAmount' ||
          values.discountType === 'GrossAmount' ? (
            <Row>
              <Column xs={50}>
                <FormGroup>
                  <InputField type="number" name="value" label="Amount" />
                </FormGroup>
              </Column>
              <Column xs={50}>
                <FormGroup>
                  <InputField name="currency" label="Currency" />
                </FormGroup>
              </Column>
            </Row>
          ) : values.discountType === 'Percentage' ? (
            <Row>
              <Column xs={50}>
                <FormGroup>
                  <InputField type="number" name="value" label="Percentage" />
                </FormGroup>
              </Column>
            </Row>
          ) : null}
        </Form>
      )}
    />
  ) : null;
};
