import React, { FC, useState } from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd';
import availableElements, { propertyTemplates } from './elementTemplates';
import { Button, Column, Text } from '@nimles/react-web-components';
import { UpdateElementModal, AddElementModal } from './ElementModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faPlus,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import elementTemplates from './elementTemplates';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import styled from '@emotion/styled';
import { ElementModel } from '@nimles/models';
import { ImagePreview } from '../images';
import { ImageObjectPreview } from '../images/ImageObjectPreview';

let newTempId = 1000000;

function isValidChild(child?: ElementModel, parentType?: string | null) {
  console.log('parent', parentType);

  if (!parentType) {
    return false;
  }

  const parents =
    child?.type &&
    elementTemplates.find(({ type }) => child.type === type)?.parents;

  console.log('parents', parents);

  const isValid = !parents || parents.includes(parentType);

  console.log('isValidChild', isValid);

  return isValid;
}

function searchTree(
  element?: ElementModel,
  id?: string
): ElementModel | null | undefined {
  if (element?.id === id) {
    return element;
  } else if (element?.children) {
    var i;
    var result: ElementModel | null | undefined = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], id);
    }
    return result;
  }
  return null;
}

const ElementContainer = styled.div<{ type: string; isDragging: boolean }>`
  flex: ${({ type }) => (type === 'Column' ? 100 : 'auto')};
  border: 1px solid lightgrey;
  border-radius: 2px;
  background-color: ${({ isDragging }) =>
    isDragging ? 'lightgreen' : 'white'};
  display: flex;
  flex-direction: column;
`;

const ElementList = styled.div<{
  type?: string | null;
  isDraggingOver: boolean;
}>`
  flex: 1;
  min-height: 40px;
  display: ${({ type }) =>
    type === 'Row' || type === 'Column' ? 'flex' : 'block'};
  flex-direction: ${({ type }) => (type === 'Column' ? 'column' : 'row')};
  align-items: stretch;
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? 'skyblue' : 'transparent'};
  transition: all 0.5s;
  flex-wrap: wrap;
`;

const AddElementButton = styled.button`
  flex: 1 0 auto;
  border: 1px dashed lightgrey;
  background: #efefef;
  outline: none;
  &:focus {
    outline: none;
  }
`;

interface AddElementProps {
  parent: ElementModel;
  onAddElement: (parent: ElementModel) => void;
}

const AddElement = ({ parent, onAddElement }: AddElementProps) => (
  <Column flex="1">
    <AddElementButton
      onClick={(e) => {
        e.preventDefault();
        onAddElement(parent);
      }}
    >
      <i className="fa fa-plus" />
    </AddElementButton>
  </Column>
);

const ElementHeader = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  cursor: move;

  &:hover {
    button {
      display: block;
    }
  }
`;

const StyledIcon = styled.i`
  margin-right: 8px;
`;

const Icon = ({ type }: { type?: string | null }) => {
  const element = type && availableElements.find((e) => e.type === type);

  return element ? <StyledIcon className={element.icon} /> : null;
};

const Title = styled.h3`
  flex: 1;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
  margin: 0;
`;

const ElementButton = styled.button`
  border: none;
  background: transparent;
  padding: 0 10px;
  display: none;
  height: 20px;
`;

const ElementContent = styled.div`
  padding: 8px;
  cursor: pointer;
`;

const ElementPadding = styled.div`
  padding: 5px;
  flex: 100;
  overflow: hidden;
`;

const elementTypesWithChildren = elementTemplates
  .filter((e) => e.hasChildren)
  .map((e) => e.type);

const hasChildren = (element: ElementModel) => {
  return element.type && elementTypesWithChildren.indexOf(element.type) !== -1;
};

interface ContentProps {
  element: ElementModel;
}

const Content = ({ element }: ContentProps) => {
  const culture = useSelector<RootState, string | undefined>(
    ({ culture }) => culture.selected
  );
  const content = !culture
    ? element.content
    : (element.contentLocalized && element.contentLocalized[culture]) ??
      element.content;

  switch (element.type) {
    case 'Heading1':
      return <h1>{content || ''}</h1>;
    case 'Heading2':
      return <h2>{content || ''}</h2>;
    case 'Heading3':
      return <h3>{content || ''}</h3>;
    case 'Heading4':
      return <h4>{content || ''}</h4>;
    case 'Heading5':
      return <h5>{content || ''}</h5>;
    case 'Heading6':
      return <h6>{content || ''}</h6>;
    case 'Text':
      return <p>{content || ''}</p>;
    case 'RichTextHtml':
    case 'Html':
    case 'Link':
    case 'ExternalLink':
      return content ? (
        <Text
          {...element.properties}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : null;
    case 'Image':
      return element.content ? <ImagePreview value={element.content} /> : null;
    case 'NavButton':
      return <Button {...element.properties}>{content}</Button>;
    case 'Svg':
      return content ? (
        <ImageObjectPreview {...element.properties} value={content} />
      ) : null;
    default:
      return null;
  }
};

interface ElementProps {
  parent: ElementModel;
  element: ElementModel;
  index: number;
  draggedElement?: ElementModel | null;
  onAddElement: (parent: ElementModel) => void;
  onSelectElement: (element: ElementModel) => void;
  onDeleteElement: (parent: ElementModel, element: ElementModel) => void;
  onMoveElementUp: (parent: ElementModel, element: ElementModel) => void;
  onMoveElementDown: (parent: ElementModel, element: ElementModel) => void;
}

const Element = ({
  parent,
  element,
  index,
  draggedElement,
  onAddElement,
  onSelectElement,
  onDeleteElement,
  onMoveElementUp,
  onMoveElementDown,
}: ElementProps) => {
  const template = elementTemplates.find((e) => e.type === element.type);
  const title = template ? template.title : element.type;
  const iconStyle = { marginLeft: '10px' };
  const icons = element.properties
    ? Object.entries(element.properties).map(([key, value]) => {
        const property = propertyTemplates.find((p) => p.name === key);
        if (!property || !property.icon) {
          return null;
        }
        const style = { ...iconStyle };
        if (property.type === 'checkbox') {
          style['color'] = value === true ? 'green' : 'red';
        }

        return <StyledIcon key={key} className={property.icon} style={style} />;
      })
    : [];

  if (
    (element.styles && Object.values(element.styles).filter((v) => v).length) ||
    element.css
  ) {
    icons.push(<StyledIcon className="fas fa-palette" style={iconStyle} />);
  }

  return (
    <Draggable
      draggableId={element.id || 'error'}
      index={index}
      key={element.id}
    >
      {(provided, snapshot) => (
        <ElementPadding {...provided.draggableProps}>
          <ElementContainer
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            type={element.type === 'Row' ? 'Row' : 'Column'}
            isDragging={snapshot.isDragging}
          >
            <ElementHeader>
              <Icon type={element.type} />
              <Title>
                {title} <small>{element.uniqueName}</small>
                {icons.map((icon) => (
                  <span>{icon}</span>
                ))}
              </Title>

              {hasChildren(element) && (
                <ElementButton
                  onClick={(e) => {
                    e.preventDefault();
                    onAddElement(element);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </ElementButton>
              )}
              <ElementButton
                onClick={(e) => {
                  e.preventDefault();
                  onMoveElementUp(parent, element);
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </ElementButton>
              <ElementButton
                onClick={(e) => {
                  e.preventDefault();
                  onMoveElementDown(parent, element);
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </ElementButton>
              <ElementButton
                onClick={(e) => {
                  e.preventDefault();
                  onSelectElement(element);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </ElementButton>
              <ElementButton
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteElement(parent, element);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ElementButton>
            </ElementHeader>
            {hasChildren(element) ? (
              <Droppable
                droppableId={element.id || 'error'}
                direction={element.type === 'Row' ? 'horizontal' : 'vertical'}
                isDropDisabled={
                  !draggedElement ||
                  draggedElement.id === element.id ||
                  !isValidChild(element, draggedElement.type) ||
                  !!searchTree(draggedElement, element.id)
                }
              >
                {(provided, snapshot) => (
                  <ElementList
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    type={element.type}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    {element.children &&
                      element.children.map((child, index) => (
                        <Element
                          key={child.id}
                          parent={element}
                          element={child}
                          index={index}
                          draggedElement={draggedElement}
                          onAddElement={onAddElement}
                          onSelectElement={onSelectElement}
                          onDeleteElement={onDeleteElement}
                          onMoveElementDown={onMoveElementDown}
                          onMoveElementUp={onMoveElementUp}
                        />
                      ))}
                    {provided.placeholder}
                  </ElementList>
                )}
              </Droppable>
            ) : (
              <ElementContent
                onClick={(e) => {
                  e.preventDefault();
                  onSelectElement(element);
                }}
              >
                <Content element={element} />
              </ElementContent>
            )}
          </ElementContainer>
        </ElementPadding>
      )}
    </Draggable>
  );
};

interface Props {
  elements: ElementModel[];
  type: string;
  onChange: (elements: ElementModel[]) => void;
}

export const ElementsEditor: FC<Props> = ({ type, elements, onChange }) => {
  const [root, setRoot] = useState<ElementModel>({
    id: 'root',
    type: type,
    children: elements || [],
  });
  const [parent, setParent] = useState<ElementModel>();
  const [selectedElement, setSelectedElement] = useState<ElementModel>();
  const [draggedElement, setDraggedElement] = useState<ElementModel | null>();

  const handleDragStart = ({ draggableId }: DragStart) => {
    const draggedElement = searchTree(root, draggableId);
    setDraggedElement(draggedElement);
  };

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    setDraggedElement(undefined);
    if (!destination || !draggedElement || draggableId !== draggedElement.id) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceElement = searchTree(root, source.droppableId);

    if (!sourceElement) {
      return;
    }

    const destinationElement = searchTree(root, destination.droppableId);

    if (!destinationElement) {
      return;
    }

    if (
      (draggedElement.type === 'Row' && destinationElement.type !== 'Column') ||
      (draggedElement.type === 'Column' && destinationElement.type !== 'Row') ||
      (destinationElement.type === 'Row' && draggedElement.type !== 'Column')
    ) {
      return;
    }

    // Todo make elements immutable
    const sourceElements = [...(sourceElement.children ?? [])];
    sourceElements.splice(source.index, 1);
    sourceElement.children = sourceElements;

    if (destinationElement.children) {
      const destinationElements = [...destinationElement.children];
      destinationElements.splice(destination.index, 0, draggedElement);
      destinationElement.children = destinationElements;
    } else {
      destinationElement.children = [draggedElement];
    }

    setRoot({ ...root });
    onChange([...(root.children ?? [])]);
  };

  const updateElement = (element: ElementModel) => {
    Object.assign(selectedElement, element);
    setRoot({ ...root });
    onChange([...(root.children ?? [])]);
  };

  const moveElementUp = (parent: ElementModel, element: ElementModel) => {
    if (!parent?.children?.length) {
      return;
    }

    const index = parent.children.indexOf(element);

    if (index < 1) {
      return;
    }

    const children = [...parent.children];

    children.splice(index, 1);
    children.splice(index - 1, 0, element);

    parent.children = children;

    setRoot({ ...root });
    onChange([...(root.children ?? [])]);
  };

  const moveElementDown = (parent: ElementModel, element: ElementModel) => {
    if (!parent?.children?.length) {
      return;
    }

    const index = parent.children.indexOf(element);

    if (index + 1 >= parent.children.length) {
      return;
    }

    const children = [...parent.children];

    children.splice(index, 1);
    children.splice(index + 1, 0, element);

    parent.children = children;

    setRoot({ ...root });
    onChange([...(root.children ?? [])]);
  };

  const insertElement = (parent: ElementModel, element: ElementModel) => {
    const tempId = newTempId++;
    const newElement = {
      ...element,
      id: 'temp-' + tempId,
    };
    const children = parent.children
      ? [...parent.children, newElement]
      : [newElement];
    parent.children = children;

    const newRoot = { ...root };
    console.log(newRoot);

    setRoot(newRoot);
    onChange([...(root.children ?? [])]);
    setParent(undefined);
  };

  const onAddElement = (parent: ElementModel) => {
    const parentTemplate = availableElements.find(
      ({ type }) => parent.type === type
    );

    if (parentTemplate?.children?.length === 1) {
      insertElement(parent, { type: parentTemplate.children[0] });
    } else {
      setParent(parent);
    }
  };

  const deleteElement = (parent: ElementModel, element: ElementModel) => {
    const children = parent.children?.filter((c) => c.id !== element.id);
    parent.children = children;
    setRoot({ ...root });
    onChange([...(root.children ?? [])]);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
        <Droppable
          droppableId={root.id || 'error'}
          isDropDisabled={
            !draggedElement || !isValidChild(draggedElement, root.type)
          }
        >
          {(provided, snapshot) => (
            <ElementList
              {...provided.droppableProps}
              ref={provided.innerRef}
              type={root.type}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {root.children?.map((element, index) => {
                return (
                  <Element
                    key={element.id}
                    parent={root}
                    element={element}
                    index={index}
                    draggedElement={draggedElement}
                    onAddElement={onAddElement}
                    onSelectElement={setSelectedElement}
                    onDeleteElement={deleteElement}
                    onMoveElementDown={moveElementDown}
                    onMoveElementUp={moveElementUp}
                  />
                );
              })}
              {provided.placeholder}
              <AddElement parent={root} onAddElement={onAddElement} />
            </ElementList>
          )}
        </Droppable>
      </DragDropContext>

      <UpdateElementModal
        element={selectedElement}
        onClose={() => setSelectedElement(undefined)}
        onChange={updateElement}
      />
      {parent && (
        <AddElementModal
          root={root.type}
          parent={parent}
          onClose={() => setParent(undefined)}
          onAdd={(element, parent) => insertElement(parent, element)}
        />
      )}
    </>
  );
};
