import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorContainer = styled.div`
  display: flex;
`;

const Swatch = styled.button`
  flex: 1;
  padding: 4px;
  background: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  outline: none;
  box-shadow: none;
  margin: 8px 0px;
`;

const Remove = styled.button`
  padding: 4px;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-left-width: 0;
  outline: none;
  box-shadow: none;
  margin: 8px 0px;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface ColorProps {
  color?: string;
  textColor?: string;
  image?: string;
}

const Color = styled.div<ColorProps>`
  width: 100%;
  height: 37px;
  border-radius: 3px;
  padding: 0;
  color: ${({ textColor }) => textColor || '#000'};
  background-color: ${({ color }) => color};
  background-image: ${({ image }) => image};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

interface Props {
  id?: string;
  value: string;
  onChange: (value?: string) => void;
  placeholder?: string;
  textColor?: string;
  valid?: boolean;
  invalid?: boolean;
}

export const ColorPicker: FC<Props> = ({
  id,
  value,
  onChange,
  placeholder,
  textColor,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ColorContainer id={id}>
      <Swatch
        onClick={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
        }}
      >
        <Color color={value} textColor={textColor}>
          {placeholder}
        </Color>
      </Swatch>
      <Remove>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
            onChange(undefined);
          }}
        />
      </Remove>
      {isOpen ? (
        <PopOver>
          <Cover
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          />
          <SketchPicker
            color={value}
            onChange={(color) =>
              onChange(
                `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
              )
            }
          />
        </PopOver>
      ) : null}
    </ColorContainer>
  );
};
