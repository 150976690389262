import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useThemes } from '../../../hooks/themes';

export const Themes: FC = () => {
  const history = useHistory();

  const { themes } = useThemes();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Themes
              <div className="card-header-actions">
                <Link to="/cms/themes/new" className="btn btn-sm btn-primary">
                  New Theme
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {themes.map((theme) => {
                  const themeLink = `/cms/themes/${theme.id}`;
                  return (
                    <tr key={theme.id?.toString()}>
                      <td>
                        <Link to={themeLink}>
                          <div>{theme.name}</div>
                          {/* <small>{theme.id}</small> */}
                        </Link>
                      </td>
                      <td>{getOrganizationName(theme.organizationId)}</td>
                      <td>{theme.accessibility}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle nav>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                history.push(
                                  `/cms/themes/new?themeId=${theme.id}`
                                )
                              }
                            >
                              Clone
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
