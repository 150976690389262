import React, { FC } from 'react';
import { CustomInputProps, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field, FieldProps } from 'formik';
import ReactQuill from 'react-quill';
import styled from '@emotion/styled';

const StyledReactQuill = styled(ReactQuill)`
  background: #fff;
  color: #000;
`;

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (value: any) => {
    setFieldValue(name, value);
  };

  return (
    <div key={name}>
      {props.label ? <Label for={name}>{props.label}</Label> : null}
      <FormGroup>
        <StyledReactQuill
          id={name}
          value={value || ''}
          onChange={handleChange}
        />
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    </div>
  );
};

export const QuillField = (props: any) => (
  <Field {...props} component={StandardInput} />
);
