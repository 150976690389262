import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { createPage, publishPage } from '@nimles/react-redux';
import { useHistory, useLocation } from 'react-router';
import { PageEditor } from './PageEditor';
import {
  removeTempIds,
  copyElements,
  saveImages,
} from '../../../components/elements/elementUtils';
import firstPageElements from './firstPageElements';
import aboutElements from './aboutElements';
import contactElements from './contactElements';
import informationElements from './informationElements';
import locationsElements from './locationsElements';
import termsElements from './termsElements';
import { ElementModel, PageModel } from '@nimles/models';
import blogElements from './blogElements';
import { useOrganizations } from '../../../hooks/organizations';
import { usePages } from '../../../hooks/pages';

const getDefaultElements = (pageType?: string | null): ElementModel[] => {
  switch (pageType) {
    case 'FirstPage':
      return firstPageElements;
    case 'About':
      return aboutElements;
    case 'Contact':
      return contactElements;
    case 'Locations':
      return locationsElements;
    case 'TermsOfSales':
      return termsElements;
    case 'Information':
      return informationElements;
    case 'Blog':
      return blogElements;
    default:
      return [];
  }
};

export const NewPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pageId = query?.get('pageId?');
  const pageType = query?.get('type');

  const { pages } = usePages();
  const template = pages.find((l) => l.id === pageId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    const page: any = await dispatch(
      createPage({
        ...values,
        elements: removeTempIds(values.elements),
        accessibility: 'Public',
      })
    );
    if (publish) {
      await dispatch(publishPage(page.id));
    }
    history.push('/cms/pages/' + page.id);
  };

  const page: PageModel | null | undefined = !pageId
    ? {
        pageType,
        elements: getDefaultElements(pageType),
      }
    : !template
    ? null
    : {
        pageType: template.pageType,
        title: `${template.title} - copy`,
        elements: template.elements && copyElements(template.elements),
      };

  return page ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Page: {page.pageType}</strong>
        </CardHeader>
        <CardBody>
          <PageEditor
            page={page}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
