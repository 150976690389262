import React, { FC, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import styled from '@emotion/styled';
import { TenantModel, UserModel } from '@nimles/models';

const User = styled.div`
  padding: 0 10px;
`;
const deleteCookies = () => {
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};

const signOut = () => {
  window.localStorage.clear();
  deleteCookies();
  window.location.href = window.location.origin + '/logout';
};

export const AccountHeaderDropdown: FC = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useSelector<RootState, UserModel | undefined>(
    ({ currentUser }) => currentUser.user
  );
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );

  return currentUser ? (
    <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle nav>
        <User>
          {currentUser.picture && (
            <img
              src={currentUser.picture}
              className="img-avatar"
              alt={currentUser.username ?? undefined}
            />
          )}
          <div className="d-flex flex-column align-items-end">
            <div>{currentUser.username}</div>
            <small>{tenant && tenant.name}</small>
          </div>
        </User>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header tag="div" className="text-center">
          <strong>Account</strong>
        </DropdownItem>
        <DropdownItem>
          <Link to="/account/profile">
            <i className="fa fa-user"></i> Profile
          </Link>
        </DropdownItem>
        <DropdownItem onClick={signOut}>
          <i className="fa fa-lock"></i> Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  ) : null;
};
