import { User } from 'oidc-client';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { userManager } from '../App';

export const LoginResponse = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState<any>();

  const handleSigninCallback = useCallback(async () => {
    const user: User = await userManager.signinRedirectCallback();

    console.log('user', user);
    localStorage.setItem('up-a-grade-user', user.toStorageString());
    const result = await dispatch({
      type: 'SIGNIN_USER',
      data: {
        accessToken: user.access_token,
        refreshToken: user.refresh_token,
        expires: user.expires_at,
      },
    });
    console.log('result', result);

    const returnUrl = new URLSearchParams(search).get('returnUrl');

    setRedirect(returnUrl || '/');
  }, [dispatch, search]);

  useEffect(() => {
    handleSigninCallback();
  }, [handleSigninCallback]);

  return redirect ? <Redirect to={redirect} /> : <div></div>;
};
