import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { deliveryOptionTemplates } from './deliveryOptionsTemplates';
import { useOrganizations } from '../../../hooks/organizations';
import { useDeliveryOptions } from '../../../hooks/deliveryOptions';

export const DeliveryOptions: FC = () => {
  const { deliveryOptions } = useDeliveryOptions();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> deliveryOptions
              <div className="card-header-actions">
                {deliveryOptionTemplates.map(({ name }) => (
                  <>
                    <Link
                      to={`/ecommerce/deliveryOptions/new?processor=${name}`}
                      className="card-header-action"
                    >
                      Add {name}
                    </Link>
                    |
                  </>
                ))}
                <Link
                  to="/ecommerce/deliveryOptions/new"
                  className="card-header-action"
                >
                  Add Delivery Option
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {deliveryOptions &&
                  deliveryOptions.map((deliveryOption: any) => {
                    const deliveryOptionLink = `/ecommerce/deliveryOptions/${deliveryOption.id}`;

                    return (
                      <tr
                        key={deliveryOption.id && deliveryOption.id.toString()}
                      >
                        <th scope="row">
                          <Link to={deliveryOptionLink}>
                            <div>
                              <strong>{deliveryOption.name}</strong>
                            </div>
                            <div>
                              <small>{deliveryOption.id}</small>
                            </div>
                            <div>{deliveryOption.uniqueName}</div>
                          </Link>
                        </th>
                        <td>
                          {getOrganizationName(deliveryOption.organizationId)}
                        </td>
                        <td>{deliveryOption.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
