import { Checkbox } from '@nimles/react-web-components';
import { FC } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useProductGroups } from '../../hooks';
import { useOrganizations } from '../../hooks/organizations';
import { usePages } from '../../hooks/pages';
import { ColorPicker } from '../ColorPicker';
import { ImagePicker } from '../images';
import { PropertyTemplate } from './elementTemplates';

const OrganizationSelector: FC<{
  template: PropertyTemplate;
  value: string;
  onChange: (value: string) => void;
}> = ({ template, value, onChange }) => {
  const { organizations } = useOrganizations();

  return (
    <Input
      type="select"
      name={template.name}
      id={template.name}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      value={value}
    >
      <option></option>
      {organizations.map((o) => (
        <option key={o.id} value={o.id}>
          {o.name}
        </option>
      ))}
    </Input>
  );
};

const ProductGroupSelector: FC<{
  template: PropertyTemplate;
  value: string;
  onChange: (value: string) => void;
}> = ({ template, value, onChange }) => {
  const { productGroups } = useProductGroups();

  return (
    <Input
      type="select"
      name={template.name}
      id={template.name}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      value={value}
    >
      <option></option>
      {productGroups.map((o) => (
        <option key={o.id} value={o.id}>
          {o.name}
        </option>
      ))}
    </Input>
  );
};

const PageSelector: FC<{
  template: PropertyTemplate;
  value: string;
  onChange: (value: string) => void;
}> = ({ template, value, onChange }) => {
  const { pages } = usePages();

  return (
    <Input
      type="select"
      name={template.name}
      id={template.name}
      onChange={(e) => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      value={value}
    >
      <option></option>
      {pages.map((o) => (
        <option key={o.id} value={o.id}>
          {o.title}
        </option>
      ))}
    </Input>
  );
};

interface PropertyEditorProps {
  properties?: {
    [key: string]: any;
  } | null;
  template: PropertyTemplate;
  onPropertyChange: (
    name: string,
    value?: string | number | boolean | undefined
  ) => void;
}

export const PropertyEditor = ({
  properties = {},
  template,
  onPropertyChange,
}: PropertyEditorProps) => {
  const value = properties?.[template.name];
  return (
    <FormGroup check={template.type === 'checkbox'}>
      {template.type !== 'checkbox' ? (
        <Label>{template.displayName}</Label>
      ) : null}
      {(template.type === 'text' || template.type === 'number') && (
        <Input
          type={template.type}
          name={template.name}
          id={template.name}
          required
          onChange={(e) => {
            e.preventDefault();
            onPropertyChange(
              template.name,
              e.target.value === ''
                ? undefined
                : template.type === 'number'
                ? Number(e.target.value)
                : e.target.value
            );
          }}
          value={value}
        />
      )}
      {template.type === 'select' && (
        <Input
          type="select"
          name={template.name}
          id={template.name}
          onChange={(e) => {
            e.preventDefault();
            onPropertyChange(template.name, e.target.value);
          }}
          value={value || ''}
        >
          <option></option>
          {template.options &&
            template.options.map((o) => <option key={o}>{o}</option>)}
        </Input>
      )}
      {template.type === 'organization' && (
        <OrganizationSelector
          template={template}
          onChange={(value) => onPropertyChange(template.name, value)}
          value={value}
        />
      )}
      {template.type === 'productGroup' && (
        <ProductGroupSelector
          template={template}
          onChange={(value) => onPropertyChange(template.name, value)}
          value={value}
        />
      )}
      {template.type === 'page' && (
        <PageSelector
          template={template}
          onChange={(value) => onPropertyChange(template.name, value)}
          value={value}
        />
      )}
      {template.type === 'authAvailability' && (
        <PageSelector
          template={template}
          onChange={(value) => onPropertyChange(template.name, value)}
          value={value}
        />
      )}
      {template.type === 'color' && (
        <ColorPicker
          value={value}
          onChange={(value) => onPropertyChange(template.name, value)}
        />
      )}
      {template.type === 'image' && (
        <ImagePicker
          value={value}
          onChange={(image) =>
            onPropertyChange(
              template.name,
              image && `/${image.tenantId}/${image.id}`
            )
          }
        />
      )}
      {template.type === 'checkbox' && (
        <Label check>
          <Checkbox
            label={template.displayName}
            required
            onChange={(e) => {
              e.preventDefault();
              onPropertyChange(template.name, e.target.checked);
            }}
            checked={value || false}
          />
        </Label>
      )}
    </FormGroup>
  );
};
