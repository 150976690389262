import { createSite } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { SiteEditor } from './SiteEditor';
import { useHistory } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';

export const NewSite: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();

  const handleSave = async (values: any) => {
    const site: any = await dispatch(createSite({ ...values }));

    history.push('/organization-management/sites/' + site.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Site</strong>
        </CardHeader>
        <CardBody>
          <SiteEditor
            site={{}}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
