/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import {
  SelectField,
  InputField,
  ReactSelectField,
} from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { Column, Row } from '@nimles/react-web-components';
import { ImageButton, ImagePlaceholder } from '../../../components/ImageButton';
import { SelectImageModal } from '../../../components/SelectImageModal';
import * as locale from 'locale-codes';
import { ImagePreview } from '../../../components/images';
import { ClientModel, OrganizationModel, TenantModel } from '@nimles/models';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    uniqueName: Yup.string()
      .min(5, `uniqueName has to be at least 5 characters`)
      .required('uniqueName is required'),
  });
};

const googleButtonCss = css`
  color: #fff;
  background: #dc4e41;

  &:hover {
    color: #fff;
    background: #c03023;
  }
`;

const googleCardCss = css`
  border: 1px solid #dc4e41 !important;
`;

const googleCardHeaderCss = css`
  color: #fff;
  background: #dc4e41;
`;

interface Props {
  tenant: TenantModel;
  clients?: ClientModel[];
  organizations?: OrganizationModel[];
  onSave: (relationship: any) => Promise<void>;
}

export const TenantEditor: FC<Props> = ({
  tenant,
  clients,
  organizations,
  onSave,
}) => {
  const [isLogoOpen, setLogoOpen] = useState(false);
  const [isBannerOpen, setBannerOpen] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      await onSave({
        ...values,
        loginProviders:
          values.loginProviders &&
          Object.values(values.loginProviders).filter((v) => v),
      });
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const loginProviders = tenant.loginProviders?.map((p) => ({
    ...p,
    scopes: p.scopes || [],
  }));

  const initialValues = {
    id: tenant.id,
    name: tenant.name || '',
    email: tenant.email || '',
    uniqueName: tenant.uniqueName || '',
    hostName: tenant.hostName || '',
    identityDomain: tenant.identityDomain || '',
    defaultClientId: tenant.defaultClientId,
    defaultOrganizationId: tenant.defaultOrganizationId,
    loginProviders: {
      google: loginProviders?.find(({ name }) => name === 'Google'),
    },
    urls: {
      resetPassword: tenant.urls?.resetPassword || '',
      verifyEmail: tenant.urls?.verifyEmail || '',
    },
    logoId: tenant.logoId,
    logoData: '',
    bannerId: tenant.bannerId,
    bannerData: '',
    languages: tenant.languages || [],
    allowedOrigins: tenant.allowedOrigins || [],
  };

  return (
    <div className="animated fadeIn">
      {tenant && (
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={handleSubmit}
          render={({
            values,
            handleSubmit,
            isSubmitting,
            isValid,
            handleReset,
            setFieldValue,
          }) => {
            const logoValue = values.logoId || values.logoData;
            const bannerValue = values.bannerId || values.bannerData;
            return (
              <Fragment>
                <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                  <EditorActions
                    onReset={handleReset}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                  />
                  <Row wrap="wrap">
                    <Column xs={100} lg={50}>
                      <fieldset>
                        {values.id && (
                          <InputField
                            label="Id"
                            type="text"
                            name="id"
                            id="id"
                            required
                            disabled
                          />
                        )}
                        <InputField
                          type="text"
                          name="name"
                          id="name"
                          label="Name"
                          placeholder="Name"
                          autoFocus={true}
                          required
                        />
                        <InputField
                          type="email"
                          name="email"
                          id="email"
                          label="Email"
                          placeholder="Email"
                          autoComplete="email"
                          required
                        />
                        <InputField
                          type="text"
                          name="uniqueName"
                          id="uniqueName"
                          label="Unique Name"
                          placeholder="Unique Name"
                        />
                        <InputField
                          label="Hostname"
                          type="text"
                          name="hostName"
                          id="hostName"
                          placeholder="Hostname"
                          required
                        />
                        <InputField
                          label="Identity domain"
                          type="text"
                          name="identityDomain"
                          id="identityDomain"
                          placeholder="Domain"
                          required
                        />
                        <ReactSelectField
                          name="languages"
                          label="Languages"
                          placeholder="Select languages"
                          options={locale.all.filter(
                            ({ tag }) => !tag.includes('-')
                          )}
                          optionLabel={(o) => `${o.name}`}
                          optionValue={(o) => o.tag}
                          isMulti
                        />
                      </fieldset>
                    </Column>
                    <Column xs={100} lg={50}>
                      <fieldset>
                        <legend>Images</legend>
                        <Label>Logo</Label>
                        <ImageButton
                          onClick={(e) => {
                            e.preventDefault();
                            setLogoOpen(true);
                          }}
                        >
                          {logoValue ? (
                            <ImagePreview value={logoValue} />
                          ) : (
                            <ImagePlaceholder>Select Logo</ImagePlaceholder>
                          )}
                        </ImageButton>
                        <Label>Banner</Label>
                        <ImageButton
                          onClick={(e) => {
                            e.preventDefault();
                            setBannerOpen(true);
                          }}
                        >
                          {bannerValue ? (
                            <ImagePreview value={bannerValue} />
                          ) : (
                            <ImagePlaceholder>Select Banner</ImagePlaceholder>
                          )}
                        </ImageButton>
                      </fieldset>
                    </Column>
                  </Row>
                  <fieldset>
                    <legend>Login Providers</legend>
                    {values.loginProviders?.google ? (
                      <Card css={googleCardCss}>
                        <CardHeader css={googleCardHeaderCss}>
                          Google
                        </CardHeader>
                        <CardBody>
                          <InputField
                            label="Client Id"
                            type="text"
                            name="loginProviders.google.clientId"
                            placeholder="Client id"
                            required
                          />
                          <InputField
                            label="Client secret"
                            type="text"
                            name="loginProviders.google.clientSecret"
                            placeholder="Client secret"
                            required
                          />
                          <FieldArray
                            name="loginProviders.google.scopes"
                            render={(arrayHelpers) => (
                              <fieldset>
                                <legend>Scopes</legend>
                                <Table responsive hover>
                                  <thead>
                                    <tr>
                                      <th scope="col" colSpan={2}>
                                        Scope
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.loginProviders.google.scopes?.map(
                                      (scope: string, index: number) => (
                                        <tr key={index}>
                                          <td>
                                            <InputField
                                              name={`loginProviders.google.scopes.${index}`}
                                              placeholder="Scope"
                                              value={scope}
                                            />
                                          </td>
                                          <td className="nostretch">
                                            <Button
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              -
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th colSpan={2}>
                                        <Button
                                          type="button"
                                          onClick={() => arrayHelpers.push('')}
                                        >
                                          Add a scope
                                        </Button>
                                      </th>
                                    </tr>
                                  </tfoot>
                                </Table>
                              </fieldset>
                            )}
                          />
                        </CardBody>
                      </Card>
                    ) : (
                      <Button
                        css={googleButtonCss}
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue('loginProviders.google', {
                            name: 'Google',
                            clientId: '',
                            clientSecret: '',
                          });
                        }}
                      >
                        Add Google
                      </Button>
                    )}
                  </fieldset>
                  <fieldset>
                    <legend>Custom urls</legend>
                    <InputField
                      label="Reset password"
                      type="text"
                      name="urls.resetPassword"
                      placeholder="Url"
                    />
                    <InputField
                      label="Verify email"
                      type="text"
                      name="urls.verifyEmail"
                      placeholder="Url"
                    />
                  </fieldset>
                  {clients && (
                    <SelectField
                      name="defaultClientId"
                      label="Default Client"
                      placeholder="Select client"
                      options={clients}
                      optionName={(o) => o.name}
                      optionValue={(o) => o.id}
                    />
                  )}
                  {organizations && (
                    <SelectField
                      name="defaultOrganizationId"
                      label="Default Organization"
                      placeholder="Select organization"
                      options={organizations}
                      optionName={(o) => o.name}
                      optionValue={(o) => o.id}
                    />
                  )}
                  <FieldArray
                    name="allowedOrigins"
                    render={(arrayHelpers) => (
                      <fieldset>
                        <legend>Allowed origins</legend>
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th scope="col" colSpan={2}>
                                Origin
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.allowedOrigins?.map(
                              (allowedScope: any, index: number) => (
                                <tr key={index}>
                                  <td>
                                    <InputField
                                      name={`allowedOrigins.${index}`}
                                      placeholder="origin"
                                      value={allowedScope}
                                    />
                                  </td>
                                  <td className="nostretch">
                                    <Button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan={2}>
                                <Button
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  Add a origin
                                </Button>
                              </th>
                            </tr>
                          </tfoot>
                        </Table>
                      </fieldset>
                    )}
                  />
                </Form>
                <SelectImageModal
                  title="Select Logo"
                  isOpen={isLogoOpen}
                  onToggle={() => setLogoOpen(!isLogoOpen)}
                  onSelect={(image) => {
                    if (image.id) {
                      setFieldValue('logoId', image.id);
                      setFieldValue('logoData', undefined);
                    } else {
                      setFieldValue('logoId', undefined);
                      setFieldValue('logoData', image.uri);
                    }
                    setLogoOpen(false);
                  }}
                />
                <SelectImageModal
                  title="Select Banner"
                  isOpen={isBannerOpen}
                  onToggle={() => setBannerOpen(!isBannerOpen)}
                  onSelect={(image) => {
                    if (image.id) {
                      setFieldValue('bannerId', image.id);
                      setFieldValue('bannerData', undefined);
                    } else {
                      setFieldValue('bannerId', undefined);
                      setFieldValue('bannerData', image.uri);
                    }
                    setBannerOpen(false);
                  }}
                />
              </Fragment>
            );
          }}
        />
      )}
    </div>
  );
};
