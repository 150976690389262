import styled from '@emotion/styled';
import React, { PropsWithChildren, Ref } from 'react';
import { BaseProps } from './Base';

const StyledButton = styled.button<{ active?: boolean; reversed?: boolean }>`
  cursor: pointer;
  color: ${({ active, reversed }) =>
    reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc'};
  border: 0;
  background: none;
  margin: 0;
`;

export const ToolbarButton = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<HTMLButtonElement>
  ) => <StyledButton {...props} ref={ref} className={className} />
);
