import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import {
  setProductAccessibility,
  updateProduct,
  publishProduct,
} from '@nimles/react-redux';
import { ProductEditor } from './ProductEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { useProductGroups } from '../../../hooks/productGroups';
import { useProducts } from '../../../hooks/products';
import { useProductProperties } from '../../../hooks/productProperties';

interface Params {
  productId: string;
}

export const Product: FC = () => {
  const dispatch = useDispatch();
  const { productId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { products } = useProducts();
  const { productGroups } = useProductGroups();
  const { productProperties } = useProductProperties();
  const product = products.find((p) => p.id === productId);

  const handleSave = async (values: any, publish: boolean) => {
    await dispatch(updateProduct({ ...product, ...values }));
    if (publish) {
      product?.id && (await dispatch(publishProduct(product.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    product?.id &&
      (await dispatch(setProductAccessibility(product.id, accessibility)));
  };

  return product && organizations ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {product.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(product.createdDate).format('LLL')}
            </div>
          ) : null}
          {product.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(product.lastModified).format('LLL')}
            </div>
          ) : null}
          {product.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(product.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Product</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={product.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ProductEditor
            product={product}
            productGroups={productGroups}
            organizations={organizations}
            productProperties={productProperties}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
