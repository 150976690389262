import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  CardAction,
  CardActions,
  Column,
  Row,
} from '@nimles/react-web-components';
import moment from 'moment';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { AmountLine } from '../../../components/Amount';
import { DisplayFields } from '../../../components/entities/DisplayFields';
import { useCategories } from '../../../hooks';
import { useOrganizations } from '../../../hooks/organizations';
import { useQuotes } from '../../../hooks/quotes';
import { useUsers } from '../../../hooks/users';
import { QuoteLine } from './QuoteLine';

interface Params {
  quoteId: string;
}

export const Quote: FC = () => {
  const { quoteId } = useParams<Params>();

  const { quotes } = useQuotes();
  const { categories } = useCategories();
  const { users } = useUsers();
  const quote = quotes.find((o) => o.id === quoteId);
  const { organizations } = useOrganizations();

  if (!quote) {
    return null;
  }

  const organization =
    (quote.head?.seller?.organizationId &&
      organizations.find(
        (org) => org.id === quote.head?.seller?.organizationId
      )) ||
    undefined;

  let user;
  if (quote.head?.buyer?.userId) {
    user = users && users.find((user) => user.id === quote.head?.buyer?.userId);
  }

  const quoteButton = '';

  const { buyer, seller, totalNetPrice, totalVat, totalGrossPrice } =
    quote?.head || {};

  const category = categories.find(({ id }) => id === quote.head?.categoryId);

  console.log(quote);
  return quote ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <CardTitle>{category?.name}</CardTitle>
          <CardSubtitle>
            {moment(quote.head?.submittedDate).format('LLL')}
          </CardSubtitle>
          <Row>
            <Column xs={100} sm={50}>
              <h4>Buyer</h4>
              {buyer && (
                <div>
                  <p>
                    Name: {buyer.firstName} {buyer.lastName}
                  </p>
                  {buyer.email && <p>Email: {buyer.email}</p>}
                  {buyer.phoneNumber && <p>Phone: {buyer.phoneNumber}</p>}
                  {buyer.deliveryAddress && (
                    <p>
                      Adress: {buyer.deliveryAddress.street},{' '}
                      {buyer.deliveryAddress.postalCode}{' '}
                      {buyer.deliveryAddress.city}
                    </p>
                  )}
                </div>
              )}
              {user && (
                <Card background="#efefef">
                  <CardBody>
                    <h5>User</h5>
                    <p>Id:{user.id}</p>
                    <p>
                      Name: {user.firstName} {user.lastName}
                    </p>
                    {user.email && <p>Email: {user.email}</p>}
                    {user.phoneNumber && <p>Phone: {user.phoneNumber}</p>}
                    {user.address && (
                      <p>
                        Adress: {user.address.street}, {user.address.postalCode}{' '}
                        {user.address.city}
                      </p>
                    )}
                  </CardBody>
                </Card>
              )}
            </Column>
            <Column xs={100} sm={50}>
              <h4>Seller</h4>
              {seller && (
                <div>
                  <p>Company: {organization?.name}</p>
                </div>
              )}
            </Column>
          </Row>
          <DisplayFields fields={quote.fields} />
          {quoteButton}
        </CardHeader>
        <CardBody>
          {quote.lines?.map((line) => (
            <QuoteLine key={line.id} line={line} />
          ))}
          <Row justify="flex-end">
            <Column>
              <AmountLine name="Gross price" value={totalGrossPrice} />
              <AmountLine name="Vat" value={totalVat} />
              <AmountLine name="Net price" value={totalNetPrice} />
            </Column>
          </Row>
        </CardBody>
        <CardActions>
          <CardAction></CardAction>
        </CardActions>
      </Card>
    </div>
  ) : null;
};
