import { useDispatch, useSelector } from 'react-redux';
import {
  ENABLE_DARKMODE,
  MINIMIZE_SIDEBAR,
  SHOW_SIDEBAR,
} from '../redux/actionTypes';
import { RootState, UIControlsState } from '../redux/types';

export const useUiControls = () => {
  const dispatch = useDispatch();
  const uiControls = useSelector<RootState, UIControlsState>(
    ({ uiControls }) => uiControls
  );

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(uiControls.sidebarShow)
      ? false
      : 'responsive';
    dispatch({ type: SHOW_SIDEBAR, data: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(uiControls.sidebarShow)
      ? true
      : 'responsive';
    dispatch({ type: SHOW_SIDEBAR, data: val });
  };

  const toggleSidebarMinimize = () =>
    dispatch({
      type: MINIMIZE_SIDEBAR,
      data: !uiControls.sidebarMinimize,
    });

  const toggleDarkMode = () =>
    dispatch({ type: ENABLE_DARKMODE, data: !uiControls.darkMode });

  return {
    ...uiControls,
    toggleSidebar,
    toggleSidebarMobile,
    toggleSidebarMinimize,
    toggleDarkMode,
  };
};
