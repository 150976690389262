import React from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

// sidebar nav config
import navigation from '../_nav';
import { useUiControls } from '../hooks/uiControls';

const TheSidebar = () => {
  const { sidebarShow, sidebarMinimize, toggleSidebar, toggleSidebarMinimize } =
    useUiControls();

  return (
    <CSidebar
      show={sidebarShow}
      unfoldable
      onShowChange={() => toggleSidebar()}
      minimize={sidebarMinimize}
      onMinimizeChange={() => toggleSidebarMinimize()}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
