import { QuoteModel } from '@nimles/models';
import { loadQuotes, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useQuotes = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<QuoteModel>>(
    ({ quotes }) => quotes
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadQuotes());
    }
  }, [tenantId, dispatch]);

  return {
    quotes: values,
  };
};
