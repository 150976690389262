import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import {
  setReviewAccessibility,
  updateReview,
  publishReview,
} from '@nimles/react-redux';
import { ReviewEditor } from './ReviewEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useProducts } from '../../../hooks/products';
import { useReviewGroups } from '../../../hooks/reviewGroups';
import { useOrganizations } from '../../../hooks/organizations';
import { useReviews } from '../../../hooks';

interface Params {
  reviewId: string;
}

export const Review: FC = () => {
  const dispatch = useDispatch();
  const { reviewId } = useParams<Params>();
  const { organizations } = useOrganizations();
  const { products } = useProducts();
  const { reviewGroups } = useReviewGroups();
  const { reviews } = useReviews();

  const review = reviews.find((p) => p.id === reviewId);

  const handleSave = async (values: any, publish: boolean) => {
    await dispatch(updateReview({ ...review, ...values }));
    if (publish && review?.id) {
      await dispatch(publishReview(review.id));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    if (review?.id) {
      await dispatch(setReviewAccessibility(review.id, accessibility));
    }
  };

  return review && organizations ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {review.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(review.createdDate).format('LLL')}
            </div>
          ) : null}
          {review.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(review.lastModified).format('LLL')}
            </div>
          ) : null}
          {review.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(review.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Review</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={review.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ReviewEditor
            review={review}
            reviewGroups={reviewGroups}
            organizations={organizations}
            products={products}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
