import { OrganizationModel, ProductStockModel } from '@nimles/models';
import { InputField, SelectField } from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { FC } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import { EditorActions } from '../../../components/Editor';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { validate } from '../../_shared/formUtils';

const deliveryTypes = [
  {
    name: 'Delivery by Buyer',
    value: 'DeliveryByBuyer',
  },
  {
    name: 'Delivery by Seller',
    value: 'DeliveryBySeller',
  },
  {
    name: 'Delivery Integration',
    value: 'DeliveryIntegration',
  },
];

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  productStock: ProductStockModel;
  organizations: OrganizationModel[];
  onSave: (productStock: ProductStockModel) => Promise<void>;
}

export const ProductStockEditor: FC<Props> = ({
  productStock,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };
  const values: ProductStockModel = productStock && {
    id: productStock.id,
    organizationId: productStock.organizationId,
    fields: productStock.fields || [],
  };

  console.log('VALUES ', values);

  return productStock ? (
    <Formik
      enableReinitialize
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
              {values.id && (
                <InputField
                  name="id"
                  label="Id"
                  value={values.id}
                  disabled={true}
                />
              )}
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={productStock.organizationId}
              />

              <SelectField
                name="deliveryType"
                label="Delivery type"
                placeholder="Select delivery type"
                options={deliveryTypes}
                optionName={(o) => o.name}
                optionValue={(o) => o.value}
                value={values.organizationId}
              />

              <FieldArray
                name="fields"
                render={(arrayHelpers) => (
                  <div>
                    {values.fields.map((field: any, index: number) => {
                      return (
                        <Row key={index}>
                          <Col flex>
                            <InputField
                              name={`fields.${index}.value`}
                              label={field.name}
                              placeholder="Value"
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          description: '',
                          unitNetPrice: 0,
                          unitGrossPrice: 0,
                        })
                      }
                    >
                      Add line
                    </Button>
                  </div>
                )}
              />
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
