import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useParams } from 'react-router';
import { updateTheme, setThemeAccessibility } from '@nimles/react-redux';
import { ThemeEditor } from './ThemeEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { useThemes } from '../../../hooks/themes';

export const Theme = () => {
  const dispatch = useDispatch();
  const { themeId } = useParams<{ themeId: string }>();

  const { themes } = useThemes();
  const theme = themes.find((l) => l.id === themeId);

  const onSave = async (values: any) => {
    await dispatch(updateTheme({ ...values }));
  };

  const setAccessibility = async (accessibility: any) => {
    theme?.id &&
      (await dispatch(setThemeAccessibility(theme.id, accessibility)));
  };

  return theme ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit Theme</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={theme.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ThemeEditor theme={theme} onSave={(values: any) => onSave(values)} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
