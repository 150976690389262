import { TenantModel } from '@nimles/models';
import { loadTenants, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useTenants = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values, selected } = useSelector<RootState, State<TenantModel>>(
    ({ tenants }) => tenants
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadTenants());
    }
  }, [tenantId, dispatch]);

  return {
    tenants: values,
    selectedTenant: selected,
    tenantId,
  };
};
