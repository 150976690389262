import React from 'react';
import styled from '@emotion/styled';
import { Column, Row } from '@nimles/react-web-components';

const Line = styled.div`
  border-bottom: 1px solid #ecdede;
  font-size: 1.2rem;
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
`;
const NameColumn = styled(Column)`
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ValueColumn = styled(Column)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface Props {
  name?: string;
  value?: number | null;
}

export const AmountLine = ({ name, value }: Props) => {
  return (
    <Line>
      <StyledRow>
        <NameColumn>{name}</NameColumn>
        <ValueColumn>
          {value &&
            new Intl.NumberFormat('sv', {
              style: 'currency',
              currency: 'EUR',
            }).format(value)}
        </ValueColumn>
      </StyledRow>
    </Line>
  );
};
