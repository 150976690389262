import {
  CustomInputProps,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { Field, FieldProps } from 'formik';
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
} from 'react-beautiful-dnd';
import { Column } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { FC } from 'react';

let tempId = 1;

const ElementContainer = styled.div<{ isDragging: boolean }>`
  flex: 100;
  border: 1px solid lightgrey;
  border-radius: 2px;
  background-color: ${({ isDragging }) =>
    isDragging ? 'lightgreen' : 'white'};
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
`;

const ElementPadding = styled.div`
  padding: 5px;
  flex: 100;
`;

const ElementList = styled.div<{ isDraggingOver: any }>`
  flex: 1;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? 'skyblue' : 'white'};
  transition: all 0.5s;
`;

const AddElementButton = styled.button`
  flex: 1 0 auto;
  border: 1px dashed lightgrey;
  background: #efefef;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const tempArray = [
    ...list.slice(0, startIndex),
    ...list.slice(startIndex + 1),
  ];

  return [
    ...tempArray.slice(0, endIndex),
    list[startIndex],
    ...tempArray.slice(endIndex),
  ];
};

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field: { value, name, onChange }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onAdd,
  ...props
}) => {
  const handleDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    setFieldValue(name, reorder(value, source.index, destination.index));
  };

  const handleAdd = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onAdd({ tempId: tempId++ });
  };

  return (
    <div key={name}>
      {props.label ? <Label for={name}>{props.label}</Label> : null}
      <FormGroup>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={name}>
            {(provided, snapshot) => (
              <ElementList
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {value.map(
                  (
                    v: {
                      id: any;
                      tempId: any;
                      name: string | number | readonly string[] | undefined;
                    },
                    index: number
                  ) => {
                    return (
                      <Draggable draggableId={v.id || v.tempId} index={index}>
                        {(provided, snapshot) => (
                          <ElementPadding {...provided.draggableProps}>
                            <ElementContainer
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              isDragging={snapshot.isDragging}
                            >
                              <Input
                                value={v.name}
                                onChange={(e) => {
                                  e.preventDefault();
                                  v.name = e.target.value;
                                  setFieldValue(name, [...value]);
                                }}
                              />
                            </ElementContainer>
                          </ElementPadding>
                        )}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
                <Column flex="1">
                  <AddElementButton onClick={handleAdd}>
                    <i className="fa fa-plus" />
                  </AddElementButton>
                </Column>
              </ElementList>
            )}
          </Droppable>
        </DragDropContext>
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
    </div>
  );
};

const DragAndDropField = (props: any) => (
  <Field {...props} component={StandardInput} />
);

export default DragAndDropField;
