import React from 'react';
import styled from '@emotion/styled';
import { Label } from '@nimles/react-web-components';

const Value = styled.div`
  padding: 8px 16px;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #efefef;
  border-radius: 4px;
`;

export const DisplayField = ({
  label,
  value,
}: {
  label: string;
  value: any;
}) => {
  return (
    <>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </>
  );
};
