import { ClientModel } from '@nimles/models';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { useClients } from '../../../hooks/clients';

function ClientRow({ client }: { client: ClientModel }) {
  const clientLink = `/identity/clients/${client.id}`;

  return (
    <tr key={client.id?.toString()}>
      <th scope="row">
        <Link to={clientLink}>
          <div>
            <strong>{client.name}</strong>
          </div>
          <div>
            <small>{client.id}</small>
          </div>
          <div>{client.uniqueName}</div>
        </Link>
      </th>
      <td>
        {client.allowedGrantTypes?.map((grantType) => (
          <div>{grantType}</div>
        ))}
      </td>
      <td>
        {client.allowedScopes?.map((scope) => (
          <div>{scope}</div>
        ))}
      </td>
    </tr>
  );
}

export const Clients: FC = () => {
  const { clients } = useClients();

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Clients
              <div className="card-header-actions">
                <Link
                  to="/identity/clients/new"
                  className="btn btn-sm btn-primary"
                >
                  New Client
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">name</th>
                  <th scope="col">grant types</th>
                  <th scope="col">scopes</th>
                </tr>
              </thead>
              <tbody>
                {clients &&
                  clients.map((client: any, index: number) => (
                    <ClientRow key={index} client={client} />
                  ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
