import { ElementModel } from '@nimles/models';
import { useState } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { SelectImageModal } from '../../SelectImageModal';
import { FullEditor } from './Base';
import { Icon } from './Icon';
import { ToolbarButton } from './ToolbarButton';

export const InsertImageButton = () => {
  const editor = useSlateStatic();
  const [isOpen, setOpen] = useState(false);

  return (
    <ToolbarButton
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        setOpen(true);
      }}
    >
      <Icon>image</Icon>
      {isOpen ? (
        <SelectImageModal
          title="Select Image"
          isOpen={isOpen}
          onToggle={() => setOpen(!isOpen)}
          onSelect={(image) => {
            if (image.id) {
              insertImage(editor, image.id);
            }
            setOpen(false);
          }}
        />
      ) : null}
    </ToolbarButton>
  );
};

export const insertImage = (editor: FullEditor, url: string) => {
  const text = { text: '' };
  const image: ElementModel = { type: 'Image', content: url, children: [text] };
  Transforms.insertNodes(editor, image);
};
