import React from 'react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ImageEditor } from './ImageEditor';
import { Image } from './Image';
import { FileModel } from '@nimles/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImageContainer = styled.div`
  display: flex;
`;

const Swatch = styled.button`
  flex: 1;
  padding: 4px;
  background: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  display: block;
  cursor: pointer;
  margin: 8px 0px;
`;

const Remove = styled.button`
  padding: 4px;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-left-width: 0;
  outline: none;
  box-shadow: none;
  margin: 8px 0px;
`;

const PopOver = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
  width: 100%;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Dropdown = styled.div`
  padding: 5px;
  background: #fff;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-width: 300px;
  max-width: 100vw;
  border: 1px solid grey;
  border-radius: 4px;
`;

export const ImagePicker = ({
  value,
  onChange,
  organizationId,
}: {
  value: string;
  onChange: (image?: FileModel) => void;
  organizationId?: string;
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleChange = (image: FileModel) => {
    onChange(image);
    setOpen(false);
  };

  return (
    <ImageContainer>
      <Swatch
        onClick={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
        }}
      >
        <Image value={value} />
      </Swatch>
      <Remove>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
            onChange(undefined);
          }}
        />
      </Remove>
      <PopOver isOpen={isOpen}>
        <Cover
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}
        />
        <Dropdown>
          <ImageEditor
            onChange={handleChange}
            organizationId={organizationId}
          />
        </Dropdown>
      </PopOver>
    </ImageContainer>
  );
};
