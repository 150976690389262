import React, { useEffect, useState } from 'react';
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import moment from 'moment';
import { Value } from '@nimles/react-redux';

const toSentence = (input: string) =>
  !input
    ? ''
    : (input.charAt(0) + input?.slice(1).toLowerCase()).replace('_', ' ');

const TheHeaderDropdownNotifications = () => {
  const [showAll, setShowAll] = useState(false);
  const [seenId, setSeenId] = useState<number>(0);
  const [previousSeenId, setPreviousSeenId] = useState<number>(0);
  const [badge, setBadge] = useState<number>();
  const notifications = useSelector<RootState, Value[]>(
    ({ notifications }) => notifications.values
  );

  useEffect(() => {
    setBadge(
      notifications.filter(
        ({ id, log }) =>
          +id > seenId ||
          !log.some(({ status }: { status: string }) => status === 'COMPLETED')
      ).length
    );
    setShowAll(false);
  }, [notifications, seenId]);

  const filteredNotifications = notifications.filter(
    ({ id }, index) => +id > previousSeenId || index < 10
  );
  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle
        className="c-header-nav-link"
        caret={false}
        onClick={() => {
          setPreviousSeenId(seenId);
          setSeenId(Math.max(...notifications.map(({ id }) => +id)));
        }}
      >
        <CIcon name="cil-bell" />
        {badge ? (
          <CBadge shape="pill" color="info">
            {badge}
          </CBadge>
        ) : null}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light">
          <strong>You have {notifications.length} notifications</strong>
        </CDropdownItem>
        {(showAll ? notifications : filteredNotifications)
          .sort((a, b) => +b.id - +a.id)
          .map(({ id, log, type }) => {
            const latest = log.sort(
              (a: { timestamp: number }, b: { timestamp: number }) =>
                b.timestamp - a.timestamp
            )[0];
            return (
              <CDropdownItem key={id} href="#">
                <div className="message">
                  <div>
                    <small className="text-muted">
                      {toSentence(latest.status)}
                    </small>
                    <small className="text-muted float-right mt-1">
                      {moment(latest.timestamp).format('LTS')}
                    </small>
                  </div>
                  <div className="text-truncate font-weight-bold">
                    {toSentence(type)}
                  </div>
                  <div className="small text-muted text-truncate"></div>
                </div>
              </CDropdownItem>
            );
          })}

        {filteredNotifications.length < notifications.length ? (
          <CDropdownItem
            href="#"
            className="text-center border-top"
            onClick={() => setShowAll(true)}
          >
            <strong>View all messages</strong>
          </CDropdownItem>
        ) : null}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdownNotifications;
