import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useParams } from 'react-router';
import {
  updateLayout,
  setLayoutAccessibility,
  publishLayout,
} from '@nimles/react-redux';
import { LayoutEditor } from './LayoutEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useLayouts } from '../../../hooks/layouts';

interface Params {
  layoutId: string;
}

export const Layout = () => {
  const dispatch = useDispatch();
  const { layoutId } = useParams<Params>();

  const { layouts } = useLayouts();
  const layout = layouts.find((l) => l.id === layoutId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    await dispatch(
      updateLayout({ ...values, elements: removeTempIds(values.elements) })
    );
    if (publish) {
      layout?.id && (await dispatch(publishLayout(layout.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    layout?.id &&
      (await dispatch(setLayoutAccessibility(layout.id, accessibility)));
  };

  return layout ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit Layout: {layout.layoutType}</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={layout.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <LayoutEditor layout={layout} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
