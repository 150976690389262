import { OrganizationModel, TenantModel } from '@nimles/models';
import { loadOrganizations, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useOrganizations = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );
  const { values, selected } = useSelector<RootState, State<OrganizationModel>>(
    ({ organizations }) => organizations
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadOrganizations());
    }
  }, [tenantId, dispatch]);

  return {
    organizations: values,
    currentOrganizationId: selected?.id ?? tenant?.defaultOrganizationId,
  };
};
