import { ClientModel, TenantModel } from '@nimles/models';
import { InputField, CheckboxField } from '@nimles/react-web-forms';
import ClientOAuth2 from 'client-oauth2';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { Button, Col, Form, FormGroup, Row, Table } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';

const validationSchema = function (values: any) {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

const newSecret = () => {
  // then to call it, plus stitch in '4' in the third group
  const guid = (
    S4() +
    S4() +
    '-' +
    S4() +
    '-4' +
    S4().substr(0, 3) +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  ).toLowerCase();

  return btoa(guid);
};

interface Props {
  client: ClientModel;
  tenant: TenantModel;
  onSave: (relationship: any) => Promise<void>;
}

const requestToken = async (
  tenantId: string,
  clientId: string,
  clientSecret: string
) => {
  const options = {
    clientId,
    clientSecret,
    accessTokenUri: `http://localhost:9009/tenants/${tenantId}/connect/token`,
    authorizationUri: `http://localhost:9009/tenants/${tenantId}/connect/authorizee`,
    redirectUri: 'http://example.com/auth/github/callback',
    scopes: ['nimles'],
  };
  const oauth2Client = new ClientOAuth2(options);

  const response = await oauth2Client.credentials.getToken();

  console.log(response);
};

export const ClientEditor: FC<Props> = ({ client, onSave, tenant }) => {
  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const clientModel: ClientModel = {
        ...client,
        ...values,
        allowedGrantTypes: Object.entries(values.allowedGrantTypes)
          .filter(([key, value]) => value)
          .map(([key, value]) => key),
      };
      await onSave(clientModel);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = client && {
    id: client.id,
    name: client.name || '',
    description: client.description || '',
    allowOfflineAccess: client.allowOfflineAccess || true,
    allowAccessTokensViaBrowser: client.allowAccessTokensViaBrowser || true,
    secrets: client.secrets || [],
    replyUrls: client.replyUrls || [],
    logins: client.loginProviders || [],
    allowedScopes: client.allowedScopes || [],
    allowedGrantTypes: {
      implicit:
        !!client.allowedGrantTypes &&
        client.allowedGrantTypes.some((grant: string) => grant === 'implicit'),
      hybrid:
        !!client.allowedGrantTypes &&
        client.allowedGrantTypes.some((grant: string) => grant === 'hybrid'),
      authorization_code:
        !!client.allowedGrantTypes &&
        client.allowedGrantTypes.some(
          (grant: string) => grant === 'authorization_code'
        ),
      client_credentials:
        !!client.allowedGrantTypes &&
        client.allowedGrantTypes.some(
          (grant: string) => grant === 'client_credentials'
        ),
      password:
        !!client.allowedGrantTypes &&
        client.allowedGrantTypes.some((grant: string) => grant === 'password'),
    },
  };

  return client ? (
    <Formik<typeof values>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        touched,
        status,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setTouched,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              {values.id && (
                <InputField
                  label="Id"
                  type="text"
                  name="id"
                  required
                  disabled
                />
              )}
              <InputField label="Name" name="name" required />
              <InputField label="Description" type="text" name="description" />
              <CheckboxField
                label="Implicit"
                name="allowedGrantTypes.implicit"
              />
              <CheckboxField label="Hybrid" name="allowedGrantTypes.hybrid" />
              <CheckboxField
                label="Authorization code"
                name="allowedGrantTypes.authorization_code"
              />
              <CheckboxField
                label="Client credentials"
                name="allowedGrantTypes.client_credentials"
              />
              <CheckboxField
                label="Resource owner password"
                name="allowedGrantTypes.password"
              />
              <FieldArray
                name="secrets"
                render={(arrayHelpers) => (
                  <fieldset>
                    <legend>Secrets</legend>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th scope="col">description</th>
                          <th scope="col" colSpan={2}>
                            secret
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.secrets &&
                          values.secrets.length > 0 &&
                          values.secrets.map((secret: any, index: number) => (
                            <tr key={index}>
                              <td>
                                <InputField
                                  name={`secrets.${index}.description`}
                                  label="Description"
                                  placeholder="Description"
                                  required
                                />
                              </td>
                              <td>
                                <InputField
                                  name={`secrets.${index}.value`}
                                  label="Secret"
                                  placeholder="Secret"
                                  required
                                  disabled
                                />
                              </td>
                              <td className="nostretch">
                                <Button
                                  type="button"
                                  onClick={() =>
                                    tenant.id &&
                                    values.id &&
                                    requestToken(
                                      tenant.id,
                                      values.id,
                                      secret.value
                                    )
                                  }
                                >
                                  Token
                                </Button>
                                <Button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan={3}>
                            <Button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({ value: newSecret() })
                              }
                            >
                              Add a secret
                            </Button>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </fieldset>
                )}
              />
              <FieldArray
                name="replyUrls"
                render={(arrayHelpers) => (
                  <fieldset>
                    <legend>Reply urls</legend>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th scope="col">Description</th>
                          <th scope="col" colSpan={2}>
                            Reply url
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.replyUrls &&
                          values.replyUrls.length > 0 &&
                          values.replyUrls.map(
                            (replyUrl: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  <InputField
                                    name={`replyUrls.${index}.description`}
                                    placeholder="Description"
                                    required
                                  />
                                </td>
                                <td>
                                  <InputField
                                    name={`replyUrls.${index}.value`}
                                    placeholder="Reply url"
                                    required
                                  />
                                </td>
                                <td className="nostretch">
                                  <Button
                                    style={{ marginTop: '2px' }}
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan={3}>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.push({})}
                            >
                              Add a reply url
                            </Button>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </fieldset>
                )}
              />
              <FieldArray
                name="allowedScopes"
                render={(arrayHelpers) => (
                  <fieldset>
                    <legend>Allowed scopes</legend>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th scope="col" colSpan={2}>
                            scope
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.allowedScopes &&
                          values.allowedScopes.length > 0 &&
                          values.allowedScopes.map(
                            (allowedScope: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  <InputField
                                    name={`allowedScopes.${index}`}
                                    placeholder="Scope"
                                    value={allowedScope}
                                  />
                                </td>
                                <td className="nostretch">
                                  <Button
                                    style={{ marginTop: '2px' }}
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan={2}>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.push('')}
                            >
                              Add a scope
                            </Button>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </fieldset>
                )}
              />
              <CheckboxField
                label="Allow offline access"
                name="allowOfflineAccess"
              />
              <CheckboxField
                label="Allow access of tokens from browser"
                name="allowAccessTokensViaBrowser"
              />
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  className="mr-1"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
