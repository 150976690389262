import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { RelationshipEditor } from './RelationshipEditor';
import { useParams } from 'react-router';
import { updateRelationship } from '@nimles/react-redux';
import { useOrganizations } from '../../../hooks/organizations';
import { useRelationships } from '../../../hooks/relationships';

interface Params {
  relationshipId: string;
}

export const Relationship: FC = () => {
  const dispatch = useDispatch();
  const { organizations } = useOrganizations();

  const { relationshipId } = useParams<Params>();
  const { relationships } = useRelationships();
  const relationship = relationships.find((r) => r.id === relationshipId);

  const onSave = async (values: any) => {
    await dispatch(updateRelationship({ ...relationship, ...values }));
  };

  return relationship ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New User</strong>
        </CardHeader>
        <CardBody>
          <RelationshipEditor
            relationship={relationship}
            relationships={relationships}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
