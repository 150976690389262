import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { RelationshipEditor } from './RelationshipEditor';
import { useHistory } from 'react-router';
import { createRelationship } from '@nimles/react-redux';
import { useLocation } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';
import { useRelationships } from '../../../hooks/relationships';

const relationship = {};

export const NewRelationship: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const parentId = query?.get('parentId?');
  const contactType = query?.get('contactType');

  const { organizations } = useOrganizations();

  const { relationships } = useRelationships();

  const onSave = async (values: any) => {
    console.log(values);
    if (values.contactType === 'Person') {
      const domain = values.contact.email?.split('@')[1]?.toLowerCase();
      if (domain) {
        const parent = relationships.find(
          (r) =>
            r.organizationId === values.organizationId &&
            r.contact?.domain === domain
        );
        if (parent) {
          values.parentId = parent.id;
        } else {
          const result: any = await dispatch(
            createRelationship({
              organizationId: values.organizationId,
              contactType: 'Organization',
              contact: {
                name: domain.split('.')[0],
                domain,
              },
            })
          );
          values.parentId = result.id;
        }
      }
    }
    const action = createRelationship({ ...values });
    const relationship: any = await dispatch(action);
    history.push('/crm/contacts/' + relationship.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Relationship</strong>
        </CardHeader>
        <CardBody>
          <RelationshipEditor
            relationship={{
              ...relationship,
              contactType: contactType?.toString(),
              parentId: parentId?.toString(),
            }}
            relationships={relationships}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
