import { DeliveryOptionModel } from '@nimles/models';
import { loadDeliveryOptions, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useDeliveryOptions = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<DeliveryOptionModel>>(
    ({ deliveryOptions }) => deliveryOptions
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadDeliveryOptions());
    }
  }, [tenantId, dispatch]);

  return {
    deliveryOptions: values,
  };
};
