import { FC } from 'react';
import { FieldModel } from '@nimles/models';
import { StripeConnectButton } from './StripeConnectButton';

interface PaymentOptionTemplate {
  name: string;
  fields: FieldModel[];
  connectButton?: FC<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  >;
}

export const paymentOptionTemplates: PaymentOptionTemplate[] = [
  {
    name: 'Klarna',
    fields: [
      { name: 'klarna_username', value: '' },
      { name: 'klarna_password', value: '' },
    ],
  },
  {
    name: 'Stripe',
    fields: [],
    connectButton: StripeConnectButton,
  },
  {
    name: 'Fortnox',
    fields: [
      { name: 'fortnox_accesstoken', value: '' },
      { name: 'fortnox_clientsecret', value: '' },
    ],
  },
];
