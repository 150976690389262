import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { publishCoursework } from '@nimles/react-redux';
import { useHistory, useLocation } from 'react-router';
import { CourseworkEditor } from './CourseworkEditor';
import { CourseworkModel } from '@nimles/models';
import { useOrganizations } from '../../../hooks/organizations';
import { useCourseworks } from '../../../hooks/courseworks';

export const NewCoursework: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const courseworkId = query?.get('courseworkId?');
  const courseworkType = query?.get('type');

  const { courseworks } = useCourseworks();
  const template = courseworks.find((l: any) => l.id === courseworkId);

  const coursework: CourseworkModel | null | undefined = !courseworkId
    ? {
        courseworkType,
      }
    : !template
    ? null
    : {
        courseworkType: template.courseworkType,
        title: `${template.title} - copy`,
      };

  const handleSave = async (values: any, publish: boolean) => {
    if (publish) {
      coursework?.id && (await dispatch(publishCoursework(coursework.id)));
    }
    coursework?.id && history.push('/lms/courseworks/' + coursework.id);
  };

  return coursework ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Coursework: {coursework.courseworkType}</strong>
        </CardHeader>
        <CardBody>
          <CourseworkEditor
            coursework={coursework}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
