import { UserModel } from '@nimles/models';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useUsers } from '../../../hooks/users';

function UserRow(props: { user: UserModel }) {
  const user = props.user;
  const userLink = `/identity/users/${user.id}`;

  return user.id ? (
    <tr key={user.id.toString()}>
      <th scope="row">
        <Link to={userLink}>
          <div>
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </div>
          <div>
            <small>{user.id}</small>
          </div>
          <div>{user.username}</div>
        </Link>
      </th>
      <td>{user.role}</td>
    </tr>
  ) : null;
}

export const Users: FC = () => {
  const { users } = useUsers();

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Users
              <div className="card-header-actions">
                <Link
                  to="/identity/users/new"
                  className="btn btn-sm btn-primary"
                >
                  New User
                </Link>
              </div>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">name</th>
                    <th scope="col">role</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((user: any, index: number) => (
                      <UserRow key={index} user={user} />
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
