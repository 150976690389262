import { QuoteRequestModel, OrganizationModel } from '@nimles/models';
import { InputField, SelectField } from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import React, { FC } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  quoteRequest: QuoteRequestModel;
  organizations: OrganizationModel[];
  newQuoteRequestType?: string;
  onSave: (quoteRequest: QuoteRequestModel) => Promise<void>;
}

export const QuoteRequestEditor: FC<Props> = ({
  quoteRequest,
  organizations,
  newQuoteRequestType,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values: QuoteRequestModel = quoteRequest && {
    id: quoteRequest.id,
    organizationId: '',
    head: {
      message: quoteRequest?.head?.message || '',
      // categoryIds: quoteRequest?.head?.categoryIds || [],
      buyer: {
        email: quoteRequest?.head?.buyer?.email || '',
        phoneNumber: quoteRequest?.head?.buyer?.phoneNumber || '',
        billingAddress: {
          id: quoteRequest?.head?.buyer?.billingAddress?.id,
          street: quoteRequest?.head?.buyer?.billingAddress?.street || '',
          coAddress: quoteRequest?.head?.buyer?.billingAddress?.coAddress || '',
          city: quoteRequest?.head?.buyer?.billingAddress?.city || '',
          postalCode:
            quoteRequest?.head?.buyer?.billingAddress?.postalCode || '',
          state: quoteRequest?.head?.buyer?.billingAddress?.state || '',
          country: quoteRequest?.head?.buyer?.billingAddress?.country || '',
        },
        deliveryAddress: {
          id: quoteRequest?.head?.buyer?.deliveryAddress?.id,
          street: quoteRequest?.head?.buyer?.deliveryAddress?.street || '',
          coAddress:
            quoteRequest?.head?.buyer?.deliveryAddress?.coAddress || '',
          city: quoteRequest?.head?.buyer?.deliveryAddress?.city || '',
          postalCode:
            quoteRequest?.head?.buyer?.deliveryAddress?.postalCode || '',
          state: quoteRequest?.head?.buyer?.deliveryAddress?.state || '',
          country: quoteRequest?.head?.buyer?.deliveryAddress?.country || '',
        },
        userId: quoteRequest?.head?.buyer?.userId,
        organizationId: quoteRequest?.head?.buyer?.organizationId,
      },
      seller: {
        userId: quoteRequest?.head?.seller?.userId,
        organizationId: quoteRequest?.head?.seller?.organizationId,
      },
    },
    lines: quoteRequest.lines || [],
  };

  return quoteRequest ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              {values.id && <InputField name="id" label="Id" disabled={true} />}
              <SelectField
                name="organizationId"
                label="Organization"
                placeholder="Select organization"
                options={organizations}
                optionName={(o) => o.name}
                optionValue={(o) => o.id}
              />

              <FieldArray
                name="lines"
                render={(arrayHelpers) => (
                  <div>
                    {values.lines.map((line: any, index: number) => {
                      return (
                        <div key={index}>
                          <Row>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.name`}
                                label="Name"
                                placeholder="Name"
                                value={line.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col flex>
                              <InputField
                                type="textarea"
                                name={`lines.${index}.description`}
                                label="Description"
                                placeholder="Description"
                                value={line.description}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.unitNetPrice`}
                                label="Unit net price"
                                placeholder="Unit net price"
                                value={line.unitNetPrice}
                              />
                            </Col>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.unitGrossPrice`}
                                label="Unit gross price"
                                placeholder="Unit gross price"
                                value={line.unitGrossPrice}
                              />
                            </Col>
                            <Col flex>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                -
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          description: '',
                          unitNetPrice: 0,
                          unitGrossPrice: 0,
                        })
                      }
                    >
                      Add line
                    </Button>
                  </div>
                )}
              />
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  className="mr-1"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
