import { FC, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import styled from '@emotion/styled';
import { CourseworkModel, OrganizationModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, `Title has to be at least 2 characters`)
      .required('Title is required'),
  });
};

interface Props {
  coursework: CourseworkModel;
  organizations: OrganizationModel[];
  onSave: (coursework: any, publish: boolean) => Promise<void>;
}
export const WordCount = styled.div`
  text-align: right;
  font-size: 12px;
`;

export const CourseworkEditor: FC<Props> = ({
  coursework,
  organizations,
  onSave,
}) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const coursework = {
        ...values,
      };
      await onSave(coursework, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: coursework.id,
    title: coursework.title || '',
    description: coursework.description || '',
    courseworkType: coursework.courseworkType || '',
    organizationId: coursework.organizationId,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onPublish={() => {
                  setPublish(true);
                  submitForm();
                }}
                onSave={() => {
                  setPublish(false);
                  submitForm();
                }}
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
              {values.id && (
                <InputField name="id" label="Id" disabled required />
              )}
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={coursework.organizationId}
              />
              <InputField name="title" label="Title" required />
              <InputField name="uniqueName" label="Unique name" />
              <LocalizedField name="description">
                {({ localizedName }) => (
                  <QuillField name={localizedName} label="Description" />
                )}
              </LocalizedField>
            </Form>
          </Col>
        </Row>
      )}
    />
  );
};
