import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { RootState } from '../redux/types';

interface Props {
  component: FC<any>;
  [key: string]: any;
}

export const ProtectedRoute = ({ component, ...args }: Props) => (
  <Route
    component={(props: any) => (
      <RequireAuthentication {...props} component={component} />
    )}
    {...args}
  />
);

const RequireAuthentication = ({ component: Component, ...props }: Props) => {
  const history = useHistory();
  const accessToken = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.accessToken
  );

  if (!accessToken) {
    history.push('/login');
    return null;
  }

  return <Component {...props} />;
};
