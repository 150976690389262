import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';

export const ConnectPaymentOption: FC = () => {
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody></CardBody>
      </Card>
    </div>
  );
};
