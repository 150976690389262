import React, { FC, useState, useEffect, CSSProperties } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { LocationModel } from '@nimles/models';

declare global {
  interface Window {
    google: any;
  }
}

const containerStyle: CSSProperties = {
  width: '100%',
  flex: 1,
  minHeight: '300px',
  borderRadius: '4px',
};

interface Props {
  center?: LocationModel;
  zoom?: number;
  locations?: LocationModel[];
  mapStyle?: CSSProperties;
  fitLocations?: boolean;
  disableDefaultUI?: boolean;
  onChange?: (map: any) => void;
}

const MyComponent: FC<Props> = ({
  children,
  center,
  zoom,
  locations,
  mapStyle,
  fitLocations,
  disableDefaultUI,
  onChange,
}) => {
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map && locations) {
      if (fitLocations || !center) {
        const bounds = new google.maps.LatLngBounds();
        locations.forEach((location) => {
          const locationLatLng = toLatLng(location);
          locationLatLng && bounds.extend(locationLatLng);
        });
        const centerLatLng = toLatLng(center);
        if (centerLatLng) {
          bounds.extend(centerLatLng);
        }
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        console.log(map.getZoom());
      }
    }
  }, [map, locations, center, fitLocations]);

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(undefined);
  };

  const onIdle = () => {
    if (onChange) {
      onChange(map);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={mapStyle || containerStyle}
      center={toLatLng(center)}
      zoom={zoom || 10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={onIdle}
      options={{ disableDefaultUI }}
    >
      {children}
    </GoogleMap>
  );
};

export const Map: FC<Props> = React.memo(MyComponent);

export const toLatLng = (
  location?: LocationModel
): google.maps.LatLngLiteral | undefined =>
  location?.latitude && location.longitude
    ? {
        lat: location.latitude,
        lng: location.longitude,
      }
    : undefined;

export const toLocation = (
  location?: google.maps.LatLngLiteral
): LocationModel | undefined =>
  location
    ? {
        latitude: location.lat,
        longitude: location.lng,
      }
    : undefined;

export const distance = (
  location1: google.maps.LatLngLiteral,
  location2: google.maps.LatLngLiteral,
  unit?: string
) => {
  const { lat: lat1, lng: lng1 } = location1;
  const { lat: lat2, lng: lon2 } = location2;

  if (lat1 === lat2 && lng1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lng1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};
