import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { CustomerEditor } from './CustomerEditor';
import { useHistory } from 'react-router';
import { createCustomer } from '@nimles/react-redux';
import { CustomerModel } from '@nimles/models';
import { useOrganizations } from '../../../hooks/organizations';
import { useCustomers } from '../../../hooks/customers';

const customer: CustomerModel = {
  buyer: {
    customerType: 'Private',
  },
};

export const NewCustomer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations } = useOrganizations();

  const { customers } = useCustomers();

  const onSave = async (values: any) => {
    console.log(values);
    const action = createCustomer({ ...values });
    const customer: any = await dispatch(action);
    history.push('/ecommerce/customers/' + customer.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Customer</strong>
        </CardHeader>
        <CardBody>
          <CustomerEditor
            customer={{
              ...customer,
            }}
            customers={customers}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
