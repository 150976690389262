import { ElementModel } from '@nimles/models';

const elements: ElementModel[] = [
  {
    type: 'Section',
    id: 'temp-1',
    children: [
      {
        type: 'Container',
        id: 'temp-11',
        children: [
          {
            type: 'Html',
            content: '<h1>Blog</h1><p>Some blog</p>',
            id: 'temp-111',
          },
          {
            type: 'BlogPosts',
            id: 'temp-112',
          },
        ],
      },
    ],
  },
];

export default elements;
