import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createProductGroup } from '@nimles/react-redux';
import { ProductGroupEditor } from './ProductGroupEditor';
import {
  useCategories,
  useProductGroups,
  useProductProperties,
} from '../../../hooks';

const productGroup = {
  name: '',
  description: '',
  allowedGrantTypes: [],
  allowOfflineAccess: true,
  allowAccessTokensViaBrowser: true,
  secrets: [],
  replyUrls: [],
  logins: [],
};

export const NewProductGroup: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories } = useCategories();
  const { productGroups } = useProductGroups();
  const { productProperties } = useProductProperties();

  const onSave = async (values: any) => {
    const productGroup: any = await dispatch(createProductGroup({ ...values }));

    history.push('/pim/productGroups/' + productGroup.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New ProductGroup</strong>
        </CardHeader>
        <CardBody>
          <ProductGroupEditor
            productGroup={productGroup}
            productGroups={productGroups}
            productProperties={productProperties}
            categories={categories}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
