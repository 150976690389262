import { OrderModel, OrganizationModel } from '@nimles/models';
import { Column } from '@nimles/react-web-components';
import { InputField, ReactSelectField } from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { FC } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  order: OrderModel;
  organizations: OrganizationModel[];
  newOrderType?: string;
  onSave: (order: OrderModel) => Promise<void>;
}

export const OrderEditor: FC<Props> = ({
  order,
  organizations,
  newOrderType,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values: OrderModel = order && {
    id: order.id,
    organizationId: '',
    head: {
      message: order?.head?.message || '',
      // categoryIds: order?.head?.categoryIds || [],
      buyer: {
        email: order?.head?.buyer?.email || '',
        phoneNumber: order?.head?.buyer?.phoneNumber || '',
        billingAddress: {
          id: order?.head?.buyer?.billingAddress?.id,
          street: order?.head?.buyer?.billingAddress?.street || '',
          coAddress: order?.head?.buyer?.billingAddress?.coAddress || '',
          city: order?.head?.buyer?.billingAddress?.city || '',
          postalCode: order?.head?.buyer?.billingAddress?.postalCode || '',
          state: order?.head?.buyer?.billingAddress?.state || '',
          country: order?.head?.buyer?.billingAddress?.country || '',
        },
        deliveryAddress: {
          id: order?.head?.buyer?.deliveryAddress?.id,
          street: order?.head?.buyer?.deliveryAddress?.street || '',
          coAddress: order?.head?.buyer?.deliveryAddress?.coAddress || '',
          city: order?.head?.buyer?.deliveryAddress?.city || '',
          postalCode: order?.head?.buyer?.deliveryAddress?.postalCode || '',
          state: order?.head?.buyer?.deliveryAddress?.state || '',
          country: order?.head?.buyer?.deliveryAddress?.country || '',
        },
        userId: order?.head?.buyer?.userId,
        organizationId: order?.head?.buyer?.organizationId,
      },
      seller: {
        userId: order?.head?.seller?.userId,
        organizationId: order?.head?.seller?.organizationId,
      },
    },
    lines: order.lines || [],
  };

  return order ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              {values.id && (
                <InputField
                  name="id"
                  label="Id"
                  value={values.id}
                  disabled={true}
                />
              )}
              <Row>
                <Column xs={100} lg={100}>
                  <fieldset>
                    <legend>Buyer</legend>

                    <ReactSelectField
                      name="head.buyer.organizationId"
                      label="Organization"
                      placeholder="Select organization"
                      options={organizations}
                      optionLabel={(o) => o.name}
                      optionValue={(o) => o.id}
                    />
                  </fieldset>
                </Column>
                <Column xs={100} lg={100}>
                  <fieldset>
                    <legend>Buyer</legend>

                    <ReactSelectField
                      name="head.buyer.organizationId"
                      label="Organization"
                      placeholder="Select organization"
                      options={organizations}
                      optionLabel={(o) => o.name}
                      optionValue={(o) => o.id}
                    />
                  </fieldset>
                </Column>
              </Row>
              <Row>
                <Column xs={50}>
                  <InputField
                    name="head.buyer.firstName"
                    label="Firstname"
                    placeholder="Firstname"
                  />
                </Column>
                <Column xs={50}>
                  <InputField
                    name="head.buyer.lastName"
                    label="Lastname"
                    placeholder="Lastname"
                  />
                </Column>
              </Row>
              <InputField
                name="message"
                label="Message"
                placeholder="Message"
                value={values.head.message}
              />

              <FieldArray
                name="lines"
                render={(arrayHelpers) => (
                  <div>
                    {values.lines.map((line: any, index: number) => {
                      return (
                        <div key={index}>
                          <Row>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.name`}
                                label="Name"
                                placeholder="Name"
                                value={line.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col flex>
                              <InputField
                                type="textarea"
                                name={`lines.${index}.description`}
                                label="Description"
                                placeholder="Description"
                                value={line.description}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.unitNetPrice`}
                                label="Unit net price"
                                placeholder="Unit net price"
                                value={line.unitNetPrice}
                              />
                            </Col>
                            <Col flex>
                              <InputField
                                name={`lines.${index}.unitGrossPrice`}
                                label="Unit gross price"
                                placeholder="Unit gross price"
                                value={line.unitGrossPrice}
                              />
                            </Col>
                            <Col flex>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                -
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          description: '',
                          unitNetPrice: 0,
                          unitGrossPrice: 0,
                        })
                      }
                    >
                      Add line
                    </Button>
                  </div>
                )}
              />
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  className="mr-1"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
