import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { PipelineEditor } from './PipelineEditor';
import { useHistory } from 'react-router';
import { createPipeline } from '@nimles/react-redux';
import { useOrganizations } from '../../../hooks/organizations';

const pipeline = {};

export const NewPipeline: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    console.log(values);
    const action = createPipeline({ ...values });
    const pipeline: any = await dispatch(action);
    history.push('/crm/pipelines/' + pipeline.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Pipeline</strong>
        </CardHeader>
        <CardBody>
          <PipelineEditor
            pipeline={pipeline}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
