import { ButtonGroup, Button } from 'reactstrap';
import { changeCulture } from '../redux/culture';
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';
import { CultureState, RootState } from '../redux/types';

interface Props {
  size?: string;
}

export const CultureSelector: FC<Props> = ({ size }) => {
  const dispatch = useDispatch();
  const { values: cultures, selected: selectedCulture } = useSelector<
    RootState,
    CultureState
  >(({ culture }) => culture);

  return (
    <ButtonGroup>
      <Button
        size={size}
        color={!selectedCulture ? 'primary' : 'secondary'}
        onClick={() => {
          dispatch(changeCulture());
        }}
      >
        Default
      </Button>
      {cultures.map((culture) => (
        <Button
          key={culture}
          size={size}
          color={culture === selectedCulture ? 'primary' : 'secondary'}
          onClick={() => {
            dispatch(changeCulture(culture));
          }}
        >
          {culture.toUpperCase()}
        </Button>
      ))}
    </ButtonGroup>
  );
};
