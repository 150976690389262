import { OrganizationModel, PaymentOptionModel } from '@nimles/models';
import { InputField } from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { FC, useMemo } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import { EditorActions } from '../../../components/Editor';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { validate } from '../../_shared/formUtils';
import { paymentOptionTemplates } from './paymentOptionsTemplates';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  paymentOption: PaymentOptionModel;
  organizations: OrganizationModel[];
  onSave: (paymentOption: PaymentOptionModel) => Promise<void>;
}

export const PaymentOptionEditor: FC<Props> = ({
  paymentOption,
  organizations,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };
  const paymentOptionTemplate = paymentOptionTemplates.find(
    ({ name }) =>
      name.toLowerCase() ===
      paymentOption.paymentProcessor?.toString().toLowerCase()
  );
  const values: PaymentOptionModel = paymentOption && {
    id: paymentOption.id,
    paymentProcessor: paymentOption.paymentProcessor || '',
    name: paymentOption.name || '',
    organizationId: paymentOption.organizationId,
    paymentType: paymentOption.paymentType,
    fields: [
      ...(paymentOption.fields || []),
      ...((paymentOption?.paymentProcessor && paymentOptionTemplate?.fields) ||
        []),
    ],
  };

  console.log('VALUES ', values);

  const requiresConnect =
    paymentOption?.paymentProcessor && !paymentOption?.connected;

  const ConnectButton = useMemo(
    () => paymentOptionTemplate?.connectButton ?? Button,
    [paymentOptionTemplate]
  );

  return paymentOption ? (
    <Formik
      enableReinitialize
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              {!requiresConnect ? (
                <EditorActions
                  onReset={handleReset}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                />
              ) : null}
              {values.id && (
                <InputField
                  name="id"
                  label="Id"
                  value={values.id}
                  disabled={true}
                />
              )}
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={paymentOption.organizationId}
              />
              <InputField name="name" label="Name" placeholder="Name" />

              <FieldArray
                name="fields"
                render={(arrayHelpers) => (
                  <div>
                    {values.fields.map((field: any, index: number) => {
                      return (
                        <Row key={index}>
                          <Col flex>
                            <InputField
                              name={`fields.${index}.value`}
                              label={field.name}
                              placeholder="Value"
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          description: '',
                          unitNetPrice: 0,
                          unitGrossPrice: 0,
                        })
                      }
                    >
                      Add line
                    </Button>
                  </div>
                )}
              />

              {requiresConnect ? <ConnectButton /> : null}
            </Form>
          </Col>
        </Row>
      )}
    />
  ) : null;
};
