import { ElementModel } from '@nimles/models';

const elements: ElementModel[] = [
  {
    type: 'Section',
    id: 'temp-1',
    uniqueName: 'section-1',
    properties: { backgroundImage: '#999999' },
    children: [
      {
        type: 'Container',
        id: 'temp-11',
        uniqueName: 'container-1',
        children: [
          {
            type: 'Html',
            content: '<h1>First Page</h1>',
            id: 'temp-111',
            uniqueName: 'html-1',
          },
        ],
      },
    ],
  },
  {
    type: 'Section',
    id: 'temp-2',
    uniqueName: 'section-2',
    children: [
      {
        type: 'Container',
        id: 'temp-21',
        uniqueName: 'container-2',
        children: [
          {
            type: 'Html',
            content: '<h2>Welcome</h2><p>This is the first page</>',
            id: 'temp-111',
            uniqueName: 'html-1',
          },
        ],
      },
    ],
  },
];

export default elements;
