import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useCertificates } from '../../../hooks/certificates';

const certificateTypes = [
  { name: 'Import Pem', value: 'ImportPem' },
  { name: 'Import Pfx', value: 'ImportPfx' },
  { name: 'Let´s Encrypt', value: 'LetsEncrypt' },
];

export const Certificates: FC = () => {
  const history = useHistory();

  const { certificates } = useCertificates();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={6}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Certificates
              <div className="card-header-actions">
                <UncontrolledDropdown>
                  <DropdownToggle>New Certificate</DropdownToggle>
                  <DropdownMenu>
                    {certificateTypes.map((type) => (
                      <DropdownItem
                        key={type.value}
                        tag={Link}
                        to={`/identity/certificates/new?type=${type.value}`}
                      >
                        {type.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Domain</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {certificates &&
                  certificates.map((certificate: any, index: number) => {
                    const certificateLink = `/identity/certificates/${certificate.id}`;
                    return (
                      <tr key={certificate.id}>
                        <th scope="row">
                          <Link to={certificateLink}>{certificate.id}</Link>
                        </th>
                        <td>
                          <Link to={certificateLink}>{certificate.domain}</Link>
                        </td>
                        <td>
                          {getOrganizationName(certificate.organizationId)}
                        </td>
                        <td>{certificate.certificateType}</td>
                        <td>{certificate.accessibility}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle nav>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/identity/certificates/new?certificateId=${certificate.id}`
                                  )
                                }
                              >
                                Clone
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
