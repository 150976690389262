import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTenants } from '.';
import { loadCultures } from '../redux/culture';
import { CultureState, RootState } from '../redux/types';

export const useCulture = () => {
  const dispatch = useDispatch();
  const { selectedTenant } = useTenants();
  const { values: cultures, selected: selectedCulture } = useSelector<
    RootState,
    CultureState
  >(({ culture }) => culture);

  useEffect(() => {
    dispatch(loadCultures(selectedTenant?.languages ?? []));
  }, [selectedTenant, dispatch]);

  return {
    cultures,
    defaultLocalizedFields: cultures.reduce((fields, lng) => {
      fields[lng] = '';
      return fields;
    }, {}),
    defaultCulture: selectedTenant?.languages?.find((x) => x),
    selectedCulture,
  };
};
