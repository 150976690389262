import { Field, Formik } from 'formik';
import React, { FC, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

function getAccessibility(accessibility?: string | null) {
  switch (accessibility) {
    case 'Public':
      return 'Public';
    case 'Shared':
      return 'Shared';
    default:
      return 'Private';
  }
}

interface Props {
  accessibility?: string | null;
  onChange: (accessibility: string) => Promise<void>;
}

const accessibilityColors = {
  private: 'danger',
  shared: 'primary',
  public: 'success',
};

const getColor = (accessibility?: string | null) =>
  accessibility
    ? accessibilityColors[accessibility.toLowerCase()]
    : accessibilityColors.private;

export const AccessibilityButton: FC<Props> = ({ accessibility, onChange }) => {
  const [isOpen, setOpen] = useState(false);

  const setAccessibility = async ({ accessibility }: any) => {
    await onChange(accessibility);
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        color={getColor(accessibility)}
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {getAccessibility(accessibility)}
      </Button>
      <Modal isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
        <Formik
          initialValues={{ accessibility: accessibility || '' }}
          onSubmit={setAccessibility}
          render={({ handleSubmit, isSubmitting, isValid }) => (
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <ModalHeader toggle={() => setOpen(!isOpen)}>
                Change accessibility
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="role">Accessibility</Label>

                  <Field
                    component="select"
                    name="accessibility"
                    className="form-control"
                  >
                    <option value=""></option>
                    <option value="Private">Private</option>
                    <option value="Shared">Shared</option>
                    <option value="Public">Public</option>
                  </Field>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    </Fragment>
  );
};
