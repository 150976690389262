import { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { SetupProgress } from './SetupProgress';
import { Column, Row } from '@nimles/react-web-components';
import { TimeChart } from '../../components/charts/TimeChart';

export const Home: FC = () => {
  return (
    <div className="animated fadeIn">
      <Row wrap="wrap">
        <Column xs={100}>
          <Card>
            <CardBody>
              <h1>Welcome to Nimles</h1>
              <p>
                Follow the steps in this list to start your project. Once the
                list is completed, your app and website will be ready to be
                published
              </p>
              <SetupProgress />
            </CardBody>
          </Card>
        </Column>
        <Column xs={50}>
          <Card>
            <TimeChart />
          </Card>
        </Column>
      </Row>
    </div>
  );
};
