import { ClassNames } from '@emotion/react';
import React, { PropsWithChildren, Ref } from 'react';
import { BaseProps } from './Base';

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLSpanElement>
  ) => (
    <ClassNames>
      {({ css, cx }) => (
        <span
          {...props}
          ref={ref}
          className={cx(
            'material-icons',
            className,
            css`
              font-size: 18px;
              vertical-align: text-bottom;
            `
          )}
        />
      )}
    </ClassNames>
  )
);
