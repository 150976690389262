import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createProduct, publishProduct } from '@nimles/react-redux';
import { ProductEditor } from './ProductEditor';
import { ProductModel } from '@nimles/models';
import {
  useOrganizations,
  useProductGroups,
  useProductProperties,
} from '../../../hooks';

export const NewProduct: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations, currentOrganizationId } = useOrganizations();
  const { productGroups } = useProductGroups();
  const { productProperties } = useProductProperties();

  const handleSave = async (values: any, publish: boolean) => {
    const product: any = await dispatch(createProduct({ ...values }));

    if (publish) {
      await dispatch(publishProduct(product.id));
    }
    history.push('/pim/products/' + product.id);
  };

  const product: ProductModel = {
    name: '',
    description: '',
    organizationId: currentOrganizationId,
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <strong>New Product</strong>
        </CardHeader>
        <CardBody>
          <ProductEditor
            product={product}
            productGroups={productGroups}
            organizations={organizations}
            productProperties={productProperties}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
