import { Store, AnyAction } from 'redux';
import { Dispatch } from 'react';

const logger = (store: Store) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  console.group(action.type);
  const returnValue = next(action);
  console.log('action', action);
  console.log('state', store.getState());
  console.groupEnd();
  return returnValue;
};

export default logger;
