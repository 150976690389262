import React, { FC } from "react";
import { Col, Row } from "reactstrap";

interface Props {
  name: string;
}

export const DisplayProperty: FC<Props> = ({ name, children, ...props }) => {
  return (
    <Row {...props}>
      <Col>{name}</Col>
      <Col>{children}</Col>
    </Row>
  );
};
