import { createQuote } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { QuoteEditor } from './QuoteEditor';
import { useLocation } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';
import { useQuoteRequests } from '../../../hooks/quoteRequests';
import { useQuotes } from '../../../hooks/quotes';

export const NewQuote: FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const parentId = query?.get('parentId?');
  const quoteRequestId = query?.get('quoteRequestId');
  const { quotes } = useQuotes();
  const { quoteRequests } = useQuoteRequests();
  const parentQuote =
    (parentId && quotes.find((o) => o.id === parentId)) || undefined;
  const quoteRequest =
    (quoteRequestId && quoteRequests.find((o) => o.id === quoteRequestId)) ||
    undefined;
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    await dispatch(
      createQuote({
        ...values,
        bySeller: true,
        seller: {
          organizationId: values.organizationId,
        },
        parentQuoteId: parentQuote?.id,
        buyer: {
          userId: parentQuote?.head?.buyer?.userId,
        },
        message: values.message,
      })
    );
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Quote</strong>
          <p>Create new quote:</p>
          <QuoteEditor
            quote={{
              head: {
                quoteRequestId: quoteRequestId?.toString(),
                parentQuoteId: parentId?.toString(),
                categoryId:
                  parentQuote?.head?.categoryId ??
                  quoteRequest?.head?.categoryId,
              },
              lines:
                parentQuote?.lines?.map((line) => ({ ...line })) ??
                quoteRequest?.lines?.map(({ productGroupId }) => ({
                  productGroupId,
                })),
            }}
            onSave={onSave}
            newQuoteType="Quote"
            organizations={organizations}
          />
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </div>
  );
};
