import { ElementModel } from '@nimles/models';

const elements: ElementModel[] = [
  {
    type: 'Section',
    id: 'temp-1',
    properties: { backgroundImage: '#999999' },
    children: [
      {
        type: 'Container',
        id: 'temp-11',
        children: [
          {
            type: 'Html',
            content: '<h1>About</h1><p>Some information about you</p>',
            id: 'temp-111',
          },
        ],
      },
    ],
  },
];

export default elements;
