import { createCertificate } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { CertificateEditor } from './CertificateEditor';
import { useLocation, useHistory } from 'react-router-dom';
import { RootState } from '../../../redux/types';
import { CertificateModel, TenantModel } from '@nimles/models';
import { useCertificates } from '../../../hooks/certificates';

export const NewCertificate: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const certificateId = query?.get('certificateId?');
  const certificateType = query?.get('type');

  const { certificates } = useCertificates();
  const template = certificates.find((l) => l.id === certificateId);

  const onSave = async (values: any) => {
    const certificate: any = await dispatch(createCertificate({ ...values }));

    history.push('/identity/certificates/' + certificate.id);
  };

  const certificate: CertificateModel | null | undefined = !certificateId
    ? {
        certificateType,
      }
    : !template
    ? null
    : {
        certificateType: template.certificateType,
        domain: `${template.domain} - copy`,
        uniqueName: `${template.uniqueName}-copy`,
        csr: template.csr && { ...template.csr },
      };

  return certificate && tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Certificate</strong>
        </CardHeader>
        <CardBody>
          <CertificateEditor
            certificate={certificate}
            tenant={tenant}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
