import {
  QuoteModel,
  OrganizationModel,
  QuoteLineModel,
  ProductGroupModel,
} from '@nimles/models';
import { Row, Column } from '@nimles/react-web-components';
import { InputField, SelectField } from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import * as Yup from 'yup';
import { EditorActions } from '../../../components/Editor';
import { DisplayField } from '../../../components/fields/DisplayField';
import { QuillField } from '../../../components/fields/QuillField';
import { useProducts } from '../../../hooks/products';
import { RootState } from '../../../redux/types';
import { validate } from '../../_shared/formUtils';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  quote: QuoteModel;
  organizations: OrganizationModel[];
  newQuoteType?: string;
  onSave: (quote: QuoteModel) => Promise<void>;
}

export const QuoteEditor: FC<Props> = ({
  quote,
  organizations,
  newQuoteType,
  onSave,
}) => {
  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values: QuoteModel = quote && {
    id: quote.id,
    organizationId: '',
    head: {
      message: quote?.head?.message || '',
      // categoryIds: quote?.head?.categoryIds || [],
      buyer: {
        email: quote?.head?.buyer?.email || '',
        phoneNumber: quote?.head?.buyer?.phoneNumber || '',
        billingAddress: {
          id: quote?.head?.buyer?.billingAddress?.id,
          street: quote?.head?.buyer?.billingAddress?.street || '',
          coAddress: quote?.head?.buyer?.billingAddress?.coAddress || '',
          city: quote?.head?.buyer?.billingAddress?.city || '',
          postalCode: quote?.head?.buyer?.billingAddress?.postalCode || '',
          state: quote?.head?.buyer?.billingAddress?.state || '',
          country: quote?.head?.buyer?.billingAddress?.country || '',
        },
        deliveryAddress: {
          id: quote?.head?.buyer?.deliveryAddress?.id,
          street: quote?.head?.buyer?.deliveryAddress?.street || '',
          coAddress: quote?.head?.buyer?.deliveryAddress?.coAddress || '',
          city: quote?.head?.buyer?.deliveryAddress?.city || '',
          postalCode: quote?.head?.buyer?.deliveryAddress?.postalCode || '',
          state: quote?.head?.buyer?.deliveryAddress?.state || '',
          country: quote?.head?.buyer?.deliveryAddress?.country || '',
        },
        userId: quote?.head?.buyer?.userId,
        organizationId: quote?.head?.buyer?.organizationId,
      },
      seller: {
        userId: quote?.head?.seller?.userId,
        organizationId: quote?.head?.seller?.organizationId,
      },
    },
    lines: quote.lines || [],
  };

  return quote ? (
    <Formik
      enableReinitialize
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        submitForm,
      }) => (
        <Row>
          <Column xs={100}>
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onSave={() => {
                  submitForm();
                }}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
              {values.id && <InputField name="id" label="Id" disabled={true} />}
              <SelectField
                name="organizationId"
                label="Organization"
                placeholder="Select organization"
                options={organizations}
                optionName={(o) => o.name}
                optionValue={(o) => o.id}
              />

              <FieldArray
                name="lines"
                render={(arrayHelpers) => (
                  <div>
                    {values.lines.map((line: any, index: number) => (
                      <QuoteLineEditor
                        line={line}
                        index={index}
                        onRemove={() => arrayHelpers.remove(index)}
                      />
                    ))}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          name: '',
                          description: '',
                          unitNetPrice: 0,
                          unitGrossPrice: 0,
                        })
                      }
                    >
                      Add line
                    </Button>
                  </div>
                )}
              />
            </Form>
          </Column>
        </Row>
      )}
    />
  ) : null;
};

const QuoteLineEditor = ({
  line,
  index,
  onRemove,
}: {
  line: QuoteLineModel;
  index: number;
  onRemove: () => void;
}) => {
  const { products } = useProducts();
  const productGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ productGroups }) => productGroups.values
  );

  const product =
    (line.productId && products.find(({ id }) => id === line.productId)) ||
    undefined;
  const productGroup =
    (line.productGroupId &&
      productGroups.find(({ id }) => id === line.productGroupId)) ||
    undefined;

  return (
    <div>
      <Row>
        <Column flex>
          {productGroup?.name ? (
            <DisplayField label="Product Group" value={productGroup.name} />
          ) : null}
          {product?.name && (
            <DisplayField label="Product" value={product.name} />
          )}
          {line.name || (!line.productGroupId && !line.productId) ? (
            <InputField
              name={`lines.${index}.name`}
              label="Name"
              placeholder="Name"
            />
          ) : null}
        </Column>
      </Row>
      <Row>
        <Column flex>
          <QuillField
            type="textarea"
            name={`lines.${index}.description`}
            label="Description"
            placeholder="Description"
          />
        </Column>
      </Row>
      <Row>
        <Column flex>
          <InputField
            name={`lines.${index}.unitNetPrice`}
            label="Unit net price"
            placeholder="Unit net price"
          />
        </Column>
        <Column flex>
          <InputField
            name={`lines.${index}.unitGrossPrice`}
            label="Unit gross price"
            placeholder="Unit gross price"
          />
        </Column>
        <Column flex>
          <Button type="button" onClick={() => onRemove()}>
            -
          </Button>
        </Column>
      </Row>
    </div>
  );
};
