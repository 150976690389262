import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { createLayout, publishLayout } from '@nimles/react-redux';
import { useHistory, useLocation } from 'react-router';
import { LayoutEditor } from './LayoutEditor';
import {
  removeTempIds,
  copyElements,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useOrganizations } from '../../../hooks/organizations';
import { useLayouts } from '../../../hooks/layouts';

export const NewLayout: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const layoutId = query?.get('layoutId?');
  const layoutType = query?.get('type');

  const { layouts } = useLayouts();
  const template = layouts.find((l: any) => l.id === layoutId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    const layout: any = await dispatch(
      createLayout({
        ...values,
        elements: removeTempIds(values.elements),
        accessibility: 'Public',
      })
    );
    if (publish) {
      await dispatch(publishLayout(layout.id));
    }
    history.push('/cms/layouts/' + layout.id);
  };

  const layout = !layoutId
    ? {
        layoutType,
        elements: [],
      }
    : !template
    ? null
    : {
        layoutType: template.layoutType,
        name: `${template.name} - copy`,
        uniqueName: `${template.uniqueName}-copy`,
        elements: template.elements && copyElements(template.elements),
      };

  return layout ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Layout: {layout.layoutType}</strong>
        </CardHeader>
        <CardBody>
          <LayoutEditor
            layout={layout}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
