import styled from '@emotion/styled';
import React, { FC } from 'react';

const StyledFieldset = styled.fieldset`
  margin: 20px 0;
`;

const StyledLegend = styled.legend`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  padding-top: 5px;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: black;
    position: absolute;
    height: 3px;
    top: -5px;
    left: 0;
    width: 30px;
  }
`;

export const Fieldset: FC<{ legend?: string }> = ({ children, legend }) => {
  return (
    <StyledFieldset>
      <StyledLegend>{legend}</StyledLegend>
      {children}
    </StyledFieldset>
  );
};
