import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { useOrganizations } from '../../../hooks/organizations';
import { useDiscounts } from '../../../hooks/discounts';

export const Discounts: FC = () => {
  const { discounts } = useDiscounts();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"></i> Discounts
          <div className="card-header-actions">
            <Link
              to="/ecommerce/discounts/new"
              className="btn btn-sm btn-primary"
            >
              New Discount
            </Link>
          </div>
        </CardHeader>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Organization</th>
              <th scope="col">Value</th>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              <th scope="col">Accessibility</th>
            </tr>
          </thead>
          <tbody>
            {discounts.map((discount) => {
              const discountLink = `/ecommerce/discounts/${discount.id}`;
              return (
                <tr key={discount.id?.toString()}>
                  <td>
                    <Link to={discountLink}>
                      <div>
                        <strong>{discount.name}</strong>
                      </div>
                      <div>
                        <small>{discount.id}</small>
                      </div>
                    </Link>
                  </td>
                  <td>{getOrganizationName(discount.organizationId)}</td>
                  <td>{discount.value}</td>
                  <td>{discount.startDate}</td>
                  <td>{discount.endDate}</td>
                  <td>{discount.accessibility}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
