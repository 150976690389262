import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { CustomerEditor } from './CustomerEditor';
import { useParams } from 'react-router';
import { updateCustomer } from '@nimles/react-redux';
import { useOrganizations } from '../../../hooks/organizations';
import { useCustomers } from '../../../hooks/customers';

interface Params {
  customerId: string;
}

export const Customer: FC = () => {
  const dispatch = useDispatch();
  const { organizations } = useOrganizations();

  const { customerId } = useParams<Params>();
  const { customers } = useCustomers();
  const customer = customers.find((r) => r.id === customerId);

  const onSave = async (values: any) => {
    await dispatch(updateCustomer({ ...customer, ...values }));
  };

  return customer ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New User</strong>
        </CardHeader>
        <CardBody>
          <CustomerEditor
            customer={customer}
            customers={customers}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
