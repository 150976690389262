import { CustomInputProps, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field, FieldProps } from 'formik';
import { ColorPicker } from '../ColorPicker';
import { FC } from 'react';

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <div key={field.name}>
      {props.label ? <Label for={field.name}>{props.label}</Label> : null}
      <FormGroup>
        <ColorPicker
          id={field.name}
          valid={!errors[field.name]}
          invalid={touched[field.name] && !!errors[field.name]}
          {...field}
          onChange={(color) => setFieldValue(field.name, color)}
        />
        <FormFeedback>{errors[field.name]}</FormFeedback>
      </FormGroup>
    </div>
  );
};

const ColorField = (props: any) => (
  <Field {...props} component={StandardInput} />
);

export default ColorField;
