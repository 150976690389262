import { createOrder } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { OrderEditor } from './OrderEditor';
import { useOrganizations } from '../../../hooks/organizations';
import { useOrders } from '../../../hooks/orders';

interface Params {
  parentId: string;
}

export const NewOrder: FC = () => {
  const dispatch = useDispatch();
  const { parentId } = useParams<Params>();
  const { orders } = useOrders();
  const parentOrder = orders.find((o) => o.id === parentId);
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    await dispatch(
      createOrder({
        ...values,
        bySeller: true,
        seller: {
          organizationId: values.organizationId,
        },
        parentOrderId: parentOrder?.id,
        buyer: {
          userId: parentOrder?.head?.buyer?.userId,
        },
        message: values.message,
      })
    );
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Order</strong>
          <p>Create new order:</p>
          <OrderEditor
            order={{}}
            onSave={onSave}
            newOrderType="Quote"
            organizations={organizations}
          />
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </div>
  );
};
