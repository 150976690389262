import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { useOrganizationMembers } from '../../../hooks/organizationMembers';
import { useTenants } from '../../../hooks';

export const Members: FC = () => {
  const { tenants } = useTenants();
  const { organizationMembers } = useOrganizationMembers();

  const getTenantName = (tenantId?: string | null) => {
    const tenant = tenants.find((o) => o.id === tenantId);

    return tenant ? tenant.name : tenantId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Members
              <div className="card-header-actions">
                <Link
                  to="/organization-management/members/new"
                  className="btn btn-sm btn-primary"
                >
                  New Member
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Tenant</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {organizationMembers &&
                  organizationMembers.map(
                    (organizationMember: any, index: any) => {
                      const organizationMemberLink = `/organization-management/members/${organizationMember.id}`;

                      return (
                        <tr key={organizationMember.id.toString()}>
                          <th scope="row">
                            <Link to={organizationMemberLink}>
                              {organizationMember.id}
                            </Link>
                          </th>
                          <td>
                            <Link to={organizationMemberLink}>
                              {organizationMember.name}
                            </Link>
                          </td>
                          <td>{getTenantName(organizationMember.tenantId)}</td>
                          <td>{organizationMember.accessibility}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
