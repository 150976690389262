import { PricelistModel } from '@nimles/models';
import { loadPricelists, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const usePricelists = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<PricelistModel>>(
    ({ pricelists }) => pricelists
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadPricelists());
    }
  }, [tenantId, dispatch]);

  return {
    pricelists: values,
  };
};
