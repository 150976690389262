import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import {
  setPricelistAccessibility,
  updatePricelist,
} from '@nimles/react-redux';
import { PricelistEditor } from './PricelistEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { useProducts } from '../../../hooks';
import { usePricelists } from '../../../hooks/pricelists';

interface Params {
  pricelistId: string;
}

export const Pricelist: FC = () => {
  const dispatch = useDispatch();
  const { pricelistId } = useParams<Params>();
  const { pricelists } = usePricelists();
  const pricelist = pricelists.find((p) => p.id === pricelistId);

  const { organizations } = useOrganizations();
  const { products } = useProducts();

  const handleSave = async (values: any) => {
    await dispatch(updatePricelist({ ...pricelist, ...values }));
  };

  const setAccessibility = async (accessibility: any) => {
    pricelist?.id &&
      (await dispatch(setPricelistAccessibility(pricelist.id, accessibility)));
  };

  return pricelist && organizations ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {pricelist.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(pricelist.createdDate).format('LLL')}
            </div>
          ) : null}
          {pricelist.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(pricelist.lastModified).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Pricelist</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={pricelist.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <PricelistEditor
            pricelist={pricelist}
            products={products}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
