import { Column, Row } from '@nimles/react-web-components';
import { InputField, TextAreaField } from '@nimles/react-web-forms';
import { Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { Form, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { EditorActions } from '../../../components/Editor';
import { OrganizationModel, SiteModel } from '@nimles/models';
import { ImageEditor } from '../../../components/images';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  site: SiteModel;
  organizations: OrganizationModel[];
  onSave: (site: any) => Promise<void>;
}

const AddressFieldSet = ({ label, name }: { label: string; name: string }) => (
  <fieldset>
    <legend>{label}</legend>
    <InputField name={name + '.street'} label="Street" placeholder="Street" />
    <InputField
      name={name + '.postalCode'}
      label="Postal code"
      placeholder="Postal code"
    />
    <InputField name={name + '.city'} label="City" placeholder="City" />
    <InputField
      name={name + '.country'}
      label="Country"
      placeholder="Country"
    />
  </fieldset>
);

export const SiteEditor: FC<Props> = ({ site, organizations, onSave }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: SiteModel = site && {
    id: site.id,
    organizationId: site.organizationId,
    name: site.name || '',
    description: site.description || '',
    uniqueName: site.uniqueName || '',
    email: site.email || '',
    phoneNumber: site.phoneNumber || '',
    address: {
      street: (site.address && site.address.street) || '',
      postalCode: (site.address && site.address.postalCode) || '',
      city: (site.address && site.address.city) || '',
      country: (site.address && site.address.country) || '',
    },
    imageIds: site.imageIds || [],
    website: site.website || '',
    tags: site.tags || [],
    logoId: site.logoId,
  };

  return site ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => (
        <>
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            {values.id && <InputField name="id" label="Id" disabled={true} />}
            <OrganizationSelectField
              organizations={organizations}
              defaultOrganizationId={site.organizationId}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <InputField
                  label="Name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
                <InputField
                  label="Unique name"
                  type="text"
                  name="uniqueName"
                  required
                />
                <InputField label="Host name" type="text" name="hostName" />
                <InputField
                  name="corporateIdentity"
                  label="Corporate Identity"
                  placeholder="Corporate Identity"
                />
              </Column>
              <Column xs={100} lg={50} justify="center"></Column>
            </Row>
            <TextAreaField
              name="description"
              label="Description"
              placeholder="Description"
            />
            <InputField name="email" label="Email" placeholder="Email" />
            <InputField
              name="phoneNumber"
              label="Phone number"
              placeholder="Phone number"
            />
            <AddressFieldSet name="address" label="Address" />
          </Form>

          <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
              Select Image
            </ModalHeader>
            <ModalBody>
              <ImageEditor
                onChange={(image) => {
                  if (image.id) {
                    setFieldValue('logoId', image.id);
                    setFieldValue('logoData', undefined);
                  } else {
                    setFieldValue('logoId', undefined);
                    setFieldValue('logoData', image.uri);
                  }
                }}
              />
            </ModalBody>
          </Modal>
        </>
      )}
    />
  ) : null;
};
