import { Transforms, Editor, Element } from 'slate';
import { useSlate } from 'slate-react';
import { FullEditor, isBlockActive } from './Base';
import { Icon } from './Icon';
import { ToolbarButton } from './ToolbarButton';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      active={isBlockActive(editor, format)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </ToolbarButton>
  );
};

const toggleBlock = (editor: FullEditor, format: string | null | undefined) => {
  const isActive = isBlockActive(editor, format);
  const isList = format && LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      Element.isElement(node) &&
      !!node.type &&
      LIST_TYPES.includes(node.type),
    split: true,
  });
  const newProperties: Partial<Element> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
