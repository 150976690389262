import { createClient } from '@nimles/react-redux';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ClientEditor } from './ClientEditor';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux/types';
import { TenantModel } from '@nimles/models';

export const NewClient: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );

  const onSave = async (values: any) => {
    const client: any = await dispatch(createClient({ ...values }));

    history.push('/identity/clients/' + client.id);
  };

  return tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Client</strong>
        </CardHeader>
        <CardBody>
          <ClientEditor client={{}} tenant={tenant} onSave={onSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
