import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { useTenants } from '../../../hooks';
import { useSites } from '../../../hooks/sites';

export const Sites: FC = () => {
  const { tenants } = useTenants();
  const { sites } = useSites();

  const getTenantName = (tenantId?: string | null) => {
    const tenant = tenants.find((o) => o.id === tenantId);

    return tenant ? tenant.name : tenantId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Sites
              <div className="card-header-actions">
                <Link
                  to="/organization-management/sites/new"
                  className="btn btn-sm btn-primary"
                >
                  New Site
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Tenant</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {sites &&
                  sites.map((site: any, index: any) => {
                    const siteLink = `/organization-management/sites/${site.id}`;

                    return (
                      <tr key={site.id.toString()}>
                        <th scope="row">
                          <Link to={siteLink}>{site.id}</Link>
                        </th>
                        <td>
                          <Link to={siteLink}>{site.name}</Link>
                        </td>
                        <td>{getTenantName(site.tenantId)}</td>
                        <td>{site.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
