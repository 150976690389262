import { CertificateModel } from '@nimles/models';
import { loadCertificates, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useCertificates = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<CertificateModel>>(
    ({ certificates }) => certificates
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadCertificates());
    }
  }, [tenantId, dispatch]);

  return {
    certificates: values,
  };
};
