import React from 'react';
import { Button } from 'reactstrap';
import { translate, Translation } from '../services/translator';
import CIcon from '@coreui/icons-react';

interface Props {
  size?: string;
  icon?: boolean;
  text: string;
  from: string;
  to: string[];
  onTranslated: (translations?: Translation[]) => void;
}

export const Translator = ({
  size,
  icon,
  text,
  from,
  to,
  onTranslated,
}: Props) => {
  const handleTranslate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const translations = await translate(text, from, to);

    onTranslated(translations);
  };
  return (
    <Button
      size={size}
      primary
      disabled={!to?.length}
      onClick={handleTranslate}
    >
      {icon ? <CIcon name="cil-translate" /> : 'Translate'}
    </Button>
  );
};
