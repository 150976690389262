import { FC, useState } from 'react';
import { Button, Form, Table } from 'reactstrap';
import { Formik, FormikValues, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { Column, Row } from '@nimles/react-web-components';
import {
  InputField,
  SelectField,
  TextAreaField,
} from '@nimles/react-web-forms';
import { QuillField } from '../../../components/fields/QuillField';
import { LocalizedField } from '../../../components/LocalizedField';
import { EditorActions } from '../../../components/Editor';
import {
  ReviewModel,
  OrganizationModel,
  ReviewGroupModel,
  ProductModel,
  LocalizedTextModel,
} from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import ButtonGroupField from '../../../components/fields/ButtonGroupField';
import { ImageIdsField } from '../../../components/fields/ImageIdsField';

const targetTypes = ['Product'];

const validationSchema = function () {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  review: ReviewModel;
  organizations: OrganizationModel[];
  reviewGroups: ReviewGroupModel[];
  products: ProductModel[];
  onSave: (review: any, publish: boolean) => Promise<void>;
}

const LocalizedFields = {
  en: '',
  sv: '',
};

export const ReviewEditor: FC<Props> = ({
  review,
  organizations,
  reviewGroups,
  products,
  onSave,
}) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      console.info(values);
      const review = {
        ...values,
      };
      await onSave(review, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: ReviewModel = review && {
    id: review.id,
    imageIds: review.imageIds?.map((x) => x) || [],
    title: review.title || '',
    titleLocalized: { ...LocalizedFields, ...review.titleLocalized },
    longTitle: review.longTitle || '',
    longTitleLocalized: { ...LocalizedFields, ...review.longTitleLocalized },
    summary: review.summary || '',
    summaryLocalized: { ...LocalizedFields, ...review.summaryLocalized },
    description: review.description || '',
    descriptionLocalized: {
      ...LocalizedFields,
      ...review.descriptionLocalized,
    },
    uniqueName: review.uniqueName || '',
    organizationId: review.organizationId,
    pros: review.pros || [],
    cons: review.cons || [],
    targetId: review.targetId,
    targetType: review.targetType,
    score: review.score || { name: '', comment: { value: '' } },
    scores: review.scores || [],
    sections: review.sections || [],
  };

  return review ? (
    <Formik<ReviewModel>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onPublish={() => {
                setPublish(true);
                submitForm();
              }}
              onSave={() => {
                setPublish(false);
                submitForm();
              }}
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Information</legend>
                  {values.id && (
                    <InputField name="id" label="Id" disabled required />
                  )}
                  <OrganizationSelectField
                    organizations={organizations}
                    defaultOrganizationId={review.organizationId}
                  />
                  <SelectField
                    name="targetType"
                    label="Type of reviewed target"
                    placeholder="Select type"
                    options={targetTypes}
                  />
                  <SelectField
                    name="targetId"
                    label="Reviewed target"
                    placeholder="Select target"
                    options={products}
                    optionName={(o) => o.name}
                    optionValue={(o) => o.id}
                  />
                  <LocalizedField label="Title" name="title">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <LocalizedField label="Long title" name="longTitle">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <InputField name="uniqueName" label="Unique name" />
                </fieldset>
              </Column>
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Images</legend>
                  <ImageIdsField name="imageIds" />
                </fieldset>
              </Column>
            </Row>
            <LocalizedField label="Summary" name="summary">
              {({ localizedName }) => (
                <TextAreaField name={localizedName} wordCount />
              )}
            </LocalizedField>
            <LocalizedField label="Description" name="description">
              {({ localizedName }) => <QuillField name={localizedName} />}
            </LocalizedField>
            <Row>
              <Column xs={100} md={50}>
                <FieldArray
                  name="pros"
                  render={(arrayHelpers) => (
                    <fieldset>
                      <legend>Pros</legend>
                      <Table responsive hover>
                        <tbody>
                          {values.pros?.map(
                            (text: LocalizedTextModel, index: number) => (
                              <tr key={index}>
                                <td>
                                  <LocalizedField name={`pros.${index}.value`}>
                                    {({ localizedName }) => (
                                      <InputField
                                        name={localizedName}
                                        required
                                      />
                                    )}
                                  </LocalizedField>
                                </td>
                                <td className="nostretch">
                                  <Button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan={2}>
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    value: '',
                                    values: { ...LocalizedFields },
                                  })
                                }
                              >
                                Add value
                              </Button>
                            </th>
                          </tr>
                        </tfoot>
                      </Table>
                    </fieldset>
                  )}
                />
              </Column>
              <Column xs={100} md={50}>
                <FieldArray
                  name="cons"
                  render={(arrayHelpers) => (
                    <fieldset>
                      <legend>Cons</legend>
                      <Table responsive hover>
                        <tbody>
                          {values.cons?.map(
                            (text: LocalizedTextModel, index: number) => (
                              <tr key={index}>
                                <td>
                                  <LocalizedField name={`cons.${index}.value`}>
                                    {({ localizedName }) => (
                                      <InputField
                                        name={localizedName}
                                        required
                                      />
                                    )}
                                  </LocalizedField>
                                </td>
                                <td className="nostretch">
                                  <Button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan={2}>
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    value: '',
                                    values: { ...LocalizedFields },
                                  })
                                }
                              >
                                Add value
                              </Button>
                            </th>
                          </tr>
                        </tfoot>
                      </Table>
                    </fieldset>
                  )}
                />
              </Column>
            </Row>
            <fieldset>
              <legend>Score</legend>
              <ButtonGroupField
                name="score.value"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              />
              <QuillField label="Comment" name="score.comment.value" />
            </fieldset>

            <FieldArray
              name="sections"
              render={(arrayHelpers) => (
                <fieldset>
                  <legend>Sections</legend>
                  <Table responsive hover>
                    <tbody>
                      {values.sections?.map((section, index) => (
                        <tr key={index}>
                          <td>
                            <LocalizedField name={`sections.${index}.value`}>
                              {({ localizedName }) => (
                                <QuillField name={localizedName} required />
                              )}
                            </LocalizedField>
                          </td>
                          <td className="nostretch">
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={2}>
                          <Button
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                value: '',
                                values: { ...LocalizedFields },
                              })
                            }
                          >
                            Add value
                          </Button>
                        </th>
                      </tr>
                    </tfoot>
                  </Table>
                </fieldset>
              )}
            />
          </Form>
        );
      }}
    />
  ) : null;
};
