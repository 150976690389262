import { ElementModel } from '@nimles/models';

export function compare<TItem, TKey>(
  getKey: (item: TItem) => TKey,
  descending?: boolean
) {
  return (a: TItem, b: TItem) => {
    if (getKey(a) < getKey(b)) {
      return descending ? 1 : -1;
    }
    if (getKey(a) > getKey(b)) {
      return descending ? -1 : 1;
    }
    return 0;
  };
}

export const compareBy = (
  key: string,
  secondKey?: string,
  descending?: boolean
) => {
  return (a: { [x: string]: number }, b: { [x: string]: number }) => {
    if (a[key] < b[key]) {
      return descending ? 1 : -1;
    }
    if (a[key] > b[key]) {
      return descending ? -1 : 1;
    }
    if (secondKey) {
      if (a[secondKey] < b[secondKey]) {
        return descending ? 1 : -1;
      }
      if (a[secondKey] > b[secondKey]) {
        return descending ? -1 : 1;
      }
    }
    return 0;
  };
};

export var groupByCount = function (
  items: { key: any; value: number }[],
  key: any
) {
  let item = items.find((i: { key: any }) => i.key === key);
  if (item) {
    item.value++;
  } else {
    items.push({ key, value: 1 });
  }
  return items;
};

export const toUniqueName = (input: string) =>
  input
    .toLowerCase()
    .replace('   ', '-')
    .replace('  ', '-')
    .replace(' ', '-')
    .replace('ä', 'a')
    .replace('å', 'a')
    .replace('ö', 'o')
    .replace(/[^(a-z0-9-)*]/g, '')
    .toLowerCase();

export function getContent(
  element: ElementModel,
  type: string
): string | null | undefined {
  if (element?.type?.toLowerCase() === type) {
    return element.content;
  }
  if (!element?.children) {
    return undefined;
  }
  for (const child of element.children) {
    const content = getContent(child, type);
    if (content) {
      return content;
    }
  }

  return undefined;
}
