import { FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useLayouts } from '../../../hooks/layouts';

const layoutTypes = ['Web', 'App', 'Header', 'Footer', 'Undefined'];

export const Layouts: FC = () => {
  const history = useHistory();

  const { layouts } = useLayouts();
  const { organizations } = useOrganizations();
  const location = useLocation();
  const layoutType = new URLSearchParams(location.search)?.get('layoutType');

  const selectedLayouts = layoutType
    ? layouts.filter((layout) => layout.layoutType === layoutType)
    : layouts;

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Layouts
              <div className="card-header-actions">
                <UncontrolledDropdown>
                  <DropdownToggle size="sm" primary>
                    New Layout
                  </DropdownToggle>
                  <DropdownMenu>
                    {layoutTypes.map((type) => (
                      <DropdownItem
                        key={type}
                        tag={Link}
                        to={`/cms/layouts/new?type=${type}`}
                      >
                        {type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <Nav tabs>
              <NavItem>
                <NavLink tag={Link} to="/cms/layouts" active={!layoutType}>
                  All
                </NavLink>
              </NavItem>
              {layoutTypes.map((type) => (
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={`/cms/layouts?layoutType=${type}`}
                    active={layoutType === type}
                  >
                    {type}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {selectedLayouts &&
                  selectedLayouts.map((layout) => {
                    const layoutLink = `/cms/layouts/${layout.id}`;
                    return (
                      <tr key={layout.id?.toString()}>
                        <td>
                          <Link to={layoutLink}>
                            <div>{layout.name}</div>
                            {/* <small>{layout.id}</small> */}
                          </Link>
                        </td>
                        <td>{getOrganizationName(layout.organizationId)}</td>
                        <td>{layout.layoutType}</td>
                        <td>{layout.accessibility}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle nav>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/cms/layouts/new?layoutId=${layout.id}`
                                  )
                                }
                              >
                                Clone
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
