import { FC, useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  Slate,
  ReactEditor,
  useSelected,
  useFocused,
} from 'slate-react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { ElementModel } from '@nimles/models';
import { isImageUrl } from '../../utils/isImageUrl';
import styled from '@emotion/styled';
import { Toolbar } from './components/Toolbar';
import { BlockButton } from './components/BlockButton';
import { FullEditor } from './components/Base';
import { MarkButton, toggleMark } from './components/MarkButton';
import { insertImage, InsertImageButton } from './components/ImageButton';
import { Image } from '../images';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

type CustomText = {
  text?: string | null;
  bold?: true;
  italic?: boolean;
  code?: boolean;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: FullEditor;
    Element: ElementModel;
    Text: CustomText;
  }
}
interface Props {
  value?: Descendant[] | null;
  onChange: (value: Descendant[]) => void;
}

export const SlateEditor = ({ value, onChange }: Props) => {
  // const [value, setValue] = useState<Descendant[]>(initialValue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withImages(withHistory(withReact(createEditor() as ReactEditor))),
    []
  );

  console.log('Elements', value);

  return (
    <Slate
      editor={editor}
      value={value ?? []}
      onChange={(value) => onChange(value)}
    >
      <Toolbar>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" icon="looks_one" />
        <BlockButton format="heading-two" icon="looks_two" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        <InsertImageButton />
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter some rich text…"
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const Element: FC<{ attributes: any; element: ElementModel }> = (props) => {
  const { attributes, children, element } = props;

  switch (element.type?.toLowerCase()) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'image':
      return <ImageElement {...props} />;
    case 'section':
      return <BorderElement name="Section" {...props} />;
    case 'container':
      return <BorderElement name="Container" {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const withImages = (editor: FullEditor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    console.log('isVoid', element);
    return element.type === 'Image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    console.log('insertData', data);
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            typeof url === 'string' && insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const StyledImage = styled(Image)<{ selected: boolean; focused: boolean }>`
  display: block;
  max-width: 100%;
  max-height: 20em;
  box-shadow: ${({ selected, focused }) =>
    selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
`;

const ImageElement = ({ attributes, children, element }: any) => {
  const selected = useSelected();
  const focused = useFocused();

  console.log(element);
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <StyledImage
          selected={selected}
          focused={focused}
          value={element.content}
        />
      </div>
      {children}
    </div>
  );
};

const StyledDiv = styled.div<{
  selected: boolean;
  focused: boolean;
  name: string;
}>`
  display: block;
  position: relative;
  max-width: 100%;
  max-height: 20em;
  border: 1px dashed gray;
  border-color: ${({ selected, focused }) =>
    selected && focused ? '#B4D5FF' : 'grey'};
  padding: 10px 5px 5px;
  margin: 10px 0 0;
  background: #fff;

  &::before {
    display: block;
    content: ${({ name }) => `'${name}'`};
    position: absolute;
    top: -10px;
    padding: 0 5px;
    background: #fff;
    font-size: 14px;
    color: ${({ selected, focused }) =>
      selected && focused ? '#B4D5FF' : 'grey'};
  }
`;

const BorderElement = ({ attributes, children, element, name }: any) => {
  const selected = useSelected();
  const focused = useFocused();

  console.log(element);
  return (
    <StyledDiv
      {...attributes}
      name={name}
      contentEditable={true}
      selected={selected}
      focused={focused}
    >
      {children}
    </StyledDiv>
  );
};
