import React, { FC, useState } from 'react';
import { CustomInputProps, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field, FieldProps } from 'formik';
import { Column, Row, Input } from '@nimles/react-web-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

const Tag = styled.div`
  text-align: center;
  width: 100%;
  padding: 8px 0 8px 16px;
  margin: 8px 0px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  background: lightgrey;
`;
const RemoveTag = styled.button`
  background: transparent;
  outline: none;
  border: none;
  margin-left: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 8px 0px;
`;

const TagInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  outline: none !important;
`;

const AddTag = styled.button`
  background: transparent;
  outline: none !important;
  border: 1px solid rgb(204, 204, 204);
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [newTag, setNewTag] = useState('');
  return (
    <div key={field.name}>
      {props.label ? <Label for={field.name}>{props.label}</Label> : null}
      <FormGroup>
        <Row wrap="wrap" align="center">
          {field.value.map((tag: string) => (
            <Column>
              <Tag>
                {tag}
                <RemoveTag
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldValue(
                      field.name,
                      field.value.filter((item: string) => item !== tag)
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </RemoveTag>
              </Tag>
            </Column>
          ))}
          <Column>
            <InputContainer>
              <TagInput
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
              />
              <AddTag
                disabled={!newTag}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue(field.name, [...field.value, newTag]);
                  setNewTag('');
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </AddTag>
            </InputContainer>
          </Column>
        </Row>
        <FormFeedback>{errors[field.name]}</FormFeedback>
      </FormGroup>
    </div>
  );
};

const TagsField = (props: any) => (
  <Field {...props} component={StandardInput} />
);

export default TagsField;
