import { FC, useState } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { ElementsEditor } from '../../../components/elements/ElementsEditor';
import { InputField, TextAreaField } from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import styled from '@emotion/styled';
import { OrganizationModel, PageModel } from '@nimles/models';
import { ImageButton, ImagePlaceholder } from '../../../components/ImageButton';
import { ImagePreview } from '../../../components/images';
import { SelectImageModal } from '../../../components/SelectImageModal';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';
import { SlateField } from '../../../components/fields/SlateField';

const validationSchema = function () {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, `Title has to be at least 2 characters`)
      .required('Title is required'),
  });
};

const localizedValues = {
  en: '',
  sv: '',
};

interface Props {
  page: PageModel;
  organizations: OrganizationModel[];
  onSave: (page: any, publish: boolean) => Promise<void>;
}
export const WordCount = styled.div`
  text-align: right;
  font-size: 12px;
`;

export const PageEditor: FC<Props> = ({ page, organizations, onSave }) => {
  const [publish, setPublish] = useState(false);
  const [isImageOpen, setImageOpen] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const page = {
        ...values,
      };
      await onSave(page, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: page.id,
    title: page.title || '',
    longTitle: page.longTitle || '',
    titleLocalized: { ...localizedValues, ...page.titleLocalized },
    summary: page.summary || '',
    summaryLocalized: { ...localizedValues, ...page.summaryLocalized },
    description: page.description || '',
    descriptionLocalized: {
      ...localizedValues,
      ...page.descriptionLocalized,
    },
    uniqueName: page.uniqueName || '',
    pageType: page.pageType || '',
    elements: page.elements || [],
    organizationId: page.organizationId,
    imageId: page.imageId,
    imageData: '',
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => {
        const imageValue = values.imageId || values.imageData;
        return (
          <Row>
            <Col lg="12">
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <EditorActions
                  onPublish={() => {
                    setPublish(true);
                    submitForm();
                  }}
                  onSave={() => {
                    setPublish(false);
                    submitForm();
                  }}
                  onReset={handleReset}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                />
                <fieldset>
                  {values.id && (
                    <InputField name="id" label="Id" disabled required />
                  )}
                  <OrganizationSelectField
                    organizations={organizations}
                    defaultOrganizationId={page.organizationId}
                  />
                  <LocalizedField label="Title" name="title">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <LocalizedField label="Long title" name="longTitle">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <InputField name="uniqueName" label="Unique name" />

                  <LocalizedField label="Summary" name="summary">
                    {({ localizedName }) => (
                      <>
                        <TextAreaField name={localizedName} />
                        <WordCount>{values.summary?.length || 0}</WordCount>
                      </>
                    )}
                  </LocalizedField>
                  <LocalizedField
                    label="Description"
                    name="description"
                    translator
                  >
                    {({ localizedName }) => <QuillField name={localizedName} />}
                  </LocalizedField>

                  <Label>Image</Label>
                  <ImageButton
                    onClick={(e) => {
                      e.preventDefault();
                      setImageOpen(true);
                    }}
                  >
                    {imageValue ? (
                      <ImagePreview value={imageValue} />
                    ) : (
                      <ImagePlaceholder>Select Image</ImagePlaceholder>
                    )}
                  </ImageButton>
                </fieldset>
                <fieldset>
                  <legend>Content</legend>
                  <SlateField name="elements" />
                </fieldset>
                <fieldset>
                  <legend>Elements</legend>
                  <ElementsEditor
                    type="Page"
                    elements={values.elements}
                    onChange={(elements) => setFieldValue('elements', elements)}
                  />
                </fieldset>
              </Form>
              <SelectImageModal
                title="Select Image"
                isOpen={isImageOpen}
                onToggle={() => setImageOpen(!isImageOpen)}
                onSelect={(image) => {
                  if (image.id) {
                    setFieldValue('imageId', image.id);
                    setFieldValue('imageData', undefined);
                  } else {
                    setFieldValue('imageId', undefined);
                    setFieldValue('imageData', image.uri);
                  }
                  setImageOpen(false);
                }}
              />
            </Col>
          </Row>
        );
      }}
    />
  );
};
