import {
  InputField,
  ReactSelectField,
  SelectField,
  TextAreaField,
} from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import { EditorActions } from '../../../components/Editor';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import { Button, Column, Row } from '@nimles/react-web-components';
import {
  CategoryModel,
  ProductGroupModel,
  ProductPropertyModel,
} from '@nimles/models';
import { ImageIdField } from '../../../components/fields/ImageIdField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  productGroup: ProductGroupModel;
  productGroups: ProductGroupModel[];
  productProperties: ProductPropertyModel[];
  categories: CategoryModel[];
  onSave: (product: any, publish: boolean) => Promise<void>;
}

const LocalizedFields = {
  en: '',
  sv: '',
};

export const ProductGroupEditor: FC<Props> = ({
  productGroup,
  productGroups,
  productProperties,
  categories,
  onSave,
}) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const product = {
        ...values,
      };
      await onSave(product, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: ProductGroupModel = productGroup && {
    id: productGroup.id,
    name: productGroup.name || '',
    nameLocalized: {
      ...LocalizedFields,
      ...productGroup.nameLocalized,
    },
    longName: productGroup.longName || '',
    longNameLocalized: {
      ...LocalizedFields,
      ...productGroup.longNameLocalized,
    },
    additionalName: productGroup.additionalName || '',
    additionalNameLocalized: {
      ...LocalizedFields,
      ...productGroup.additionalNameLocalized,
    },
    summary: productGroup.summary || '',
    summaryLocalized: { ...LocalizedFields, ...productGroup.summaryLocalized },
    description: productGroup.description || '',
    descriptionLocalized: {
      ...LocalizedFields,
      ...productGroup.descriptionLocalized,
    },
    uniqueName: productGroup.uniqueName || '',
    categoryId: productGroup.categoryId,
    imageId: productGroup.imageId,
    productPropertyIds: productGroup.productPropertyIds || [],
    parentId: productGroup.parentId,
    texts: productGroup.texts || [],
  };

  return productGroup ? (
    <Formik<ProductGroupModel>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        submitForm,
      }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onPublish={() => {
                setPublish(true);
                submitForm();
              }}
              onSave={() => {
                setPublish(false);
                submitForm();
              }}
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Information</legend>
                  {values.id && (
                    <InputField name="id" label="Id" disabled={true} />
                  )}
                  <LocalizedField label="Name" name="name">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <LocalizedField label="Long name" name="longName">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <InputField name="uniqueName" label="Unique name" />
                  <LocalizedField label="Additional name" name="additionalName">
                    {({ localizedName }) => <InputField name={localizedName} />}
                  </LocalizedField>
                  <SelectField
                    name="categoryId"
                    label="Category"
                    options={categories}
                    optionName={(o) => o.name}
                    optionValue={(o) => o.id}
                  />
                  <SelectField
                    name="parentId"
                    label="Parent"
                    options={productGroups}
                    optionName={(o) => o.name}
                    optionValue={(o) => o.id}
                  />
                </fieldset>
              </Column>

              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Images</legend>
                  <ImageIdField name="imageId" />
                </fieldset>
              </Column>
            </Row>
            <LocalizedField label="Summary" name="summary">
              {({ localizedName }) => (
                <TextAreaField name={localizedName} wordCount />
              )}
            </LocalizedField>
            <LocalizedField label="Description" name="description">
              {({ localizedName }) => <QuillField name={localizedName} />}
            </LocalizedField>

            <fieldset>
              <legend>Texts</legend>
              <FieldArray
                name="texts"
                render={(arrayHelpers) => (
                  <div>
                    {values.texts?.map((text, index) => (
                      <div key={index}>
                        <LocalizedField name={`texts.${index}.value`}>
                          {({ localizedName }) => (
                            <QuillField name={localizedName} />
                          )}
                        </LocalizedField>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          value: '',
                          values: {},
                        })
                      }
                    >
                      Add a Text
                    </Button>
                  </div>
                )}
              />
            </fieldset>
            <ReactSelectField
              name="productPropertyIds"
              label="Product properties"
              placeholder="Select product properties"
              options={productProperties}
              optionLabel={(o) => `${o.name}`}
              optionValue={(o) => o.id}
              isMulti
            />
          </Form>
        );
      }}
    />
  ) : null;
};
