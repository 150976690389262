import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { useOrganizations } from '../../../hooks/organizations';
import { usePosts } from '../../../hooks/posts';

export const Posts: FC = () => {
  const { posts } = usePosts();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Posts
              <div className="card-header-actions">
                <Link to="/cms/posts/new" className="btn btn-sm btn-primary">
                  New Post
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Title</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {posts &&
                  posts.map((post) => {
                    const postLink = `/cms/posts/${post.id}`;
                    return (
                      <tr key={post.id?.toString()}>
                        <th scope="row">
                          <Link to={postLink}>{post.id}</Link>
                        </th>
                        <td>
                          <Link to={postLink}>{post.title}</Link>
                        </td>
                        <td>{getOrganizationName(post.organizationId)}</td>
                        <td>{post.postType}</td>
                        <td>{post.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
