import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { PipelineEditor } from './PipelineEditor';
import { useParams } from 'react-router';
import { updatePipeline } from '@nimles/react-redux';
import { useOrganizations } from '../../../hooks/organizations';
import { usePipelines } from '../../../hooks/pipelines';

interface Params {
  pipelineId: string;
}

export const Pipeline: FC = () => {
  const dispatch = useDispatch();
  const { organizations } = useOrganizations();

  const { pipelineId } = useParams<Params>();
  const { pipelines } = usePipelines();
  const pipeline = pipelines.find((r) => r.id === pipelineId);

  const onSave = async (values: any) => {
    await dispatch(updatePipeline({ ...pipeline, ...values }));
  };

  return pipeline ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>{pipeline.name}</strong>
        </CardHeader>
        <CardBody>
          <PipelineEditor
            pipeline={pipeline}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
