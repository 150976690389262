import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useParams } from 'react-router';
import { updateText, setTextAccessibility } from '@nimles/react-redux';
import { TextEditor } from './TextEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { useTexts } from '../../../hooks/texts';

export const Text = () => {
  const dispatch = useDispatch();
  const { textId } = useParams<{ textId: string }>();

  const { texts } = useTexts();
  const text = texts.find((l) => l.id === textId);

  const onSave = async (values: any) => {
    await dispatch(updateText({ ...values }));
  };

  const setAccessibility = async (accessibility: any) => {
    text?.id && (await dispatch(setTextAccessibility(text.id, accessibility)));
  };

  return text ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit Text</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={text.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <TextEditor text={text} onSave={(values: any) => onSave(values)} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
