import React, { FC, useState } from 'react';
import {
  Col,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from 'reactstrap';
import { Row, Textarea } from '@nimles/react-web-components';
import classnames from 'classnames';
import { DesignEditor } from './DesignEditor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import elementTemplates from './elementTemplates';
import { LocalizedValue } from '../LocalizedValue';
import { SlateEditor } from '../slate/SlateEditor';
import styled from '@emotion/styled';
import { ElementModel } from '@nimles/models';
import { PropertyEditor } from './PropertyEditor';
import { ImageEditor } from '../images';

interface ContentProps {
  element: ElementModel;
  onChange: (
    value?: string | null,
    culture?: string | null,
    children?: any[]
  ) => void;
}

const Content: FC<ContentProps> = ({ element, onChange }) => {
  switch (element.type) {
    case 'Heading1':
    case 'Heading2':
    case 'Heading3':
    case 'Heading4':
    case 'Heading5':
    case 'Heading6':
    case 'NavButton':
    case 'Link':
    case 'ExternalLink':
      return (
        <LocalizedValue model={element} onChange={onChange} selector>
          {({ value, onChange }) => (
            <Input
              value={value}
              onChange={(e) => {
                e.preventDefault();
                onChange(e.target.value);
              }}
            />
          )}
        </LocalizedValue>
      );
    case 'Text':
      return (
        <LocalizedValue model={element} onChange={onChange} selector>
          {({ value, onChange }) => (
            <Input
              type="textarea"
              value={value}
              onChange={(e) => {
                e.preventDefault();
                onChange(e.target.value);
              }}
            />
          )}
        </LocalizedValue>
      );
    case 'Html':
      return (
        <LocalizedValue model={element} onChange={onChange} selector>
          {({ value, onChange }) => (
            <>
              <ReactQuill value={value} onChange={(value) => onChange(value)} />
              <p />
              <p>HTML</p>
              <Textarea value={value} disabled />
            </>
          )}
        </LocalizedValue>
      );
    case 'Slate':
      return (
        <SlateEditor
          value={element.children}
          onChange={(value) => onChange(null, null, value)}
        />
      );
    case 'Image':
      return (
        <ImageEditor
          value={element.content}
          onChange={(image) => onChange(image.id || image.uri)}
        />
      );
    case 'Svg':
      return (
        <ImageEditor
          mimeTypes={['image/svg+xml']}
          value={element.content}
          onChange={(image) => onChange(image.id || image.uri)}
        />
      );
    default:
      return null;
  }
};

const PropertiesButton = styled.button`
  border: none;
  background: transparent;
  outline: none !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    display: block;
    content: '';
    flex: 1;
    border-bottom: 1px solid grey;
  }
  & span {
    display: inline-block;
    padding: 0 10px;
    text-transform: uppercase;
  }
`;

interface Props {
  element: ElementModel;
  onChange: (element: ElementModel) => void;
}

export const ElementEditor: FC<Props> = ({ element, onChange }) => {
  const [isOpen, setOpen] = useState(false);

  const template = elementTemplates.find((e) => e.type === element.type);
  const propertyTemplates = template && template.properties;

  const [activeTab, setActiveTab] = useState('editor');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const setStyle = (name: string, value: string) => {
    console.log(name, value);
    const oldStyles = element.styles || {};
    onChange({ ...element, styles: { ...oldStyles, [name]: value } });
  };

  const setProperty = (name: string, value?: boolean | string | number) => {
    if (value === '' || value === false) {
      value = undefined;
    }
    onChange({
      ...element,
      properties: { ...element.properties, [name]: value },
    });
  };

  const advancedPropertyTemplates =
    propertyTemplates && propertyTemplates.filter((t) => t.isAdvanced);

  return element ? (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'editor' })}
            onClick={(e) => {
              e.preventDefault();
              toggle('editor');
            }}
          >
            Editor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'design' })}
            onClick={(e) => {
              e.preventDefault();
              toggle('design');
            }}
          >
            Design
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="editor">
          <Row>
            <Col lg="12">
              <FormGroup>
                <Content
                  element={element}
                  onChange={(value: any, culture?: string, children?: any[]) =>
                    children
                      ? onChange({ ...element, children })
                      : culture
                      ? onChange({
                          ...element,
                          contentLocalized: {
                            ...element.contentLocalized,
                            [culture]: value,
                          },
                        })
                      : onChange({ ...element, content: value })
                  }
                />
              </FormGroup>

              <FormGroup check={template?.type === 'checkbox'}>
                <Label>
                  Unique name
                  <Input
                    type="text"
                    name="uniqueName"
                    onChange={(e) =>
                      onChange({ ...element, uniqueName: e.target.value })
                    }
                    value={element.uniqueName || ''}
                  />
                </Label>
              </FormGroup>
              {propertyTemplates &&
                propertyTemplates
                  .filter((t) => !t.isAdvanced)
                  .map((template) => (
                    <PropertyEditor
                      key={template.name}
                      template={template}
                      properties={element.properties}
                      onPropertyChange={setProperty}
                    />
                  ))}
              {advancedPropertyTemplates && (
                <>
                  <PropertiesButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(!isOpen);
                    }}
                    style={{ marginBottom: '1rem' }}
                  >
                    <span>
                      {isOpen
                        ? 'Hide advanced properties'
                        : 'Show advanced properties'}
                    </span>
                  </PropertiesButton>
                  <Collapse isOpen={isOpen}>
                    {advancedPropertyTemplates.map((template) => (
                      <PropertyEditor
                        key={template.name}
                        template={template}
                        properties={element.properties}
                        onPropertyChange={setProperty}
                      />
                    ))}
                  </Collapse>
                </>
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="design">
          <DesignEditor
            styles={element.styles}
            onStyleChange={setStyle}
            css={element.css}
            onCssChange={(css) => onChange({ ...element, css })}
          />
        </TabPane>
      </TabContent>
    </>
  ) : null;
};
