import { FC, useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers, FieldArray } from 'formik';
import { validate } from '../../_shared/formUtils';
import { ElementsEditor } from '../../../components/elements/ElementsEditor';
import { InputField } from '@nimles/react-web-forms';
import { Fieldset } from '../../../components/Fieldset';
import { EditorActions } from '../../../components/Editor';
import { LayoutModel, OrganizationModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  layout: LayoutModel;
  organizations?: OrganizationModel[];
  onSave: (layout: any, publish: boolean) => Promise<void>;
}

export const LayoutEditor: FC<Props> = ({ layout, organizations, onSave }) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const layout = {
        ...values,
      };
      await onSave(layout, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    id: layout.id,
    name: layout.name || '',
    uniqueName: layout.uniqueName || '',
    hostName: layout.hostName || '',
    layoutType: layout.layoutType,
    elements: layout.elements || [],
    organizationId: layout.organizationId,
    integrations: layout.integrations || [],
  };

  return (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => (
        <Row>
          <Col lg="12">
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onPublish={() => {
                  setPublish(true);
                  submitForm();
                }}
                onSave={() => {
                  setPublish(false);
                  submitForm();
                }}
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
              {values.id && (
                <InputField label="Id" name="id" disabled required />
              )}
              <OrganizationSelectField
                organizations={organizations}
                defaultOrganizationId={layout.organizationId}
              />
              <InputField
                label="Name"
                type="text"
                name="name"
                id="name"
                required
              />
              <InputField
                label="Unique name"
                type="text"
                name="uniqueName"
                id="uniqueName"
                required
              />
              <InputField label="Host name" type="text" name="hostName" />

              <Fieldset legend="Integrations">
                <FieldArray
                  name="integrations"
                  render={(arrayHelpers) => (
                    <div>
                      {values.integrations.map(
                        (integration: any, index: number) => {
                          return (
                            <div key={index}>
                              <Row>
                                <Col flex>
                                  <h4>{integration.name}</h4>
                                  <InputField
                                    name={`integrations.${index}.properties.portalId`}
                                    label="Portal Id"
                                    placeholder="Portal Id"
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                      <Button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({
                            name: 'HubSpot',
                            properties: {
                              portalId: '',
                            },
                          })
                        }
                      >
                        Add Integration
                      </Button>
                    </div>
                  )}
                />
              </Fieldset>
              <Fieldset legend="Elements">
                <ElementsEditor
                  type={values.layoutType}
                  elements={values.elements}
                  onChange={(elements) => setFieldValue('elements', elements)}
                />
              </Fieldset>
            </Form>
          </Col>
        </Row>
      )}
    />
  );
};
