import { UserManagerSettings } from 'oidc-client';

export const constants = {
  name: '',
  tenantId: '',
  locales: ['en', 'sv'],
  defaultLocale: 'en',
  google: {
    apiKey: '',
    trackingId: '',
  },
};

export const localStorageName = 'nimles-portal';
export const localStorageUserKey = 'nimles-portal-user';
export const tenantId = 'c6cd4d44-7a67-4908-9288-8b6babca958e';
export const clientId = 'b6fcd6e2-3d20-4bbe-8ace-b060087edfbc';
export const identityServer = 'https://identity.nimles.com';
const redirectUri = window.location.origin + '/login/response';
const logoutRedirectUri = window.location.origin + '/logout/response';

export const userManagerSettings: UserManagerSettings = {
  authority: identityServer,
  client_id: clientId,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: logoutRedirectUri,
  response_type: 'code',
  scope: 'openid profile email nimles offline_access',
  acr_values: `tenant:${tenantId}`,
  revokeAccessTokenOnSignout: true,
};
