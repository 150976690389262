import { createCategory, publishCategory } from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { CategoryEditor } from './CategoryEditor';
import { useHistory } from 'react-router-dom';
import { useOrganizations } from '../../../hooks/organizations';

export const NewCategory: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();

  const handleSave = async (values: any, publish: boolean) => {
    const category: any = await dispatch(createCategory({ ...values }));
    if (publish) {
      await dispatch(publishCategory(category.id));
    }

    history.push(`/organization-management/categories/${category.id}`);
  };

  const tags = organizations
    .reduce<string[]>(
      (list, org) => (org.tags ? [...list, ...org.tags] : list),
      []
    )
    .reduce<{ name: string; count: number }[]>((items, name) => {
      let item = items.find((i) => i.name === name);
      if (item) {
        item.count++;
      } else {
        items.push({ name, count: 1 });
      }
      return items;
    }, [])
    .sort();

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Category</strong>
        </CardHeader>
        <CardBody>
          <CategoryEditor category={{}} tags={tags} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  );
};
