import React, { useState, FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { ImageProviderSearch } from './ImageSearch';
import { FileModel } from '@nimles/models';
import { ImageEditor } from './images';

interface Props {
  title: string;
  isOpen: boolean;
  onToggle: (open: boolean) => void;
  onSelect: (image: FileModel) => void;
}

export const SelectImageModal: FC<Props> = ({
  title,
  isOpen,
  onToggle,
  onSelect,
}) => {
  const [activeTab, setActiveTab] = useState('editor');

  return (
    <Modal isOpen={isOpen} toggle={() => onToggle(!isOpen)}>
      <ModalHeader toggle={() => onToggle(!isOpen)}>{title}</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'editor' })}
              onClick={() => {
                setActiveTab('editor');
              }}
            >
              Gallery
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'unsplash',
              })}
              onClick={() => {
                setActiveTab('unsplash');
              }}
            >
              Unsplash
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === 'pexels',
              })}
              onClick={() => {
                setActiveTab('pexels');
              }}
            >
              Pexels
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="editor">
            <ImageEditor onChange={onSelect} />
          </TabPane>
          <TabPane tabId="unsplash">
            <ImageProviderSearch provider="unsplash" onSelect={onSelect} />
          </TabPane>
          <TabPane tabId="pexels">
            <ImageProviderSearch provider="pexels" onSelect={onSelect} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};
