import { useEffect, FC, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import {
  AuthenticationState,
  loadCurrentUser,
  loadOrganizations,
  loadTenants,
  selectOrganization,
  setTenantId,
} from '@nimles/react-redux';
import { defaultTheme } from '@nimles/react-web-components';
import { TheLayout } from './containers';
import { RootState } from './redux/types';
import './scss/style.scss';
import { OrganizationModel, TenantModel } from '@nimles/models';
import { LoadScript } from '@react-google-maps/api';
import { User, UserManager } from 'oidc-client';
import {
  clientId,
  localStorageName,
  localStorageUserKey,
  tenantId,
  userManagerSettings,
} from './constants';
import { SIGNIN_USER } from '@nimles/react-redux/dist/identity/actionTypes';
import { initStore } from './redux/init';
import { LoginResponse } from './pages/LoginResponse';
import { Login } from './pages/Login';
import { ProtectedRoute } from './auth/ProtectedRoute';

const store = initStore({ clientId, tenantId, localStorageName });

const user = localStorage.getItem(localStorageUserKey);

export const userManager = new UserManager(userManagerSettings);
if (user) {
  userManager.storeUser(User.fromStorageString(user));
}

const AppContainer: FC = ({ children }) => {
  const dispatch = useDispatch();

  const tenant = useSelector<RootState, TenantModel | undefined>(
    ({ tenants }) => tenants.selected
  );
  const { accessToken } = useSelector<RootState, AuthenticationState>(
    ({ auth }) => auth
  );

  useEffect(() => {
    dispatch(loadTenants());
  }, [dispatch, accessToken]);

  useEffect(() => {
    dispatch(loadCurrentUser(tenantId));
  }, [dispatch]);

  useEffect(() => {
    if (tenant?.id) {
      dispatch(setTenantId(tenant.id));
    }
  }, [tenant, dispatch]);

  const loadAndSelectOrganization = useCallback(
    async (tenant: TenantModel) => {
      const organizations: any = await dispatch(loadOrganizations());
      const organization =
        tenant.defaultOrganizationId &&
        organizations.find(
          (o: OrganizationModel) => o.id === tenant.defaultOrganizationId
        );
      dispatch(selectOrganization(organization));
    },
    [dispatch]
  );

  useEffect(() => {
    if (tenant) {
      loadAndSelectOrganization(tenant);
    }
  }, [tenant, loadAndSelectOrganization]);

  const handleSigninCallback = useCallback(
    async (user: User) => {
      console.log('user', user);
      localStorage.setItem(localStorageUserKey, user.toStorageString());
      await dispatch({
        type: SIGNIN_USER,
        data: {
          accessToken: user.access_token,
          refreshToken: user.refresh_token,
          expires: user.expires_at,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    userManager.signinSilent().then(handleSigninCallback);
  }, [handleSigninCallback]);

  return <>{children}</>;
};

interface Props {
  ssr?: boolean;
}

export const App: FC<Props> = ({ ssr }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <LoadScript googleMapsApiKey="AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw">
        <Provider store={store}>
          <Router>
            <AppContainer>
              <Switch>
                <Route path="/login/response" component={LoginResponse} />
                <Route path="/login" component={Login} />
                <ProtectedRoute path="/" component={TheLayout} />
              </Switch>
            </AppContainer>
          </Router>
        </Provider>
      </LoadScript>
    </ThemeProvider>
  );
};
