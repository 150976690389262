import {
  CardAction,
  CardActions,
  Column,
  Row,
} from '@nimles/react-web-components';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import { DisplayFields } from '../../../components/entities/DisplayFields';
import { useCategories } from '../../../hooks';
import { useOrganizations } from '../../../hooks/organizations';
import { useQuoteRequests } from '../../../hooks/quoteRequests';
import { useUsers } from '../../../hooks/users';
import { QuoteRequestLine } from './QuoteRequestLine';

interface Params {
  quoteRequestId: string;
}

export const QuoteRequest: FC = () => {
  const history = useHistory();
  const { quoteRequestId } = useParams<Params>();

  const { quoteRequests } = useQuoteRequests();
  const { categories } = useCategories();
  const { users } = useUsers();
  const quoteRequest = quoteRequests.find((o) => o.id === quoteRequestId);
  const { organizations } = useOrganizations();

  if (!quoteRequest) {
    return null;
  }

  const organization =
    (quoteRequest.head?.seller?.organizationId &&
      organizations.find(
        (org) => org.id === quoteRequest.head?.seller?.organizationId
      )) ||
    undefined;

  let user;
  if (quoteRequest.head?.buyer?.userId) {
    user =
      users &&
      users.find((user) => user.id === quoteRequest.head?.buyer?.userId);
  }

  const quoteRequestButton = '';

  const { buyer, seller } = quoteRequest?.head || {};

  const category = categories.find(
    ({ id }) => id === quoteRequest.head?.categoryId
  );

  console.log(quoteRequest);
  return quoteRequest ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <CardTitle>{category?.name}</CardTitle>
          <CardSubtitle>{quoteRequest.head?.submittedDate}</CardSubtitle>
          <Row>
            <Column xs={100} sm={50}>
              <h4>Buyer</h4>
              {buyer && (
                <div>
                  <p>
                    Name: {buyer.firstName} {buyer.lastName}
                  </p>
                  <p>Email: {buyer.email}</p>
                  {buyer.deliveryAddress && (
                    <p>
                      Adress: {buyer.deliveryAddress.street},{' '}
                      {buyer.deliveryAddress.postalCode}{' '}
                      {buyer.deliveryAddress.city}
                    </p>
                  )}
                </div>
              )}
              {user && (
                <div>
                  <h5>User</h5>
                  <p>User Id:{user.id}</p>
                  <p>
                    Name: {user.firstName} {user.lastName}
                  </p>
                  <p>Email: {user.email}</p>
                  {user.address && (
                    <p>
                      Adress: {user.address.street}, {user.address.postalCode}{' '}
                      {user.address.city}
                    </p>
                  )}
                </div>
              )}
            </Column>
            <Column xs={100} sm={50}>
              <h4>Seller</h4>
              {seller && (
                <div>
                  <p>Company name: {organization?.name}</p>
                </div>
              )}
            </Column>
          </Row>
          <DisplayFields fields={quoteRequest.fields} />
          {quoteRequestButton}
        </CardHeader>
        <CardBody>
          {quoteRequest.lines?.map((line) => (
            <QuoteRequestLine key={line.id} line={line} />
          ))}
        </CardBody>
        <CardActions>
          <CardAction
            onClick={() =>
              history.push(
                `/ecommerce/quotes/new/?quoteRequestId=${quoteRequest.id}`
              )
            }
          >
            Create Quote
          </CardAction>
        </CardActions>
      </Card>
    </div>
  ) : null;
};
