import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import styled from '@emotion/styled';

interface Props {
  onUpload: (data: string, file: File) => Promise<void>;
}

const UploadArea = styled.div`
  border: 1px dashed lightgrey;
  padding: 5px;
  text-align: center;
  border-radius: 2px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  cursor: pointer;
`;

const getBase64 = (file: File) => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const ImageUpload: FC<Props> = ({ children, onUpload }) => {
  const onDrop = useCallback(
    async (files: File[]) => {
      console.log('Files', files);
      files.forEach(async (file) => {
        const data = files.length && (await getBase64(file));
        if (data) {
          await onUpload(data, file);
        }
      });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children || (
        <UploadArea>
          {isDragActive ? (
            <div>Drop the image here ...</div>
          ) : (
            <div>
              Drag 'n' drop the image here, or click to upload the image
            </div>
          )}
        </UploadArea>
      )}
    </div>
  );
};
