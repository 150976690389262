import styled from '@emotion/styled';
import { useFiles } from '../../hooks/files';

const imageBaseUrl = 'https://media.nimles.com/file';

const StyledObject = styled.svg<{ color?: string }>`
  fill: ${({ color }) => color};
`;

export const ImageObjectPreview = ({ value, ...props }: { value: string }) => {
  const { files } = useFiles();

  const imageId =
    value?.indexOf('/') === 0
      ? value
          ?.split('/')
          .reverse()
          .find((x) => x)
      : value;

  const image = files.find(({ id }) => imageId === id);

  return image ? (
    <StyledObject {...props}>
      <use xlinkHref={`${imageBaseUrl}/${image.tenantId}/${image.id}`}></use>
    </StyledObject>
  ) : null;
};
