import { createStore, compose, combineReducers } from 'redux';
import {
  cartsReducer,
  currentUserReducer,
  organizationsReducer,
  sitesReducer,
  productGroupsReducer,
  productPropertiesReducer,
  productsReducer,
  tenantsReducer,
  usersReducer,
  vehiclesReducer,
  filesReducer,
  ordersReducer,
  paymentOptionsReducer,
  deliveryOptionsReducer,
  paymentsReducer,
  layoutsReducer,
  pagesReducer,
  postsReducer,
  themesReducer,
  clientsReducer,
  authReducer,
  certificatesReducer,
  relationshipsReducer,
  pipelinesReducer,
  categoriesReducer,
  notifications,
  textsReducer,
  recordsReducer,
  realestatesReducer,
  projectsReducer,
  organizationMembersReducer,
  coursesReducer,
  courseworksReducer,
  topicsReducer,
  submissionsReducer,
  subjectsReducer,
  quotesReducer,
  quoteRequestsReducer,
  pricelistsReducer,
  customersReducer,
  productStocksReducer,
  logisticsOptionsReducer,
  discountsReducer,
  reviewGroupsReducer,
  reviewsReducer,
} from '@nimles/react-redux';
import Middleware from './middleware';
import { cultureReducer } from './culture';
import { uiControls } from './uiControls';
import { RootState } from './types';

const version = (state = 0) => {
  return state;
};

const combinedReducer = combineReducers<RootState>({
  //accounting
  records: recordsReducer,
  //ecommerce
  carts: cartsReducer,
  customers: customersReducer,
  deliveryOptions: deliveryOptionsReducer,
  discounts: discountsReducer,
  logisticsOptions: logisticsOptionsReducer,
  orders: ordersReducer,
  quotes: quotesReducer,
  quoteRequests: quoteRequestsReducer,
  paymentOptions: paymentOptionsReducer,
  payments: paymentsReducer,
  pricelists: pricelistsReducer,
  productStocks: productStocksReducer,
  //cms
  layouts: layoutsReducer,
  pages: pagesReducer,
  posts: postsReducer,
  themes: themesReducer,
  texts: textsReducer,
  //crm
  relationships: relationshipsReducer,
  pipelines: pipelinesReducer,
  //identity
  auth: authReducer,
  certificates: certificatesReducer,
  clients: clientsReducer,
  currentUser: currentUserReducer,
  tenants: tenantsReducer,
  users: usersReducer,
  //lms
  courses: coursesReducer,
  courseworks: courseworksReducer,
  topics: topicsReducer,
  subjects: subjectsReducer,
  submissions: submissionsReducer,
  //media
  files: filesReducer,
  //organizastion management
  categories: categoriesReducer,
  organizationMembers: organizationMembersReducer,
  organizations: organizationsReducer,
  sites: sitesReducer,
  //pim
  productGroups: productGroupsReducer,
  productProperties: productPropertiesReducer,
  products: productsReducer,
  reviewGroups: reviewGroupsReducer,
  reviews: reviewsReducer,
  //property management
  vehicles: vehiclesReducer,
  realEstates: realestatesReducer,
  //project management
  projects: projectsReducer,
  //only client
  culture: cultureReducer,
  uiControls,
  notifications,
  version,
});

const createStoreWithMiddleware = compose(Middleware)(createStore);

export const configureStore = (initialState = {}) => {
  return createStoreWithMiddleware(combinedReducer, initialState);
};
