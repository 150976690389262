import { FieldModel } from '@nimles/models';

interface DeliveryOptionTemplate {
  name: string;
  fields: FieldModel[];
}

export const deliveryOptionTemplates: DeliveryOptionTemplate[] = [
  {
    name: 'DHL',
    fields: [
      { name: 'klarna_username', value: '' },
      { name: 'klarna_password', value: '' },
    ],
  },
  {
    name: 'Schenker',
    fields: [
      { name: 'stripe_username', value: '' },
      { name: 'stripe_password', value: '' },
    ],
  },
  {
    name: 'Fortnox',
    fields: [
      { name: 'fortnox_accesstoken', value: '' },
      { name: 'fortnox_clientsecret', value: '' },
    ],
  },
];
