import {
  FileField,
  InputField,
  ReactSelectField,
} from '@nimles/react-web-forms';
import {
  ProductPropertyModel,
  ProductPropertyValueModel,
} from '@nimles/models';
import { QuillField } from './QuillField';
import ColorField from './ColorField';

interface Props {
  name?: string;
  productPropertyValue: ProductPropertyValueModel;
  productProperties: ProductPropertyModel[];
}

export const ProductPropertyField = ({
  name,
  productPropertyValue,
  productProperties,
}: Props) => {
  const productProperty = productProperties.find(
    ({ id }) => id === productPropertyValue.productPropertyId
  );

  switch (productProperty?.propertyType) {
    case 'Number':
      return (
        <InputField
          label={productProperty.name ?? undefined}
          name={`${name}.numberValue`}
          type="number"
        />
      );
    case 'SingleOption':
      return (
        <ReactSelectField
          label={productProperty.name ?? undefined}
          options={productProperty.options ?? []}
          name={`${name}.stringValue`}
          optionLabel={(o) => o.name}
          optionValue={(o) => o.value}
        />
      );
    case 'MultiOption':
      return (
        <ReactSelectField
          label={productProperty.name ?? undefined}
          options={productProperty.options ?? []}
          name={`${name}.values`}
          optionLabel={(o) => o.name}
          optionValue={(o) => o.value}
          isMulti
        />
      );
    case 'MultiLineText':
      return (
        <QuillField label={productProperty.name} name={`${name}.stringValue`} />
      );
    case 'SingleLineText':
      return (
        <InputField
          label={productProperty.name ?? undefined}
          name={`${name}.stringValue`}
        />
      );

    case 'Color':
      return (
        <ColorField label={productProperty.name} name={`${name}.stringValue`} />
      );

    case 'File':
      return (
        <FileField
          label={productProperty.name ?? undefined}
          name={`${name}.stringValue`}
        />
      );

    default:
      return null;
  }
};
