import { TextModel } from '@nimles/models';
import { loadTexts, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useTexts = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<TextModel>>(
    ({ texts }) => texts
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadTexts());
    }
  }, [tenantId, dispatch]);

  return {
    texts: values,
  };
};
