import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { createTheme } from '@nimles/react-redux';
import { useHistory, useLocation } from 'react-router';
import { ThemeEditor } from './ThemeEditor';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useOrganizations } from '../../../hooks/organizations';
import { useThemes } from '../../../hooks/themes';

export const NewTheme: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const themeId = query?.get('themeId?');

  const { themes } = useThemes();
  const template = themes.find((l) => l.id === themeId);

  const onSave = async (values: any) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    const theme: any = await dispatch(
      createTheme({
        ...values,
        elements: removeTempIds(values.elements),
        accessibility: 'Public',
      })
    );
    history.push('/cms/themes/' + theme.id);
  };

  const theme = !themeId
    ? {}
    : !template
    ? null
    : {
        name: `${template.name} - copy`,
        uniqueName: `${template.uniqueName}-copy`,
        styles: JSON.parse(JSON.stringify(template.styles)),
      };

  return theme ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Theme</strong>
        </CardHeader>
        <CardBody>
          <ThemeEditor
            theme={theme}
            organizations={organizations}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
