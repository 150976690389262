import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createReviewGroup } from '@nimles/react-redux';
import { ReviewGroupEditor } from './ReviewGroupEditor';
import { useCategories, useReviewGroups, useReviews } from '../../../hooks';

const reviewGroup = {
  name: '',
  description: '',
  allowedGrantTypes: [],
  allowOfflineAccess: true,
  allowAccessTokensViaBrowser: true,
  secrets: [],
  replyUrls: [],
  logins: [],
};

export const NewReviewGroup: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories } = useCategories();
  const { reviews } = useReviews();
  const { reviewGroups } = useReviewGroups();

  const onSave = async (values: any) => {
    const reviewGroup: any = await dispatch(createReviewGroup({ ...values }));

    history.push('/pim/reviewGroups/' + reviewGroup.id);
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New ReviewGroup</strong>
        </CardHeader>
        <CardBody>
          <ReviewGroupEditor
            reviewGroup={reviewGroup}
            reviewGroups={reviewGroups}
            categories={categories}
            reviews={reviews}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
