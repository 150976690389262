import { FC, useState } from 'react';
import {
  Button,
  CustomInputProps,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Field, FieldProps } from 'formik';
import { SelectImageModal } from '../SelectImageModal';
import { Image } from '../images';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { Column } from '@nimles/react-web-components';

const ReviewImage = styled.div`
  position: relative;
`;

const RemoveImageButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #333;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StandardInput: FC<CustomInputProps & FieldProps> = ({
  field: { value, name }, // { name, value, onChange, onBlur }
  form: { errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div key={name}>
      {props.label ? <Label for={name}>{props.label}</Label> : null}
      <FormGroup>
        {value.map((imageId?: string) => (
          <ReviewImage>
            <RemoveImageButton
              onClick={(e) => {
                e.preventDefault();
                setFieldValue(
                  'imageIds',
                  value.filter((id?: string) => id !== imageId)
                );
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </RemoveImageButton>
            <Image value={imageId} />
          </ReviewImage>
        ))}
        <br />
        <Row alignItems="flex-end">
          <Column flex>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
            >
              Add Image
            </Button>
          </Column>
          <Column></Column>
        </Row>
        <FormFeedback>{errors[name]}</FormFeedback>
      </FormGroup>
      <SelectImageModal
        title="Select Logo"
        isOpen={isModalOpen}
        onToggle={() => setModalOpen(!isModalOpen)}
        onSelect={(image) => {
          if (image.id) {
            setFieldValue(name, value ? [...value, image.id] : [image.id]);
          }
          setModalOpen(false);
        }}
      />
    </div>
  );
};

export const ImageIdsField = (props: any) => (
  <Field {...props} component={StandardInput} />
);
