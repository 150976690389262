import styled from '@emotion/styled';
import { Image } from './Image';
import { ImageInformation } from './ImageInformation';
import { useFiles } from '../../hooks/files';

const ImageContainer = styled.div`
  position: relative;
`;

export const ImagePreview = ({ value }: { value: string }) => {
  const { files } = useFiles();

  const imageId =
    value.indexOf('/') === 0
      ? value
          ?.split('/')
          .reverse()
          .find((x) => x)
      : value;

  const image = files.find(({ id }) => imageId === id);

  return (
    <ImageContainer>
      <ImageInformation image={image} />
      <Image value={value} image={image} background="#efefef" />
    </ImageContainer>
  );
};
