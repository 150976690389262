import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useCourseworks } from '../../../hooks/courseworks';

const courseworkTypes = [
  'Undefined',
  'FirstCoursework',
  'About',
  'Contact',
  'Locations',
  'TermsOfSales',
  'Information',
];

export const Courseworks: FC = () => {
  const history = useHistory();

  const { courseworks } = useCourseworks();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Courseworks
              <div className="card-header-actions">
                <UncontrolledDropdown>
                  <DropdownToggle>New Coursework</DropdownToggle>
                  <DropdownMenu>
                    {courseworkTypes.map((type) => (
                      <DropdownItem
                        key={type}
                        tag={Link}
                        to={`/lms/courseworks/new?type=${type}`}
                      >
                        {type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Type</th>
                  <th scope="col">Accessibility</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {courseworks &&
                  courseworks.map((coursework) => {
                    const courseworkLink = `/lms/courseworks/${coursework.id}`;
                    return (
                      <tr key={coursework.id?.toString()}>
                        <td>
                          <Link to={courseworkLink}>
                            <div>
                              <strong>{coursework.title}</strong>
                            </div>
                            <div>
                              <small>{coursework.id}</small>
                            </div>
                            {/* todo add uniquename */}
                            {/* <div>{coursework.uniqueName}</div> */}
                          </Link>
                        </td>
                        <td>
                          {getOrganizationName(coursework.organizationId)}
                        </td>
                        <td>{coursework.courseworkType}</td>
                        <td>{coursework.accessibility}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle nav>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/lms/courseworks/new?courseworkId=${coursework.id}`
                                  )
                                }
                              >
                                Clone
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
