import {
  setReviewGroupAccessibility,
  updateReviewGroup,
  publishReviewGroup,
} from '@nimles/react-redux';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { ReviewGroupEditor } from './ReviewGroupEditor';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useCategories, useReviewGroups, useReviews } from '../../../hooks';

interface Params {
  reviewGroupId: string;
}

export const ReviewGroup: FC = () => {
  const dispatch = useDispatch();
  const { reviewGroupId } = useParams<Params>();
  const { categories } = useCategories();
  const { reviews } = useReviews();
  const { reviewGroups } = useReviewGroups();
  const reviewGroup = reviewGroups.find((o: any) => o.id === reviewGroupId);

  const onSave = async (values: any, publish: boolean) => {
    await dispatch(updateReviewGroup({ ...reviewGroup, ...values }));
    if (publish) {
      reviewGroup?.id && (await dispatch(publishReviewGroup(reviewGroup.id)));
    }
  };

  const setAccessibility = async (accessibility: string) => {
    reviewGroup?.id &&
      (await dispatch(
        setReviewGroupAccessibility(reviewGroup.id, accessibility)
      ));
  };

  return reviewGroup ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {reviewGroup.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(reviewGroup.createdDate).format('LLL')}
            </div>
          ) : null}
          {reviewGroup.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(reviewGroup.lastModified).format('LLL')}
            </div>
          ) : null}
          {reviewGroup.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(reviewGroup.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>ReviewGroup</strong>
          <div className="card-header-actions">
            <Link
              to={`/pim/reviewGroups/${reviewGroup.id}/permissions`}
              className="btn btn-sm btn-primary"
            >
              Permissions
            </Link>
            <AccessibilityButton
              accessibility={reviewGroup.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ReviewGroupEditor
            reviewGroup={reviewGroup}
            reviewGroups={reviewGroups}
            categories={categories}
            reviews={reviews}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
