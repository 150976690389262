import { FieldModel } from '@nimles/models';
import { startCase } from 'lodash';
import React, { FC, Fragment, useState } from 'react';
import { Button, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import { DisplayProperty } from './DisplayProperty';

interface Props {
  fields: FieldModel[] | null | undefined;
}

export const DisplayFields: FC<Props> = ({ fields }) => {
  const [isOpen, setOpen] = useState(false);

  const fieldGroups:
    | {
        [prop: string]: any[];
      }
    | null
    | undefined =
    fields &&
    fields
      .filter((field: any) => field && field.name && field.value)
      .map((field: any) => {
        const parts = field.name.split('_');
        if (parts.length > 1) {
          return {
            group: parts[0],
            name: parts[1],
            value: field.value,
          };
        }

        return {
          name: field.name,
          value: field.value,
        };
      })
      .sort((a: any, b: any) => {
        const groupA = a.group ? a.group.toUpperCase() : undefined; // ignore upper and lowercase
        const groupB = b.group ? b.group.toUpperCase() : undefined; // ignore upper and lowercase

        if (groupA < groupB) {
          return -1;
        }
        if (groupA > groupB) {
          return 1;
        }

        const nameA = a.name ? a.name.toUpperCase() : undefined; // ignore upper and lowercase
        const nameB = b.name ? b.name.toUpperCase() : undefined; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      .reduce((array, field) => {
        const group = field.group || '';
        const item = array[group];
        if (typeof item === 'undefined') {
          array[group] = [field];
        } else {
          item.push(field);
        }

        return array;
      }, {});

  return fields ? (
    <ListGroup>
      <ListGroupItem>
        <Button
          block
          color="link"
          className="m-0 text-left"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!isOpen);
          }}
          aria-expanded={isOpen}
          aria-controls="collapseThree"
        >
          <h5 className="m-0 p-0">Fields ({fields ? fields.length : 0})</h5>
        </Button>
      </ListGroupItem>
      <Collapse isOpen={isOpen} data-parent="#accordion" id="collapseThree">
        {fieldGroups &&
          Object.entries(fieldGroups).map(([groupName, fields], groupIndex) => {
            return (
              <Fragment key={groupIndex}>
                <ListGroupItem>
                  <strong>{startCase(groupName)}</strong>
                </ListGroupItem>
                {fields.map(
                  (
                    field: { name: any; value: any },
                    fieldIndex?: string | number
                  ) => {
                    return (
                      <ListGroupItem>
                        <DisplayProperty
                          key={fieldIndex}
                          name={startCase(field.name)}
                        >
                          {field.value}
                        </DisplayProperty>
                      </ListGroupItem>
                    );
                  }
                )}
              </Fragment>
            );
          })}
      </Collapse>
    </ListGroup>
  ) : null;
};
