import {
  addCategoryPermission,
  setCategoryAccessibility,
  updateCategory,
  removeCategoryPermission,
  publishCategory,
} from '@nimles/react-redux';
import classnames from 'classnames';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Permissions } from '../../../components/Permissions';
import { CategoryEditor } from './CategoryEditor';
import { Row, Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { useCategories } from '../../../hooks';

interface Params {
  categoryId: string;
}

export const Category: FC = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams<Params>();
  const { categories } = useCategories();
  const { organizations } = useOrganizations();
  const category = categories.find((o) => o.id === categoryId);

  const handleSave = async (values: any, publish: boolean) => {
    await dispatch(updateCategory({ ...category, ...values }));
    if (publish) {
      category?.id && (await dispatch(publishCategory(category.id)));
    }
  };

  const onAddPermission = async (values: any) => {
    category?.id &&
      (await dispatch(addCategoryPermission(category.id, values)));
  };

  const onRemovePermission = async (permissionId: string) => {
    category?.id &&
      (await dispatch(removeCategoryPermission(category.id, permissionId)));
  };

  const setAccessibility = async (accessibility: any) => {
    category?.id &&
      (await dispatch(setCategoryAccessibility(category.id, accessibility)));
  };

  const [activeTab, setActiveTab] = useState('editor');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const tags = organizations
    .reduce<string[]>(
      (list, org) => (org.tags ? [...list, ...org.tags] : list),
      []
    )
    .reduce<{ name: string; count: number }[]>((items, name) => {
      let item = items.find((i) => i.name === name);
      if (item) {
        item.count++;
      } else {
        items.push({ name, count: 1 });
      }
      return items;
    }, [])
    .sort();

  return category ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {category.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(category.createdDate).format('LLL')}
            </div>
          ) : null}
          {category.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(category.lastModified).format('LLL')}
            </div>
          ) : null}
          {category.publishedDate ? (
            <div>
              <strong>Published: </strong>
              {moment(category.publishedDate).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Category</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={category.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'editor' })}
              onClick={() => {
                toggle('editor');
              }}
            >
              Editor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'permissions' })}
              onClick={() => {
                toggle('permissions');
              }}
            >
              Permissions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="editor">
            <CategoryEditor
              category={category}
              tags={tags}
              onSave={handleSave}
            />
          </TabPane>
          <TabPane tabId="permissions">
            <Permissions
              permissions={category.permissions ?? []}
              onAdd={onAddPermission}
              onRemove={onRemovePermission}
            />
          </TabPane>
        </TabContent>
        <CardBody></CardBody>
      </Card>
    </div>
  ) : null;
};
