import { RelationshipModel } from '@nimles/models';
import { loadRelationships, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useRelationships = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<RelationshipModel>>(
    ({ relationships }) => relationships
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadRelationships());
    }
  }, [tenantId, dispatch]);

  return {
    relationships: values,
  };
};
