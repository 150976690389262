import React, { FC, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectTenant, setTenantId, State } from '@nimles/react-redux';
import { RootState } from '../../redux/types';
import CIcon from '@coreui/icons-react';
import { css } from '@emotion/react';
import { TenantModel } from '@nimles/models';

export const TenantHeaderDropdown: FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { values: tenants, selected } = useSelector<
    RootState,
    State<TenantModel>
  >(({ tenants }) => tenants);

  const handleSelectTenant = async (tenant: TenantModel | any) => {
    await dispatch(selectTenant(tenant));
    dispatch(setTenantId(tenant.id));
  };

  return (
    <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle nav>
        <CIcon name="cil-address-card" />
      </DropdownToggle>
      <DropdownMenu>
        {selected ? (
          <DropdownItem
            css={css`
              flex-direction: column;
            `}
            onClick={(e) => {
              e.preventDefault();
              handleSelectTenant(null);
            }}
            active
          >
            <div>
              <div>{selected.name}</div>
              <div>
                <small>{selected.id}</small>
              </div>
            </div>
          </DropdownItem>
        ) : null}
        {tenants
          .filter((tenant) => tenant.id !== selected?.id)
          .slice(0, 10)
          .map((tenant) => {
            return (
              <DropdownItem
                key={'dropdown-tenants-' + tenant.id}
                css={css`
                  flex-direction: column;
                `}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectTenant(tenant);
                }}
              >
                <div>
                  <div>{tenant.name}</div>
                  <div>
                    <small>{tenant.id}</small>
                  </div>
                </div>
              </DropdownItem>
            );
          })}
        {tenants.length > 10 ? (
          <DropdownItem>
            <Link to="/identity/tenants" className="btn btn-sm btn-primary">
              See all
            </Link>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};
