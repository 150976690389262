import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useParams } from 'react-router';
import {
  updateCourse,
  setCourseAccessibility,
  publishCourse,
} from '@nimles/react-redux';
import { CourseEditor } from './CourseEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { useCourses } from '../../../hooks/courses';

interface Params {
  courseId: string;
}

export const Course = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams<Params>();

  const { courses } = useCourses();
  const course = courses.find((l) => l.id === courseId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    await dispatch(
      updateCourse({ ...values, elements: removeTempIds(values.elements) })
    );
    if (publish) {
      course?.id && (await dispatch(publishCourse(course.id)));
    }
  };

  const setAccessibility = async (accessibility: any) => {
    course?.id &&
      (await dispatch(setCourseAccessibility(course.id, accessibility)));
  };

  return course ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Edit Course: {course.courseType}</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={course.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <CourseEditor course={course} onSave={handleSave} />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
