import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useOrganizations } from '../../../hooks/organizations';
import { useTexts } from '../../../hooks/texts';

export const Texts: FC = () => {
  const history = useHistory();

  const { texts } = useTexts();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Texts
              <div className="card-header-actions">
                <Link to="/cms/texts/new" className="btn btn-sm btn-primary">
                  New Text
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Translations</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {texts.map((text) => {
                  const textLink = `/cms/texts/${text.id}`;
                  return (
                    <tr key={text.id?.toString()}>
                      <td>
                        <Link to={textLink}>
                          <div>{text.name}</div>
                          {/* <small>{text.id}</small> */}
                        </Link>
                      </td>
                      <td>{getOrganizationName(text.organizationId)}</td>
                      <td>
                        {text.translations &&
                          Object.entries(text.translations).map(
                            ([culture, translation]) => (
                              <div>
                                <strong>{culture}</strong> {translation}
                              </div>
                            )
                          )}
                      </td>
                      <td>{text.accessibility}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle nav>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                history.push(`/cms/texts/new?textId=${text.id}`)
                              }
                            >
                              Clone
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
