import { configureStore } from '.';

let lastSerialised: any;

interface InitStoreSettings {
  tenantId: string;
  clientId: string;
  localStorageName: string;
}

export const initStore = ({
  tenantId,
  clientId,
  localStorageName,
}: InitStoreSettings) => {
  const localStorageState = localStorage.getItem(localStorageName);

  let initialState = localStorageState ? JSON.parse(localStorageState) : {};

  const currentVersion = 1;

  if (
    !initialState ||
    !initialState.version ||
    initialState.version < currentVersion
  ) {
    initialState = { version: currentVersion };
  }
  const store = configureStore({
    ...initialState,
    auth: {
      ...initialState.auth,
      clientId,
      tenantId,
    },
  });

  store.subscribe(() => {
    const {
      auth,
      currentUser,
      tenants: { selected },
      version,
      uiControls,
      // onboarding,
      courses,
      courseworks,
      submissions,
    } = store.getState();
    const serialized = JSON.stringify({
      auth,
      currentUser,
      tenants: { values: [], selected },
      version,
      uiControls,
      // onboarding,
      courses: {
        ...courses,
        values: courses.values?.filter(
          ({ provider }) => provider?.toLowerCase() === 'google'
        ),
      },
      courseworks,
      submissions,
    });
    if (!lastSerialised || lastSerialised !== serialized) {
      lastSerialised = serialized;
      localStorage.setItem(localStorageName, serialized);
    }
  });

  return store;
};
