import { Column, Row } from '@nimles/react-web-components';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { useOrganizations, useProductGroups } from '../../../hooks';

export const ProductGroups: FC = () => {
  const { productGroups } = useProductGroups();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Column width={100}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> ProductGroups
              <div className="card-header-actions">
                <Link
                  to="/pim/productGroups/new"
                  className="btn btn-sm btn-primary"
                >
                  New ProductGroup
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {productGroups &&
                  productGroups.map((productGroup: any) => {
                    const productGroupLink = `/pim/productGroups/${productGroup.id}`;

                    return (
                      <tr key={productGroup.id.toString()}>
                        <th scope="row">
                          <Link to={productGroupLink}>{productGroup.id}</Link>
                        </th>
                        <td>
                          <Link to={productGroupLink}>{productGroup.name}</Link>
                        </td>
                        <td>
                          {getOrganizationName(productGroup.organizationId)}
                        </td>
                        <td>{productGroup.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Column>
      </Row>
    </div>
  );
};
