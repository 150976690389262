import axios from 'axios';

export interface Translation {
  text: string;
  to: string;
}

interface TranslateResponse {
  translations: Translation[];
}

export async function translate(
  text: string,
  from: string,
  to: string[]
): Promise<Translation[]> {
  const response = await axios.post<TranslateResponse>('/api/translate', {
    text,
    from,
    to,
  });

  const translationResponse: TranslateResponse = response.data;

  return translationResponse.translations;
}
