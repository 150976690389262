import {
  InputField,
  SelectField,
  TextAreaField,
} from '@nimles/react-web-forms';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import { EditorActions } from '../../../components/Editor';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import { Button, Column, Row } from '@nimles/react-web-components';
import { CategoryModel, ReviewGroupModel, ReviewModel } from '@nimles/models';
import { ImageIdField } from '../../../components/fields/ImageIdField';

const validationSchema = function () {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  reviewGroup: ReviewGroupModel;
  reviewGroups: ReviewGroupModel[];
  categories: CategoryModel[];
  reviews: ReviewModel[];
  onSave: (review: any, publish: boolean) => Promise<void>;
}

const LocalizedFields = {
  en: '',
  sv: '',
};

export const ReviewGroupEditor: FC<Props> = ({
  reviewGroup,
  reviewGroups,
  categories,
  reviews,
  onSave,
}) => {
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const review = {
        ...values,
      };
      await onSave(review, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: ReviewGroupModel = reviewGroup && {
    id: reviewGroup.id,
    title: reviewGroup.title || '',
    titleLocalized: {
      ...LocalizedFields,
      ...reviewGroup.titleLocalized,
    },
    longTitle: reviewGroup.longTitle || '',
    longTitleLocalized: {
      ...LocalizedFields,
      ...reviewGroup.longTitleLocalized,
    },
    additionalName: reviewGroup.additionalName || '',
    summary: reviewGroup.summary || '',
    summaryLocalized: { ...LocalizedFields, ...reviewGroup.summaryLocalized },
    description: reviewGroup.description || '',
    descriptionLocalized: {
      ...LocalizedFields,
      ...reviewGroup.descriptionLocalized,
    },
    uniqueName: reviewGroup.uniqueName || '',
    categoryId: reviewGroup.categoryId,
    imageId: reviewGroup.imageId,
    parentId: reviewGroup.parentId,
    texts: reviewGroup.texts || [],
    reviewIds: reviewGroup.reviewIds || [],
    bannerId: reviewGroup.bannerId,
  };

  return reviewGroup ? (
    <Formik<ReviewGroupModel>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        submitForm,
      }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate name="simpleForm">
            <EditorActions
              onPublish={() => {
                setPublish(true);
                submitForm();
              }}
              onSave={() => {
                setPublish(false);
                submitForm();
              }}
              onReset={handleReset}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
            <Row wrap="wrap">
              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Information</legend>
                  {values.id && (
                    <InputField name="id" label="Id" disabled={true} />
                  )}
                  <LocalizedField label="Title" name="title">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <LocalizedField label="Long title" name="longTitle">
                    {({ localizedName }) => (
                      <InputField name={localizedName} required />
                    )}
                  </LocalizedField>
                  <InputField name="uniqueName" label="Unique name" />
                  <SelectField
                    name="categoryId"
                    label="Category"
                    options={categories}
                    optionName={(o) => o.name}
                    optionValue={(o) => o.id}
                  />
                  <SelectField
                    name="parentId"
                    label="Parent"
                    options={reviewGroups}
                    optionName={(o) => o.name}
                    optionValue={(o) => o.id}
                  />
                </fieldset>
              </Column>

              <Column xs={100} lg={50}>
                <fieldset>
                  <legend>Images</legend>
                  <ImageIdField label="Banner" name="bannerId" />
                </fieldset>
              </Column>
            </Row>
            <LocalizedField label="Summary" name="summary">
              {({ localizedName }) => (
                <TextAreaField name={localizedName} wordCount />
              )}
            </LocalizedField>
            <LocalizedField label="Description" name="description">
              {({ localizedName }) => <QuillField name={localizedName} />}
            </LocalizedField>

            <fieldset>
              <legend>Reviews</legend>
              <FieldArray
                name="reviewIds"
                render={(arrayHelpers) => (
                  <div>
                    {values.reviewIds?.map((text, index) => (
                      <div key={index}>
                        <SelectField
                          name={`reviewIds.${index}`}
                          options={reviews}
                          optionName={(o) => o.title}
                          optionValue={(o) => o.id}
                        />
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          value: '',
                          values: {},
                        })
                      }
                    >
                      Add a Text
                    </Button>
                  </div>
                )}
              />
            </fieldset>
            <fieldset>
              <legend>Texts</legend>
              <FieldArray
                name="texts"
                render={(arrayHelpers) => (
                  <div>
                    {values.texts?.map((text, index) => (
                      <div key={index}>
                        <LocalizedField name={`texts.${index}.value`}>
                          {({ localizedName }) => (
                            <QuillField name={localizedName} />
                          )}
                        </LocalizedField>
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          value: '',
                          values: {},
                        })
                      }
                    >
                      Add a Text
                    </Button>
                  </div>
                )}
              />
            </fieldset>
          </Form>
        );
      }}
    />
  ) : null;
};
