import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { ProductPropertyEditor } from './ProductPropertyEditor';
import { createProductProperty } from '@nimles/react-redux';
import { useProductProperties } from '../../../hooks';

const productProperty = {
  name: '',
  description: '',
  allowedGrantTypes: [],
  allowOfflineAccess: true,
  allowAccessTokensViaBrowser: true,
  secrets: [],
  replyUrls: [],
  logins: [],
};

export const NewProductProperty: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productProperties } = useProductProperties();

  const onSave = async (values: any) => {
    const productProperty: any = await dispatch(
      createProductProperty({ ...values })
    );

    history.push('/pim/productProperties/' + productProperty.id);
  };

  console.log(productProperty);
  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New ProductProperty</strong>
        </CardHeader>
        <CardBody>
          <ProductPropertyEditor
            productProperty={productProperty}
            productProperties={productProperties}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
