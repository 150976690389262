import moment from 'moment';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { QuoteModel } from '@nimles/models';
import { compare } from '../../../utils';
import { useOrganizations } from '../../../hooks/organizations';
import { useCategories } from '../../../hooks';
import { useQuotes } from '../../../hooks/quotes';

const getBadge = (status?: string | null) => {
  return status === 'Active'
    ? 'success'
    : status === 'Inactive'
    ? 'secondary'
    : status === 'Pending'
    ? 'warning'
    : status === 'Banned'
    ? 'danger'
    : 'primary';
};

function QuoteRow({ quote: { id, head, lines } }: { quote: QuoteModel }) {
  const { organizations } = useOrganizations();
  const { categories } = useCategories();
  const quoteLink = `/ecommerce/quotes/${id}`;

  const category = categories.find(({ id }) => id === head?.categoryId);

  return head ? (
    <tr key={id && id.toString()}>
      <th scope="row">
        <Link to={quoteLink}>
          <div>{category?.name}</div>
          <div>{head.quoteNumber}</div>
        </Link>
      </th>
      <th scope="row">
        {head.submittedDate && moment(head.submittedDate).format('lll')}
      </th>
      <th scope="row">
        {head.buyer && head.buyer.firstName + ' ' + head.buyer.lastName}
      </th>
      <th scope="row">
        {head.seller?.organizationId &&
          organizations.find((org) => org.id === head.seller?.organizationId)
            ?.name}
      </th>
      <td>{lines?.length}</td>
      <td>
        <Link to={quoteLink}>
          <Badge color={getBadge(head.quoteStatus)}>{head.quoteStatus}</Badge>
        </Link>
      </td>
    </tr>
  ) : null;
}

export const Quotes: FC = () => {
  const { quotes } = useQuotes();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const quoteType = query?.get('quoteType?');

  const selectedQuotes = quoteType
    ? quotes.filter((quote: any) => quote.quoteType === quoteType)
    : quotes;

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Quotes
              <div className="card-header-actions">
                <Link
                  to="/checkout/quotes/new"
                  className="btn btn-sm btn-primary"
                >
                  New Quote
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Quote</th>
                  <th scope="col">Date</th>
                  <th scope="col">Buyer</th>
                  <th scope="col">Seller</th>
                  <th scope="col">Lines</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {selectedQuotes
                  ?.sort(
                    compare(
                      (qr) => qr.head?.submittedDate ?? qr.lastModified,
                      true
                    )
                  )
                  .map((quote: any, index: number) => (
                    <QuoteRow key={index} quote={quote} />
                  ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
