import { CourseworkModel } from '@nimles/models';
import { loadCourseworks, State } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useCourseworks = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<CourseworkModel>>(
    ({ courseworks }) => courseworks
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadCourseworks());
    }
  }, [tenantId, dispatch]);

  return {
    courseworks: values,
  };
};
