import { FC, useMemo } from 'react';
import { Button, Form, FormGroup, Table } from 'reactstrap';
import { Formik, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import { CheckboxField, InputField } from '@nimles/react-web-forms';
import { LocalizedField } from '../../../components/LocalizedField';
import { EditorActions } from '../../../components/Editor';
import {
  PricelistModel,
  OrganizationModel,
  ProductModel,
  ProductPriceModel,
} from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    currency: Yup.string().required('Currency is required'),
  });
};

interface GroupedPrices {
  productId: string;
  prices: ProductPriceModel[];
}

interface GroupedPriceList extends PricelistModel {
  groupedPrices?: GroupedPrices[];
}

interface Props {
  pricelist: PricelistModel;
  organizations: OrganizationModel[];
  products: ProductModel[];
  onSave: (pricelist: PricelistModel) => Promise<void>;
}

export const PricelistEditor: FC<Props> = ({
  pricelist,
  organizations,
  products,
  onSave,
}) => {
  const handleSubmit = async (
    { groupedPrices, ...values }: GroupedPriceList,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<GroupedPriceList>
  ) => {
    try {
      console.info(values);
      const pricelist: PricelistModel = {
        ...values,
        prices: groupedPrices?.reduce(
          (result, group) => [...result, ...group.prices],
          []
        ),
      };
      await onSave(pricelist);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };

  const groupedPrices = useMemo(
    () =>
      pricelist?.prices?.reduce<GroupedPrices[]>((result, price) => {
        if (!price.productId) {
          return result;
        }
        var group = result.find(
          ({ productId }) => productId === price.productId
        );
        if (group) {
          group.prices.push({ ...price });
        } else {
          result.push({ productId: price.productId, prices: [{ ...price }] });
        }
        return result;
      }, []),
    [pricelist]
  );

  const values: GroupedPriceList = pricelist && {
    id: pricelist.id,
    name: pricelist.name || '',
    currency: pricelist.currency || '',
    isGrossPrice: pricelist.isGrossPrice || false,
    groupedPrices: groupedPrices,
    organizationId: pricelist.organizationId,
  };

  return groupedPrices ? (
    <Formik<GroupedPriceList>
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        submitForm,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onSave={() => {
              submitForm();
            }}
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}
          <OrganizationSelectField
            organizations={organizations}
            defaultOrganizationId={pricelist.organizationId}
          />
          <FormGroup>
            <LocalizedField label="Name" name="name">
              {({ localizedName }) => (
                <InputField name={localizedName} required />
              )}
            </LocalizedField>
          </FormGroup>
          <FormGroup>
            <InputField name="currency" label="Currency" />
          </FormGroup>
          <FormGroup>
            <CheckboxField name="isGrossPrice" label="Is Gross Price" />
          </FormGroup>
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">Product/Variant</th>
                <th scope="col" style={{ textAlign: 'right' }}>
                  Price
                </th>
                <th scope="col" style={{ textAlign: 'right' }}>
                  Dicount Price
                </th>
                <th scope="col" style={{ textAlign: 'right' }}>
                  Vat rate (%)
                </th>
              </tr>
            </thead>
            <FieldArray
              name="groupedPrices"
              render={(groupedArrayHelpers) => (
                <tbody>
                  {values.groupedPrices?.map((group, groupIndex) => {
                    const product = products?.find(
                      ({ id }) => id === group.productId
                    );
                    return (
                      <FieldArray
                        name={`groupedPrices.${groupIndex}.prices`}
                        render={(arrayHelpers) => (
                          <>
                            <tr>
                              <th colSpan={5}>
                                {product?.name ?? group.productId}
                              </th>
                            </tr>
                            {group.prices.map((price, index) => {
                              const variant = product?.variants?.find(
                                ({ id }) => id === price.variantId
                              );

                              return (
                                <tr>
                                  <th scope="row">{variant?.name}</th>
                                  <td style={{ textAlign: 'right' }}>
                                    <InputField
                                      type="number"
                                      align="right"
                                      name={`groupedPrices.${groupIndex}.prices.${index}.price`}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <InputField
                                      type="number"
                                      align="right"
                                      name={`groupedPrices.${groupIndex}.prices.${index}.discountPrice`}
                                    />
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    <InputField
                                      type="number"
                                      align="right"
                                      name={`groupedPrices.${groupIndex}.prices.${index}.vatRate`}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            {product?.variants
                              ?.filter(
                                ({ id }) =>
                                  !group.prices.some(
                                    ({ variantId }) => variantId === id
                                  )
                              )
                              .map((variant) => (
                                <tr>
                                  <td>{variant.name}</td>
                                  <td colSpan={3}>
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          productId: product.id,
                                          variantId: variant.id,
                                        })
                                      }
                                    >
                                      +
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}
                      />
                    );
                  })}
                  <tr>
                    <th colSpan={4} scope="column">
                      Not priced products
                    </th>
                  </tr>
                  {products
                    .filter(
                      ({ id }) =>
                        !values.groupedPrices?.some(
                          ({ productId }) => productId === id
                        )
                    )
                    .map((product) => (
                      <tr>
                        <td>{product.name}</td>
                        <td colSpan={3}>
                          <Button
                            type="button"
                            onClick={() =>
                              groupedArrayHelpers.push({
                                productId: product.id,
                                prices: [],
                              })
                            }
                          >
                            +
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            />
          </Table>
        </Form>
      )}
    />
  ) : null;
};
