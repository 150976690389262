import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { createCourse, publishCourse } from '@nimles/react-redux';
import { useHistory, useLocation } from 'react-router';
import { CourseEditor } from './CourseEditor';
import {
  removeTempIds,
  saveImages,
} from '../../../components/elements/elementUtils';
import { CourseModel } from '@nimles/models';
import { useOrganizations } from '../../../hooks/organizations';
import { useCourses } from '../../../hooks/courses';

export const NewCourse: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { organizations } = useOrganizations();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const courseId = query?.get('layoutId?');
  const courseType = query?.get('type');

  const { courses } = useCourses();
  const template = courses.find((l) => l.id === courseId);

  const handleSave = async (values: any, publish: boolean) => {
    await saveImages(values.elements, dispatch, values.organizationId);
    const course: any = await dispatch(
      createCourse({
        ...values,
        elements: removeTempIds(values.elements),
        accessibility: 'Public',
      })
    );
    if (publish) {
      await dispatch(publishCourse(course.id));
    }
    history.push('/lms/courses/' + course.id);
  };

  const course: CourseModel | null | undefined = !courseId
    ? {
        courseType,
      }
    : !template
    ? null
    : {
        courseType: template.courseType,
        name: `${template.name} - copy`,
      };

  return course ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Course: {course.courseType}</strong>
        </CardHeader>
        <CardBody>
          <CourseEditor
            course={course}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
