import { Column, Row } from '@nimles/react-web-components';
import {
  InputField,
  TextAreaField,
  ReactSelectField,
} from '@nimles/react-web-forms';
import { Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { Form, Label } from 'reactstrap';
import * as Yup from 'yup';
import { validate } from '../../_shared/formUtils';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import { EditorActions } from '../../../components/Editor';
import { ImageButton, ImagePlaceholder } from '../../../components/ImageButton';
import { SelectImageModal } from '../../../components/SelectImageModal';
import { CategoryModel } from '@nimles/models';
import { Image } from '../../../components/images';

const LocalizedFields = {
  en: '',
  sv: '',
};

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  category: CategoryModel;
  tags: { name: string; count: number }[];
  onSave: (values: any, publish: boolean) => Promise<void>;
}

export const CategoryEditor: FC<Props> = ({ category, tags, onSave }) => {
  const [isLogoOpen, setLogoOpen] = useState(false);
  const [isBannerOpen, setBannerOpen] = useState(false);
  const [publish, setPublish] = useState(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file, publish);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = category && {
    id: category.id,
    uniqueName: category.uniqueName || '',
    name: category.name || '',
    nameLocalized: { ...LocalizedFields, ...category.nameLocalized },
    longName: category.longName || '',
    longNameLocalized: { ...LocalizedFields, ...category.longNameLocalized },
    summary: category.summary || '',
    summaryLocalized: { ...LocalizedFields, ...category.summaryLocalized },
    description: category.description || '',
    descriptionLocalized: {
      ...LocalizedFields,
      ...category.descriptionLocalized,
    },
    tags: category.tags || '',
    logoId: category.logoId,
    logoData: '',
    bannerId: category.bannerId,
    bannerData: '',
  };

  return category ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
        submitForm,
      }) => {
        const logoValue = values.logoId || values.logoData;
        const bannerValue = values.bannerId || values.bannerData;

        return (
          <>
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <EditorActions
                onPublish={() => {
                  setPublish(true);
                  submitForm();
                }}
                onSave={() => {
                  setPublish(false);
                  submitForm();
                }}
                onReset={handleReset}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />

              <Row wrap="wrap">
                <Column xs={100} lg={50}>
                  <fieldset>
                    <legend>Infomation</legend>
                    {values.id && (
                      <InputField name="id" label="Id" disabled={true} />
                    )}
                    <LocalizedField label="Name" name="name">
                      {({ localizedName }) => (
                        <InputField name={localizedName} required />
                      )}
                    </LocalizedField>
                    <LocalizedField label="Long name" name="longName">
                      {({ localizedName }) => (
                        <InputField name={localizedName} required />
                      )}
                    </LocalizedField>
                    <InputField name="uniqueName" label="Unique name" />
                  </fieldset>
                </Column>

                <Column xs={100} lg={50}>
                  <fieldset>
                    <legend>Images</legend>
                    <Label>Logo</Label>
                    <ImageButton
                      onClick={(e) => {
                        e.preventDefault();
                        setLogoOpen(true);
                      }}
                    >
                      {logoValue ? (
                        <Image value={logoValue} />
                      ) : (
                        <ImagePlaceholder>Select Logo</ImagePlaceholder>
                      )}
                    </ImageButton>
                    <Label>Banner</Label>
                    <ImageButton
                      onClick={(e) => {
                        e.preventDefault();
                        setBannerOpen(true);
                      }}
                    >
                      {bannerValue ? (
                        <Image value={bannerValue} />
                      ) : (
                        <ImagePlaceholder>Select Banner</ImagePlaceholder>
                      )}
                    </ImageButton>
                  </fieldset>
                </Column>
              </Row>
              <LocalizedField label="Summary" name="summary">
                {({ localizedName }) => (
                  <TextAreaField name={localizedName} wordCount />
                )}
              </LocalizedField>
              <LocalizedField label="Description" name="description">
                {({ localizedName }) => <QuillField name={localizedName} />}
              </LocalizedField>
              <ReactSelectField
                name="tags"
                label="Tags"
                placeholder="Select tags"
                options={tags}
                optionLabel={(o) => `${o.name} (${o.count})`}
                optionValue={(o) => o.name}
                onCreateOption={(label) =>
                  setFieldValue('tags', [...values.tags, label])
                }
                isMulti
              />
            </Form>
            <SelectImageModal
              title="Select Logo"
              isOpen={isLogoOpen}
              onToggle={() => setLogoOpen(!isLogoOpen)}
              onSelect={(image) => {
                if (image.id) {
                  setFieldValue('logoId', image.id);
                  setFieldValue('logoData', undefined);
                } else {
                  setFieldValue('logoId', undefined);
                  setFieldValue('logoData', image.uri);
                }
                setLogoOpen(false);
              }}
            />
            <SelectImageModal
              title="Select Banner"
              isOpen={isBannerOpen}
              onToggle={() => setBannerOpen(!isBannerOpen)}
              onSelect={(image) => {
                if (image.id) {
                  setFieldValue('bannerId', image.id);
                  setFieldValue('bannerData', undefined);
                } else {
                  setFieldValue('bannerId', undefined);
                  setFieldValue('bannerData', image.uri);
                }
                setBannerOpen(false);
              }}
            />
          </>
        );
      }}
    />
  ) : null;
};
