import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';

import { useOrganizations } from '../../../hooks/organizations';
import { useProductStocks } from '../../../hooks/productStocks';

export const ProductStocks: FC = () => {
  const { productStocks } = useProductStocks();
  const { organizations } = useOrganizations();

  const getOrganizationName = (organizationId?: string | null) => {
    const organization = organizations.find((o) => o.id === organizationId);

    return organization ? organization.name : organizationId;
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              Product Stocks
              <div className="card-header-actions">
                <Link
                  to="/ecommerce/productStocks/new"
                  className="card-header-action"
                >
                  Add Delivery Option
                </Link>
              </div>
            </CardHeader>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Accessibility</th>
                </tr>
              </thead>
              <tbody>
                {productStocks &&
                  productStocks.map((productStock: any) => {
                    const productStockLink = `/ecommerce/productStocks/${productStock.id}`;

                    return (
                      <tr key={productStock.id && productStock.id.toString()}>
                        <th scope="row">
                          <Link to={productStockLink}>{productStock.id}</Link>
                        </th>
                        <td>
                          <Link to={productStockLink}>{productStock.name}</Link>
                        </td>
                        <td>
                          {getOrganizationName(productStock.organizationId)}
                        </td>
                        <td>{productStock.accessibility}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
