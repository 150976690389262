import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { setDiscountAccessibility, updateDiscount } from '@nimles/react-redux';
import { DiscountEditor } from './DiscountEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Column } from '@nimles/react-web-components';
import moment from 'moment';
import { useOrganizations } from '../../../hooks/organizations';
import { useProducts } from '../../../hooks';
import { useDiscounts } from '../../../hooks/discounts';

interface Params {
  discountId: string;
}

export const Discount: FC = () => {
  const dispatch = useDispatch();
  const { discountId } = useParams<Params>();
  const { discounts } = useDiscounts();
  const discount = discounts.find((p) => p.id === discountId);

  const { organizations } = useOrganizations();
  const { products } = useProducts();

  const handleSave = async (values: any) => {
    await dispatch(updateDiscount({ ...discount, ...values }));
  };

  const setAccessibility = async (accessibility: any) => {
    discount?.id &&
      (await dispatch(setDiscountAccessibility(discount.id, accessibility)));
  };

  return discount && organizations ? (
    <div className="animated fadeIn">
      <Row>
        <Column flex></Column>
        <Column>
          {discount.createdDate ? (
            <div>
              <strong>Created: </strong>{' '}
              {moment(discount.createdDate).format('LLL')}
            </div>
          ) : null}
          {discount.lastModified ? (
            <div>
              <strong>Modified: </strong>
              {moment(discount.lastModified).format('LLL')}
            </div>
          ) : null}
        </Column>
      </Row>
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Discount</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={discount.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <CardBody>
          <DiscountEditor
            discount={discount}
            products={products}
            organizations={organizations}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  ) : null;
};
