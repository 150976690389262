import { Field, Formik, FormikHelpers } from 'formik';
import { FC, Fragment, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { ReactSelectField } from '@nimles/react-web-forms';
import { PermissionModel, UserModel } from '@nimles/models';
import { useUsers } from '../hooks/users';

interface Props {
  permissions: PermissionModel[];
  onAdd: (permission: PermissionModel) => Promise<void>;
  onRemove: (permissionId: string) => Promise<void>;
}

export const Permissions: FC<Props> = ({ permissions, onAdd, onRemove }) => {
  const [isAddOpen, setAddOpen] = useState(false);
  const [isRemoveOpen, setRemoveOpen] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<any>();
  const { users, currentUser } = useUsers();
  const newPermission = {
    role: '',
    userId: '',
    email: '',
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const permission = {
        ...values,
      };
      console.log(permission);
      await onAdd(permission);
      setAddOpen(() => false);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const getUser = (userId: string): UserModel => {
    const user = (
      currentUser?.user ? [currentUser.user, ...users] : users
    ).find((o) => o.id === userId);

    return user || { id: userId };
  };

  return (
    <Fragment>
      <Table responsive hover>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {permissions
            ?.filter((permission) => permission.userId)
            .map((permission) => {
              const user = permission.userId && getUser(permission.userId);
              return user ? (
                <tr key={permission.id?.toString()}>
                  <td>
                    <div>
                      <strong>
                        {user.firstName} {user.lastName}
                      </strong>
                    </div>
                    <div>
                      <small>{user.id}</small>
                    </div>
                    <div>{user.username}</div>
                  </td>
                  <td>{permission.role}</td>
                  <td>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedPermission(permission);
                        setRemoveOpen(true);
                      }}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ) : null;
            })}
        </tbody>
      </Table>
      <Button
        onClick={(e) => {
          e.preventDefault();
          setAddOpen(true);
        }}
      >
        Add
      </Button>
      <Modal isOpen={isAddOpen} toggle={() => setAddOpen(!isAddOpen)}>
        <Formik
          initialValues={newPermission}
          onSubmit={handleSubmit}
          render={({
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            values,
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <ModalHeader toggle={() => setAddOpen(!isAddOpen)}>
                Add permission
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="role">Role</Label>

                  <Field
                    component="select"
                    name="role"
                    className="form-control"
                    required
                  >
                    <option value=""></option>
                    <option value="reader">Reader</option>
                    <option value="contributor">Contributor</option>
                    <option value="owner">Owner</option>
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label for="userId">User</Label>
                </FormGroup>
                {values.email ? (
                  <FormGroup>
                    <Label>User</Label>
                    <div>{values.email}</div>
                  </FormGroup>
                ) : (
                  <ReactSelectField
                    name="userId"
                    label="User"
                    placeholder="Select user"
                    options={users}
                    optionLabel={(o) => o.username}
                    optionValue={(o) => o.id}
                    onCreateOption={(label) => setFieldValue('email', label)}
                    createLabel="Invite"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? 'Adding...' : 'Add'}
                </Button>
                <Button
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
      <Modal isOpen={isRemoveOpen} toggle={() => setRemoveOpen(!isRemoveOpen)}>
        <ModalHeader toggle={() => setRemoveOpen(!isRemoveOpen)}>
          Remove permission
        </ModalHeader>
        <ModalBody>
          <p>Are you sure that you want to remove the permission?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              await onRemove(selectedPermission.id);
              setSelectedPermission(null);
              setRemoveOpen(false);
            }}
          >
            Remove
          </Button>
          <Button
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              setSelectedPermission(null);
              setRemoveOpen(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
