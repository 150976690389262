import { FC } from 'react';
import { Form } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import { InputField } from '@nimles/react-web-forms';
import { FileField } from '../../../components/fields/FileField';
import { EditorActions } from '../../../components/Editor';
import { FileModel } from '@nimles/models';
import { OrganizationSelectField } from '../../../components/fields/OrganizationSelectField';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  file: FileModel;
  organizations: Array<any>;
  onSave: (file: any) => Promise<void>;
}

export const FileEditor: FC<Props> = ({ file, organizations, onSave }) => {
  const handleSubmit = async (
    values: FileModel,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FileModel>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ id: error.message });
    }
  };

  const values: FileModel = file && {
    id: file.id,
    name: file.name || '',
    description: file.description || '',
    uri: file.uri || '',
    organizationId: file.organizationId,
    size: file.size,
    mimeType: file.mimeType || '',
    fileName: file.fileName || '',
  };

  console.log('Uri: ', values.uri);

  return file ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          {values.id && <InputField name="id" label="Id" disabled required />}
          <OrganizationSelectField
            organizations={organizations}
            defaultOrganizationId={file.organizationId}
          />
          <FileField
            name="uri"
            label="Uri"
            onFileChange={(data, file) => {
              setFieldValue('uri', data);
              setFieldValue('mimeType', file.type);
              setFieldValue('size', file.size);
              setFieldValue('fileName', file.name);
              if (!values.name) {
                setFieldValue('name', file.name);
              }
            }}
            disabled={!!values.id}
          />
          <InputField name="mimeType" label="Mimetype" required disabled />
          <InputField name="size" label="Size" required disabled />
          <InputField
            name="fileName"
            label="Original filename"
            required
            disabled
          />
          <InputField name="name" label="Name" required />
          <InputField name="additionalName" label="Alternative text" />
          <InputField name="description" label="Description" />
        </Form>
      )}
    />
  ) : null;
};
