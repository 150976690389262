import {
  addTenantPermission,
  setTenantAccessibility,
  updateTenant,
  removeTenantPermission,
} from '@nimles/react-redux';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { TenantEditor } from './TenantEditor';
import { AccessibilityButton } from '../../../components/AccessibilityButton';
import { Permissions } from '../../../components/Permissions';
import { useOrganizations } from '../../../hooks/organizations';
import { useTenants } from '../../../hooks';
import { useClients } from '../../../hooks/clients';

interface Params {
  tenantId: string;
}

export const Tenant: FC = () => {
  const dispatch = useDispatch();
  const { tenantId } = useParams<Params>();
  const { tenants } = useTenants();
  const tenant = tenants.find((c) => c.id === tenantId);
  const { clients } = useClients();
  const { organizations } = useOrganizations();

  const onSave = async (values: any) => {
    await dispatch(updateTenant({ ...values }));
  };

  const onAddPermission = async (values: any) => {
    await dispatch(addTenantPermission(tenantId, values));
  };

  const onRemovePermission = async (permissionId: string) => {
    await dispatch(removeTenantPermission(tenantId, permissionId));
  };

  const setAccessibility = async (accessibility: any) => {
    await dispatch(setTenantAccessibility(tenantId, accessibility));
  };

  const [activeTab, setActiveTab] = useState('editor');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return tenant ? (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>Tenant</strong>
          <div className="card-header-actions">
            <AccessibilityButton
              accessibility={tenant.accessibility}
              onChange={setAccessibility}
            />
          </div>
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'editor' })}
              onClick={() => {
                toggle('editor');
              }}
            >
              Editor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'permissions' })}
              onClick={() => {
                toggle('permissions');
              }}
            >
              Permissions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="editor">
            <TenantEditor
              tenant={tenant}
              clients={clients}
              organizations={organizations}
              onSave={onSave}
            />
          </TabPane>
          <TabPane tabId="permissions">
            <Permissions
              permissions={tenant.permissions ?? []}
              onAdd={onAddPermission}
              onRemove={onRemovePermission}
            />
          </TabPane>
        </TabContent>
        <CardBody></CardBody>
      </Card>
    </div>
  ) : null;
};
