import React from 'react';
import { CFooter } from '@coreui/react';

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <span>
          <a href="https://nimles.com">Nimles</a> &copy; 2020 Cloud
          Technologies.
        </span>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
