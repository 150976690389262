import { UserModel } from '@nimles/models';
import { loadUsers, State, UserState } from '@nimles/react-redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';

export const useUsers = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.tenantId
  );
  const { values } = useSelector<RootState, State<UserModel>>(
    ({ users }) => users
  );
  const currentUser = useSelector<RootState, UserState>(
    ({ currentUser }) => currentUser
  );

  useEffect(() => {
    if (tenantId) {
      dispatch(loadUsers());
    }
  }, [tenantId, dispatch]);

  return {
    users: values,
    currentUser,
  };
};
