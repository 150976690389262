import styled from '@emotion/styled';

export const Property = styled.div``;

export const PropertyTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 0;
`;

export const PropertyValue = styled.div`
  font-weight: 400;
`;
