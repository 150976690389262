import React, { FC } from 'react';
import {
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Table,
} from 'reactstrap';
import { Formik, FieldArray, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { validate } from '../../../pages/_shared/formUtils';
import {
  InputField,
  SelectField,
} from '@nimles/react-web-forms';
import { EditorActions } from '../../../components/Editor';
import { LocalizedField } from '../../../components/LocalizedField';
import { QuillField } from '../../../components/fields/QuillField';
import { Row, Column } from '@nimles/react-web-components';
import { ProductPropertyModel } from '@nimles/models';
import { useCulture } from '../../../hooks';

const validationSchema = function () {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Name has to be at least 2 characters`)
      .required('Name is required'),
  });
};

interface Props {
  productProperty: ProductPropertyModel;
  productProperties: ProductPropertyModel[];
  onSave: (productProperty: any) => Promise<void>;
}

const propertyTypes: Array<any> = [
  'Number',
  'SingleOption',
  'MultiOption',
  'MultiLineText',
  'SingleLineText',
  'Color',
  'File',
];

export const ProductPropertyEditor: FC<Props> = ({
  productProperty,
  productProperties,
  onSave,
}) => {
  const { defaultLocalizedFields } = useCulture();

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<any>
  ) => {
    try {
      console.info(values);
      const file = {
        ...values,
      };
      await onSave(file);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = productProperty && {
    id: productProperty.id,
    name: productProperty.name || '',
    nameLocalized: {
      ...defaultLocalizedFields,
      ...productProperty.nameLocalized,
    },
    title: productProperty.title || '',
    titleLocalized: {
      ...defaultLocalizedFields,
      ...productProperty.titleLocalized,
    },
    description: productProperty.description || '',
    descriptionLocalized: {
      ...defaultLocalizedFields,
      ...productProperty.descriptionLocalized,
    },
    uniqueName: productProperty.uniqueName || '',
    useAsFilter: productProperty.useAsFilter || false,
    parentId: productProperty.parentId,
    options:
      productProperty.options?.map(
        ({ name = '', nameLocalized, value = '', ...o }) => ({
          ...o,
          name,
          nameLocalized: { ...defaultLocalizedFields, ...nameLocalized },
          value,
        })
      ) || [],
    propertyType: productProperty.propertyType || '',
  };

  return productProperty ? (
    <Formik
      initialValues={values}
      validate={validate(validationSchema)}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <EditorActions
            onReset={handleReset}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
          <Row wrap="wrap">
            <Column xs={100} lg={50}>
              <fieldset>
                <legend>Information</legend>
                {values.id && (
                  <InputField
                    name="id"
                    label="Id"
                    value={values.id}
                    disabled={true}
                  />
                )}
                <LocalizedField label="Name" name="name">
                  {({ localizedName }) => (
                    <InputField name={localizedName} required />
                  )}
                </LocalizedField>
                <LocalizedField label="Title" name="title">
                  {({ localizedName }) => (
                    <InputField name={localizedName} required />
                  )}
                </LocalizedField>
                <InputField
                  name="uniqueName"
                  label="Unique name"
                  placeholder="Unique name"
                  value={values.uniqueName}
                />
              </fieldset>
            </Column>

            <Column xs={100} lg={50}>
              <fieldset>
                <legend>Images</legend>
              </fieldset>
            </Column>
          </Row>
          <LocalizedField label="Description" name="description">
            {({ localizedName }) => <QuillField name={localizedName} />}
          </LocalizedField>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="useAsFilter"
              name="useAsFilter"
              label="Use as Filter"
              required
              valid={!errors.useAsFilter}
              invalid={!!errors.useAsFilter}
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.useAsFilter}
            >
              <FormFeedback>{errors.useAsFilter}</FormFeedback>
            </CustomInput>
          </FormGroup>
          <SelectField
            name="parentId"
            label="Parent"
            options={productProperties}
            optionName={(p) => p.name}
            optionValue={(p) => p.id}
            value={values.parentId}
          />
          <SelectField
            name="propertyType"
            label="Type"
            options={propertyTypes}
            value={values.propertyType}
          />
          {values.propertyType?.indexOf('Option') !== -1 ? (
            <FieldArray
              name="options"
              render={(arrayHelpers) => (
                <fieldset>
                  <legend>Options</legend>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.options &&
                        values.options.length > 0 &&
                        values.options.map((option: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <LocalizedField
                                label="Name"
                                name={`options.${index}.name`}
                              >
                                {({ localizedName }) => (
                                  <InputField name={localizedName} required />
                                )}
                              </LocalizedField>
                            </td>
                            <td>
                              <InputField
                                name={`options.${index}.value`}
                                placeholder="Value"
                              />
                            </td>
                            <td className="nostretch">
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                -
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={3}>
                          <Button
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                value: '',
                                description: '',
                              })
                            }
                          >
                            Add a option
                          </Button>
                        </th>
                      </tr>
                    </tfoot>
                  </Table>
                </fieldset>
              )}
            />
          ) : null}
        </Form>
      )}
    />
  ) : null;
};
