import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import { createReview, publishReview } from '@nimles/react-redux';
import { ReviewEditor } from './ReviewEditor';
import { ReviewModel } from '@nimles/models';
import { useOrganizations } from '../../../hooks/organizations';
import { useProducts } from '../../../hooks/products';
import { useReviewGroups } from '../../../hooks/reviewGroups';

export const NewReview: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations, currentOrganizationId } = useOrganizations();
  const { products } = useProducts();
  const { reviewGroups } = useReviewGroups();
  const handleSave = async (values: any, publish: boolean) => {
    const review: any = await dispatch(createReview({ ...values }));

    if (publish) {
      await dispatch(publishReview(review.id));
    }
    history.push('/pim/reviews/' + review.id);
  };

  const review: ReviewModel = {
    title: '',
    description: '',
    organizationId: currentOrganizationId,
  };

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <i className="icon-note"></i>
          <strong>New Review</strong>
        </CardHeader>
        <CardBody>
          <ReviewEditor
            review={review}
            reviewGroups={reviewGroups}
            organizations={organizations}
            products={products}
            onSave={handleSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};
